import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useParams } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import AppBar from '@mui/material/AppBar';
import Chip from '@mui/material/Chip';

import {
    Popover,
    FormLabel,
    RadioGroup,
    Radio,
    IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import MenuRecursosOperacionais from '../components/menu';
import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

export default function VizualizarProcedimento() {

    const [loading, setLoading] = useState(true);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const classes = useStyles();
    const history = useHistory();
    const { idProcedimento } = useParams();

    const [nome, setNome] = useState('');
    const [descricaoGeral, setDescricaoGeral] = useState('');
    const [instruncoes, setInstruncoes] = useState('');

    const [questoes, setQuestoes] = useState([]);

    const [area, setArea] = useState(0);
    const [tipo, setTipo] = useState(0);
    const [obrigatorio, setObrigatorio] = useState(0);
    const [etapa, setEtapa] = useState(0);
    const [exigirComprovacao, setExigirComprovacao] = useState(0);
    const [arquivos, setArquivos] = useState(null);

    const [falha, setFalha] = useState("");
    const [sucesso, setSucesso] = useState("");

    const handleChangeTipo = (event) => {
        setTipo(event.target.value);
    };

    const handleChangeObrigatorio = (event) => {
        setObrigatorio(event.target.value);
    };

    const handleChangeExigirComprovacao = (event) => {
        setExigirComprovacao(event.target.value);
    };

    const handleChangeEtapa = (event) => {
        setEtapa(event.target.value);
    };

    const handleChangeArea = (event) => {
        setArea(event.target.value);
    };

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/procedimentos/listarporid/" + idProcedimento, {
                headers: headers
            }).then(function (response) {
                setNome(response.data.procedimento.nome)
                setDescricaoGeral(response.data.procedimento.descricao_geral);
                setInstruncoes(response.data.procedimento.instruncoes);
                setArea(response.data.procedimento.area);
                setTipo(response.data.procedimento.tipo)
                setObrigatorio(response.data.procedimento.obrigatorio);
                setFalha(response.data.procedimento.falha);
                setSucesso(response.data.procedimento.sucesso);
                setEtapa(response.data.procedimento.etapa);
                setExigirComprovacao(response.data.procedimento.exigir_comprovacao);
                if (response.data.procedimento.questoes === null)
                    setQuestoes([]);
                else
                    setQuestoes(response.data.procedimento.questoes);


                setArquivos(response.data);


                setLoading(false);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();


    }, []);



    async function baixarArquivo(caminho) {
        try {
            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const response = await api.post("/v1/protected/procedimentos/baixarPdf", { caminho: encodeURIComponent(caminho) }, {
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });

            // Cria um blob com os dados recebidos
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Cria uma URL do blob
            const blobUrl = URL.createObjectURL(blob);

            // Abre uma nova guia com o arquivo PDF
            window.open(blobUrl, '_blank');
        } catch (error) {
            // Trate os erros conforme necessário
            console.log("Erro ao baixar o arquivo: " + error);
        }
    }


    const [botaoClicado, setBotaoClicado] = useState(false);



    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoAtualizar();
        }
    };




    function limparCookies() {
        Cookies.set("c_proc_nome", "");
        Cookies.set("c_proc_descricaoGeral", "");
        Cookies.set("c_proc_instruncoes", "");
        Cookies.set("c_proc_falha", "");
        Cookies.set("c_proc_sucesso", "");

        // Opcional: exibir uma mensagem de confirmação
        console.log("Todos os cookies foram limpos.");
    }



    async function funcaoAtualizar() {


        try {

            console.log("cadastrar chamado");

            const cadastro = {
                descricao_geral: descricaoGeral,
                nome: nome,
                area: area,
                tipo: tipo,
                obrigatorio: obrigatorio,
                exigir_comprovacao: exigirComprovacao,
                etapa: etapa,
                instruncoes: instruncoes,
                sucesso: sucesso,
                falha: falha,
                questoes: questoes,
            }



            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/procedimentos/atualizar/' + idProcedimento + "/" + Cookies.get("id_usuario"), cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                limparCookies();
                alert("Procedimento Atualizado");
                window.location.reload();
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }


    function returnDescricaoQuestao(questao) {
        var texto = "Nome: " + questao.nome + "\nDescrição: " + questao.descricao + "\nTipo Resposta: " +
        (questao.tipo === 0 ? "LIVRE" : "SIM/NÃO");
        return texto;
    }


    const [anchorEl, setAnchorEl] = useState(null);
    const [nomeQuestao, setNomeQuestao] = useState("");
    const [descricaoQuestao, setDescricaoQuestao] = useState("");
    const [tipoQuestao, setTipoQuestao] = useState(0);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setNomeQuestao("");
        setDescricaoQuestao("");
        setTipoQuestao(0);
    };

    const handleAdicionarQuestao = () => {
        const novaQuestao = { nome: nomeQuestao, descricao: descricaoQuestao, tipo: tipoQuestao };
        console.log("Questão adicionada:", novaQuestao);
        setQuestoes([...questoes, novaQuestao]);

        setNomeQuestao("");
        setDescricaoQuestao("");
        setTipoQuestao(0);
    };


      
    const handleRemoverQuestao = (event, questaoRemovida) => {
        // alert("Handle remover dep chamado")
         const novasQuestoes = questoes.filter(
             (questao) => questao.id_questao !== questaoRemovida.id_questao
         );
         setQuestoes(novasQuestoes);
     };
 

    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                Procedimentos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :

                <Grid container spacing={2}
                    alignItems="center"
                    justifyContent="center"
                >

                    <Grid
                        item xs={12}
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent={"flex-start"}
                    >
                        <a href="/gestao/operacional/procedimentos/listar" style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"< Procedimentos | "}</a>
                        <a href={"/gestao/operacional/procedimentos/vizualizar/" + idProcedimento} style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{" Editar Procedimento"}</a>
                    </Grid>


                    <Grid
                        item xs={12}
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent={"center"}
                        style={{ marginRight: 10, marginLeft: 10 }}
                    >


                        <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                            <AppBar position="static" >

                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"


                                >
                                    <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                    <Tab label="Imagens" style={{ backgroundColor: 'green' }} />
                                    <Tab label="Vídeos" style={{ backgroundColor: 'green' }} />
                                    <Tab label="Documentos" style={{ backgroundColor: 'green' }} />

                                </Tabs>
                            </AppBar>

                        </Box>
                    </Grid>


                    <SwipeableViews
                        index={value}
                        onChangeIndex={handleChangeIndex}
                        style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                    >


                        <Grid
                            value={value} index={0}
                            style={{ padding: 10 }}
                            container
                            direction="row"
                            item xs={12}
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                        >

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={12}
                            >
                                <Button style={{ margin: 5 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={salvar}
                                    disabled={botaoClicado}
                                > Atualizar  </Button>
                            </Grid>


                            <Grid item xs={3}
                                container
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="area-selector-label">Área</InputLabel>
                                        <Select
                                            labelId="area-selector-label"
                                            id="area-selector"
                                            value={area}
                                            onChange={handleChangeArea}
                                            label="Área"
                                        >
                                            <MenuItem value={0}>Ordem de Serviço</MenuItem>
                                            <MenuItem value={1}>Ordem de Compra</MenuItem>
                                            <MenuItem value={20}>Recursos Humanos</MenuItem>
                                            <MenuItem value={30}>Recursos Financeiras</MenuItem>
                                            <MenuItem value={40}>Rondas Virtuais</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={3}
                                container
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="Tipo-selector-label">Tipo</InputLabel>
                                        <Select
                                            labelId="Tipo-selector-label"
                                            id="Tipo-selector"
                                            value={tipo}
                                            onChange={handleChangeTipo}
                                            label="Tipo"
                                        >
                                            <MenuItem value={0}>Ánalise</MenuItem>
                                            <MenuItem value={1}>Implementação</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="etapa-selector-label">Etapa</InputLabel>
                                    <Select
                                        labelId="etapa-selector-label"
                                        id="etapa-selector"
                                        value={etapa}
                                        onChange={handleChangeEtapa}
                                        label="Etapa"

                                    >
                                        <MenuItem value={0}>Tratamento Remoto</MenuItem>
                                        <MenuItem value={2}>Ánalise Prévia</MenuItem>
                                        <MenuItem value={3}>Tratamento em Campo</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="obrigatorio-selector-label">Obrigatório</InputLabel>
                                    <Select
                                        labelId="obrigatorio-selector-label"
                                        id="obrigatorio-selector"
                                        value={obrigatorio}
                                        onChange={handleChangeObrigatorio}
                                        label="Obrigatório"
                                    >
                                        <MenuItem value={0}>NÃO</MenuItem>
                                        <MenuItem value={1}>SIM</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="exigir-selector-label">Exigir Comprovação</InputLabel>
                                    <Select
                                        labelId="exigir-selector-label"
                                        id="exigir-selector"
                                        value={exigirComprovacao}
                                        onChange={handleChangeExigirComprovacao}
                                        label="Exigir Comprovação"
                                    >
                                        <MenuItem value={0}>NÃO</MenuItem>
                                        <MenuItem value={1}>SIM</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item xs={11}>
                                <TextField
                                    variant="standard"
                                    name="nome"
                                    label="Nome"
                                    id="nome"
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                    onBlur={(e) => Cookies.set("c_proc_nome", e.target.value, { expires: 7 })}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} style={{ margin: 5 }}>
                                <TextField
                                    variant="standard"
                                    name="descricaoGeral"
                                    label="Descrição Geral"
                                    required
                                    id="descricaoGeral"
                                    value={descricaoGeral}
                                    onChange={(e) => setDescricaoGeral(e.target.value)}
                                    onBlur={(e) => Cookies.set("c_proc_descricaoGeral", e.target.value, { expires: 7 })}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ margin: 5 }}>
                                <TextField
                                    variant="standard"
                                    name="instruncoes"
                                    label="Instrunções"
                                    id="instruncoes"
                                    value={instruncoes}
                                    onChange={(e) => setInstruncoes(e.target.value)}
                                    onBlur={(e) => Cookies.set("c_proc_instruncoes", e.target.value, { expires: 7 })}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                />
                            </Grid>


                            <Grid item xs={12} style={{ margin: 5 }}>
                                <TextField
                                    variant="standard"
                                    name="falha"
                                    label="Resultado de Falha"
                                    id="falha"
                                    value={falha}
                                    onChange={(e) => setFalha(e.target.value)}
                                    onBlur={(e) => Cookies.set("c_proc_falha", e.target.value, { expires: 7 })}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ margin: 5 }}>
                                <TextField
                                    variant="standard"
                                    name="sucesso"
                                    label="Resultado de Sucesso"
                                    id="sucesso"
                                    value={sucesso}
                                    onChange={(e) => setSucesso(e.target.value)}
                                    onBlur={(e) => Cookies.set("c_proc_sucesso", e.target.value, { expires: 7 })}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                />
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={12}
                                style={{ marginTop: 1 }}
                            >
                                <IconButton onClick={handleOpen} color="primary" aria-label="Adicionar Questão">
                                    <AddIcon />
                                    questão
                                </IconButton>

                                <Popover
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                >
                                    <Box sx={{ p: 2, width: 300 }}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Nome"
                                                value={nomeQuestao}
                                                onChange={(e) => setNomeQuestao(e.target.value)}
                                                variant="outlined"
                                                margin="normal"
                                            />
                                            <TextField
                                                label="Descrição"
                                                value={descricaoQuestao}
                                                onChange={(e) => setDescricaoQuestao(e.target.value)}
                                                variant="outlined"
                                                margin="normal"
                                                multiline
                                                rows={3}
                                            />
                                            <FormLabel component="legend" sx={{ mt: 2 }}>
                                                Tipo
                                            </FormLabel>
                                            <RadioGroup
                                                value={tipoQuestao.toString()} // Convertendo o estado para string para ser compatível com o RadioGroup
                                                onChange={(e) => setTipoQuestao(parseInt(e.target.value, 10))} // Corrigindo a ordem do parseInt
                                            >
                                                <FormControlLabel
                                                    value="0" // Valor como string
                                                    control={<Radio />}
                                                    label="Livre"
                                                />
                                                <FormControlLabel
                                                    value="1" // Valor como string
                                                    control={<Radio />}
                                                    label="Sim/Não"
                                                />
                                            </RadioGroup>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                sx={{ mt: 2 }}
                                                onClick={handleAdicionarQuestao}
                                            >
                                                Inserir
                                            </Button>
                                        </FormControl>
                                    </Box>
                                </Popover>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12}
                                style={{ marginTop: 5 }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    align="center"
                                    item xs={11}
                                >

                                    <Autocomplete
                                        id="select-questoes"
                                        options={questoes}
                                        disableCloseOnSelect
                                        multiple
                                        getOptionLabel={(questao) => returnDescricaoQuestao(questao).replace(/<br \/>/g, '\n')}
                                        value={questoes}
                                        filterOptions={(options, { inputValue }) => {
                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                            return options.filter((questao) => {
                                                const cotacaoText = returnDescricaoQuestao(questao).toLowerCase();
                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label=" Questões " multiline minRows={1} />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((questao, index) => (
                                                <Chip
                                                    key={index}
                                                    label={questao.nome + " | Descrição: " + questao.descricao + " | Tipo: " + (questao.tipo === 0 ? "LIVRE" : "SIM/NÃO")}
                                                    style={{
                                                        borderRadius: 15, // Borda arredondada
                                                        backgroundColor: '#e3f2fd', // Cor de fundo azul claro
                                                        margin: '4px', // Espaçamento entre as tags
                                                    }}
                                                    {...getTagProps({ index })}
                                                    onDelete={(event) => handleRemoverQuestao(event, questao)}

                                                />
                                            ))
                                        }
                                    />
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid
                            value={value} index={1}
                            container
                            direction="row"
                            item xs={12}
                            alignItems="center"
                            justifyContent="center"
                        >


                            <Grid item xs={12}
                                container
                                alignItems="center"
                                justifyContent="center"
                            >

                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        {
                                            arquivos.imagem1 &&
                                            <Typography
                                                variant="h5"
                                            >
                                                {"Descrição: " + arquivos.procedimento.descricao_imagem1}
                                            </Typography>
                                        }

                                    </Grid>
                                    <Grid item xs={12}
                                        container
                                        style={{ paddingBottom: 30 }}
                                    >
                                        {arquivos.imagem1 &&
                                            <img
                                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                src={`data:image/jpeg;base64,${arquivos.imagem1}`} alt="Imagem 1" />
                                        }
                                    </Grid>

                                </Grid>


                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}
                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                    >
                                        {
                                            arquivos.imagem2 &&
                                            <Typography
                                                variant="h5"
                                            >
                                                {"Descrição: " + arquivos.procedimento.descricao_imagem2}
                                            </Typography>
                                        }

                                    </Grid>
                                    <Grid item xs={12}
                                        container
                                        style={{ paddingBottom: 30 }}
                                    >
                                        {arquivos.imagem2 &&
                                            <img
                                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                src={`data:image/jpeg;base64,${arquivos.imagem2}`} alt="Imagem 2" />
                                        }
                                    </Grid>

                                </Grid>


                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}
                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                    >
                                        {
                                            arquivos.imagem3 &&
                                            <Typography
                                                variant="h5"
                                            >
                                                {"Descrição: " + arquivos.procedimento.descricao_imagem3}
                                            </Typography>
                                        }

                                    </Grid>
                                    <Grid item xs={12}
                                        container
                                        style={{ paddingBottom: 30 }}
                                    >
                                        {arquivos.imagem3 &&
                                            <img
                                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                src={`data:image/jpeg;base64,${arquivos.imagem3}`} alt="Imagem 3" />
                                        }
                                    </Grid>

                                </Grid>


                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}
                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                    >
                                        {
                                            arquivos.imagem4 &&
                                            <Typography
                                                variant="h5"
                                            >
                                                {"Descrição: " + arquivos.procedimento.descricao_imagem4}
                                            </Typography>
                                        }

                                    </Grid>
                                    <Grid item xs={12}
                                        container
                                    >
                                        {arquivos.imagem4 &&
                                            <img
                                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                src={`data:image/jpeg;base64,${arquivos.imagem4}`} alt="Imagem 4" />
                                        }
                                    </Grid>

                                </Grid>


                            </Grid>

                        </Grid>

                        <Grid
                            value={value} index={2}
                            container
                            direction="row"
                            item xs={12}
                            alignItems="center"
                            justifyContent="center"
                        >



                            <Grid item xs={12}
                                container
                                alignItems="center"
                                justifyContent="center"
                            >

                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        {
                                            arquivos.video1 &&
                                            <Typography
                                                variant="h5"
                                            >
                                                {"Descrição: " + arquivos.procedimento.descricao_video1}
                                            </Typography>
                                        }

                                    </Grid>
                                    <Grid item xs={12}
                                        container
                                        style={{ paddingBottom: 30 }}
                                    >
                                        {arquivos.video1 &&
                                            <video controls width="400" height="300">
                                                <source
                                                    type="video/mp4"  // ajuste o tipo de mídia conforme necessário (por exemplo, video/mp4, video/webm, etc.)
                                                    src={`data:video/mp4;base64,${arquivos.video1}`}
                                                />
                                                Seu navegador não suporta o elemento de vídeo.
                                            </video>

                                        }
                                    </Grid>

                                </Grid>


                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}
                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                    >
                                        {
                                            arquivos.video2 &&
                                            <Typography
                                                variant="h5"
                                            >
                                                {"Descrição: " + arquivos.procedimento.descricao_video2}
                                            </Typography>
                                        }

                                    </Grid>
                                    <Grid item xs={12}
                                        container
                                        style={{ paddingBottom: 30 }}
                                    >
                                        {arquivos.video2 &&
                                            <video controls width="400" height="300">
                                                <source
                                                    type="video/mp4"  // ajuste o tipo de mídia conforme necessário (por exemplo, video/mp4, video/webm, etc.)
                                                    src={`data:video/mp4;base64,${arquivos.video2}`}
                                                />
                                                Seu navegador não suporta o elemento de vídeo.
                                            </video>
                                        }
                                    </Grid>

                                </Grid>


                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}
                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                    >
                                        {
                                            arquivos.video3 &&
                                            <Typography
                                                variant="h5"
                                            >
                                                {"Descrição: " + arquivos.procedimento.descricao_video3}
                                            </Typography>
                                        }

                                    </Grid>
                                    <Grid item xs={12}
                                        container
                                        style={{ paddingBottom: 30 }}
                                    >
                                        {arquivos.video3 &&
                                            <video controls width="400" height="300">
                                                <source
                                                    type="video/mp4"  // ajuste o tipo de mídia conforme necessário (por exemplo, video/mp4, video/webm, etc.)
                                                    src={`data:video/mp4;base64,${arquivos.video3}`}
                                                />
                                                Seu navegador não suporta o elemento de vídeo.
                                            </video>
                                        }
                                    </Grid>

                                </Grid>




                            </Grid>



                        </Grid>

                        <Grid
                            value={value} index={3}
                            container
                            direction="row"
                            item xs={12}
                            alignItems="center"
                            justifyContent="center"
                        >





                            <Grid item xs={12}
                                container
                                alignItems="center"
                                justifyContent="center"
                            >

                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        {
                                            arquivos.arquivo1 &&
                                            <Typography
                                                variant="h5"
                                            >
                                                {"Descrição: " + arquivos.procedimento.descricao_arquivo1}
                                            </Typography>
                                        }

                                    </Grid>
                                    <Grid item xs={12}
                                        container
                                        style={{ paddingBottom: 30 }}
                                    >
                                        {arquivos.arquivo1 &&
                                            <div
                                                onClick={() => {

                                                    baixarArquivo(arquivos.procedimento.caminho_arquivo1);


                                                }}
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                            </div>




                                        }
                                    </Grid>

                                </Grid>


                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}
                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                    >
                                        {
                                            arquivos.arquivo2 &&
                                            <Typography
                                                variant="h5"
                                            >
                                                {"Descrição: " + arquivos.procedimento.descricao_arquivo2}
                                            </Typography>
                                        }

                                    </Grid>
                                    <Grid item xs={12}
                                        container
                                        style={{ paddingBottom: 30 }}
                                    >
                                        {arquivos.arquivo2 &&
                                            <div
                                                onClick={() => {

                                                    baixarArquivo(arquivos.procedimento.caminho_arquivo2);


                                                }}
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                            </div>



                                        }
                                    </Grid>

                                </Grid>


                                <Grid item xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}
                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                    >
                                        {
                                            arquivos.arquivo3 &&
                                            <Typography
                                                variant="h5"
                                            >
                                                {"Descrição: " + arquivos.procedimento.descricao_arquivo3}
                                            </Typography>
                                        }

                                    </Grid>
                                    <Grid item xs={12}
                                        container
                                        style={{ paddingBottom: 30 }}
                                    >
                                        {arquivos.arquivo3 &&
                                            <div
                                                onClick={() => {

                                                    baixarArquivo(arquivos.procedimento.caminho_arquivo2);


                                                }}
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                            </div>

                                        }
                                    </Grid>

                                </Grid>





                            </Grid>

                        </Grid>

                    </SwipeableViews>

                </Grid>
            }

        </div >
    );
}


