import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Chip from '@mui/material/Chip';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import { Button } from "@material-ui/core";



import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';






function novaEquipe() {
    window.location.href = "/gestao/operacional/equipes/cadastrar"

}

export default function Clientes() {



    const [loading, setLoading] = useState(true);
    const [equipes, setEquipes] = useState([]);


    const [height, setHeight] = useState(0);


    async function listarEquipes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/equipes/listartodos", {
                headers: headers
            }).then(function (response) {
                setEquipes(response.data)

                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar equipes: " + _err)

        }

    }


    useEffect(() => {




        listarEquipes();


    }, []);




    const handleClickGerenciar = (event, cellValues) => {


        window.location.href = "/gestao/administracao/clientes/gerenciarcliente/" + cellValues.row.id_cliente;


    };

    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    // Função para obter a descrição do endereço
    function returnDescricaoEndereco(objeto) {
        return ` ${objeto.cidade} UF: ${objeto.estado}`;
    }

    // Função para obter a descrição mais minimalista do colaborador
    function returnDescricaoColaborador(colaborador) {
        const endereco = returnDescricaoEndereco(colaborador);
        return `ID: ${colaborador.id_colaborador}, Nome: ${colaborador.nome} ${colaborador.sobrenome}, Cidade: ${endereco}`;
    }



    const CustomCell = ({ value }) => (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '8px' }}>
            {value.map((descricao, index) => (

                <Chip key={index} label={descricao} />

            ))}
        </div>
    );

    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {
                return (
                    <Grid>


                        <IconButton
                            color="primary"
                            onClick={(event) => handleClickGerenciar(event, cellValues)}
                        >
                            <VisibilityIcon />
                        </IconButton>

                    </Grid>

                )
            },
        },
        {
            headerName: 'ID',
            field: 'id_equipe',
            id: 1,
            width: 30,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Nome',
            field: 'nome',
            id: 2,
            width: 250,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Descrição dos Colaboradores',
            field: 'descricaoColaboradores',
            headerClassName: 'cabecalho_azul',
            id: 3,
            width: 800,
            renderCell: (params) => (
                <CustomCell
                    value={params.row.colaboradores.map((colaborador) => returnDescricaoColaborador(colaborador))}
                />
            ),
        },
    ];




    return (
        <div>

            <NavegadorGestao
                Operacional={true}
                Equipes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />


            <div style={{ padding: 5, width: '100%', height: '100%' }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novaEquipe}
                    >
                        Nova Equipe
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div style={{ height: '100%', width: '100%' }}>
                        <>
                            <DataGrid
                                pagination
                                checkboxSelection
                                rows={equipes}
                                pageSize={20}
                                rowHeight={40}
                                getRowId={(row) => row.id_equipe}
                                columns={columnsDataGrid}
                                disableColumnResize={false}
                                onCellClick={handleCellClick}
                                onRowClick={handleRowClick}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />

                        </>
                    </div>
                }


            </div>



        </div>
    );
}



