import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';

import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Box,
    Tooltip
} from '@mui/material';


import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import icone_clientes from '../../../../../assets/imgs/icone_clientes.png';
import icone_instalacoes from '../../../../../assets/imgs/icone_instalacoes.png';

import EditIcon from '@mui/icons-material/Edit';

import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import TextField from '@material-ui/core/TextField';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
    MaterialReactTable,

} from 'material-react-table';
import HomeIcon from '@mui/icons-material/Home';




function novoCliente() {
    window.location.href = "/gestao/administracao/clientes/cadastrarcliente/"

}

export default function Clientes() {



    const [loading, setLoading] = useState(true);
    const [clientes, setClientes] = useState([]);




    const [idUsuarioAAlterar, setIdUsuarioAlterar] = useState(0);
    const [alertOpen, setAlertOpen] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const [buttonOkDisabled, setButtonOkDisabled] = useState(false);

    const handleOpenAlert = (id) => {
        setIdUsuarioAlterar(id);
        setMensagem('');

        setAlertOpen(true);
    };


    async function handleCloseAlert() {
        setButtonOkDisabled(true);

        try {


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = Cookies.get('id_usuario');

            const response = await api.put('/v1/protected/clientes/mudarStatus/' + id_usuario_logado + "/" + idUsuarioAAlterar + "/" + mensagem,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Colaborador Atualizado com sucesso!")



            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }


        setIdUsuarioAlterar(0);
        setMensagem('');
        setAlertOpen(false);
        setButtonOkDisabled(false);


        listarClientes();

    }


    const [numInstalacoes, setNumInstalacoes] = useState(0);
    const [numClientes, setNumClientes] = useState(0);

    async function listarClientes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/clientes/listarTodos", {
                headers: headers
            }).then(function (response) {

                const updatedData = [];

                var num_clientes = 0;
                var num_instalacoes = 0;

                // Percorre todos os clientes
                response.data.forEach(cliente => {
                    // Adicionar o cliente principal ao novo array
                    updatedData.push({
                        ...cliente,
                        id_apresentacao: cliente.id_instalacao,
                        id_cliente: cliente.id_cliente,
                        status: cliente.status === 1 ? 'ATIVO' : 'DESATIVADO',
                        tipo_identificacao: cliente.tipo_cliente === 0 ? cliente.cpf : cliente.cnpj,
                        nome_pesquisa: cliente.tipo_cliente === 0
                            ? `${cliente.nome || ""} ${cliente.sobrenome || ""}`.trim()
                            : `${cliente.nome_fantasia || ""}${cliente.razao_social ? ` ${cliente.razao_social}` : ""}`.trim(),
                        endereco_completo: `${cliente.logradouro}, nº ${cliente.numero}, ${cliente.bairro}, ${cliente.cidade} ${cliente.estado} CEP: ${cliente.cep}`,
                        identificador_integrador: "", // Sempre vazio para clientes
                        instalacoes: cliente.instalacoes,
                        tipo: 0,
                        tipo_texto: "CLIENTE",
                    });

                    num_clientes++;
                    num_instalacoes += cliente.instalacoes.length;

                    // Adicionar instalações do cliente (se existirem)
                    if (cliente.instalacoes && cliente.instalacoes.length > 0) {
                        const instalacoesModificadas = cliente.instalacoes.map(instalacao => ({
                            ...instalacao,
                            id_apresentacao: instalacao.id_instalacao,
                            id_instalacao: instalacao.id_instalacao,
                            id_cliente: cliente.id_cliente,
                            status: instalacao.status === 1 ? "ATIVADO" : "DESATIVADO",
                            tipo_identificacao: instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj,
                            nome_pesquisa: instalacao.tipo_instalacao === 0
                                ? `${instalacao.nome} ${instalacao.sobrenome}`.trim()
                                : instalacao.nome_fantasia,
                            endereco_completo: `${instalacao.logradouro}, nº ${instalacao.numero}, ${instalacao.bairro}, ${instalacao.cidade} ${instalacao.estado} CEP: ${instalacao.cep}`,
                            identificador_integrador: instalacao.identificador_integrador, // Preserva o valor da instalação
                            tipo: 1,
                            tipo_texto: "CONTA",
                            instalacoes: [],
                        }));

                        // Adicionar as instalações ao novo array
                        updatedData.push(...instalacoesModificadas);
                    }
                });

                setNumClientes(num_clientes);
                setNumInstalacoes(num_instalacoes);
                // Atualizar o estado com o novo array combinado
                setClientes(updatedData);

                setLoading(false);

            });



        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar clientes: " + _err)

        }

    }


    useEffect(() => {




        listarClientes();


    }, []);




    const handleClickGerenciar = (cellValues) => {

        if (parseInt(cellValues.tipo, 10) === 0) {
            window.location.href = "/gestao/administracao/clientes/gerenciarcliente/" + cellValues.id_cliente;
        } else {
            window.location.href = "/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/" + cellValues.id_instalacao + "/" + cellValues.id_cliente;
        }

    };

    const handleClickGerenciarInstalacao = (event, row) => {

        window.location.href = "/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/" + row.id_instalacao + "/" + row.id_cliente;

    };







    const columns = useMemo(
        () => [

            {
                accessorKey: 'id_apresentacao', //normal accessorKey
                header: 'ID',
                size: 60,
            },
            {
                accessorKey: 'tipo_texto', // Nome da chave no objeto de dados
                header: 'Tipo',
                size: 100,
                Cell: ({ cell }) => {
                    const value = cell.getValue();
                    return value === 'CLIENTE' ? (
                        <PersonOutlineIcon />
                    ) : value === 'CONTA' ? (
                        <HomeIcon />
                    ) : null;
                },
            },

            {
                accessorKey: 'status', //normal accessorKey
                header: 'STATUS',
                size: 100,
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span
                            style={{
                                padding: "5px 10px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                                backgroundColor: renderedCellValue === "ATIVADO" ? "#b2fab4" : "#fbb4b4", // Verde médio ou vermelho médio
                                color: renderedCellValue === "ATIVADO" === 1 ? "#2e7d32" : "#c62828", // Verde escuro ou vermelho escuro
                                fontWeight: "bold",
                                fontSize: 12,
                            }}
                        >
                            {renderedCellValue}
                        </span>
                    )

                },

            },
            {
                accessorKey: 'identificador_integrador', //normal accessorKey
                header: 'SIGMA ID',
                size: 100,
            },

            {
                header: 'CPF/CNPJ',
                size: 150,
                accessorKey: 'tipo_identificacao',
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span >
                            {renderedCellValue}
                        </span>
                    )
                },
            },
            {
                header: 'Grupo Cliente',
                accessorKey: 'grupo_cliente.nome',
                size: 150,
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span>
                            {renderedCellValue}
                        </span>
                    )

                },
            },

            {
                header: 'Nome',
                accessorKey: 'nome_pesquisa',
                size: 350,
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span>
                            {renderedCellValue}
                        </span>
                    )

                },
            },

            {
                header: 'Endereço',
                accessorKey: 'endereco_completo',
                size: 450,
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span style={{ fontSize: 12 }} >
                            {
                                renderedCellValue
                            }
                        </span>
                    )

                },
            },


            {
                header: 'Estado',
                accessorKey: 'estado',
                size: 80,
            },

            {
                header: '',
                accessorKey: 'instalacao_pesquisa',
                Cell: () => null, // Não renderiza conteúdo visível na célula
                style: {
                    width: '1px', // Largura mínima
                    visibility: 'hidden', // Torna a coluna invisível
                    display: 'none', // Esconde a coluna completamente
                },
            },


        ],
        [],
    );




    const columnsInstalacoes = useMemo(
        () => [

            {
                accessorKey: 'id_instalacao', //normal accessorKey
                header: 'ID',
                size: 60,
            },
            {
                accessorKey: 'identificador_integrador', //normal accessorKey
                header: 'SIGMA ID',
                size: 60,
            },
            {
                accessorKey: 'status_instalacao', //normal accessorKey
                header: 'STATUS',
                size: 100,
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span
                            style={{
                                padding: "5px 10px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                                backgroundColor: renderedCellValue === "ATIVADO" ? "#b2fab4" : "#fbb4b4", // Verde médio ou vermelho médio
                                color: renderedCellValue === "ATIVADO" === 1 ? "#2e7d32" : "#c62828", // Verde escuro ou vermelho escuro
                                fontWeight: "bold",
                                fontSize: 12,
                            }}
                        >
                            {renderedCellValue}
                        </span>
                    )

                },

            },
            {
                accessorKey: 'grupo_cliente.nome', //normal accessorKey
                header: 'Grupo Cliente',
                size: 60,
            },
            {
                accessorKey: 'identificacao', //normal accessorKey
                header: 'IDENTIFICAÇÃO',
                size: 200,
            },

            {
                accessorKey: 'nome_instalacao', //normal accessorKey
                header: 'NOME/NOME FANTASIA',
                size: 200,
            },

            {
                accessorKey: 'estado', //normal accessorKey
                header: 'Estado',
                size: 400,
            },



        ],
        [],
    );




    return (
        <div>

            <NavegadorGestao
                BaseDados={true}
                Clientes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ padding: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >


                    <Grid
                        container
                        direction="row"
                        alignItems="flex-start"
                        item
                        xs={3}
                        justifyContent={"flex-start"}
                        style={{ backgroundColor: '#2F4F4F', padding: 5, margin: 5 }}
                    >
                        <img
                            alt={"icone"}
                            style={{
                                textAlign: 'center',
                                width: '35px', // Define a largura da imagem
                                height: '35px' // Define a altura da imagem
                            }}
                            src={icone_clientes}
                        />

                        {/* Container para alinhar os textos */}
                        <div style={{ textAlign: "center" }}>
                            {/* Número de instalações */}
                            <span
                                style={{
                                    fontSize: 30,
                                    color: 'white',
                                    fontWeight: 'bold',
                                    marginLeft: '5px'
                                }}
                            >
                                {numClientes}
                            </span>

                            {/* Texto 'instalações' */}
                            <span
                                style={{
                                    fontSize: 30,
                                    color: 'white',
                                    fontWeight: 'bold',
                                    marginLeft: '2px',
                                }}
                            >
                                clientes
                            </span>
                        </div>
                    </Grid>


                    <Grid
                        container
                        direction="row"
                        alignItems="flex-start"
                        item
                        xs={4}
                        justifyContent={"flex-start"}
                        style={{ backgroundColor: '#720309', padding: 5, margin: 5 }}
                    >
                        <img
                            alt={"icone"}
                            style={{
                                textAlign: 'center',
                                width: '35px', // Define a largura da imagem
                                height: '35px' // Define a altura da imagem
                            }}
                            src={icone_instalacoes}
                        />

                        {/* Container para alinhar os textos */}
                        <div style={{ textAlign: "center" }}>
                            {/* Número de instalações */}
                            <span
                                style={{
                                    fontSize: 30,
                                    color: 'white',
                                    fontWeight: 'bold',
                                    marginLeft: '5px'
                                }}
                            >
                                {numInstalacoes}
                            </span>

                            {/* Texto 'instalações' */}
                            <span
                                style={{
                                    fontSize: 26,
                                    color: 'white',
                                    fontWeight: 'bold',
                                    marginLeft: '2px',
                                }}
                            >
                                contas/instalações
                            </span>
                        </div>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        item xs={2}
                    >

                        <Button style={{ margin: 5 }}
                            variant="contained"
                            color="primary"
                            onClick={novoCliente}
                        >
                            Novo Cliente
                        </Button>
                    </Grid>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        {clientes && (
                            <MaterialReactTable
                                columns={columns}
                                data={clientes}
                                enableGlobalFilterModes
                                enablePagination={true}
                                enableRowActions
                                paginationDisplayMode={'pages'}
                                getRowId={(row) => row.id_apresentacao}
                                enableTopToolbar={true}
                                enableBottomToolbar={true}
                                enableColumnFilters={true}
                                enableColumnActions={false}
                                sortable={true}
                                enableSorting={true}
                                enableRowSelection={false}


                                enableColumnFilterModes={true}
                                enableExpandAll={true}
                                enableColumnPinning={true}
                                enableColumnResizing={true}




                                initialState={{
                                    pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe aqui
                                    showGlobalFilter: true,
                                    density: 'compact',
                                }}
                                muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                muiPaginationProps={{
                                    rowsPerPageOptions: [50, 100, 200],
                                    variant: 'outlined',
                                }}
                                enableFullScreenToggle={true}
                                renderDetailPanel={({ row }) => (
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            left: '1px',
                                            maxWidth: '3000px',
                                            position: 'sticky',
                                            width: '100%',
                                        }}
                                    >

                                        <MaterialReactTable
                                            columns={columnsInstalacoes}
                                            data={row.original.instalacoes.map(item => {
                                                item.status_instalacao = item.status === 1 ? "ATIVADO" : "DESATIVADO";
                                                item.identificacao = item.tipo_instalacao === 0 ? item.cpf : item.cnpj;
                                                item.nome_instalacao = item.tipo_instalacao === 0 ? (item.nome + " " + item.sobrenome) : item.nome_fantasia;
                                                item.id_cliente = row.original.id_cliente
                                                return item;
                                            }).sort((a, b) => {
                                                // Ordenar pelo atributo 'identificador_integrador'
                                                if (a.identificador_integrador < b.identificador_integrador) return -1;
                                                if (a.identificador_integrador > b.identificador_integrador) return 1;
                                                return 0;
                                            })}
                                            enableBottomToolbar={true}
                                            enableTopToolbar={true}
                                            enableGlobalFilterModes
                                            enablePagination={true}
                                            enableRowActions={true}
                                            paginationDisplayMode={'pages'}
                                            getRowId={(row) => row.id_instalacao}
                                            initialState={{
                                                pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe aqui
                                                showGlobalFilter: true,
                                                density: 'compact',
                                            }}
                                            muiTableContainerProps={{ sx: { maxHeight: "3000px" } }}
                                            muiTableBodyRowProps={{
                                                sx: {
                                                    height: 20,
                                                    color: 'black' // Define a cor do texto para preto
                                                },
                                            }}
                                            muiPaginationProps={{
                                                rowsPerPageOptions: [50, 100, 500],
                                                variant: 'outlined',
                                            }}
                                            enableFullScreenToggle={false}
                                            renderRowActions={({ row }) => (
                                                <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>


                                                    <Tooltip title="Gerenciar">
                                                        <IconButton color="error"
                                                            onClick={(event) => handleClickGerenciarInstalacao(event, row.original)}
                                                        >
                                                            <SettingsIcon />
                                                        </IconButton>
                                                    </Tooltip>

                                                </Box>
                                            )}

                                        />

                                    </Box>
                                )}
                                renderRowActions={({ row }) => (
                                    <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>

                                        <Tooltip title="Gerenciar">
                                            <IconButton color="error" onClick={() => handleClickGerenciar(row.original)}>
                                                <SettingsIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </Box>
                                )}
                            />
                        )}


                    </div>
                }


            </div>



            <Dialog
                fullScreen={false}
                open={alertOpen}
                onClose={handleCloseAlert}
                aria-labelledby="confirm-dialog"
                BackdropProps={{
                    invisible: true,
                }}
            >
                <DialogTitle
                    style={{ backgroundColor: 'black', color: 'white' }}
                    id="confirm-dialog"> Mudar Status de Colaborador</DialogTitle>
                <DialogContent
                    style={{ backgroundColor: 'black' }}
                >
                    <div>
                        <AppBar
                            style={{ backgroundColor: 'black', color: 'white' }}

                            sx={{ position: 'relative' }}>

                            <Grid container spacing={1}

                                style={{ backgroundColor: 'black', color: 'white' }}

                                item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    disable={buttonOkDisabled}
                                    id="mensagem"
                                    variant="standard"
                                    name="mensagem"
                                    fullWidth
                                    label="Mensagem"
                                    required
                                    autoComplete="mensagem"
                                    value={mensagem}
                                    onChange={e => setMensagem(e.target.value)}
                                    multiline
                                    rows={3}
                                    InputProps={{
                                        style: { color: 'white' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'white' },
                                    }}

                                />

                                <Grid
                                    disable={buttonOkDisabled}
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                    onClick={(e) => handleCloseAlert()}
                                >
                                    <Button style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        ok
                                    </Button>
                                </Grid>
                            </Grid>
                        </AppBar>
                    </div>
                </DialogContent>

            </Dialog >
        </div>
    );
}



