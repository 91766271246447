import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import globalConfig from '../../../../../configs/globalConfigs';
import AppBar from '@mui/material/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Stomp } from '@stomp/stompjs';

import Close from '@material-ui/icons/Close'
import { Snackbar } from '@material-ui/core';
import { Menu } from '@mui/material';

import Cookies from 'js-cookie';
import { MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import {
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import { useHistory } from "react-router-dom";
import moment from 'moment';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    GoogleMap,
    Marker,
    LoadScript,
    OverlayView,
    StandaloneSearchBox,
    DirectionsService,
    DirectionsRenderer,
    DistanceMatrixService,
    MaxZoomService, Circle, Polygon, Polyline
} from "@react-google-maps/api";

import {
    MaterialReactTable
} from 'material-react-table';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import BuildIcon from '@mui/icons-material/Build';
import Switch from '@mui/material/Switch';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/Power';



import api from '../../../../../services/api';


import NavegadorGestao from "../../../../../components/NavBarGestao";
import "./MapPage.css";

import switch_off from '../../../../../assets/imgs/switch-off.png';
import switch_on from '../../../../../assets/imgs/switch-on.png';


import mini_icone_carro from '../../../../../assets/imgs/mini_icone_carro.png';

import mini_icone_moto from '../../../../../assets/imgs/mini_icone_moto.png';

import icone_origem from '../../../../../assets/imgs/icone_origem.png';
import icone_destino from '../../../../../assets/imgs/icone_destino.png';
import icone_parada from '../../../../../assets/imgs/icone_parada.png';


import icone_pgm from '../../../../../assets/imgs/icone_pgm.png';

import icone_medidor from '../../../../../assets/imgs/icone_medidor.png';

import icone_subestacao from '../../../../../assets/imgs/icone_subestacao.png';
import icone_casa from '../../../../../assets/imgs/icone_casa.png';
import icone_loja from '../../../../../assets/imgs/icone_loja.png';


import alarmeAudio from '../../../../../assets/mp3/alarme.mp3';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '1000px',

        backgroundColor: 'white',
    },
    toolbar: {

    },
    toolbarIcon: {

    },
    appBar: {

    },
    appBarShift: {

    },
    menuButton: {

    },
    menuButtonHidden: {

    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {

    },
    drawerPaperClose: {

    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        display: 'flex',


    },
    container: {
        display: 'flex',
        height: '1000px',

        backgroundColor: 'green',


    },
    paper: {

    },
    fixedHeight: {

    },
}));


const libraries = ["places"];


// Gerenciador de Áudio (Singleton)
const audioManager = {
    audio: new Audio(alarmeAudio),
    isPlaying: false,
    play() {
        if (!this.isPlaying) {
            this.audio.loop = true;
            this.audio.volume = 1.0;
            this.audio
                .play()
                .then(() => {
                    this.isPlaying = true;
                })
                .catch(error => console.error("Erro ao reproduzir o áudio:", error));
        }
    },
    pause() {
        if (this.isPlaying) {
            this.audio.pause();
            this.audio.currentTime = 0;
            this.isPlaying = false;
        }
    }
};


export default function MonitoramentoMapa() {
    const audioRef = useRef(new Audio(alarmeAudio)); // Inicializar o ref com o áudio importado

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const classes = useStyles();
    const history = useHistory();


    const [value, setValue] = React.useState(0);



    const [showTable, setShowTable] = useState(true);

    const [idLocalDefinindoPerimetro, setIdLocalDefinindoPerimetro] = useState(0); // Indica se está definindo perímetros
    const [definingPerimeter, setDefiningPerimeter] = useState(false); // Indica se está definindo perímetros
    const [perimeterPoints, setPerimeterPoints] = useState([]); // Armazena os pontos do perímetro
    const [currentPerimeter, setCurrentPerimeter] = useState(1); // Controla qual perímetro está sendo definido (P1 a P4)



    const handleMapClick = (event) => {
        if (definingPerimeter && currentPerimeter <= 8) {
            const newPoint = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
                label: `Perímetro ${currentPerimeter}`
            };
            setPerimeterPoints([...perimeterPoints, newPoint]); // Adiciona o novo ponto ao estado
            setCurrentPerimeter(currentPerimeter + 1); // Avança para o próximo perímetro


        } else {
            //  event.preventDefault(); // Impede o menu de contexto padrão do navegador
            // event.stopPropagation(); // Impede a propagação do evento para o mapa
        }
    };



    const handleMenuClick = (index) => {
        // Aqui você pode adicionar lógica para lidar com cliques em outros itens
        // Além de exibir a tabela ao clicar no ícone "Instalações"
        if (index === 0) {
            setShowTable(!showTable); // Alterna a visibilidade da tabela
        }
    };



    const [map, setMap] = useState();
    const mapRef = useRef(null);



    const [lat, setLat] = useState();
    const [lng, setLng] = useState();

    const [mapPosition, setMapPosition] = useState({ lat: lat, lng: lng });
    const [zoom, setZoom] = useState(5);

    const handleCenterChanged = () => {
        if (map) {
            const center = map.getCenter();
            const newPosition = { lat: center.lat(), lng: center.lng() };
            // Somente atualiza se houver uma mudança real na posição
            if (newPosition.lat !== mapPosition.lat || newPosition.lng !== mapPosition.lng) {
                setMapPosition(newPosition);
            }
        }
    };

    const handleZoomChanged = () => {
        if (map) {
            const newZoom = map.getZoom();
            // Somente atualiza se houver uma mudança real no nível de zoom
            if (newZoom !== zoom) {
                setZoom(newZoom);
            }
        }
    };


    const onMapLoad = (map) => {
        setMap(map);
        mapRef.current = map; // Armazena a instância do mapa
        setGoogleLoaded(true);
    };

    const handleClickInstalacao = (instalacao) => {
        console.log("clicou: " + instalacao.latitude + "," + instalacao.longitude);

        setZoom(18);
        setMapPosition({
            lat: parseFloat(instalacao.latitude),
            lng: parseFloat(instalacao.longitude)
        });
    };


    const handleMarkerClick = (lat, lng) => {

        console.log("cliqou: " + lat + "," + lng)

        setZoom(18);
        setMapPosition({ lat, lng });

    };

    const options = {
        mapTypeId: 'satellite',
        streetViewControl: false, // Desativa o Street View
        fullscreenControl: false, // Desativa o controle de tela cheia
        mapTypeControl: false, // Desativa a opção de alterar o tipo de mapa
        zoomControl: true, // Ativa somente o controle de zoom
        maxZoom: 21,
        styles: [
            {
                featureType: "poi", // Oculta pontos de interesse
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "road", // Simplicidade nas rodovias
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "transit", // Remove transporte público
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "administrative.land_parcel", // Remove labels de terrenos
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "administrative.neighborhood", // Remove labels de bairros
                stylers: [{ visibility: "off" }],
            },
        ],
    };

    const [endereco, setEndereco] = useState();

    const [instalacoes, setInstalacoes] = useState([]);

    const marker = [
        {
            position: { lat: lat, lng: lng },
            title: "Unidade Base",
        },
    ];

    const [markers, setMarkers] = useState([]);


    const containerStyle = {
        width: '100%',
        height: '100%'
    };



    const [configuracoes, setConfiguracoes] = useState();
    const [loading, setLoading] = useState();
    const [loadingInstalacoes, setLoadingInstalacoes] = useState();

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/configuracoes", {
                headers: headers
            }).then(function (response) {
                setConfiguracoes(response.data);

                const lati = parseFloat(response.data.unidade_base.latitude);
                const lngi = parseFloat(response.data.unidade_base.longitude);

                setLat(lati);
                setLng(lngi);

                console.log("Lat: " + lati);
                console.log("Lng: " + lngi);
                setMapPosition({ lat: lati, lng: lngi });


                const logradouro = response.data.unidade_base.logradouro;
                const numero = response.data.unidade_base.numero;
                const bairro = response.data.unidade_base.bairro;
                const complemento = response.data.unidade_base.complemento;
                const cidade = response.data.unidade_base.cidade;
                const estado = response.data.unidade_base.estado;
                const cep = response.data.unidade_base.cep;

                const end = `${logradouro}, ${numero}, ${bairro}, ${cidade}, ${estado} - ${cep}`;
                setEndereco(end);
                console.log("Endereço: " + end);
                setLoading(false);


            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    const [googleLoaded, setGoogleLoaded] = useState(false);

    const handleLoadGoogle = () => {
        setGoogleLoaded(true);
    };




    function checkDimenssoes() {


        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        setWidth(largura)

    }

    const [width, setWidth] = useState(0);



    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });


    useEffect(() => {


        listarInformacoes();
        // checkDimenssoes();




    }, []);








    function processarIcone(tipo) {
        if (tipo === 0) {
            return icone_pgm;
        } else if (tipo === 10) {
            return icone_medidor;
        }
    }



    function processarIconeInstalacao(tipo) {
        if (tipo === 0) {
            return icone_casa;
        } else if (tipo === 1) {
            return icone_loja;
        } else if (tipo == 2) {
            return icone_subestacao;
        }
    }

    const LocalComponent = ({ id_instalacao, lat, lng, onClick, tipo_icone, nome_mapa, status, instalacao }) => {
        const [showMenu, setShowMenu] = useState(false);

        const handleContextMenu = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setShowMenu(!showMenu);
        };

        const handleClickGerenciar = () => {
            const url = `/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/${id_instalacao}/0`;
            window.open(url, '_blank');
            setShowMenu(!showMenu);
        };

        const handleClickDefinirPerimetro = (event, id_instalacao) => {
            event.stopPropagation();
            setDefiningPerimeter(true);
            setIdLocalDefinindoPerimetro(id_instalacao);
            setCurrentPerimeter(1);
            setPerimeterPoints([]); // Reseta o perímetro para iniciar um novo
            setShowMenu(false);
        };

        const handleClickSalvarPerimetro = (event, id_instalacao) => {
            event.stopPropagation();
            salvarPerimetro(id_instalacao);
            setDefiningPerimeter(false);
            setIdLocalDefinindoPerimetro(0);
            setPerimeterPoints([]);
            setShowMenu(false);
        };

        async function salvarPerimetro() {
            try {
                console.log("cadastrar chamado");
                const cadastro = {};
                perimeterPoints.slice(0, 8).forEach((point, index) => {
                    if (point) {
                        cadastro[`latitude${index + 1}`] = point.lat.toString();
                        cadastro[`longitude${index + 1}`] = point.lng.toString();
                    }
                });

                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/clientes/instalacoes/atualizarperimetro/${id_instalacao}`;
                const response = await api.put(url, cadastro, { headers });

                if (response.data) {
                    setSnackbarMessage('Perímetro salvo com sucesso!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                }
            } catch (error) {
                setSnackbarMessage('Erro ao salvar perímetro.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error("Erro ao salvar perímetro:", error);
            }
        }



        return (
            <OverlayView
                position={{ lat, lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -(height / 2),
                })}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        transform: "translate(-50%, -50%)",
                        height: "50px",
                        width: "50px",
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        onClick(lat, lng);
                    }}
                    className={status === 0 ? "piscar" : ""}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 0 }}>
                        <img
                            alt={"icone"}
                            style={{
                                textAlign: 'center',
                                width: '40px',
                                height: '40px'
                            }}
                            src={processarIconeInstalacao(tipo_icone)}
                            onContextMenu={handleContextMenu}
                        />
                        <span style={{
                            color: 'white',
                            fontSize: '10px',
                            textShadow: '1px 1px 0px black, -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black',
                            fontWeight: 'bold'
                        }}>
                            {nome_mapa}
                        </span>
                    </div>
                    {showMenu && (
                        <div style={{
                            position: 'absolute',
                            backgroundColor: 'white',
                            border: '1px solid black',
                            borderRadius: '4px',
                            padding: '10px',
                            top: '50px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 1000,
                            boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                        }}>
                            <IconButton color="error" onClick={handleClickGerenciar}>
                                <SettingsIcon />
                                <span style={{ marginLeft: '8px' }}>Gerenciar</span>
                            </IconButton>

                            {!definingPerimeter && (
                                <IconButton color="error" onClick={(event) => handleClickDefinirPerimetro(event, id_instalacao)}>
                                    <SettingsIcon />
                                    <span style={{ marginLeft: '8px' }}>Definir Perímetro</span>
                                </IconButton>
                            )}

                            {definingPerimeter && (
                                <IconButton color="error" onClick={(event) => handleClickSalvarPerimetro(event, id_instalacao)}>
                                    <SettingsIcon />
                                    <span style={{ marginLeft: '8px' }}>Salvar Perímetro</span>
                                </IconButton>
                            )}
                        </div>
                    )}


                </div>
            </OverlayView>
        );
    };



    const ModuloPGMComponent = ({ lat, lng, tipo, onClick, status, equipamento, perimetro, id_instalacao }) => {

        const [isHovered, setIsHovered] = React.useState(false);

        const [showMenu, setShowMenu] = useState(false);

        const [anchorEl, setAnchorEl] = useState(null);
        const [anchorElReles, setAnchorElReles] = useState(null);
        const [anchorElAudios, setAnchorElAudios] = useState(null);

        const [subMenuOpen, setSubMenuOpen] = useState(false);
        const [subMenuOpenReles, setSubMenuOpenReles] = useState(false);
        const [subMenuOpenAudios, setSubMenuOpenAudios] = useState(false);

       
        

        const handleMenuOpen = useCallback((event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
            setSubMenuOpen(true);
        }, []);

        const handleMenuOpenReles = (event) => {
            event.preventDefault(event);
            event.stopPropagation();
            setAnchorElReles(event.currentTarget);
            setSubMenuOpenReles(true);
        };

        const handleMenuOpenAudios = (event) => {
            event.preventDefault(event);
            event.stopPropagation();
            setAnchorElAudios(event.currentTarget);
            setSubMenuOpenAudios(true);
        };



        const handleMenuClose = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorEl(null);
            setSubMenuOpen(false);
        };

        const handleMenuCloseReles = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorElReles(null);
            setSubMenuOpenReles(false);
        };

        const handleMenuCloseAudios = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorElAudios(null);
            setSubMenuOpenAudios(false);
        };



        const handleContextMenu = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setShowMenu(!showMenu);
        };

        const localPerimeterPoints = useMemo(() => {
            return perimetro
                ? Array.from({ length: 8 }, (_, i) => {
                      const lat = parseFloat(perimetro[`latitude${i + 1}`]);
                      const lng = parseFloat(perimetro[`longitude${i + 1}`]);
                      return !isNaN(lat) && !isNaN(lng) ? { lat, lng } : null;
                  }).filter(point => point !== null)
                : [];
        }, [perimetro]);

        // Calcular a diferença de tempo
        const now = new Date();
        const lastResponse = new Date(equipamento.modulo_pgm.data_hora_ultima_resposta);
        const differenceInMinutes = (now - lastResponse) / 1000 / 60; // Convertendo de milissegundos para minutos

        // Verificar se o alarme deve ser ativado
        const alarme = equipamento.alarme;



        // Definir o estado do equipamento
        let statusText;
        let statusColor;
        let isBlinking = false;




        const armada = equipamento.modulo_pgm.armada;

        if (alarme) {
            statusText = "ALARME";
            statusColor = "red";
            isBlinking = true; // Habilitar o piscar se estiver em alarme
        } else if (differenceInMinutes < 2) {
            if (armada) {
                statusText = "ONLINE - ARMADA";
                statusColor = "green";
            }
            else {
                statusText = "ONLINE - DESARMADA";
                statusColor = "yellow";
            }
        } else {
            statusText = "OFFLINE";
            statusColor = "red";
            isBlinking = true; // Habilitar o piscar se estiver offline
        }

        // Controlar reprodução de áudio
        useEffect(() => {
            if (alarme) {
                audioManager.play();
            } else {
                audioManager.pause();
            }
        }, [alarme]);

        const [mostrarRele, setMostrarRele] = useState(false);

        useEffect(() => {
            let timeoutId;
            if (!isHovered) {
              // Define um temporizador de 5 segundos para ocultar os relés
              timeoutId = setTimeout(() => {
                setMostrarRele(false);
              }, 5000);
            } else {
              // Cancela o temporizador caso esteja em estado "hovered"
              setMostrarRele(true);
              clearTimeout(timeoutId);
            }
          
            return () => clearTimeout(timeoutId);
          }, [isHovered]);


        async function handleClickPgm(comando) {

            console.log("handleClickPgm")
            try {
                const cadastro = {};
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/criarporareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/" + comando;
                const response = await api.get(url, { headers });

                if (response.data) {
                    setSnackbarMessage('Comando Enviado!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                }

                setAnchorElAudios(null);
                setSubMenuOpenAudios(false);
                setAnchorElReles(null);
                setSubMenuOpenReles(false);
                setAnchorEl(null);
                setSubMenuOpen(false);

            } catch (error) {
                setSnackbarMessage('Erro ao enviar comando');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error("Erro ao enviar o comando:", error);
            }
        }

        const processarIndexAtivarRele = (index) => {
            if (index === 1) {
                //rele 1
                return 1;
            } else if (index === 2) {
                //rele 2
                return 2;
            } else if (index === 3) {
                //rele 3
                return -1;
            } else if (index === 4) {
                //rele 4
                return -1;
            }
        }

        const processarIndexDesativarRele = (index) => {
            if (index === 1) {
                //rele 1
                return 4;
            } else if (index === 2) {
                //rele 2
                return 5;
            } else if (index === 3) {
                //rele 3
                return -1;
            } else if (index === 4) {
                //rele 4
                return -2;
            }
        }


        return (
            <OverlayView
                position={{ lat, lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -(height / 2),
                })}
            >
                <div>
                    {(alarme || differenceInMinutes > 2 || zoom > 15) && (
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                transform: "translate(-50%, -50%)",
                                height: "100px",
                                width: "100px",
                                // border: `2px solid ${alarme ? statusColor : 'transparent'}`, // Borda de 2px, incolor se sem alarme
                                borderRadius: "50%", // Fazer o perímetro circular
                                boxShadow: alarme ? `0 0 30px 150px rgba(255, 0, 0, 0.5)` : "none", // Sombra afastada e translúcida
                            }}
                            onClick={(event) => {
                                event.stopPropagation(); // Impede a propagação do evento para o mapa
                                onClick(lat, lng);
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                                <img
                                    alt={"icone"}
                                    style={{
                                        textAlign: 'center',
                                        width: '40px',  // Define a largura da imagem
                                        height: '40px'  // Define a altura da imagem
                                    }}
                                    src={processarIcone(tipo)}
                                    onContextMenu={handleContextMenu}

                                />
                            </div>



                            {showMenu && (
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: 'white',
                                    border: '1px solid black',
                                    borderRadius: '4px',
                                    padding: '10px',
                                    top: '50px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    zIndex: 1000,
                                    boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                                }}>
                                    <IconButton color="error" onClick={handleMenuOpen}>
                                        <SettingsIcon />
                                        <span style={{ marginLeft: '8px' }}>PGM</span>
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={subMenuOpen}
                                        onClose={handleMenuClose}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                        PaperProps={{
                                            style: {
                                                border: '1px solid black',
                                                borderRadius: '4px',
                                                padding: '10px',
                                                zIndex: 555,
                                                boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                                            }
                                        }}
                                    >
                                        <MenuItem onClick={(e) => { handleMenuOpenReles(e); /* Ação para Reles */ }}>Reles</MenuItem>
                                        <MenuItem onClick={(e) => { handleMenuOpenAudios(e); /* Ação para Audio */ }}>Audio</MenuItem>
                                    </Menu>

                                    <Menu
                                        anchorEl={anchorElReles}
                                        open={subMenuOpenReles}
                                        onClose={handleMenuCloseReles}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                        PaperProps={{
                                            style: {
                                                border: '1px solid black',
                                                borderRadius: '4px',
                                                padding: '10px',
                                                zIndex: 555,
                                                boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                                            }
                                        }}
                                    >
                                        {[1, 2, 3, 4].map((index) => {
                                            const releNome = equipamento?.modulo_pgm[`nome_rele${index}`];
                                            const status = equipamento?.modulo_pgm[`status_rele${index}`]; // Exemplo de como pegar o status do relé

                                            return (
                                                <MenuItem key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span>{releNome}</span>

                                                    {status === "on" ? (
                                                        <IconButton color="error" onClick={() => { handleClickPgm(processarIndexDesativarRele(index)) }}>
                                                            <PowerOffIcon /> {/* Ícone para Desativar */}
                                                            <span style={{ marginLeft: '4px' }}>Desativar</span>
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton color="primary" onClick={() => { handleClickPgm(processarIndexAtivarRele(index)) }}>
                                                            <PowerIcon /> {/* Ícone para Ativar */}
                                                            <span style={{ marginLeft: '4px' }}>Ativar</span>
                                                        </IconButton>
                                                    )}
                                                </MenuItem>
                                            );

                                        })}
                                    </Menu>

                                    <Menu

                                        anchorEl={anchorElAudios}
                                        open={subMenuOpenAudios}
                                        onClose={handleMenuCloseAudios}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                        PaperProps={{
                                            style: {
                                                border: '1px solid black',
                                                borderRadius: '4px',
                                                padding: '10px',
                                                zIndex: 555,
                                                boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                                            }
                                        }}
                                    >
                                    </Menu>


                                </div>
                            )}



                            {equipamento.modulo_pgm &&
                                (<div>
                                    <span style={{ color: 'white', fontSize: '14px', fontWeight: 'bold' }}>{equipamento.modulo_pgm.codigo + "-" + equipamento.nome_mapa} </span>
                                    <br></br>
                                    <span style={{
                                        color: statusColor,
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        animation: isBlinking ? 'blink 2s step-start infinite' : 'none'
                                    }}>
                                        {statusText}
                                    </span>
                                </div>
                                )
                            }

                            {equipamento.modulo_pgm &&
                                (<div>
                                    <span
                                        style={{
                                            color: 'white', fontSize: '14px', fontWeight: 'bold'
                                        }}
                                    >{ }</span>
                                </div>
                                )
                            }
                            {zoom > 15 && mostrarRele &&  (
                                <div style={{
                                    color: 'black', fontSize: '14px', fontWeight: 'bold',
                                    display: 'flex', alignItems: 'center', gap: '8px', marginTop: '10px'
                                }}>
                                    {[1, 2, 3, 4].map((index) => {
                                        const releNome = equipamento?.modulo_pgm[`nome_rele${index}`];
                                        const status = equipamento?.modulo_pgm[`status_rele${index}`];
                                        const iconPath = status === "on" ? switch_on : switch_off;

                                        return (
                                            <div key={index} style={{
                                                border: '1px solid black',
                                                borderRadius: '4px', padding: '5px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                                            }}>
                                                <span>{releNome}</span>
                                                {status === "on" ? (
                                                    <IconButton color="error" onClick={() => { handleClickPgm(processarIndexDesativarRele(index)) }}>
                                                        <img src={iconPath} alt={`${status} icon`} style={{ width: '40px', height: '40px', marginLeft: '4px' }} />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton color="primary" onClick={() => { handleClickPgm(processarIndexAtivarRele(index)) }}>
                                                        <img src={iconPath} alt={`${status} icon`} style={{ width: '40px', height: '40px', marginLeft: '4px' }} />
                                                    </IconButton>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            {zoom > 15 && (
                                localPerimeterPoints.length >= 2 && idLocalDefinindoPerimetro !== id_instalacao) && (
                                    localPerimeterPoints.length === 2 ? (
                                        <Polyline
                                            path={localPerimeterPoints}
                                            options={{ strokeColor: statusColor, strokeOpacity: 1.0, strokeWeight: 2 }}
                                        />
                                    ) : (
                                        <Polygon
                                            paths={localPerimeterPoints}
                                            options={{
                                                fillColor: statusColor,
                                                fillOpacity: 0.35,
                                                strokeColor: statusColor,
                                                strokeOpacity: 0.8,
                                                strokeWeight: 2
                                            }}
                                        />
                                    )
                                )}
                        </div>
                    )}
                </div>
            </OverlayView>
        );
    };




    const columnsInstalacoes = useMemo(
        () => [



            {
                accessorKey: 'nome_mapa', //normal accessorKey
                header: 'Local',
                size: 150,
                maxHeight: 60,
            },
            {
                accessorKey: 'identificador_integrador', //normal accessorKey
                header: 'ID Integrador',
                size: 100,
                maxHeight: 60,
            },

        ],
        [],
    );


    const polygonOptions = {
        strokeColor: 'blue',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'blue',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
    };




    const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());


    const [isConnected, setIsConnected] = useState(false);


    useEffect(() => {
        // Obter o token do cookie
        const token = Cookies.get('token');

        // Criar a conexão WebSocket
        const socket = new WebSocket(`wss://gruporosinetos.com/api/monitoramentowebsocket?token=${token}`);

        // Passando o WebSocket para o Stomp
        const client = Stomp.over(socket);

        if (!isConnected) {
            // Definir o comportamento para quando a conexão for aberta
            client.connect({}, () => {
                setIsConnected(true);
                console.log('Conectado ao WebSocket');

                // Inscrever no tópico /topic/dados
                client.subscribe('/topic/dados', (message) => {
                    // Aqui você pode tratar a mensagem recebida
                    //console.log('Dados recebidos:', message.body);
                    console.log("atualizou");

                    try {
                        const dados = JSON.parse(message.body); // Parse do JSON recebido
                        const currentTime = Date.now();


                        // Função para verificar alterações profundas nas instalações e equipamentos
                        const dadosSaoDiferentes = (novosDados, dadosAtuais) => {
                            if (novosDados.length !== dadosAtuais.length) return true;

                            // Percorre cada instalação
                            for (let i = 0; i < novosDados.length; i++) {
                                const novaInstalacao = novosDados[i];
                                const instalacaoAtual = dadosAtuais[i];

                                // Verifica se o ID ou outra propriedade relevante da instalação mudou
                                if (novaInstalacao.id_instalacao !== instalacaoAtual.id_instalacao ||
                                    novaInstalacao.nome_mapa !== instalacaoAtual.nome_mapa) {
                                    return true;
                                }

                                // Verifica se o número de equipamentos mudou
                                if (novaInstalacao.equipamentos.length !== instalacaoAtual.equipamentos.length) return true;

                                // Percorre cada equipamento da instalação
                                for (let j = 0; j < novaInstalacao.equipamentos.length; j++) {
                                    const novoEquipamento = novaInstalacao.equipamentos[j];
                                    const equipamentoAtual = instalacaoAtual.equipamentos[j];

                                    // Verifica se o ID do equipamento ou status do alarme mudaram
                                    if (novoEquipamento.id_equipamento !== equipamentoAtual.id_equipamento ||
                                        novoEquipamento.alarme !== equipamentoAtual.alarme) {
                                        return true;
                                    }

                                    // Se o equipamento é do tipo modulo_pgm, verifica os status dos relés
                                    if (novoEquipamento.tipo_equipamento_area_tecnica === 0) {
                                        // Verifica status_rele1 até status_rele4
                                        for (let k = 1; k <= 4; k++) {
                                            const releKey = `status_rele${k}`;
                                            if (novoEquipamento.modulo_pgm[releKey] !== equipamentoAtual.modulo_pgm[releKey]) {
                                                return true;
                                            }
                                        }
                                    }
                                }
                            }

                            // Se nenhuma diferença foi encontrada
                            return false;
                        };

                        setInstalacoes((instalacoesAtuais) => {
                            const shouldUpdate =
                                dadosSaoDiferentes(dados, instalacoesAtuais) ||
                                (currentTime - lastUpdateTime > 30000); // Verifica se passaram 30 segundos

                            if (shouldUpdate) {
                                setLastUpdateTime(currentTime); // Atualiza o tempo da última atualização
                                return dados; // Atualiza o estado com os novos dados
                            }
                            return instalacoesAtuais; // Mantém o estado inalterado se não houver mudanças
                        });

                    } catch (error) {
                        console.error('Erro ao fazer parse dos dados:', error);
                    }
                    setLoadingInstalacoes(false);


                });

                // Configurar envio a cada 5 segundos
                const intervalId = setInterval(() => {
                    if (client.connected) {
                        client.send("/app/requestData", {}, JSON.stringify({}));
                    }
                }, 5000); // 5000ms = 5 segundos


            }, (error) => {
                console.error('Erro ao conectar ao WebSocket:', error);
            });
        }

        // Limpeza da conexão quando o componente for desmontado
        return () => {
            if (client.connected) {
                client.disconnect(() => {
                    console.log('Desconectado do WebSocket');
                });
            }
        };
    }, []); // O array vazio garante que isso será executado uma única vez



    return (
        <div  >
            <NavegadorGestao
                AreaTecnica={true}
                Monitoramento={true}
                MonitoramentoMapa={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            {loading ?
                <Skeleton animation={"wave"} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <div className={classes.content}>
                    <React.Fragment >
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            item xs={12}
                        >


                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12}
                                style={{ position: 'absolute', top: 5, left: 0, width: '100%', height: '100%', zIndex: 0 }}
                            >
                                <LoadScript
                                    googleMapsApiKey={globalConfig.mapKey}
                                    libraries={libraries}
                                    loadingElement={<div>Loading...</div>}
                                >
                                    <GoogleMap
                                        //onLoad={onMapLoad}

                                        onClick={handleMapClick}
                                        center={mapPosition} // Posição mantida pelo estado
                                        zoom={zoom}          // Zoom mantido pelo estado
                                        onLoad={(mapInstance) => setMap(mapInstance)}
                                        onCenterChanged={handleCenterChanged}
                                        onZoomChanged={handleZoomChanged}
                                        mapContainerStyle={containerStyle}
                                        options={options}
                                    >

                                        <Marker key={marker.title} position={marker.position} title={marker.title} />


                                        {!loadingInstalacoes && instalacoes && instalacoes.map((instalacao) => {
                                            const latInstalacao = parseFloat(instalacao.latitude);
                                            const lngInstalacao = parseFloat(instalacao.longitude);
                                            const instalacaoValida = !isNaN(latInstalacao) && !isNaN(lngInstalacao);

                                            return (
                                                instalacaoValida && (
                                                    <React.Fragment key={instalacao.id_instalacao}>
                                                        <LocalComponent
                                                            id_instalacao={instalacao.id_instalacao}
                                                            lat={latInstalacao}
                                                            lng={lngInstalacao}
                                                            onClick={handleMarkerClick}
                                                            tipo_icone={instalacao.icone_mapa}
                                                            nome_mapa={instalacao.nome_mapa}
                                                            instalacao={instalacao}
                                                        />
                                                        {instalacao.equipamentos.map((equipamento) => {
                                                            const latEquipamento = parseFloat(equipamento.latitude);
                                                            const lngEquipamento = parseFloat(equipamento.longitude);
                                                            const equipamentoValido = !isNaN(latEquipamento) && !isNaN(lngEquipamento);

                                                            return (
                                                                equipamentoValido && equipamento.tipo_equipamento_area_tecnica === 0 && (
                                                                    <ModuloPGMComponent
                                                                        key={equipamento.id_equipamento}
                                                                        lat={latEquipamento}
                                                                        lng={lngEquipamento}
                                                                        tipo={equipamento.tipo_equipamento_area_tecnica}
                                                                        onClick={handleMarkerClick}
                                                                        equipamento={equipamento}
                                                                        perimetro={instalacao.perimetro}
                                                                        id_instalacao={instalacao.id_instalacao}
                                                                    />
                                                                )
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                )
                                            );
                                        })}


                                        {/* Exibe os marcadores dos perímetros definidos */}
                                        {perimeterPoints.map((point, index) => (
                                            <Marker
                                                key={index}
                                                position={{ lat: point.lat, lng: point.lng }}
                                                label={point.label}
                                            />
                                        ))}


                                        {perimeterPoints.length === 2 && (
                                            <Polyline
                                                path={perimeterPoints.map(point => ({ lat: point.lat, lng: point.lng }))}
                                                options={{ strokeColor: '#FF0000', strokeOpacity: 1.0, strokeWeight: 2 }}
                                            />
                                        )}

                                        {perimeterPoints.length >= 3 && perimeterPoints.length <= 8 && (
                                            <Polygon
                                                paths={perimeterPoints.map(point => ({ lat: point.lat, lng: point.lng }))}
                                                options={{
                                                    fillColor: '#FF0000',
                                                    fillOpacity: 0.35,
                                                    strokeColor: '#FF0000',
                                                    strokeOpacity: 0.8,
                                                    strokeWeight: 2
                                                }}
                                            />
                                        )}






                                    </GoogleMap>
                                </LoadScript>
                            </Grid>

                        </Grid>


                    </React.Fragment>
                </div>

            }


            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    top: '100px', // Ajuste conforme a altura do NavegadorGestao
                    height: 'calc(100vh - 100px)',
                    '& .MuiDrawer-paper': {
                        position: 'fixed',
                        top: '100px', // Ajuste conforme a altura do NavegadorGestao
                        width: '300px', // Defina a largura do Drawer conforme necessário
                    },
                }}
            >
                <List>
                    <ListItem button onClick={() => handleMenuClick(0)}>
                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                        <ListItemText primary="Instalações" />
                    </ListItem>

                    {showTable && (
                        <Box
                            sx={{
                                width: '100%',
                                padding: '16px', // Espaçamento opcional
                            }}
                        >
                            <MaterialReactTable
                                columns={columnsInstalacoes}
                                data={instalacoes}
                                enableGlobalFilterModes={true}
                                enableTopToolbar={true}
                                enableBottomToolbar={false}
                                enablePagination={false}
                                enableColumnFilters={true}
                                enableColumnActions={false}
                                sortable={false}
                                enableSorting={false}
                                enableRowSelection={false}
                                color={'black'}
                                paginationDisplayMode={'pages'}
                                getRowId={(row) => row.id_instalacao}
                                initialState={{
                                    pagination: { pageSize: 1000, pageIndex: 0 },
                                    showGlobalFilter: true,
                                    color: 'black'
                                }}
                                muiTableBodyRowProps={({ row }) => ({
                                    sx: {
                                        height: 40,
                                        color: 'black', // Define a cor do texto para preto
                                        cursor: 'pointer', // Define o cursor como pointer para indicar que a linha é clicável
                                    },
                                    onClick: () => handleClickInstalacao(row.original), // Chama a função ao clicar na linha
                                })}
                                muiTableContainerProps={{ sx: { maxHeight: "1000px", color: 'black' } }}


                                enableColumnFilterModes={false}
                                enableExpandAll={true}
                                enableColumnPinning={true}
                                enableColumnResizing={true}
                                muiPaginationProps={{
                                    rowsPerPageOptions: [20, 50, 100],
                                    variant: 'outlined',
                                }}
                                enableFullScreenToggle={true}
                                muiTablePaperProps={({ table }) => ({
                                    style: {
                                        zIndex: table.getState().isFullScreen ? 1000 : undefined,
                                        color: 'black',
                                    },
                                })}
                            />
                        </Box>
                    )}

                </List>
            </Drawer>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    style: {
                        backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336',
                        marginTop: '60px', // Ajuste a altura conforme necessário
                        fontSize: '16px',
                        fontWeight: 'bold',
                        border: '1px solid black',
                        borderRadius: '4px',
                        padding: '10px',
                        zIndex: 555,
                        boxShadow: '0 2px 6px rgba(0,0,0,0.3)',

                    }
                }}
            />

        </div >
    );
}





