import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@mui/material/Chip';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import { Viewer, Worker } from '@react-pdf-viewer/core';
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

export default function CadastrarSolicitacao() {

    const [procedimentos, setProcedimentos] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {




        async function listarProcedimentos() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }



                await api.get("v1/protected/procedimentos/listartodos", {
                    headers: headers
                }).then(function (response) {
                    setProcedimentos(response.data)


                    setLoading(false);

                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar os Procedimentos: " + _err)

            }

        }
        listarProcedimentos();


    }, []);

    const [procedimentosSelecionados, setProcedimentosSelecionados] = useState([]);

    const handleAdicionarProcedimento = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novosProcedimentos = valoresSelecionados.filter(
            (opcao) => !procedimentosSelecionados.some((selecionado) => selecionado.id_procedimento === opcao.id_procedimento)
        );

        setProcedimentosSelecionados([...procedimentosSelecionados, ...novosProcedimentos]);
    };


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    const [botaoClicado, setBotaoClicado] = useState(false);




    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };



    const classes = useStyles();
    const history = useHistory();

    const [solicitacao, setSolicitacao] = useState('');
    const [descricao, setDescricao] = useState('');


    async function funcaoSalvar() {


        try {

            console.log("cadastrar chamado");



            const cadastro = {
                descricao: descricao,
                solicitacao: solicitacao,
                usuario_criador: { id_usuario: Cookies.get("id_usuario") },
                procedimentos: procedimentosSelecionados,

            }




            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/solicitacoes/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Solicitação Cadastrada");
                history.push({
                    pathname: "/gestao/operacional/solicitacoes/listar",
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }

    function processarEtapa(etapa) {
        let texto = "";
        if (etapa === 0) {
            texto = "Tratamento Remoto";
        } else if (etapa === 2) {
            texto = "Ánalise Prévia";
        } else if (etapa === 3) {
            texto = "Tratamento em Campo";
        }
        return texto;
    }
    function returnDescricaoProcedimento(procedimento) {
        var texto = "Nome: " + procedimento.nome + "\n Descrição: " + procedimento.descricao_geral +
            (procedimento.obrigatorio === 1 ? " Obrigatório" : "Opcional" + "\n" +
                "Etapa: " + (processarEtapa(procedimento.etapa))
            );
        return texto;
    }




    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                Solicitacoes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }} ></div>
            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                 
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

<Grid
                        item xs={12}
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent={"flex-start"}
                    >
                        <a href="/gestao/operacional/solicitacoes/listar" style={{ fontSize: 16, color: 'black', marginRight: '5px' }}>{"< Solicitações | "}</a>
                        <span style={{ fontSize: 16, color: 'black'}}>{" Nova Solicitação"}</span>
                    </Grid>


                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                                style={{marginRight:10, marginLeft: 10}}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >

                                        <Tabs
                                            value={value}
                                            onChange={handleChangeTab}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"


                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />

                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>




                            <Grid
                                style={{ paddingTop: 5 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >


                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >

                                    <Grid
                                        value={value} index={0}
                                        style={{ padding: 10 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >


                                        <Grid item xs={12}>
                                            <TextField
                                                variant="standard"
                                                name="solicitacao"
                                                label="Solicitação"
                                                required
                                                id="solicitacao"
                                                value={solicitacao}
                                                onChange={(e) => setSolicitacao(e.target.value)}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={12} style={{ padding: 10 }}>
                                            <TextField
                                                variant="standard"
                                                name="descricao"
                                                label="Descrição"
                                                id="descricao"
                                                value={descricao}
                                                onChange={(e) => setDescricao(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={3}
                                            />
                                        </Grid>




                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 5 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                            >

                                                <Autocomplete
                                                    id="select-procedimentos"
                                                    options={procedimentos}
                                                    disableCloseOnSelect
                                                    multiple
                                                    getOptionLabel={(procedimento) => returnDescricaoProcedimento(procedimento).replace(/<br \/>/g, '\n')}
                                                    value={procedimentosSelecionados}
                                                    onChange={handleAdicionarProcedimento}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((procedimento) => {
                                                            const cotacaoText = returnDescricaoProcedimento(procedimento).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Procedimentos " multiline rows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoProcedimento(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>





                                    </Grid>

                                </SwipeableViews>


                            </Grid>



                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={10}
                            >
                                <Button style={{ marginTop: 5 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={salvar}
                                    disabled={botaoClicado}
                                > Salvar  </Button>
                            </Grid>

                        </Grid>

            }

        </div >
    );
}


