// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideInFromLeft {
    from {
      transform: translateX(100%); /* Começa fora da tela à esquerda */
    }
    to {
      transform: translateX(0); /* Termina na posição correta */
    }
  }
  
  .fullPageContainer {
    position: fixed;
    top: 0;
    right: 0; /* Garante que começa no lado esquerdo */
    width: 70%; /* Define a largura do menu */
    height: 100%;
    background-color: white;
    z-index: 1001; /* Garante que está acima dos outros componentes */
    box-shadow: 10px 0 40px rgba(0, 0, 0, 1); /* Sombra mais profunda e intensa */
    overflow-y: auto;
    animation: slideInFromLeft 0.3s ease-out; /* Usa a animação corrigida */
    display: flex;
    flex-direction: column;
    padding: 16px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/painelAdmin/AreaTecnica/Monitoramento/Mosaico/styles.css"],"names":[],"mappings":"AAAA;IACI;MACE,2BAA2B,EAAE,mCAAmC;IAClE;IACA;MACE,wBAAwB,EAAE,+BAA+B;IAC3D;EACF;;EAEA;IACE,eAAe;IACf,MAAM;IACN,QAAQ,EAAE,wCAAwC;IAClD,UAAU,EAAE,6BAA6B;IACzC,YAAY;IACZ,uBAAuB;IACvB,aAAa,EAAE,kDAAkD;IACjE,wCAAwC,EAAE,mCAAmC;IAC7E,gBAAgB;IAChB,wCAAwC,EAAE,6BAA6B;IACvE,aAAa;IACb,sBAAsB;IACtB,aAAa;EACf","sourcesContent":["@keyframes slideInFromLeft {\r\n    from {\r\n      transform: translateX(100%); /* Começa fora da tela à esquerda */\r\n    }\r\n    to {\r\n      transform: translateX(0); /* Termina na posição correta */\r\n    }\r\n  }\r\n  \r\n  .fullPageContainer {\r\n    position: fixed;\r\n    top: 0;\r\n    right: 0; /* Garante que começa no lado esquerdo */\r\n    width: 70%; /* Define a largura do menu */\r\n    height: 100%;\r\n    background-color: white;\r\n    z-index: 1001; /* Garante que está acima dos outros componentes */\r\n    box-shadow: 10px 0 40px rgba(0, 0, 0, 1); /* Sombra mais profunda e intensa */\r\n    overflow-y: auto;\r\n    animation: slideInFromLeft 0.3s ease-out; /* Usa a animação corrigida */\r\n    display: flex;\r\n    flex-direction: column;\r\n    padding: 16px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
