import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@mui/material/AppBar';
import { useDropzone } from 'react-dropzone';
import {  Card, CardContent } from '@mui/material';

import { useHistory } from "react-router-dom";

import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";


const drawerWidth = 240;


const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));



export default function CadastrarOrdemDeServico() {


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const classes = useStyles();
    const history = useHistory();

    const [descricao, setDescricao] = useState('');

    const [loading, setLoading] = useState(true);




    const [descricaoImagem1, setDescricaoImagem1] = useState('');
    const [descricaoImagem2, setDescricaoImagem2] = useState('');
    const [descricaoImagem3, setDescricaoImagem3] = useState('');
    const [descricaoImagem4, setDescricaoImagem4] = useState('');


    const [descricaoVideo1, setDescricaoVideo1] = useState('');
    const [descricaoVideo2, setDescricaoVideo2] = useState('');
    const [descricaoVideo3, setDescricaoVideo3] = useState('');


    const [descricaoArquivo1, setDescricaoArquivo1] = useState('');
    const [descricaoArquivo2, setDescricaoArquivo2] = useState('');
    const [descricaoArquivo3, setDescricaoArquivo3] = useState('');

   const [imagemSelecionada1, setImagemSelecionada1] = useState(null);
    const [imagemSelecionada2, setImagemSelecionada2] = useState(null);
    const [imagemSelecionada3, setImagemSelecionada3] = useState(null);
    const [imagemSelecionada4, setImagemSelecionada4] = useState(null);

 const onDrop1 = useCallback((acceptedFiles) => {
        // Obtém o arquivo recebido
        const file = acceptedFiles[0];
        if (!file) {
            alert("Nenhum arquivo recebido.");
            return;
        }

        // Converte a imagem para PNG usando Canvas
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenha a imagem no Canvas
                ctx.drawImage(img, 0, 0);

                // Converte o Canvas para PNG
                canvas.toBlob(
                    (blob) => {
                        const convertedFile = new File([blob], `${file.name.split('.')[0]}.png`, { type: "image/png" });
                        setImagemSelecionada1(convertedFile);
                        console.log("Imagem convertida para PNG:", convertedFile);
                    },
                    "image/png",
                    1 // Qualidade máxima
                );
            };
        };
        reader.readAsDataURL(file);
    }, []);

    const onDrop2 = useCallback((acceptedFiles) => {
        // Obtém o arquivo recebido
        const file = acceptedFiles[0];
        if (!file) {
            alert("Nenhum arquivo recebido.");
            return;
        }

        // Converte a imagem para PNG usando Canvas
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenha a imagem no Canvas
                ctx.drawImage(img, 0, 0);

                // Converte o Canvas para PNG
                canvas.toBlob(
                    (blob) => {
                        const convertedFile = new File([blob], `${file.name.split('.')[0]}.png`, { type: "image/png" });
                        setImagemSelecionada2(convertedFile);
                        console.log("Imagem convertida para PNG:", convertedFile);
                    },
                    "image/png",
                    1 // Qualidade máxima
                );
            };
        };
        reader.readAsDataURL(file);
    }, []);

    const onDrop3 = useCallback((acceptedFiles) => {
        // Obtém o arquivo recebido
        const file = acceptedFiles[0];
        if (!file) {
            alert("Nenhum arquivo recebido.");
            return;
        }

        // Converte a imagem para PNG usando Canvas
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenha a imagem no Canvas
                ctx.drawImage(img, 0, 0);

                // Converte o Canvas para PNG
                canvas.toBlob(
                    (blob) => {
                        const convertedFile = new File([blob], `${file.name.split('.')[0]}.png`, { type: "image/png" });
                        setImagemSelecionada3(convertedFile);
                        console.log("Imagem convertida para PNG:", convertedFile);
                    },
                    "image/png",
                    1 // Qualidade máxima
                );
            };
        };
        reader.readAsDataURL(file);
    }, []);

    const onDrop4 = useCallback((acceptedFiles) => {
        // Obtém o arquivo recebido
        const file = acceptedFiles[0];
        if (!file) {
            alert("Nenhum arquivo recebido.");
            return;
        }

        // Converte a imagem para PNG usando Canvas
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenha a imagem no Canvas
                ctx.drawImage(img, 0, 0);

                // Converte o Canvas para PNG
                canvas.toBlob(
                    (blob) => {
                        const convertedFile = new File([blob], `${file.name.split('.')[0]}.png`, { type: "image/png" });
                        setImagemSelecionada4(convertedFile);
                        console.log("Imagem convertida para PNG:", convertedFile);
                    },
                    "image/png",
                    1 // Qualidade máxima
                );
            };
        };
        reader.readAsDataURL(file);
    }, []);



    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: onDrop1,

        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: 'image/*', // Restringe a aceitação apenas para arquivos .png
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: onDrop2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: 'image/*', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({
        onDrop: onDrop3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: 'image/*', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps4, getInputProps: getInputProps4 } = useDropzone({
        onDrop: onDrop4,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: 'image/*', // Restringe a aceitação apenas para arquivos .png
    });



    const [videoSelecionado1, setVideoSelecionado1] = useState(null);
    const [videoSelecionado2, setVideoSelecionado2] = useState(null);
    const [videoSelecionado3, setVideoSelecionado3] = useState(null);


    const onDropVideo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropVideo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropVideo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado3(acceptedFiles[0]);
    }, []);


    const { getRootProps: getRootVideoProps1, getInputProps: getInputVideoProps1 } = useDropzone({
        onDrop: onDropVideo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps2, getInputProps: getInputVideoProps2 } = useDropzone({
        onDrop: onDropVideo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps3, getInputProps: getInputVideoProps3 } = useDropzone({
        onDrop: onDropVideo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });



    const [arquivoSelecionado1, setArquivoSelecionado1] = useState(null);
    const [arquivoSelecionado2, setArquivoSelecionado2] = useState(null);
    const [arquivoSelecionado3, setArquivoSelecionado3] = useState(null);


    const onDropArquivo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropArquivo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropArquivo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado3(acceptedFiles[0]);
    }, []);



    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps2, getInputProps: getInputArquivoProps2 } = useDropzone({
        onDrop: onDropArquivo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps3, getInputProps: getInputArquivoProps3 } = useDropzone({
        onDrop: onDropArquivo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });


    const [projetos, setProjetos] = useState([]);


    const [projetosPeD, setProjetosPeD] = useState([]);
    const [projetoPeDSelecionado, setProjetoPeDSelecionado] = useState(null);

    const [motivo, setMotivo] = useState('');

    const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);

    const [instalacoes, setInstalacoes] = useState([]);
    const [instalacaoSelecionada, setInstalacaoSelecionada] = useState(null);

    const [solicitacoes, setSolicitacoes] = useState([]);
    const [solicitacaoSelecionada, setSolicitacaoSelecionada] = useState(null);

    const [solicitantes, setSolicitantes] = useState([]);

    const [solicitanteSelecionado, setSolicitanteSelecionado] = useState(null);

    const [nivelPrioridade, setNivelPrioridade] = useState(0);

    const [botaoSalvarClickado, setBotaoSalvarClickado] = useState(false);

    const handleChangeNivelPrioridade = (event) => {
        setNivelPrioridade(event.target.value);
    };

    const [autoCad, setAutoCad] = useState(false);

    const location = useLocation();

    const { categoriaCad, projetoCad, propostaCad, itensCad } = location.state || {};
    const [categoria, setCategoria] = useState(2);
    const [projetoSelecionado, setProjetoSelecionado] = useState(null);
    const [propostaSelecionada, setPropostaSelecionada] = useState(null);

    const [tipoSolicitante, setTipoSolicitante] = useState(0);


    async function listarInformacoesIniciais() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("/v1/protected/solicitacoes/listartodos", {
                headers: headers
            }).then(function (response) {
                setSolicitacoes(response.data);


                const selectedSolicitacao = response.data.find(solicitacao => solicitacao.id_solicitacao === 7);



                api.get("/v1/protected/user/listartodos", {
                    headers: headers
                }).then(function (response) {
                    setSolicitantes(response.data)
                    handleSetCategoria(2);
                    if (projetoCad) {
                        setAutoCad(true);
                        setCategoria(categoriaCad);
                        setProjetos([]);
                        setProjetoSelecionado(projetoCad);
                        setPropostaSelecionada(propostaCad);

                        setSolicitacaoSelecionada(selectedSolicitacao || null); // Define null se não encontrar o solicitante
                        setDescricao("Elaborar Ordem de Compra de Insumos da Proposta Nº " + propostaCad.id_proposta + " no Projeto Nº " + projetoCad.id_projeto + " da instalação " + returnDescricaoInstalacao(projetoCad.instalacao));


                        setLoading(false);
                    }
                    else {

                        api.get("v1/protected/projetos/listartodos", {
                            headers: headers
                        }).then(function (response) {
                            setProjetos(response.data)

                            api.get("v1/protected/ped/listartodos", {
                                headers: headers
                            }).then(function (response) {
                                setProjetosPeD(response.data);

                                setLoading(false);
                            });
                        });




                    }

                });


            });



        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Solicitações: " + _err)

        }

    }



    function handleSetSolicitacao(solicitacao) {
        setSolicitacaoSelecionada(solicitacao);
    }


    function handleSolicitante(solicitante) {
        setSolicitanteSelecionado(solicitante);
    }


    function handleSetProjeto(projeto) {
        setProjetoSelecionado(projeto);
    }


    function handleSetProposta(proposta) {
        setPropostaSelecionada(proposta);
    }


    function handleSetProjetoPeD(projeto) {
        setProjetoPeDSelecionado(projeto);
    }




    useEffect(() => {
        listarInformacoesIniciais();


    }, []);


    function returnDescricaoInstalacao(instalacao) {
        var texto = "SIGMA: " + instalacao.identificador_integrador + "\nCPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n" + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }


    function returnDescricaoSolicitacao(solicitacao) {
        var texto = solicitacao.solicitacao;
        return texto;
    }


    const [solicitantesSelecionados, setSolicitantesSelecionados] = useState([]);

    const handleAdicionarSolicitante = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novosSolicitantes = valoresSelecionados.filter(
            (opcao) => !solicitantesSelecionados.some((selecionado) => selecionado.id_usuario === opcao.id_usuario)
        );

        setSolicitantesSelecionados([...solicitantesSelecionados, ...novosSolicitantes]);
    };


    const handleRemoverSolicitante = (event, solicitanteRemovido) => {
        const novosSolicitantes = solicitantesSelecionados.filter(
            (solicitante) => solicitante.id_usuario !== solicitanteRemovido.id_usuario
        );
        setSolicitantesSelecionados(novosSolicitantes);
    };



    async function salvar() {

        setBotaoSalvarClickado(true);

        try {


            const formData = new FormData();
            formData.append('usuario_criador.id_usuario', Cookies.get("id_usuario"));
            formData.append('categoria_ordem', categoria);
            formData.append('descricao_solicitacao', descricao);
            formData.append('nivel_prioridade', nivelPrioridade);
            formData.append('tipo_solicitante', tipoSolicitante);


        

            if (autoCad) {
                formData.append('auto_cad', 1);

            }


            if (categoria === 0) {
                if (!projetoSelecionado) {
                    alert("Selecione o Projeto!");
                    return;
                } else {
                    formData.append('projeto_id_cad', projetoSelecionado.id_projeto);
                    if (!propostaSelecionada) {
                        alert("Selecione a Proposta!")
                        return;
                    } else {
                        formData.append('proposta_id_cad', propostaSelecionada.id_proposta);
                    }
                }
            } else if (categoria === 1) {
                if (!projetoPeDSelecionado) {
                    alert("Selecione o Projeto de Pesquisa e Desenvolvimento!");
                    return;
                } else {
                    formData.append('ped_id_cad', projetoPeDSelecionado.id_projeto);
                }
            } else if (categoria === 2) {
                if (!instalacaoSelecionada) {
                    alert("Selecione a Instalação onde a O.S será executada!");
                    return;
                } else {
                    formData.append('instalacao.id_instalacao', instalacaoSelecionada.id_instalacao);
                }
            }

            if (!solicitacaoSelecionada) {
                alert("Informe a Solicitação!");
                setBotaoSalvarClickado(false);
                return;
            } else {
                formData.append('solicitacao.id_solicitacao', solicitacaoSelecionada.id_solicitacao);
            }


            if (solicitacaoSelecionada.id_solicitacao !== 7) {
                    if(tipoSolicitante === 0){
                         formData.append('usuario_solicitante.id_usuario',Cookies.get("id_usuario"));
                    }else if(tipoSolicitante === 1){
                        //outro usuairo é o solicitante
                        if (!solicitanteSelecionado.id_usuario) {
                            alert("Informe o Solicitante!");
                            setBotaoSalvarClickado(false);
                            return;
                        }else{
                            formData.append('usuario_solicitante.id_usuario',Cookies.get("id_usuario"));
                        } 
                    }else if(tipoSolicitante === 2){
                        //um cliente é um solicitante
                    }
                
            } else {
                if (!solicitantesSelecionados || solicitantesSelecionados.length < 1) {
                    alert("Informe um ou mais solicitantes!");
                    setBotaoSalvarClickado(false);
                    return;
                } else {
                   // cadastro.solicitantes = solicitantesSelecionados;
                }
            }


            /*
            cadastro.auto_cad = 1;
            if (!usuarioSelecionado) {
                alert("Informe o Analista a executar a O.S");
                setBotaoSalvarClickado(false);
                return;
            } else {
                cadastro.analista = { id_usuario: usuarioSelecionado.id_usuario };
                cadastro.motivo = motivo;
            }*/


            if (autoCad) {
               // cadastro.itens_pre_requerimento = itensCad;
                //cadastro.finalizar_requerimento_itens = 1;
            }


            console.log("cadastrar chamado");


            formData.append('descricao_imagem1', descricaoImagem1);
            formData.append('descricao_imagem2', descricaoImagem2);
            formData.append('descricao_imagem3', descricaoImagem3);
            formData.append('descricao_imagem4', descricaoImagem4);


            formData.append('descricao_video1', descricaoVideo1);
            formData.append('descricao_video2', descricaoVideo2);
            formData.append('descricao_video3', descricaoVideo3);

            formData.append('descricao_arquivo1', descricaoArquivo1);
            formData.append('descricao_arquivo2', descricaoArquivo2);
            formData.append('descricao_arquivo3', descricaoArquivo3);



            // Adicionar as imagens ao FormData
            formData.append('imagens', imagemSelecionada1);
            formData.append('imagens', imagemSelecionada2);
            formData.append('imagens', imagemSelecionada3);
            formData.append('imagens', imagemSelecionada4);

            formData.append('videos', videoSelecionado1);
            formData.append('videos', videoSelecionado2);
            formData.append('videos', videoSelecionado3);

            formData.append('arquivos', arquivoSelecionado1);
            formData.append('arquivos', arquivoSelecionado2);
            formData.append('arquivos', arquivoSelecionado3);

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/ordemservico/cadastrar', formData,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Ordem de Serviço Cadastrada");
                history.push({
                    pathname: "/gestao/administracao/ordensdeservicos/editar/" + cadastro_salvo.id_ordem_servico,
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
                setBotaoSalvarClickado(false);

            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
            setBotaoSalvarClickado(false);

        }



    }


    function returnDescricaoSolicitante(usuario) {
        var texto = usuario.nome_amigavel;
        return texto;
    }

    function returnDescricaoProjeto(projeto) {
        var texto = projeto.id_projeto + " - " + projeto.descricao_projeto + "\n";//+ returnDescricaoInstalacao(projeto.instalacao);
        return texto;
    }


    function returnDescricaoProposta(proposta) {
        var texto = proposta.id_proposta + " - " + proposta.descricao_proposta;
        return texto;
    }


    function returnDescricaoProjetoPeD(projeto) {
        var texto = projeto.id_projeto + " - " + projeto.descricao_projeto;
        return texto;
    }


    function returnDescricaoUsuario(usuario) {
        var texto = "ID_Usuario: " + usuario.id_usuario + "\nNome: " + usuario.nome_amigavel + "\nRegiões de Atuação: " + usuario.regiao_cobertura;

        var dados_colaborador = "";

        if (usuario.tipo_associacao === 0) {
            //colaborador
            dados_colaborador += ("\nDepartamentos: " + usuario.colaborador.departamentos.map(departamento => departamento.nome).join(", "));
            dados_colaborador += ("\nCargos: " + usuario.colaborador.cargos.map(cargo => cargo.nome).join(", "));
            dados_colaborador += ("\nFunções: " + usuario.colaborador.funcoes.map(funcao => funcao.nome).join(", "));


        }

        texto += ("\n" + dados_colaborador);


        return texto;
    }

    async function handleSetCategoria(categoria) {

        setCategoria(categoria);
        if (categoria === 2) {
            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            await api.get("v1/protected/clientes/instalacoes/listartodos", {
                headers: headers
            }).then(function (response) {
                setInstalacoes(response.data)
            });
        }

    }


    return (
        <div>
            <NavegadorGestao
                ListarOrdemSevicos={'underline'}
                corGestaoServicosMarcado={'orange'}
                Gestao={'underline'}
                corGestaoMarcado={'orange'}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            {!loading &&

                <Grid container spacing={2} item xs={12}  >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        align="flex-start"
                        item xs={12}
                        style={{ width: '100%', margin: 0, padding: 0 }}
                    >

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            align="flex-start"
                            item xs={12}
                            style={{ width: '100%', margin: 0, padding: 0 }}
                        >

                            <a href={"/gestao/administracao/ordensdeservicos/listar"} style={{ fontSize: 14, color: 'black', marginRight: '5px', paddingTop: 10 }}>{"< Ordens de Serviços | "}</a>
                            <span style={{ fontSize: 18, color: 'black', marginRight: '5px', paddingTop: 10 }}>{" Nova Ordem de Serviço"}</span>

                        </Grid>

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                        >
                            <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                <AppBar position="static" >

                                    <Tabs
                                        value={value}
                                        onChange={handleChangeTab}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"


                                    >
                                        <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                        <Tab label="Imagens" style={{ backgroundColor: 'green' }} />
                                        <Tab label="Vídeos" style={{ backgroundColor: 'green' }} />
                                        <Tab label="Documentos" style={{ backgroundColor: 'green' }} />

                                    </Tabs>
                                </AppBar>

                            </Box>
                        </Grid>


                        <SwipeableViews
                            index={value}
                            onChangeIndex={handleChangeIndex}
                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                        >
                            <Grid
                                value={value} index={0}
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >

                                <Grid
                                    style={{ paddingTop: 5 }}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={2}
                                >


                                    <Grid item xs={12}
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ paddingTop: 5 }}
                                    >
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id="Tipo-selector-label">Categoria</InputLabel>
                                                <Select
                                                    labelId="Tipo-selector-label"
                                                    id="Tipo-selector"
                                                    value={categoria}
                                                    disabled={autoCad}
                                                    onChange={(e) => handleSetCategoria(e.target.value)}
                                                    label="Categoria"
                                                >
                                                    <MenuItem value={0}>Projeto</MenuItem>
                                                    <MenuItem value={1}>Pesquisa & Desenvolvimento</MenuItem>
                                                    <MenuItem value={2}>Serviços e Manutenção</MenuItem>
                                                    <MenuItem value={3}>Renovar Estoque</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>



                                    {categoria === 0 &&
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 1 }}
                                            spacing={2}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                align="flex-end"
                                                item xs={6}
                                                style={{ marginTop: 1 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={8}
                                                >

                                                    <Autocomplete
                                                        id="select-projeto"
                                                        options={projetos}
                                                        disabled={autoCad}
                                                        getOptionLabel={(projeto) => returnDescricaoProjeto(projeto).replace(/<br \/>/g, '\n')}
                                                        value={projetoSelecionado}
                                                        onChange={(e, v) => handleSetProjeto(v)}
                                                        filterOptions={(options, { inputValue }) => {
                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                            return options.filter((cotacao) => {
                                                                const cotacaoText = returnDescricaoProjeto(cotacao).toLowerCase();
                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                            });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Projeto " multiline minRows={3} />
                                                        )}
                                                        renderOption={(option) => (
                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoProjeto(option) }} />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>




                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                align="flex-start"
                                                item xs={6}
                                                style={{ marginTop: 1 }}
                                            >
                                                {
                                                    projetoSelecionado &&
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={8}
                                                    >

                                                        <Autocomplete
                                                            disabled={autoCad}
                                                            id="select-proposta"
                                                            options={autoCad ? [] : projetoSelecionado.propostas}
                                                            getOptionLabel={(proposta) => returnDescricaoProposta(proposta).replace(/<br \/>/g, '\n')}
                                                            value={propostaSelecionada}
                                                            onChange={(e, v) => handleSetProposta(v)}
                                                            filterOptions={(options, { inputValue }) => {
                                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                return options.filter((cotacao) => {
                                                                    const cotacaoText = returnDescricaoProposta(cotacao).toLowerCase();
                                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                });
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label=" Proposta " multiline minRows={3} />
                                                            )}
                                                            renderOption={(option) => (
                                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoProposta(option) }} />
                                                            )}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>



                                        </Grid>
                                    }

                                    {categoria === 1 &&

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 1 }}
                                            spacing={2}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={6}
                                                style={{ marginTop: 1 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={8}
                                                >

                                                    <Autocomplete
                                                        id="select-projeto-ped"
                                                        options={projetosPeD}
                                                        disabled={autoCad}
                                                        getOptionLabel={(projeto) => returnDescricaoProjetoPeD(projeto).replace(/<br \/>/g, '\n')}
                                                        value={projetoPeDSelecionado}
                                                        onChange={(e, v) => handleSetProjetoPeD(v)}
                                                        filterOptions={(options, { inputValue }) => {
                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                            return options.filter((cotacao) => {
                                                                const cotacaoText = returnDescricaoProjetoPeD(cotacao).toLowerCase();
                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                            });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Projeto de P&D " multiline minRows={3} />
                                                        )}
                                                        renderOption={(option) => (
                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoProjetoPeD(option) }} />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                    {categoria === 2 &&

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 1 }}
                                            spacing={2}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={6}
                                                style={{ marginTop: 1 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={8}
                                                >
                                                    <Autocomplete
                                                        id="select-instalacao"
                                                        options={instalacoes}
                                                        disabled={autoCad}
                                                        getOptionLabel={(instalacao) => returnDescricaoInstalacao(instalacao).replace(/<br \/>/g, '\n')}
                                                        value={instalacaoSelecionada}
                                                        onChange={(e, v) => setInstalacaoSelecionada(v)}
                                                        filterOptions={(options, { inputValue }) => {
                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                            return options.filter((instalacao) => {
                                                                const cotacaoText = returnDescricaoInstalacao(instalacao).toLowerCase();
                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                            });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Instalação " multiline minRows={3} />
                                                        )}
                                                        renderOption={(option) => (
                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoInstalacao(option) }} />
                                                        )}
                                                    />

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }


                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                        style={{ marginTop: 20 }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={5}
                                        >

                                            <Autocomplete
                                                id="select-solicitacao"
                                                options={solicitacoes}
                                                disabled={autoCad}
                                                getOptionLabel={(solicitacao) => returnDescricaoSolicitacao(solicitacao).replace(/<br \/>/g, '\n')}
                                                value={solicitacaoSelecionada}
                                                onChange={(e, v) => handleSetSolicitacao(v)}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((cotacao) => {
                                                        const cotacaoText = returnDescricaoSolicitacao(cotacao).toLowerCase();
                                                        return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Solicitação " multiline minRows={3} />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitacao(option) }} />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>



                                    {(solicitacaoSelecionada && solicitacaoSelecionada.id_solicitacao === 7) ?
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-solicitantes"
                                                    options={solicitantes}

                                                    multiple
                                                    getOptionLabel={(solicitante) => returnDescricaoSolicitante(solicitante).replace(/<br \/>/g, '\n')}
                                                    value={solicitantesSelecionados}
                                                    onChange={handleAdicionarSolicitante}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((solicitante) => {
                                                            const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Solicitantes " multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                                    )}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((solicitante, index) => (
                                                            <Chip
                                                                label={returnDescricaoSolicitante(solicitante)}
                                                                {...getTagProps({ index })}
                                                                onDelete={(event) => handleRemoverSolicitante(event, solicitante)}
                                                            />
                                                        ))
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 1 }}
                                        >
                                            <Grid item xs={12}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={3}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="Tipo-selector-label">Solicitante</InputLabel>
                                                        <Select
                                                            labelId="Tipo-selector-label"
                                                            id="Tipo-selector"
                                                            value={tipoSolicitante}
                                                            disabled={autoCad}
                                                            onChange={(e) => setTipoSolicitante(e.target.value)}
                                                            label="Solicitante"
                                                        >
                                                            <MenuItem value={0}>Sou o solicitante</MenuItem>
                                                            <MenuItem value={10}>Usuário do Sistema</MenuItem>
                                                            <MenuItem value={20}>Cliente</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            {tipoSolicitante === 10 &&
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={12}
                                                    style={{ marginTop: 10 }}
                                                >
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={5}
                                                    >

                                                        <Autocomplete
                                                            id="select-ordem-solicitante"
                                                            options={solicitantes}

                                                            getOptionLabel={(solicitante) => returnDescricaoSolicitante(solicitante).replace(/<br \/>/g, '\n')}
                                                            value={solicitanteSelecionado}
                                                            onChange={(e, v) => handleSolicitante(v)}
                                                            filterOptions={(options, { inputValue }) => {
                                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                return options.filter((solicitante) => {
                                                                    const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                });
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label=" Usuário Solicitador " multiline minRows={1} />
                                                            )}
                                                            renderOption={(option) => (
                                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                                            )}

                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    }


                                    <Grid item xs={12}
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ paddingTop: 10 }}
                                    >
                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <InputLabel id="area-selector-label">Nível Prioridade</InputLabel>
                                                <Select
                                                    labelId="area-selector-label"
                                                    id="area-selector"
                                                    value={nivelPrioridade}
                                                    onChange={handleChangeNivelPrioridade}

                                                    label="Nível SLA"
                                                >
                                                    <MenuItem value={0}>
                                                        Nível 0 - Comum
                                                    </MenuItem>
                                                    <MenuItem value={1}>
                                                        Nível 1 - Urgente
                                                    </MenuItem>
                                                    <MenuItem value={2}>
                                                        Nível 2 - Priorizar
                                                    </MenuItem>


                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>


                                    <Grid item xs={12}
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ paddingTop: 4 }}
                                    >
                                        <Grid item xs={10}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <TextField
                                                variant="standard"
                                                name="descricao"
                                                label="Observações"
                                                required
                                                id="descricao"
                                                value={descricao}
                                                onChange={(e) => setDescricao(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={2}
                                            />
                                        </Grid>
                                    </Grid>




                                </Grid>


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={10}
                                >
                                    <Button style={{ marginTop: 5 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={salvar}
                                        disabled={botaoSalvarClickado}
                                    > Salvar  </Button>
                                </Grid>
                            </Grid>

                            <Grid
                                value={value} index={1}
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >




                                <Grid item xs={12} style={{ padding: 40 }}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={3}>
                                        <Card>
                                            <CardContent>
                                                {imagemSelecionada1 ? (
                                                    <img
                                                        src={URL.createObjectURL(imagemSelecionada1)}
                                                        alt="Imagem Selecionada 1"
                                                        style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                    />
                                                ) : (
                                                    <div {...getRootProps1()} style={dropzoneStyles}>
                                                        <input {...getInputProps1()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte uma imagem aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoImagem1"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoImagem1"
                                                        value={descricaoImagem1}
                                                        onChange={(e) => setDescricaoImagem1(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        minRows={1}
                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Card>
                                            <CardContent>
                                                {imagemSelecionada2 ? (
                                                    <img
                                                        src={URL.createObjectURL(imagemSelecionada2)}
                                                        alt="Imagem Selecionada 2"
                                                        style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                    />
                                                ) : (
                                                    <div {...getRootProps2()} style={dropzoneStyles}>
                                                        <input {...getInputProps2()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte uma imagem aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoImagem2"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoImagem2"
                                                        value={descricaoImagem2}
                                                        onChange={(e) => setDescricaoImagem2(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        minRows={1}
                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Card>
                                            <CardContent>
                                                {imagemSelecionada3 ? (
                                                    <img
                                                        src={URL.createObjectURL(imagemSelecionada3)}
                                                        alt="Imagem Selecionada 3"
                                                        style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                    />
                                                ) : (
                                                    <div {...getRootProps3()} style={dropzoneStyles}>
                                                        <input {...getInputProps3()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte uma imagem aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoImagem3"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoImagem3"
                                                        value={descricaoImagem3}
                                                        onChange={(e) => setDescricaoImagem3(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        minRows={1}

                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>


                                    <Grid item xs={3}>
                                        <Card>
                                            <CardContent>
                                                {imagemSelecionada4 ? (
                                                    <img
                                                        src={URL.createObjectURL(imagemSelecionada4)}
                                                        alt="Imagem Selecionada 4"
                                                        style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                    />
                                                ) : (
                                                    <div {...getRootProps4()} style={dropzoneStyles}>
                                                        <input {...getInputProps4()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte uma imagem aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoImagem4"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoImagem4"
                                                        value={descricaoImagem4}
                                                        onChange={(e) => setDescricaoImagem4(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        minRows={1}

                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                </Grid>

                            </Grid>

                            <Grid
                                value={value} index={2}
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >



                                <Grid item xs={12} style={{ padding: 40 }}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >

                                    <Grid item xs={8}>
                                        <Card>
                                            <CardContent>
                                                {videoSelecionado1 ? (
                                                    <video
                                                        controls
                                                        src={URL.createObjectURL(videoSelecionado1)}
                                                        alt="Vídeo Selecionado"
                                                        style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                    />
                                                ) : (
                                                    <div {...getRootVideoProps1()} style={dropzoneStyles}>
                                                        <input {...getInputVideoProps1()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte um vídeo aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoVideo1"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoVideo1"
                                                        value={descricaoVideo1}
                                                        onChange={(e) => setDescricaoVideo1(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        minRows={1}

                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Card>
                                            <CardContent>
                                                {videoSelecionado2 ? (
                                                    <video
                                                        controls
                                                        src={URL.createObjectURL(videoSelecionado2)}
                                                        alt="Vídeo Selecionado"
                                                        style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                    />
                                                ) : (
                                                    <div {...getRootVideoProps2()} style={dropzoneStyles}>
                                                        <input {...getInputVideoProps2()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte um vídeo aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoVideo2"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoVideo2"
                                                        value={descricaoVideo2}
                                                        onChange={(e) => setDescricaoVideo2(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        minRows={1}

                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Card>
                                            <CardContent>
                                                {videoSelecionado3 ? (
                                                    <video
                                                        controls
                                                        src={URL.createObjectURL(videoSelecionado3)}
                                                        alt="Vídeo Selecionado"
                                                        style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                    />
                                                ) : (
                                                    <div {...getRootVideoProps3()} style={dropzoneStyles}>
                                                        <input {...getInputVideoProps3()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte um vídeo aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoVideo3"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoVideo3"
                                                        value={descricaoVideo3}
                                                        onChange={(e) => setDescricaoVideo3(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        minRows={1}

                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid
                                value={value} index={3}
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >




                                <Grid item xs={12} style={{ padding: 40 }}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={8}>
                                        <Card>
                                            <CardContent>
                                                {arquivoSelecionado1 ? (

                                                    <div
                                                        href={URL.createObjectURL(arquivoSelecionado1)}
                                                        onClick={() => window.open(URL.createObjectURL(arquivoSelecionado1), '_blank')}
                                                        onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado1))}
                                                        style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                        <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                    </div>


                                                ) : (
                                                    <div {...getRootArquivoProps1()} style={dropzoneStyles}>
                                                        <input {...getInputArquivoProps1()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte um arquivo aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoArquivo1"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoArquivo1"
                                                        value={descricaoArquivo1}
                                                        onChange={(e) => setDescricaoArquivo1(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        minRows={1}

                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Card>
                                            <CardContent>
                                                {arquivoSelecionado2 ? (
                                                    <div
                                                        href={URL.createObjectURL(arquivoSelecionado2)}
                                                        onClick={() => window.open(URL.createObjectURL(arquivoSelecionado1), '_blank')}
                                                        onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado2))}
                                                        style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                        <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                    </div>
                                                ) : (
                                                    <div {...getRootArquivoProps2()} style={dropzoneStyles}>
                                                        <input {...getInputArquivoProps2()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte um arquivo aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoArquivo2"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoArquivo2"
                                                        value={descricaoArquivo2}
                                                        onChange={(e) => setDescricaoArquivo2(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        minRows={1}

                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Card>
                                            <CardContent>
                                                {arquivoSelecionado3 ? (
                                                    <div
                                                        href={URL.createObjectURL(arquivoSelecionado3)}
                                                        onClick={() => window.open(URL.createObjectURL(arquivoSelecionado3), '_blank')}
                                                        onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado3))}
                                                        style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                        <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                    </div>
                                                ) : (
                                                    <div {...getRootArquivoProps3()} style={dropzoneStyles}>
                                                        <input {...getInputArquivoProps3()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte um arquivo aqui ou clique para adicionar
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="descricaoArquivo3"
                                                        label="Descrição"
                                                        required
                                                        id="descricaoArquivo3"
                                                        value={descricaoArquivo3}
                                                        onChange={(e) => setDescricaoArquivo3(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        minRows={1}

                                                    />
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </SwipeableViews>
                    </Grid>

                </Grid>
            }

        </div >
    );
}


