import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown'
import './styles.css';
import logo from '../../assets/imgs/logo_kapplan_energy.png';
import Grid from '@material-ui/core/Grid';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import mini_icone_informacao from '../../assets/imgs/mini_icone_informacao.png';
import Cookies from 'js-cookie';
import { Box } from "@mui/material";
import TextField from '@material-ui/core/TextField';
import { useLocation } from 'react-router-dom';
import Draggable from "react-draggable";

import api from '../../services/api';
import Typography from '@material-ui/core/Typography';
import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from "react-router-dom";
import {
  DialogActions,
  DialogContentText,
} from "@mui/material";
import {

  Link

} from "react-router-dom";


const NavegadorGestao = (props) => {


  const [openRondaVirtualEmAndamento, setOpenRondaVirtualEmAndamento] = useState(false);
  const [rondaVirtualEmAndamento, setRondaVirtualEmAndamento] = useState(null);

  const location = useLocation(); 

  const [navBar, setNavbar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tarefas, setTarefas] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [width, setWidth] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [tarefaEmVista, setTarefaEmvista] = useState();
  const regra = Cookies.get('regra');

  const history = useHistory();

  const [justificativa, setJustificativa] = useState(false);
  const [mensagemJustificativa, setMensagemJustificativa] = useState(null);

  const handleJustificativa = () => {
    setMensagemJustificativa(null);
    setJustificativa(true);
  }

  const [openBalao, setOpenBalao] = useState(false);
  const handleOpenBalao = () => {

    setOpenBalao(true);
  };

  const handleCloseBalao = () => {
    setMensagemJustificativa(null);
    setJustificativa(false);
    setOpenBalao(false);
  };

  const handleIrParaGestaoRondaVirtual = () => {
    history.push({
      pathname: "/gestao/operacional/rondavirtual/gerenciar/" + rondaVirtualEmAndamento.id_ronda_virtual,
    })
  };





  async function handleBalaoOk() {
    //verificar se a ronda virtual ainda esta pendente
    try {


      console.log("Vizualizar tarefa chamado");
      const id_usuario = Cookies.get('id_usuario');


      var url = "v1/protected/rondavirtual/verificarpendente/" + parseInt(rondaVirtualPendente.id_ronda_virtual, 10);
      //console.log("Url: " + url);
      await api.get(url).then(function (response) {
        // alert(response.data);
        if (response.data === "ronda_nula") {
          setRondaVirtualPendente(null);
          handleCloseBalao();
          alert("Ronda não mais listada no sistema!");
        } else if (response.data === "disponivel") {
          //assumir
          const token = Cookies.get('token');
          const headers = {
            'Authorization': 'Bearer ' + token
          }
          const cadastro = {
            usuario_executor: { id_usuario: Cookies.get("id_usuario") },
          }
          api.put("/v1/protected/rondavirtual/assumirrondavirtual/" + rondaVirtualPendente.id_ronda_virtual + "/" + Cookies.get("id_usuario"), cadastro, {
            headers: headers
          }).then(function (response) {
            if (parseInt(response.data.usuario_executor.id_usuario) === parseInt(Cookies.get("id_usuario"), 10)) {
              setRondaVirtualPendente(null);
              handleCloseBalao();
              alert("Ronda Virtual assumida por você");
              history.push({
                pathname: "/gestao/operacional/rondavirtual/gerenciar/" + response.data.id_ronda_virtual,
              })
            } else {
              setRondaVirtualPendente(null);
              handleCloseBalao();
              alert("Erro, consulte o administrador");
            }
          });

        } else {
          setRondaVirtualPendente(null);
          handleCloseBalao();
          alert("Usuário " + response.data + " já assumiu a execução dessa ronda virtual");
        }
      });


    } catch (_err) {
      console.log("Erro ao vizualizar acao, err: " + _err);

    }
  };


  async function handleFinalizarJustificativa() {
    //verificar se a ronda virtual ainda esta pendente
    try {
      if(mensagemJustificativa.length < 10){
        alert("Detalhe sua justificativa!");
        return;
      }

       const id_usuario = Cookies.get('id_usuario');


      var url = "v1/protected/rondavirtual/verificarpendente/" + parseInt(rondaVirtualPendente.id_ronda_virtual, 10);
      //console.log("Url: " + url);
      await api.get(url).then(function (response) {
        // alert(response.data);
        if (response.data === "ronda_nula") {
          setRondaVirtualPendente(null);
          handleCloseBalao();
          alert("Ronda não mais listada no sistema!");
        } else if (response.data === "disponivel") {
          //cancelar
          const token = Cookies.get('token');
          const headers = {
            'Authorization': 'Bearer ' + token
          }
          const cadastro = {
            id_usuario: Cookies.get("id_usuario"),
            id_ronda_virtual: rondaVirtualPendente.id_ronda_virtual,
            mensagem: mensagemJustificativa,
          }
          api.put("/v1/protected/rondavirtual/cancelarrondavirtual", cadastro, {
            headers: headers
          }).then(function (response) {

            if (response.data) {
              setRondaVirtualPendente(null);
              handleCloseBalao();
              alert("Justificativa Registrada!");
            } else {
              alert("Erro inesperado, consulte o administrador!");
            }

          });

        } else {
          setRondaVirtualPendente(null);
          handleCloseBalao();
          alert("Usuário " + response.data + " já assumiu a execução dessa ronda virtual");
        }
      });


    } catch (_err) {
      console.log("Erro ao vizualizar acao, err: " + _err);

    }
  };


  function checkDimenssoes() {
    var largura = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    setWidth(largura);

  }

  window.addEventListener('resize', function (event) {
    checkDimenssoes();
  });

  window.addEventListener('scroll', function (event) {
    changeBackground();
  });


  const changeBackground = () => {
    //console.log(window.scrollY)
    if (window.scrollY >= 200) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {

    //alert("Regras: " + regra);

    checkDimenssoes();

  }, []);






  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const handleOpenAlert = (tarefa) => {
    console.log("handle open alert chamado!");
    setTarefaEmvista(tarefa);
    setAlertOpen(true);
    vizualizarTarefa(tarefa);
  };



  async function vizualizarTarefa(tarefa) {
    try {


      console.log("Vizualizar tarefa chamado");
      const id_usuario = Cookies.get('id_usuario');


      var url = "v1/protected/tarefasgestao/vizualizar/" + parseInt(tarefa.id_tarefa);
      //console.log("Url: " + url);
      await api.put(url).then(function (response) {

        if (response) {
          setTarefas(tarefas.filter(tarefa => tarefa.id_tarefa !== tarefa.id_tarefa));

        }

      });


    } catch (_err) {
      console.log("Erro ao vizualizar acao, err: " + _err);

    }
  }


  async function criarOCporOs() {

    try {


      if (tarefaEmVista.ordem_servico.finalizar_requerimento_itens === 0) {
        alert("Aguarda até que o criador da O.S finalize o Requerimento de itens!");
        return;
      }

      const headers = {
        'Authorization': 'Bearer ' + Cookies.get("token")
      }

      const response = await api.post('/v1/protected/ordensdecompras/ordemdecompra/cadastrarporos', tarefaEmVista.ordem_servico,
        { headers: headers });

      const cadastro_salvo = response.data;
      if (cadastro_salvo) {
        alert("Ordem de Compra Cadastrada!");
        history.push({
          pathname: "/gestao/administracao/ordensdecompras/editar/" + cadastro_salvo.id_ordem_compra,
        })
      } else {
        alert("Erro de Conexão, tente novamente mais tarde");

      }


    } catch (_err) {
      console.log("erro ao cadastrar: " + _err);
      alert("Erro de Conexão, tente novamente mais tarde");

    }
  }





  const handleCloseAlert = () => {

    setAlertOpen(false);
    setTarefaEmvista(null);

  }

  function processarLink() {


    if (tarefaEmVista.especificidade === 0 && tarefaEmVista.ordem_compra) {
      //para ordes de compra
      return "/gestao/administracao/ordensdecompras/editar/" + tarefaEmVista.ordem_compra.id_ordem_compra;
    }
    else if (tarefaEmVista.especificidade === 0) {
      return "/gestao/administracao/ordensdecompras/editar/" + tarefaEmVista.id_ordem_compra;

    }
    else if (tarefaEmVista.especificidade === 1) {
      return "/gestao/administracao/ordensdeservicos/editar/" + tarefaEmVista.ordem_servico.id_ordem_servico;

    } else if (tarefaEmVista.especificidade === 2) {
      return "/gestao/financas/lancamento/gerenciar/" + tarefaEmVista.id_lancamento;

    }



  }




  async function pesquisarBackGround() {


    try {


      const id_usuario_logado = Cookies.get('id_usuario');

      let url = "v1/protected/tarefasgestao/listarPorUsuario/" + id_usuario_logado;

      //console.log("Url: " + url);
      await api.get(url).then(function (response) {

        setTarefas(response.data);
        //console.log(JSON.stringify(response.data));
        setLoading(false);

      });


    } catch (_err) {
      console.log("erro: " + _err);

    }


  }

  const [rondaVirtualPendente, setRondaVirtualPendente] = useState(null);


  async function pesquisarBackGroundRondas() {


    try {

      const id_usuario_logado = Cookies.get('id_usuario');

      let url = "v1/protected/rondavirtual/buscarpendente/" + id_usuario_logado;

      await api.get(url).then(function (response) {

        if (response.data) {
          if (response.data.usuario_executor) {

            if (parseInt(response.data.usuario_executor.id_usuario,10) === parseInt(Cookies.get('id_usuario'),10)) {

              setRondaVirtualEmAndamento(response.data);
              setOpenRondaVirtualEmAndamento(true);
            } else {
              setRondaVirtualPendente(null);
              handleCloseBalao();
            }
          } else {
            setRondaVirtualPendente(response.data);
            handleOpenBalao();
          }
        }
        else {
          setRondaVirtualPendente(null);
          handleCloseBalao();
        }


      });


    } catch (_err) {
      console.log("erro: " + _err);

    }


  }


  useEffect(() => {

    checkDimenssoes();

     pesquisarBackGround();
    pesquisarBackGroundRondas();

  }, []);



  useEffect(() => {



    const interval = setInterval(() => {


      pesquisarBackGroundRondas();


    }, 30000);

    return () => clearInterval(interval);

  }, [rondaVirtualPendente]);

  useEffect(() => {



    const interval = setInterval(() => {


       pesquisarBackGround();


    }, 1000);

    return () => clearInterval(interval);

  }, [tarefas]);

  const toggleDrawer = (option) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (option) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }

  };



  function onConfirmSair() {
    Cookies.set('token', undefined);
    window.location.href = "/login"


  }



  function returnDescricaoInstalacaoResumida(instalacao) {
    var texto = instalacao.identificador_integrador + "-" +
      (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

    return texto;
  }


  return (
    <div
      style={{ zIndex: 5, backgroundColor: navBar === true ? 'white' : props.corFundo, position: props.travado ? 'sticky' : null, top: props.travado ? 0 : null, borderBottom: '1px solid black' }}

    >
      <Grid
        container
        direction="row"
        item xs={12} sm={12} md={12} lg={12} xl={12}

      >
        <Grid
          container
          direction="row"
          item xs={width > 900 ? 5 : 12} sm={width > 900 ? 4 : 12} md={width > 900 ? 4 : 12} lg={width > 900 ? 4 : 12} xl={width > 900 ? 3 : 12}
          alignItems="center"
          justifyContent="center"
          style={{ padding: 10 }}
        >
          <div style={{}}>
            <Link className="a"

              to={{
                pathname: "/",

              }}
            >
              <img alt="logomarca" width={200}
                src={logo}
              />
            </Link>
          </div>
        </Grid>

        <Grid
          container
          direction="row"
          item xs={width > 900 ? 8 : 12} sm={width > 900 ? 8 : 12} md={width > 900 ? 8 : 12} lg={width > 900 ? 8 : 12} xl={width > 900 ? 9 : 12}
          alignItems="center"
          justifyContent={"center"}

        >
          <Navbar expand="xl" className={"color-nav"}>
            <Container  >
              <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ backgroundColor: 'rgba(255,255,255,0.8)' }} />
              <Navbar.Collapse
                style={{
                  backgroundColor: width >= 1200 ? "rgba(0,0,0,0.0)" : "white",
                }} >


                <Nav className="ml-auto">

                  {regra.includes("ADMIN")
                    && (
                      <NavDropdown
                        title="Área Técnica"
                        id="nav-dropdown"
                        style={{ textDecoration: props.AreaTecnica ? 'underline' : '', backgroundColor: props.AreaTecnica ? 'orange' : 'white' }}
                      >



                        <NavDropdown
                          title="Monitoramento"
                          id="nav-dropdown"
                          style={{ textDecoration: props.Monitoramento ? 'underline' : '', backgroundColor: props.Monitoramento ? 'orange' : 'white' }}

                        >
                          <NavDropdown.Item
                            eventKey="1.0"
                            style={{
                              textDecoration: props.MonitoramentoMosaico ? 'underline' : '',
                              backgroundColor: props.MonitoramentoMosaico ? 'orange' : 'white',
                            }}
                            id="nav-link"
                            href="/tecnica/monitoramento/mosaico"
                            target="_blank"
                            rel="noopener noreferrer" // Segurança ao abrir links externos
                          >
                            Mosaico
                          </NavDropdown.Item>

                          <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.MonitoramentoDashboard ? 'underline' : '', backgroundColor: props.MonitoramentoDashboard ? 'orange' : 'white' }} id="nav-link" href="/tecnica/monitoramento/dashboard">Dashboard
                          </NavDropdown.Item>


                          <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.MonitoramentoMapa ? 'underline' : '', backgroundColor: props.MonitoramentoMapa ? 'orange' : 'white' }} id="nav-link" href="/tecnica/monitoramento/mapa">Mapa
                          </NavDropdown.Item>


                        </NavDropdown>


                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Equipamentos ? 'underline' : '', backgroundColor: props.Equipamentos ? 'orange' : 'white' }} id="nav-link" href="/tecnica/equipamentos">Equipamentos
                        </NavDropdown.Item>


                      </NavDropdown>
                    )
                  }



                  {regra.includes("ADMIN")
                    && (
                      <NavDropdown
                        title="Base de Dados"
                        id="nav-dropdown"
                        style={{ textDecoration: props.BaseDados ? 'underline' : '', backgroundColor: props.BaseDados ? 'orange' : 'white' }}
                      >
                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Unidades ? 'underline' : '', backgroundColor: props.Unidades ? 'orange' : 'white' }} id="nav-link" href="/gestao/administracao/unidades/listar">Unidades
                        </NavDropdown.Item>

                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Clientes ? 'underline' : '', backgroundColor: props.Clientes ? 'orange' : 'white' }} id="nav-link" href="/gestao/administracao/clientes/listar">Clientes
                        </NavDropdown.Item>

                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Projetos ? 'underline' : '', backgroundColor: props.Projetos ? 'orange' : 'white' }} id="nav-link" href="/gestao/administracao/projetos/listar">Projetos
                        </NavDropdown.Item>


                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.PeD ? 'underline' : '', backgroundColor: props.PeD ? 'orange' : 'white' }} id="nav-link" href="/gestao/administracao/ped/listar">Pesquisa e Desenvolvimento
                        </NavDropdown.Item>

                        <NavDropdown
                        title="Tabelas Auxiliares"
                        id="nav-dropdown"
                        style={{ textDecoration: props.TabelasAuxiliaresClientes ? 'underline' : '', backgroundColor: props.TabelasAuxiliaresClientes ? 'orange' : 'white' }}

                      >
                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.GruposClientes ? 'underline' : 'white', backgroundColor: props.GruposClientes ? 'orange' : 'white' }} id="nav-link" href="/gestao/administracao/clientes/gruposclientes/listar">Grupos de Clientes
                        </NavDropdown.Item>

                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.GruposControle ? 'underline' : 'white', backgroundColor: props.GruposControle ? 'orange' : 'white' }} id="nav-link" href="/gestao/administracao/clientes/gruposcontrole/listar">Grupos de Controle
                        </NavDropdown.Item>


                      </NavDropdown>


                      </NavDropdown>
                    )
                  }


                  <NavDropdown
                    title="Operacional"
                    id="nav-dropdown"
                    style={{ textDecoration: props.Operacional ? 'underline' : '', backgroundColor: props.Operacional ? 'orange' : 'white' }}

                  >

                    <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Agendamentos ? 'underline' : '', backgroundColor: props.Agendamentos ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/agendamentos/listar">Agendamentos
                    </NavDropdown.Item>


                    <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Equipes ? 'underline' : '', backgroundColor: props.Equipes ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/equipes/listar">Equipes
                    </NavDropdown.Item>


                    <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Solicitacoes ? 'underline' : '', backgroundColor: props.Solicitacoes ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/solicitacoes/listar">Solicitações
                    </NavDropdown.Item>

                    <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Procedimentos ? 'underline' : '', backgroundColor: props.Procedimentos ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/procedimentos/listar">Procedimentos
                    </NavDropdown.Item>

                    <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Veiculos ? 'underline' : '', backgroundColor: props.Veiculos ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/frota/veiculos/listar">Veículos
                    </NavDropdown.Item>

                    <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Rotas ? 'underline' : '', backgroundColor: props.Rotas ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/frota/rotas/listar">Rotas
                    </NavDropdown.Item>


                    <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.RondaVirtual ? 'underline' : '', backgroundColor: props.RondaVirtual ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/rondavirtual/listar">Ronda Virtual
                    </NavDropdown.Item>

                    <NavDropdown
                      title="Notificações"
                      id="nav-dropdown"
                      style={{ textDecoration: props.Notificacoes ? 'underline' : '', backgroundColor: props.Notificacoes ? 'orange' : 'white' }}

                    >
                      <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Notificacoes ? 'underline' : 'white', backgroundColor: props.Notificacoes ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/notificacoes/listar">Notificações
                      </NavDropdown.Item>


                      <NavDropdown
                        title="Tabelas Auxiliares"
                        id="nav-dropdown"
                        style={{ textDecoration: props.TabelasAuxiliares ? 'underline' : '', backgroundColor: props.TabelasAuxiliares ? 'orange' : 'white' }}

                      >
                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.TipoOcorrencias ? 'underline' : 'white', backgroundColor: props.TipoOcorrencias ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/notificacoes/tiposocorrencias/listar">Tipos de Ocorrências
                        </NavDropdown.Item>


                      </NavDropdown>

                    </NavDropdown>

                  </NavDropdown>


                  {regra.includes("ADMIN")
                    && (
                      <NavDropdown
                        title="Almoxarifado"
                        id="nav-dropdown"
                        style={{ textDecoration: props.Almoxarifado ? 'underline' : '', backgroundColor: props.Almoxarifado ? 'orange' : 'white' }}

                      >

                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Insumos ? 'underline' : '', backgroundColor: props.Insumos ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/almoxarifado/insumos/listar">Insumos
                        </NavDropdown.Item>

                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Armazenamento ? 'underline' : '', backgroundColor: props.Armazenamento ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/almoxarifado/armazenamento/listar">Armazenamento
                        </NavDropdown.Item>

                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.OpEntrada ? 'underline' : '', backgroundColor: props.OpEntrada ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/almoxarifado/entradaestoque/listar">Operações de Entrada
                        </NavDropdown.Item>

                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Estoque ? 'underline' : '', backgroundColor: props.Estoque ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/almoxarifado/estoque/listar">Estoque
                        </NavDropdown.Item>

                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Fornecedores ? 'underline' : '', backgroundColor: props.Fornecedores ? 'orange' : 'white' }} id="nav-link" href="/gestao/operacional/almoxarifado/fornecedores/listar/1">Fornecedores
                        </NavDropdown.Item>



                      </NavDropdown>
                    )
                  }

                  {(regra.includes("ADMIN") || regra.includes("TEC_OS"))
                    && (
                      <NavDropdown
                        title="Gestão"
                        id="nav-dropdown"
                        style={{ textDecoration: props.Gestao ? 'underline' : '', backgroundColor: props.Gestao ? 'orange' : 'white' }}

                      >
                        {(regra.includes("SUPER_ADMIN") || regra.includes("ADMIN_OC"))
                          && (
                            <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.OrdensCompras ? 'underline' : 'white', backgroundColor: props.OrdensCompras ? 'orange' : 'white' }} id="nav-link" href="/gestao/administracao/ordensdecompras/listar">Ordens de Compras
                            </NavDropdown.Item>
                          )
                        }

                        {(regra.includes("SUPER_ADMIN") || regra.includes("ADMIN_OS") || regra.includes("TEC_OS"))
                          && (
                            <NavDropdown.Item eventKey="2.0" style={{ textDecoration: props.OrdensServicos ? 'underline' : 'white', backgroundColor: props.OrdensServicos ? 'orange' : 'white' }} id="nav-link" href="/gestao/administracao/ordensdeservicos/listar">Ordens de Serviços
                            </NavDropdown.Item>
                          )
                        }
                      </NavDropdown>
                    )
                  }


                  {(regra.includes("SUPER_ADMIN") || regra.includes("ADMIN_RH"))
                    && (
                      <NavDropdown
                        title="RH"
                        id="nav-dropdown"
                        style={{ textDecoration: props.RH ? 'underline' : '', backgroundColor: props.RH ? 'orange' : 'white' }}

                      >
                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Colaboradores ? 'underline' : 'white', backgroundColor: props.Colaboradores ? 'orange' : 'white' }} id="nav-link" href="/gestao/rh/colaboradores/listar">Colaboradores
                        </NavDropdown.Item>


                        <NavDropdown
                          title="Tabelas Auxiliares"
                          id="nav-dropdown"
                          style={{ textDecoration: props.TabelasAuxiliaresRh ? 'underline' : '', backgroundColor: props.TabelasAuxiliaresRh ? 'orange' : 'white' }}

                        >
                          <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Funcoes ? 'underline' : 'white', backgroundColor: props.Funcoes ? 'orange' : 'white' }} id="nav-link" href="/gestao/rh/funcoes/listar">Funções
                          </NavDropdown.Item>

                          <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Departamentos ? 'underline' : 'white', backgroundColor: props.Departamentos ? 'orange' : 'white' }} id="nav-link" href="/gestao/rh/departamentos/listar">Departamentos
                          </NavDropdown.Item>

                          <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Cargos ? 'underline' : 'white', backgroundColor: props.Cargos ? 'orange' : 'white' }} id="nav-link" href="/gestao/rh/cargos/listar">Cargos
                          </NavDropdown.Item>

                        </NavDropdown>

                      </NavDropdown>

                    )
                  }

                  {(regra.includes("SUPER_ADMIN") || regra.includes("ADMIN_FINAN"))
                    && (
                      <NavDropdown
                        title="Finanças"
                        id="nav-dropdown"
                        style={{ textDecoration: props.Financas ? 'underline' : '', backgroundColor: props.Financas ? 'orange' : 'white' }}

                      >


                        <NavDropdown
                          title="Tabelas Auxiliares"
                          id="nav-dropdown"
                          style={{ textDecoration: props.TabelasAuxiliaresFinancas ? 'underline' : '', backgroundColor: props.TabelasAuxiliaresFinancas ? 'orange' : 'white' }}

                        >
                          <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.MetodosPagamento ? 'underline' : 'white', backgroundColor: props.MetodosPagamento ? 'orange' : 'white' }} id="nav-link" href="/gestao/financas/metodospagamento/listar">Métodos de Pagamento
                          </NavDropdown.Item>

                          <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.CondicoesPagamento ? 'underline' : 'white', backgroundColor: props.CondicoesPagamento ? 'orange' : 'white' }} id="nav-link" href="/gestao/financas/condicoespagamento/listar">Condições de Pagamento
                          </NavDropdown.Item>

                          <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.GrupoContas ? 'underline' : 'white', backgroundColor: props.GrupoContas ? 'orange' : 'white' }} id="nav-link" href="/gestao/financas/grupocontas/listar">Grupo de Contas
                          </NavDropdown.Item>


                          <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Contas ? 'underline' : 'white', backgroundColor: props.Contas ? 'orange' : 'white' }} id="nav-link" href="/gestao/financas/contas/listar">Contas
                          </NavDropdown.Item>

                          <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.InstituicaoBancaria ? 'underline' : 'white', backgroundColor: props.InstituicaoBancaria ? 'orange' : 'white' }} id="nav-link" href="/gestao/financas/instituicaobancaria/listar">Instituições Bancárias
                          </NavDropdown.Item>

                        </NavDropdown>

                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Lancamento ? 'underline' : 'white', backgroundColor: props.Lancamento ? 'orange' : 'white' }} id="nav-link" href="/gestao/financas/lancamento/listar">Lançamentos
                        </NavDropdown.Item>

                      </NavDropdown>

                    )
                  }



                  {regra.includes("SUPER_ADMIN")
                    && (
                      <NavDropdown
                        title={
                          <SettingsOutlinedIcon sx={{ fontSize: 24, color: 'black' }} />
                        }
                        id="nav-dropdown"
                        style={{ textDecoration: props.TabelasAuxiliares ? 'underline' : '', backgroundColor: props.TabelasAuxiliares ? 'orange' : 'white' }}

                      >
                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Configuracoes ? 'underline' : 'white', backgroundColor: props.Configuracoes ? 'orange' : 'white' }} id="nav-link" href="/administracao/configuracoes">Configurações
                        </NavDropdown.Item>

                        <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.Usuarios ? 'underline' : 'white', backgroundColor: props.Usuarios ? 'orange' : 'white' }} id="nav-link" href="/administracao/usuarios">Usuários
                        </NavDropdown.Item>

                      </NavDropdown>

                    )
                  }



                  <NavDropdown
                    title={
                      <AccountCircleIcon sx={{ fontSize: 24, color: 'black' }} />
                    }
                    id="nav-dropdown"
                    style={{ textDecoration: props.TabelasAuxiliares ? 'underline' : '', backgroundColor: props.TabelasAuxiliares ? 'orange' : 'white' }}

                  >
                    <NavDropdown.Item eventKey="1.0" style={{ textDecoration: props.MinhaConta ? 'underline' : 'white', backgroundColor: props.MinhaConta ? 'orange' : 'white' }} id="nav-link" href="/minhaconta">Minha Conta
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      eventKey="1.0"
                      style={{
                        textDecoration: props.Sair ? 'underline' : 'white',
                        backgroundColor: props.Sair ? 'orange' : 'white'
                      }}
                      id="nav-link"
                      onClick={onConfirmSair}
                    >
                      Sair
                    </NavDropdown.Item>

                  </NavDropdown>




                  <NavDropdown id="nav-dropdown"
                    drop='start'

                    title={
                      <NotificationsIcon
                        className={tarefas.length > 0 ? "shake-bell" : ""}
                        style={{ color: "green" }}
                        sx={{ fontSize: 24, color: 'green' }} />
                    }


                  >
                    <div > {/* Defina a largura desejada */}

                      {
                        loading && tarefas ?
                          null
                          :
                          tarefas.map((tarefa) => {
                            return (
                              <NavDropdown.Item
                                onClick={(ev) => handleOpenAlert(tarefa)}
                                style={{ backgroundColor: 'white', color: 'black', paddingRight: 40 }}
                                key={tarefa.id_tarefa} eventKey={tarefa.id_tarefa}
                              >
                                <Grid item xs={12} container spacing={2} alignItems="center" style={{ marginRight: 30, marginLeft: 30 }}>
                                  <Grid item xs={6}>
                                    <Typography variant="h7">
                                      {tarefa.nome}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} container justifyContent="flex-end">
                                    <img style={{ height: '30px', marginInline: 5 }} alt={'mini_icone_informacao'} src={mini_icone_informacao} />
                                  </Grid>
                                </Grid>
                              </NavDropdown.Item>
                            )
                          })
                      }
                    </div>



                  </NavDropdown>




                </Nav>

              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Grid >
      </Grid >


      <div>

        {tarefaEmVista &&
          <Dialog
            open={alertOpen}
            onClose={handleCloseAlert}
            aria-labelledby="confirm-dialog"
          >
            <DialogTitle id="confirm-dialog">{ }</DialogTitle>
            <DialogContent >
              <div >

                <Grid container spacing={2}
                  style={{ padding: 20 }}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  item xs={12} >
                  <Grid

                    item xs={6}

                  >
                    <Typography
                      variant="h5"
                    >
                      {tarefaEmVista.nome}
                    </Typography>
                  </Grid>

                  <Grid

                    item xs={6}

                  >
                    <div style={{ display: 'flex', justifyContent: 'center', margin: 0, width: '40px', height: '40px' }}>
                      <img alt={'mini_icone_informacao'} style={{ textAlign: 'center' }} src={mini_icone_informacao}></img>
                    </div>
                  </Grid>



                  <Grid

                    item xs={12}

                  >
                    <Typography
                      variant="h6"
                      component="div"
                      style={{ color: 'black' }}
                    >
                      {tarefaEmVista.descricao.split('\n').map((line, index) => {
                        const parts = line.split('\n'); // Dividindo a linha pela quebra de linha
                        return (
                          <React.Fragment key={index}>
                            <React.Fragment>
                              <a
                                href={processarLink()} // Adicione o link aqui
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'underline', color: 'blue' }}
                              >
                                {parts[0]} {/* Somente a primeira parte é o link */}
                              </a>
                              {parts[1]} {/* Mostra o restante do texto */}
                            </React.Fragment>
                            {/* Adiciona uma quebra de linha, se não for a última linha */}
                            {index !== tarefaEmVista.descricao.split('\n').length - 1 && <br />}
                          </React.Fragment>
                        );
                      })}
                    </Typography>
                  </Grid>
                  {tarefaEmVista.ordem_servico?.solicitacao.id_solicitacao === 7 &&
                    tarefaEmVista.ordem_servico?.id_ordem_compra === 0
                    &&
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      item xs={6}
                    >
                      <Button style={{ marginTop: 20 }}
                        variant="contained"
                        color="primary"
                        onClick={criarOCporOs}

                      >
                        Criar a Ordem de Compra
                      </Button>
                    </Grid>
                  }
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={6}
                  >
                    <Button style={{ marginTop: 20 }}
                      variant="contained"
                      color="primary"
                      onClick={handleCloseAlert}

                    >
                      OK
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>

          </Dialog >
        }

      </div>


      {openBalao && (
         <Draggable>
        <Box
          sx={{
            position: "fixed",
            top: "20px",
            left: "20px",
            backgroundColor: "#fff",
            border: "2px solid #000", // Borda preta mais espessa
            borderRadius: "8px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)", // Aumenta a sombra
            padding: "16px",
            zIndex: 9999, // Garante que fique por cima de outros elementos
          }}


        >
          <Typography variant="body1" gutterBottom>
            {"Nova Ronda Virtual nº " + rondaVirtualPendente.id_ronda_virtual + " pendente"}
            <br />
            {"Localidade: " + returnDescricaoInstalacaoResumida(rondaVirtualPendente.instalacao)}
            <br />
            {"Grupo: " + rondaVirtualPendente.instalacao.grupo_cliente.nome}
          </Typography>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "8px",
            }}
            item
            container
            xs={12}
          >
            {!justificativa && (
              <Button
                variant="contained"
                color="primary"
                disabled={justificativa}
                onClick={handleBalaoOk}
                sx={{ marginRight: "8px" }}
              >
                Assumir
              </Button>
            )}
            {!justificativa && (
              <Button variant="outlined" color="secondary" onClick={handleJustificativa}>
                Agora não
              </Button>
            )}
            {justificativa && (
              <Grid
                item
                container
                xs={12}
                style={{ paddingTop: 2 }}
              >
                <TextField
                  variant="standard"
                  name="justificativa"
                  label="Justificativa"
                  required
                  id="justificativa"
                  value={mensagemJustificativa}
                  onChange={(e) => setMensagemJustificativa(e.target.value)}
                  fullWidth
                  multiline
                  minRows={2}
                />
              </Grid>
            )}
            {justificativa && (
              <Button variant="outlined" color="secondary" onClick={(e) => handleFinalizarJustificativa()}>
                Finalizar
              </Button>
            )}

          </Grid>
        </Box>
        </Draggable>
      )}


      {openRondaVirtualEmAndamento &&  !location.pathname.includes('rondavirtual') && (
          <Draggable>
        <Box
          sx={{
            position: "fixed",
            top: "20px",
            left: "20px",
            backgroundColor: "#fff",
            border: "2px solid #000", // Borda preta mais espessa
            borderRadius: "8px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)", // Aumenta a sombra
            padding: "16px",
            zIndex: 9999, // Garante que fique por cima de outros elementos
          }}


        >
          <Typography variant="body1" gutterBottom>
            {"Você tem uma Ronda Virtual está em andamento!"}
            <br />
            {"Ronda Virtual nº " + rondaVirtualEmAndamento.id_ronda_virtual}
            <br />
            {"Localidade: " + returnDescricaoInstalacaoResumida(rondaVirtualEmAndamento.instalacao)}
            <br />
            {"Grupo: " + rondaVirtualEmAndamento.instalacao.grupo_cliente.nome}
          </Typography>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "8px",
            }}
            item
            container
            xs={12}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleIrParaGestaoRondaVirtual()}
              sx={{ marginRight: "8px" }}
            >
              Ir para gestão da Ronda Virtual
            </Button>
          </Grid>
        </Box>
        </Draggable>
      )}


    </div >
  )
}
export default NavegadorGestao;