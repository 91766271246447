
import React, { useState } from 'react';


import icone_local_protegido from '../../../../../../assets/imgs/icone_local_protegido.png';

import icone_local_alarme from '../../../../../../assets/imgs/icone_local_alarme.png';
import icone_offline from '../../../../../../assets/imgs/icone_offline.png';

import icone_local_desprotegido from '../../../../../../assets/imgs/icone_local_desprotegido.png';
import Grid from '@material-ui/core/Grid';



    
export default function CabecalhoInformacoes(props) {
    const pgmsArmadas = props.pgmsArmadas;
    const pgmsDesarmadas = props.pgmsDesarmadas;
    const pgmsOfflines = props.pgmsOfflines;
    const pgmsAlarmadas = props.pgmsAlarmadas;

 

    return (
        <Grid
                        container
                        direction="row"
                        alignItems="center"
                        item xs={12}
                        justifyContent={"center"}
                        style={{ padding: 1, margin: 1 }}
                        spacing={2}
                    >
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            item
                            xs={2}
                            justifyContent={"center"}
                            style={{ backgroundColor: 'green', padding: 1, margin: 1 , paddingBottom: '10px'}}
                        >
                            <img
                                alt={"icone"}
                                style={{
                                    textAlign: 'center',
                                    width: '70px', // Define a largura da imagem
                                    height: '70px', // Define a altura da imagem
                                    paddingTop: '10px'
                                }}
                                src={icone_local_protegido}
                            />

                            {/* Container para alinhar os textos */}
                            <div style={{ textAlign: "center" }}>
                                {/* Número de instalações */}
                                <span
                                    style={{
                                        fontSize: 36,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        display: "block", // Para alinhar em bloco
                                        marginBottom: "1px" // Controla a distância vertical
                                    }}
                                >
                                    {pgmsArmadas}
                                </span>

                                {/* Texto 'instalações' */}
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        paddingLeft: "10px"
                                    }}
                                >
                                    locais protegidos
                                </span>
                            </div>
                        </Grid>


                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            item
                            xs={2}
                            justifyContent={"center"}
                            style={{ backgroundColor: 'orange', padding: 1, margin: 1, paddingBottom: '10px' }}
                        >
                            <img
                                alt={"icone"}
                                style={{
                                    textAlign: 'center',
                                    width: '70px', // Define a largura da imagem
                                    height: '70px', // Define a altura da imagem
                                    paddingTop: '10px'
                                }}
                                src={icone_local_desprotegido}
                            />

                            {/* Container para alinhar os textos */}
                            <div style={{ textAlign: "center" }}>
                                {/* Número de instalações */}
                                <span
                                    style={{
                                        fontSize: 36,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        display: "block", // Para alinhar em bloco
                                        marginBottom: "1px" // Controla a distância vertical
                                    }}
                                >
                                    {pgmsDesarmadas}
                                </span>

                                {/* Texto 'instalações' */}
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        paddingLeft: "10px" // Recuo horizontal para direita
                                    }}
                                >
                                    locais desarmados
                                </span>
                            </div>
                        </Grid>


                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            item
                            xs={2}
                            justifyContent={"center"}
                            style={{ backgroundColor: 'purple', padding: 1, margin: 1, paddingBottom: '10px' }}
                        >
                            <img
                                alt={"icone"}
                                style={{
                                    textAlign: 'center',
                                    width: '70px', // Define a largura da imagem
                                    height: '70px', // Define a altura da imagem
                                    paddingTop: '10px'
                                }}
                                src={icone_offline}
                            />

                            {/* Container para alinhar os textos */}
                            <div style={{ textAlign: "center" }}>
                                {/* Número de instalações */}
                                <span
                                    style={{
                                        fontSize: 36,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        display: "block", // Para alinhar em bloco
                                        marginBottom: "1px" // Controla a distância vertical
                                    }}
                                >
                                    {pgmsOfflines}
                                </span>

                                {/* Texto 'instalações' */}
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        paddingLeft: "10px" // Recuo horizontal para direita
                                    }}
                                >
                                    {"locais desconectados"}
                                </span>
                            </div>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            item
                            xs={2}
                            justifyContent={"center"}
                            style={{ backgroundColor: 'red', padding: 1, margin: 1, paddingBottom: '10px' }}
                        >
                            <img
                                alt={"icone"}
                                style={{
                                    textAlign: 'center',
                                    width: '70px', // Define a largura da imagem
                                    height: '70px', // Define a altura da imagem
                                    paddingTop: '10px'
                                }}
                                src={icone_local_alarme}
                            />

                            {/* Container para alinhar os textos */}
                            <div style={{ textAlign: "center" }}>
                                {/* Número de instalações */}
                                <span
                                    style={{
                                        fontSize: 36,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        display: "block", // Para alinhar em bloco
                                        marginBottom: "1px" // Controla a distância vertical
                                    }}
                                >
                                    {pgmsAlarmadas}
                                </span>

                                {/* Texto 'instalações' */}
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        paddingLeft: "10px" // Recuo horizontal para direita
                                    }}
                                >
                                    locais em alarme
                                </span>
                            </div>
                        </Grid>

                    </Grid>
    );
};

