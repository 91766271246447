import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Chip from '@mui/material/Chip';

import Skeleton from '@material-ui/lab/Skeleton';
 import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddIcon from "@mui/icons-material/Add";

import {
    Popover,
    FormLabel,
    RadioGroup,
    Radio,
    IconButton,
} from "@mui/material";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import {
    MaterialReactTable,

} from 'material-react-table';
import { FormControl } from '@mui/material';

import { Button, MenuItem, Select, InputLabel } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { styled } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import TextField from '@material-ui/core/TextField';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Box,
     Tooltip
} from '@mui/material';

 
const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));


const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'whit',
        borderColor: 'blue',
    },
}));


function novoGrupo() {
    window.location.href = "/gestao/administracao/clientes/gruposclientes/cadastrar"

}

export default function GruposClientes() {



    const [loading, setLoading] = useState(true);
    const [objetos, setObjetos] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [nome, setNome] = useState("");

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setNome("");
    };

 
    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/grupocliente/listartodos", {
                headers: headers
            }).then(function (response) {
                setObjetos(response.data)

                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Solicitações: " + _err)

        }

    }


    useEffect(() => {


 

        listarInformacoes();


    }, []);







    const columns = useMemo(
        () => [


          
            {
                accessorKey: 'id_grupo_cliente', //normal accessorKey
                header: 'ID',
                size: 60,
            },




            {
                accessorKey: 'nome', // normal accessorKey
                header: 'Nome',
                size: 180,
            },
          
        ],
        [],
    );




    async function funcaoSalvar() {

        try {

            console.log("cadastrar chamado");
            const cadastro = {
                nome: nome,
            }


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/grupocliente/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                handleClose();
                alert("Grupo de Clientes cadastrado!");
                 listarInformacoes();
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
             alert("Erro de Conexão, tente novamente mais tarde");
        }



    }



    return (
        <div>
            <NavegadorGestao
                TabelasAuxiliaresClientes={true}
                GruposClientes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ padding: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                      
                      <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={12}
                                style={{ marginTop: 1 }}
                            >
                                
                                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                    >
                        Novo Grupo
                    </Button>
                </Grid>

                                <Popover
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                >
                                    <Box sx={{ p: 2, width: 300 }}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Nome"
                                                value={nome}
                                                onChange={(e) => setNome(e.target.value)}
                                                variant="outlined"
                                                margin="normal"
                                            />
                                       
                                          
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                sx={{ mt: 2 }}
                                                onClick={(e) => funcaoSalvar()}
                                            >
                                                Inserir
                                            </Button>
                                        </FormControl>
                                    </Box>
                                </Popover>
                            </Grid>

                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div style={{ height: '100%', width: '100%' }}>
                          {objetos && (
                                <MaterialReactTable
                                    columns={columns}
                                    data={objetos}
                                    enableGlobalFilterModes
                                    enablePagination={true}
                                    enableRowActions
                                    paginationDisplayMode={'pages'}
                                    getRowId={(row) => row.id_grupo_cliente}
                                    enableTopToolbar={true}
                                    enableBottomToolbar={true}
                                    enableColumnFilters={true}
                                    enableColumnActions={false}
                                    sortable={true}
                                    enableSorting={true}
                                    enableRowSelection={false}
                                    enableColumnFilterModes={true}
                                    enableExpandAll={true}
                                    enableColumnPinning={true}
                                    enableColumnResizing={true}
                                    initialState={{
                                        pagination: { pageSize: 100, pageIndex: 0 }, // Correção da sintaxe aqui
                                        showGlobalFilter: true,
                                        density: 'compact',
                                    }}

                                    muiPaginationProps={{
                                        rowsPerPageOptions: [100, 500, 1000],
                                        variant: 'outlined',
                                    }}
                                    enableFullScreenToggle={true}
                                    renderDetailPanel={({ row }) => (
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                left: '30px',
                                                maxWidth: '1000px',
                                                position: 'sticky',
                                                width: '100%',
                                            }}
                                        >
                                        </Box>
                                    )}
                                    renderRowActions={({ row }) => (
                                        <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>
                                            <Tooltip title="Gerenciar">
                                                <IconButton
                                                    color="primary"
                                                    href={`/gestao/administracao/clientes/gruposclientes/editar/${row.original.id_grupo_cliente}`}
                                                >
                                                    <SettingsIcon />
                                                </IconButton>
                                            </Tooltip>

                                        </Box>
                                    )}

                                />
                            )}

                    </div>
                }


            </div>



        </div>
    );
}



