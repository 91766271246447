import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { format, addDays, isValid, parse } from 'date-fns';


import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import { useParams } from "react-router-dom";

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

export default function AtualizarProcedimentoExecucao() {


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    const [botaoClicado, setBotaoClicado] = useState(false);




    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };

    const { idProcedimentoExecucacao } = useParams();
    const { idOrdemServico } = useParams();
    const { telaRetorno } = useParams();
    const { idEntradaEstoque } = useParams();


    const [itemOrdemCompra, setItemOrdemCompra] = useState(null);

    const [unidadeBase, setUnidadebase] = useState(null);
    const [ordemServico, setOrdemServico] = useState(null);
    const [instalacaoSelecionada, setInstalacaoSelecionada] = useState(null);

    const [armazenamentoSelecionado, setArmazenamentoSelecionado] = useState(null);
    const [armazenamentos, setArmazenamentos] = useState([]);

    const [dataInicial, setDataInicial] = useState(format(new Date(), 'dd/MM/yyyy'));
    const [horaInicial, setHoraInicial] = useState("08:00");
    const [dataFinal, setDataFinal] = useState(format(addDays(new Date(), 7), 'dd/MM/yyyy'));
    const [horaFinal, setHoraFinal] = useState("12:00");

    const classes = useStyles();
    const history = useHistory();

    const [observacoes, setObservacoes] = useState('');
    const [resultado, setResultado] = useState(1);
    const [quantidadesAceitos, setQuantidadesAceitos] = useState("");
    const [quantidadesAveriguados, setQuantidadesAveriguados] = useState("");
    const [quantidadesRecebidos, setQuantidadesRecebidos] = useState("");



    const [descricaoImagem1, setDescricaoImagem1] = useState('');
    const [descricaoImagem2, setDescricaoImagem2] = useState('');
    const [descricaoImagem3, setDescricaoImagem3] = useState('');
    const [descricaoImagem4, setDescricaoImagem4] = useState('');


    const [descricaoVideo1, setDescricaoVideo1] = useState('');
    const [descricaoVideo2, setDescricaoVideo2] = useState('');
    const [descricaoVideo3, setDescricaoVideo3] = useState('');


    const [descricaoArquivo1, setDescricaoArquivo1] = useState('');
    const [descricaoArquivo2, setDescricaoArquivo2] = useState('');
    const [descricaoArquivo3, setDescricaoArquivo3] = useState('');


    const descriptions = [
        "",
        "Tentativa de Realizar o Procedimento, não realizado, pois não é adequado",
        "Procedimento Realizado, mas não é necessário",
        "Procedimento Realizado, mas não é eficaz para essa solicitação",
        "Procedimento realizado, é necessário ,mas deve ser melhorado",
        "Procedimento realizado, é essencial para resolver a solicitação"
    ];

    const handleChangeStart = (event, newValue) => {
        console.log("nota: " + newValue);
        if (!newValue)
            newValue = nota;
        setNota(newValue); // Deselect if clicking on the selected star again
        setDescricaoNota(descriptions[newValue]);
    };

    const [descricaoNota, setDescricaoNota] = useState(descriptions[5]);

    const [nota, setNota] = useState(5);
    const [comentarioNota, setComentarioNota] = useState("");

    const [obrigatorio, setObrigatorio] = useState(0);

    const handleChangeResultado = (event) => {
        setResultado(event.target.value);
    };

    const [imagemSelecionada1, setImagemSelecionada1] = useState(null);
    const [imagemSelecionada2, setImagemSelecionada2] = useState(null);
    const [imagemSelecionada3, setImagemSelecionada3] = useState(null);
    const [imagemSelecionada4, setImagemSelecionada4] = useState(null);


    const onDrop1 = useCallback((acceptedFiles) => {
        // Obtém o arquivo recebido
        const file = acceptedFiles[0];
        if (!file) {
            alert("Nenhum arquivo recebido.");
            return;
        }

        // Converte a imagem para PNG usando Canvas
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenha a imagem no Canvas
                ctx.drawImage(img, 0, 0);

                // Converte o Canvas para PNG
                canvas.toBlob(
                    (blob) => {
                        const convertedFile = new File([blob], `${file.name.split('.')[0]}.png`, { type: "image/png" });
                        setImagemSelecionada1(convertedFile);
                        console.log("Imagem convertida para PNG:", convertedFile);
                    },
                    "image/png",
                    1 // Qualidade máxima
                );
            };
        };
        reader.readAsDataURL(file);
    }, []);

    const onDrop2 = useCallback((acceptedFiles) => {
        // Obtém o arquivo recebido
        const file = acceptedFiles[0];
        if (!file) {
            alert("Nenhum arquivo recebido.");
            return;
        }

        // Converte a imagem para PNG usando Canvas
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenha a imagem no Canvas
                ctx.drawImage(img, 0, 0);

                // Converte o Canvas para PNG
                canvas.toBlob(
                    (blob) => {
                        const convertedFile = new File([blob], `${file.name.split('.')[0]}.png`, { type: "image/png" });
                        setImagemSelecionada2(convertedFile);
                        console.log("Imagem convertida para PNG:", convertedFile);
                    },
                    "image/png",
                    1 // Qualidade máxima
                );
            };
        };
        reader.readAsDataURL(file);
    }, []);

    const onDrop3 = useCallback((acceptedFiles) => {
        // Obtém o arquivo recebido
        const file = acceptedFiles[0];
        if (!file) {
            alert("Nenhum arquivo recebido.");
            return;
        }

        // Converte a imagem para PNG usando Canvas
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenha a imagem no Canvas
                ctx.drawImage(img, 0, 0);

                // Converte o Canvas para PNG
                canvas.toBlob(
                    (blob) => {
                        const convertedFile = new File([blob], `${file.name.split('.')[0]}.png`, { type: "image/png" });
                        setImagemSelecionada3(convertedFile);
                        console.log("Imagem convertida para PNG:", convertedFile);
                    },
                    "image/png",
                    1 // Qualidade máxima
                );
            };
        };
        reader.readAsDataURL(file);
    }, []);

    const onDrop4 = useCallback((acceptedFiles) => {
        // Obtém o arquivo recebido
        const file = acceptedFiles[0];
        if (!file) {
            alert("Nenhum arquivo recebido.");
            return;
        }

        // Converte a imagem para PNG usando Canvas
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;

                // Desenha a imagem no Canvas
                ctx.drawImage(img, 0, 0);

                // Converte o Canvas para PNG
                canvas.toBlob(
                    (blob) => {
                        const convertedFile = new File([blob], `${file.name.split('.')[0]}.png`, { type: "image/png" });
                        setImagemSelecionada4(convertedFile);
                        console.log("Imagem convertida para PNG:", convertedFile);
                    },
                    "image/png",
                    1 // Qualidade máxima
                );
            };
        };
        reader.readAsDataURL(file);
    }, []);



    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: onDrop1,

        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: 'image/*', // Restringe a aceitação apenas para arquivos .png
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: onDrop2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: 'image/*', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({
        onDrop: onDrop3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: 'image/*', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps4, getInputProps: getInputProps4 } = useDropzone({
        onDrop: onDrop4,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: 'image/*', // Restringe a aceitação apenas para arquivos .png
    });



    const [videoSelecionado1, setVideoSelecionado1] = useState(null);
    const [videoSelecionado2, setVideoSelecionado2] = useState(null);
    const [videoSelecionado3, setVideoSelecionado3] = useState(null);


    // Função para lidar com o "paste"
    const handlePaste = useCallback(
        (event) => {
            const clipboardItems = event.clipboardData.items;

            for (const item of clipboardItems) {
                if (item.type.startsWith("image/")) {
                    const file = item.getAsFile();
                    if (file) {
                        // Converte para PNG usando Canvas (caso necessário)
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            const img = new Image();
                            img.src = e.target.result;
                            img.onload = () => {
                                const canvas = document.createElement("canvas");
                                const ctx = canvas.getContext("2d");
                                canvas.width = img.width;
                                canvas.height = img.height;

                                // Desenha a imagem no Canvas
                                ctx.drawImage(img, 0, 0);

                                // Converte o Canvas para PNG
                                canvas.toBlob(
                                    (blob) => {
                                        const convertedFile = new File(
                                            [blob],
                                            `imagem-${Date.now()}.png`,
                                            { type: "image/png" }
                                        );

                                        // Define a próxima imagem disponível
                                        if (imagemSelecionada1 === null) {
                                            setImagemSelecionada1(convertedFile);
                                        } else if (imagemSelecionada2 === null) {
                                            setImagemSelecionada2(convertedFile);
                                        } else if (imagemSelecionada3 === null) {
                                            setImagemSelecionada3(convertedFile);
                                        } else if (imagemSelecionada4 === null) {
                                            setImagemSelecionada4(convertedFile);
                                        } else {
                                            alert("Todas as imagens já foram preenchidas!");
                                        }
                                    },
                                    "image/png",
                                    1
                                );
                            };
                        };
                        reader.readAsDataURL(file);
                    }
                }
            }
        },
        [imagemSelecionada1, imagemSelecionada2, imagemSelecionada3, imagemSelecionada4] // Adicionar dependências
    );

    // Listener para evento "paste"
    useEffect(() => {
        window.addEventListener("paste", handlePaste);
        return () => {
            window.removeEventListener("paste", handlePaste);
        };
    }, [handlePaste]);


    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const recordedChunksRef = useRef([]);

    const startRecording = async () => {
        try {
            // Captura a tela
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true, // Inclui o áudio da tela, se disponível
            });

            // Captura o microfone
            const audioStream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });

            // Combina os streams de tela e microfone
            const combinedStream = new MediaStream([
                ...screenStream.getTracks(),
                ...audioStream.getTracks(),
            ]);

            // Configura o MediaRecorder
            const mediaRecorder = new MediaRecorder(combinedStream, {
                mimeType: "video/webm",
            });

            // Configura os eventos de gravação
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    recordedChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                // Cria um blob com os dados gravados
                const blob = new Blob(recordedChunksRef.current, {
                    type: "video/webm",
                });
                recordedChunksRef.current = [];

                // Cria um arquivo a partir do blob
                const file = new File([blob], `video-${Date.now()}.webm`, {
                    type: "video/webm",
                });

                // Salva o arquivo em videoSelecionado1
             
                // Salva o arquivo em videoSelecionado1
                if (!videoSelecionado1)
                    setVideoSelecionado1(file);
                else if (!videoSelecionado2) {
                    setVideoSelecionado2(file);
                } else if(!videoSelecionado3){
                    setVideoSelecionado3(file);
                }

                // Limpa os streams
                screenStream.getTracks().forEach((track) => track.stop());
                audioStream.getTracks().forEach((track) => track.stop());
            };

            // Inicia a gravação
            mediaRecorder.start();
            mediaRecorderRef.current = mediaRecorder;
            setIsRecording(true);
        } catch (error) {
            console.error("Erro ao iniciar a gravação:", error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    const onDropVideo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropVideo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropVideo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado3(acceptedFiles[0]);
    }, []);


    const { getRootProps: getRootVideoProps1, getInputProps: getInputVideoProps1 } = useDropzone({
        onDrop: onDropVideo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps2, getInputProps: getInputVideoProps2 } = useDropzone({
        onDrop: onDropVideo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps3, getInputProps: getInputVideoProps3 } = useDropzone({
        onDrop: onDropVideo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });



    const [arquivoSelecionado1, setArquivoSelecionado1] = useState(null);
    const [arquivoSelecionado2, setArquivoSelecionado2] = useState(null);
    const [arquivoSelecionado3, setArquivoSelecionado3] = useState(null);


    const onDropArquivo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropArquivo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropArquivo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado3(acceptedFiles[0]);
    }, []);



    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps2, getInputProps: getInputArquivoProps2 } = useDropzone({
        onDrop: onDropArquivo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps3, getInputProps: getInputArquivoProps3 } = useDropzone({
        onDrop: onDropArquivo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });



    function novoObjeto() {
        if (procedimentoExecucao.procedimento.id_procedimento === 1) {
            //nova equipe
            window.open("/gestao/operacional/equipes/cadastrar", '_blank');

        } else if (procedimentoExecucao.procedimento.id_procedimento === 2) {
            //novo veiculo
            window.open("/gestao/operacional/frota/veiculos/cadastrarveiculo", '_blank');
        } else if (procedimentoExecucao.procedimento.id_procedimento === 3) {
            //nova rota

            window.open("/gestao/operacional/frota/rotas/cadastrarrota/2/" + idOrdemServico + "/" + procedimentoExecucao.id_procedimento_execucao, '_blank');
        } else if (procedimentoExecucao.procedimento.id_procedimento === 7) {
            //novo agendamento completo
            window.open("/gestao/operacional/agendamentos/cadastrar/2/" + idOrdemServico + "/" + procedimentoExecucao.id_procedimento_execucao, '_blank');
        }
    }


    async function funcaoSalvar() {


        try {

            console.log("cadastrar chamado");

            const formData = new FormData();

            // Adicionar os dados do procedimento
            formData.append('id_procedimento_execucao', idProcedimentoExecucacao);
            formData.append('resultado', resultado);
            formData.append('usuario_executor.id_usuario', Cookies.get('id_usuario'));
            formData.append('observacoes', observacoes);
            formData.append("nota", nota);
            formData.append("comentario_nota", comentarioNota);

            if(parseInt(procedimentoExecucao.procedimento.exigir_comprovacao === 1,10) === 1){

                if (imagemSelecionada1 || imagemSelecionada2 || imagemSelecionada3 || imagemSelecionada4
                    || videoSelecionado1 || videoSelecionado2 || videoSelecionado3 ||
                    arquivoSelecionado1 || arquivoSelecionado2 || arquivoSelecionado3
                ) {
    
                } else {
                    alert("O procedimento exige comprovação, anexe no minimo uma midia comprovatoria sendo imagem, video ou arquivo pdf");
                    setBotaoClicado(false);
                    return;
                }
    

             
            }
            if (procedimentoExecucao.questao_resposta) {
                for (const questao of procedimentoExecucao.questao_resposta) {
                    if (questao.tipo === 0) {
                        if (
                            !questao.resposta_livre || // Nulo ou indefinido
                            questao.resposta_livre.trim() === "" || // Vazio
                            questao.resposta_livre.trim().length < 5 // Comprimento menor que 5
                        ) {
                            alert(`Responda a questão: ${questao.nome}`);
                            setBotaoClicado(false);
                            return;
                        }
                    }
                }
            }

            if (comentarioNota && comentarioNota.trim() !== "" && comentarioNota.length > 3) {
                formData.append("comentario_nota", comentarioNota);
            } else {
                alert("Ajude a melhorar o sistema, forneça um comentário sobre esse procedimento no campo Avaliação do Procedimento!");
                setBotaoClicado(false);
                return;
            }


            const resposta = window.confirm("Suas informações estão corretas? Uma vez salvo, o procedimento será finalizado e não pode ser mais atualizado, em caso de duvidas, revise suas informações!");
            if (resposta) {

            } else {
                setBotaoClicado(false);
                return;
            }



            formData.append('descricao_imagem1', descricaoImagem1);
            formData.append('descricao_imagem2', descricaoImagem2);
            formData.append('descricao_imagem3', descricaoImagem3);
            formData.append('descricao_imagem4', descricaoImagem4);


            formData.append('descricao_video1', descricaoVideo1);
            formData.append('descricao_video2', descricaoVideo2);
            formData.append('descricao_video3', descricaoVideo3);

            formData.append('descricao_arquivo1', descricaoArquivo1);
            formData.append('descricao_arquivo2', descricaoArquivo2);
            formData.append('descricao_arquivo3', descricaoArquivo3);



            // Adicionar as imagens ao FormData
            formData.append('imagens', imagemSelecionada1);
            formData.append('imagens', imagemSelecionada2);
            formData.append('imagens', imagemSelecionada3);
            formData.append('imagens', imagemSelecionada4);

            formData.append('videos', videoSelecionado1);
            formData.append('videos', videoSelecionado2);
            formData.append('videos', videoSelecionado3);

            formData.append('arquivos', arquivoSelecionado1);
            formData.append('arquivos', arquivoSelecionado2);
            formData.append('arquivos', arquivoSelecionado3);


            if (procedimentoExecucao.procedimento.id_procedimento === 1) {
                if (equipeSelecionada != null)
                    formData.append('id_equipe', equipeSelecionada.id_equipe);
                else {
                    alert("Selecione a Equipe");
                    setBotaoClicado(false);
                    return;
                }
            }

            if (procedimentoExecucao.procedimento.id_procedimento === 2) {
                if (veiculoSelecionado != null)
                    formData.append('id_veiculo', veiculoSelecionado.id_veiculo);
                else {
                    alert("Selecione o Veículo");
                    setBotaoClicado(false);
                    return;
                }
            }


            if (procedimentoExecucao.procedimento.id_procedimento === 3) {
                if (rotaSelecionada != null)
                    formData.append('id_rota', rotaSelecionada.id_rota);
                else {
                    alert("Selecione a Rota");
                    setBotaoClicado(false);
                    return;
                }
            }


            if (procedimentoExecucao.procedimento_ordem_compra == 1) {
                formData.append('quantidades_averiguadas', quantidadesAveriguados);
                formData.append('quantidades_aceitas', quantidadesAceitos);
                formData.append('quantidades_recebidas', quantidadesRecebidos);

                if (!armazenamentoSelecionado) {
                    alert("Selecione o Local de Armazenamento!");
                    setBotaoClicado(false);
                    return;
                }
                formData.append('id_armazenamento', armazenamentoSelecionado.id_armazenamento);

                const shouldProceed = window.confirm("Ao Salvar o Procedimento, os novos itens averiguados serão inseridos no estoque. Deseja prosseguir com a adição de " + quantidadesAceitos + " " + itemOrdemCompra.insumo.descricao + " ao estoque ?");

                if (!shouldProceed) {
                    setBotaoClicado(false);
                    return;
                }
            }



            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/ordemservico/atualizarprocedimentoexecucao/' + idOrdemServico, formData,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Procedimento Atualizado");

                const id_procedimento = procedimentoExecucao.procedimento.id_procedimento;
                if (procedimentoExecucao.procedimento.area === 0) {
                    history.push({
                        pathname: "/gestao/administracao/ordensdeservicos/editar/" + idOrdemServico,
                    })
                } else {

                    try {

                        if (parseInt(idEntradaEstoque) > 0) {
                            //retornar a tela de gerenciar entrada de estoque
                            history.push({
                                pathname: "/gestao/operacional/almoxarifado/entradaestoque/gerenciarentradaestoque/" + idEntradaEstoque,
                            })
                        } else {

                            if (procedimentoExecucao.procedimento_ordem_compra == 1) {
                                alert("Os novos itens foram adicionados ao Estoque, não esqueça de gerenciar cada um atualizado local do armazenamento, indetificadores, como número de série, sku, entre outros, e o teste de funcionamento!");
                            }

                            if (telaRetorno === 0) {
                                history.push({
                                    pathname: "/gestao/administracao/ordensdeservicos/editar/" + idOrdemServico,
                                })
                            } else if (telaRetorno === 1) {
                                history.push({
                                    pathname: "/gestao/administracao/ordensdeservicos/gerenciartratamentoemcampo/" + idOrdemServico,
                                })
                            }
                        }

                    } catch (_err) {
                        if (procedimentoExecucao.procedimento_ordem_compra == 1) {
                            alert("Os novos itens foram adicionados ao Estoque, não esqueça de gerenciar cada um atualizado local do armazenamento, indetificadores, como número de série, sku, entre outros, e o teste de funcionamento!");
                        }

                        if (telaRetorno === 0) {
                            history.push({
                                pathname: "/gestao/administracao/ordensdeservicos/editar/" + idOrdemServico,
                            })
                        } else if (telaRetorno === 1) {
                            history.push({
                                pathname: "/gestao/administracao/ordensdeservicos/gerenciartratamentoemcampo/" + idOrdemServico,
                            })
                        }
                    }


                }

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }

    const [loading, setLoading] = useState(true);

    const [procedimentoExecucao, setProcedimentoExecucao] = useState();



    async function listarVeiculos() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/veiculos/listartodos", {
                headers: headers
            }).then(function (response) {
                setVeiculos(response.data)

                setLoading(false);
            });

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar veiculos: " + _err)

        }

    }



    async function listarRotas() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/rotas/listarotasporordemservico/" + idOrdemServico, {
                headers: headers
            }).then(function (response) {
                setRotas(response.data)

                api.get("v1/protected/configuracoes", {
                    headers: headers
                }).then(function (response) {
                    setUnidadebase(response.data.unidade_base);
                    setLoading(false);
                });


            });

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar veiculos: " + _err)

        }

    }


    async function listarAgendamentosCompletos() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/agendamentos/listaragendamentoscompletosporordemservico/" + idOrdemServico, {
                headers: headers
            }).then(function (response) {
                setAgendamentosCompletos(response.data)
                setLoading(false);

            });

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar agendamentos completos para essa O.S: " + _err)

        }

    }

    async function listarEquipes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/equipes/listartodos", {
                headers: headers
            }).then(function (response) {
                setEquipes(response.data)

                setLoading(false);
            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar veiculos: " + _err)

        }

    }






    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/ordemservico/listarprocedimentoexecucaoporid/" + idProcedimentoExecucacao, {
                headers: headers
            }).then(function (response) {

                setProcedimentoExecucao(response.data.procedimento);

                console.log("id do procedimento: " + response.data.procedimento.procedimento.id_procedimento);

                api.get("/v1/protected/ordemservico/listarporid/" + idOrdemServico, {
                    headers: headers
                }).then(function (response_os) {
                    setOrdemServico(response_os.data);
                    setInstalacaoSelecionada(response_os.data.instalacao);
                });



                if (response.data.procedimento.procedimento.id_procedimento === 1) {
                    listarEquipes();
                }
                else if (response.data.procedimento.procedimento.id_procedimento === 2) {
                    listarVeiculos();

                } else if (response.data.procedimento.procedimento.id_procedimento === 3) {
                    listarRotas();


                }

                else if (response.data.procedimento.procedimento.id_procedimento === 7) {
                    listarAgendamentosCompletos();


                }
                else if (response.data.procedimento.procedimento_ordem_compra === 1) {
                    //listar item ordem compra
                    api.get("/v1/protected/ordensdecompras/ordemdecompra/buscaritem/" + response.data.procedimento.id_item_ordem_compra, {
                        headers: headers
                    }).then(function (response) {
                        setItemOrdemCompra(response.data)

                        //listar armazenamentos
                        api.get("v1/protected/almoxarifado/armazenamento/listar", {
                            headers: headers
                        }).then(function (response) {
                            setArmazenamentos(response.data)
                            setLoading(false);
                        });

                    });
                }


                else {
                    setLoading(false);

                }


            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();



    }, []);


    useEffect(() => {

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                console.log('Página recuperou o foco');

                try {

                    if (procedimentoExecucao.procedimento.id_procedimento === 1) {
                        //nova equipe
                        console.log("atualizando equipes");
                        listarEquipes();


                    } else if (procedimentoExecucao.procedimento.id_procedimento === 2) {
                        //novo veiculo
                        console.log("atualizando veiculos");
                        listarVeiculos();

                    } else if (procedimentoExecucao.procedimento.id_procedimento === 3) {
                        //nova rota
                        console.log("atualizando rotas");
                        listarRotas();

                    } else if (procedimentoExecucao.procedimento.id_procedimento === 7) {
                        //nova rota
                        console.log("atualizando agendamentos completos");
                        listarAgendamentosCompletos();

                    }
                } catch (_err) {
                    // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                    console.log("Erro ao atualizar apos recuperar foco: " + _err)

                }

            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };

    }, [procedimentoExecucao]);



    const [equipes, setEquipes] = useState([]);
    const [equipeSelecionada, setEquipeSelecionada] = useState();
    function handleSetEquipe(equipe) {
        setEquipeSelecionada(equipe);
    }


    function returnDescricaoStatusEquipe(status) {
        switch (status) {
            case -1:
                return "Desativada";
            case 0:
                return "Livre";
            case 1:
                return "Designada - Em Deslocamento para Tratamento de O.S em campo";
            case 2:
                return "Designada - Tratando Equipe em Campo";
            case 3:
                return "Designada - Retornando de Tratamento de O.S em campo";
            case 4:
                // Adicione mais casos conforme necessário
                return "Outro status";
            default:
                return "Status Desconhecido";
        }
    }

    function returnDescricaoEquipe(equipe) {
        var texto = "ID Equipe: " + equipe.id_equipe +
            "\n Status: " + (returnDescricaoStatusEquipe(equipe.status_equipe)) +
            "\n Nome: " + equipe.nome +
            "\n Descrição: " + equipe.descricao +
            "\n Região de Atuação: " + equipe.regiao_atuacao;

        return texto;
    }



    function handleSetArmazenamento(armazenamento) {
        setArmazenamentoSelecionado(armazenamento);
    }


    function returnDescricaoArmazenamento(armazenamento) {
        var texto = "Descrição: " + armazenamento.descricao + "\n" +
            "Sala: " + armazenamento.sala + "\n" +
            "Corredor: " + armazenamento.corredor + "\n" +
            "Bloco: " + armazenamento.bloco + "\n" +
            "Gondola: " + armazenamento.gondola + "\n" +
            "Prateleira: " + armazenamento.prateleira;

        return texto;
    }


    const procedimentoDefinirEquipe = () => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={12}
                style={{ marginTop: 20 }}
            >



                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                    item xs={5}
                >

                    <Autocomplete
                        id="select-instalacao"
                        options={equipes}
                        disableCloseOnSelect
                        getOptionLabel={(equipe) => returnDescricaoEquipe(equipe).replace(/<br \/>/g, '\n')}
                        value={equipeSelecionada}
                        onChange={(e, v) => handleSetEquipe(v)}
                        filterOptions={(options, { inputValue }) => {
                            const inputKeywords = inputValue.toLowerCase().split(" ");
                            return options.filter((cotacao) => {
                                const cotacaoText = returnDescricaoEquipe(cotacao).toLowerCase();
                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                            });
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label=" Equipe " multiline rows={5} />
                        )}
                        renderOption={(option) => (
                            <div>
                                {returnDescricaoEquipe(option).split('\n').map((line, index) => (
                                    <Typography key={index}>{line}</Typography>
                                ))}
                            </div>
                        )}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    item xs={12}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        item xs={2}
                        style={{ paddingTop: 5 }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => novoObjeto()}
                        > Nova Equipe  </Button>
                    </Grid>
                </Grid>


            </Grid>
        );
    }




    const [veiculos, setVeiculos] = useState([]);
    const [veiculoSelecionado, setVeiculoSelecionado] = useState();

    function handleSetVeiculo(veiculo) {
        setVeiculoSelecionado(veiculo);
    }


    function returnDescricaoStatusServicoVeiculo(status) {
        switch (status) {
            case -1:
                return "Desativada";
            case 0:
                return "Livre";
            case 1:
                return "Designada - Em Deslocamento para Tratamento de O.S em campo";
            case 2:
                return "Designada - Tratando Equipe em Campo";
            case 3:
                return "Designada - Retornando de Tratamento de O.S em campo";
            case 4:
                // Adicione mais casos conforme necessário
                return "Outro status";
            default:
                return "Status Desconhecido";
        }
    }

    function returnDescricaoVeiculo(veiculo) {
        var texto = "ID Veículo: " + veiculo.id_veiculo +
            "\n Status: " + (returnDescricaoStatusEquipe(veiculo.status_servico_veiculo)) +
            "\n Placa: " + veiculo.placa +
            "\n Marca|Modelo Ano/Ano: " + veiculo.marca + "|" + veiculo.modelo + " " + veiculo.ano_modelo + "/" + veiculo.ano_veiculo +
            "\n Região de Atuação: " + veiculo.regiao_atuacao;

        return texto;
    }



    const procedimentoDefinirVeiculo = () => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={12}
                style={{ marginTop: 20 }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                    item xs={5}
                >

                    <Autocomplete
                        id="select-instalacao"
                        options={veiculos}
                        disableCloseOnSelect
                        getOptionLabel={(veiculo) => returnDescricaoVeiculo(veiculo).replace(/<br \/>/g, '\n')}
                        value={veiculoSelecionado}
                        onChange={(e, v) => handleSetVeiculo(v)}
                        filterOptions={(options, { inputValue }) => {
                            const inputKeywords = inputValue.toLowerCase().split(" ");
                            return options.filter((cotacao) => {
                                const cotacaoText = returnDescricaoVeiculo(cotacao).toLowerCase();
                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                            });
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label=" Veículo " multiline rows={5} />
                        )}
                        renderOption={(option) => (
                            <div>
                                {returnDescricaoVeiculo(option).split('\n').map((line, index) => (
                                    <Typography key={index}>{line}</Typography>
                                ))}
                            </div>
                        )}
                    />

                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    item xs={12}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        item xs={2}
                        style={{ paddingTop: 5 }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => novoObjeto()}
                        > Novo Veículo  </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const [agendamentosCompletos, setAgendamentosCompletos] = useState(null);

    const [agendamentoCompletoSelecionado, setAgendamentoCompletoSelecionado] = useState(null);

    function returnDescricaoAgendamentoCompleto(agendamento) {
        var texto = "";

        texto += returnDescricaoEquipe(agendamento.equipe);
        texto += "\n\n";
        texto += returnDescricaoVeiculo(agendamento.veiculo);

        return texto;
    }


    function handleSetAgendamentoCompleto(agendamentoCompleto) {
        setAgendamentoCompletoSelecionado(agendamentoCompleto);
    }

    const procedimentoDefinirAgendamentoCompleto = () => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={12}
                style={{ marginTop: 20 }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                    item xs={5}
                >

                    <Autocomplete
                        id="select-agendamento"
                        options={agendamentosCompletos}
                        disableCloseOnSelect
                        getOptionLabel={(agendamentoCompleto) => returnDescricaoAgendamentoCompleto(agendamentoCompleto).replace(/<br \/>/g, '\n')}
                        value={agendamentoCompletoSelecionado}
                        onChange={(e, v) => handleSetAgendamentoCompleto(v)}
                        filterOptions={(options, { inputValue }) => {
                            const inputKeywords = inputValue.toLowerCase().split(" ");
                            return options.filter((cotacao) => {
                                const cotacaoText = returnDescricaoAgendamentoCompleto(cotacao).toLowerCase();
                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                            });
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label=" Agendamento de Data, Equipe e Veiculo para execução de Ordem de Serviço " multiline rows={5} />
                        )}
                        renderOption={(option) => (
                            <div>
                                {returnDescricaoAgendamentoCompleto(option).split('\n').map((line, index) => (
                                    <Typography key={index}>{line}</Typography>
                                ))}
                            </div>
                        )}
                    />

                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    item xs={12}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        item xs={2}
                        style={{ paddingTop: 5 }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => novoObjeto()}
                        > Novo Agendamento  </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const [rotas, setRotas] = useState([]);
    const [rotaSelecionada, setRotaSelecionada] = useState();

    function handleSetRota(rota) {
        setRotaSelecionada(rota);
    }



    function returnDescricaoRota(rota) {

        var componentes = rota.componentes_rota;

        // Encontre o componente com tipo igual a 0
        const componenteTipo0 = Array.from(componentes).find(componente => componente.tipo === 0);
        const componenteTipo1 = Array.from(componentes).find(componente => componente.tipo === 1);

        // Se o componente com tipo 0 for encontrado, atribua o valor do atributo endereco à variável texto
        const origem = componenteTipo0.endereco;
        const destino = componenteTipo1.endereco;



        var texto = "ID Rota: " + rota.id_rota +
            "\nDescrição: " + rota.descricao +
            "\nOrigem: " + origem +
            "\nDestino: " + destino;

        return texto;
    }

    function returnDescricaoUnidade(unidade) {



        var nome = unidade.nome_fantasia;

        var endereco = unidade.logradouro + ", nº " + unidade.numero + ", " + unidade.bairro + ", " + unidade.cidade + " " + unidade.estado + " CEP: " + unidade.cep;


        var texto = "ID: " + unidade.id_unidade + "\nCNPJ: " + unidade.cnpj +
            "\n " + nome + "\n Endereço: " + endereco;
        return texto;
    }


    function returnDescricaoInstalacao(instalacao) {

        var endereco = instalacao.logradouro + ", nº " + instalacao.numero + ", " + instalacao.bairro + ", " + instalacao.cidade + " " + instalacao.estado + " CEP: " + instalacao.cep;


        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia)
            + "\n Endereço: " + endereco;

        return texto;
    }


    const procedimentoDefinirRota = () => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={12}
                style={{ marginTop: 20 }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    item xs={12}
                    style={{ marginTop: 5 }}
                >
                    <Typography align="center" variant="h7" > Definição de Rota</Typography>

                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    item xs={12}
                    style={{ marginTop: 5 }}
                >
                    <Typography align="center" style={{ fontSize: 12 }} >{"Origem: " + returnDescricaoUnidade(unidadeBase)} </Typography>
                </Grid>
                {instalacaoSelecionada &&
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        align="center"
                        item xs={12}
                        style={{ marginTop: 5 }}
                    >
                        <Typography align="center" style={{ fontSize: 12 }} >{"Destino: " + returnDescricaoInstalacao(instalacaoSelecionada)} </Typography>
                    </Grid>
                }


                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                    item xs={5}
                >

                    <Autocomplete
                        id="select-instalacao"
                        options={rotas}
                        disableCloseOnSelect
                        getOptionLabel={(rota) => returnDescricaoRota(rota).replace(/<br \/>/g, '\n')}
                        value={rotaSelecionada}
                        onChange={(e, v) => handleSetRota(v)}
                        filterOptions={(options, { inputValue }) => {
                            const inputKeywords = inputValue.toLowerCase().split(" ");
                            return options.filter((cotacao) => {
                                const cotacaoText = returnDescricaoRota(cotacao).toLowerCase();
                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                            });
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label=" Rota " multiline rows={5} />
                        )}
                        renderOption={(option) => (
                            <div>
                                {returnDescricaoRota(option).split('\n').map((line, index) => (
                                    <Typography key={index}>{line}</Typography>
                                ))}
                            </div>
                        )}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    item xs={2}
                    style={{ paddingTop: 5 }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => novoObjeto()}
                    > Nova Rota  </Button>
                </Grid>
            </Grid>
        );
    }




    const procedimentoDefinirAgendamento = () => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={12}
                style={{ marginTop: 5 }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    item xs={12}
                    style={{ marginTop: 5 }}
                >
                    <Typography align="center" variant="h7" > Agendamento</Typography>
                </Grid>



                <Grid
                    container
                    direction="row"
                    item xs={12}
                    alignItems="center"
                    justifyContent="center"
                    style={{ marginTop: 10 }}
                >

                    <Grid
                        container
                        direction="row"
                        item xs={6}
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >

                        <Grid
                            container
                            direction="row"
                            item xs={3}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <TextField
                                variant="standard"
                                name="horaInicial"
                                label="Hora Inicial"
                                id="horaInicial"
                                value={horaInicial}
                                onChange={(e) => setHoraInicial(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            item xs={3}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <TextField
                                variant="standard"
                                name="dataInicial"
                                label="Data Inicial"
                                id="dataInicial"
                                value={dataInicial}
                                onChange={(e) => setDataInicial(e.target.value)}
                                fullWidth
                            />
                        </Grid>



                        <Grid
                            container
                            direction="row"
                            item xs={3}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <TextField
                                variant="standard"
                                name="horaFinal"
                                label="Hora Final"
                                id="horaFinal"
                                value={horaFinal}
                                onChange={(e) => setHoraFinal(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            item xs={3}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <TextField
                                variant="standard"
                                name="dataFinal"
                                label="Data Final"
                                id="dataFinal"
                                value={dataFinal}
                                onChange={(e) => setDataFinal(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>





            </Grid>
        );
    }


    const procedimentoDefinirQuantidades = () => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={7}
                style={{ marginTop: 5 }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    item xs={12}
                >

                    <Grid item xs={12} >

                        <Typography align="center" variant="h5">
                            {procedimentoExecucao.quantidades_averiguar + " " + itemOrdemCompra.insumo.descricao + " para averiguação"}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            variant="standard"
                            name="quantidadesRecebidos"
                            label="Quantidade de Itens Recebidos"
                            required
                            id="quantidadesRecebidos"
                            value={quantidadesRecebidos}
                            onChange={(e) => setQuantidadesRecebidos(e.target.value)}
                            fullWidth

                        />
                    </Grid>


                    <Grid item xs={12} >
                        <TextField
                            variant="standard"
                            name="quantidadesAveriguados"
                            label="Quantidade de Itens Averiguados"
                            required
                            id="quantidadesAveriguados"
                            value={quantidadesAveriguados}
                            onChange={(e) => setQuantidadesAveriguados(e.target.value)}
                            fullWidth

                        />
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            variant="standard"
                            name="quantidadesAceitos"
                            label="Quantidade de Itens Aceitos"
                            required
                            id="quantidadesAceitos"
                            value={quantidadesAceitos}
                            onChange={(e) => setQuantidadesAceitos(e.target.value)}
                            fullWidth

                        />
                    </Grid>



                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        align="center"
                        item xs={12}
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                    >

                        <Autocomplete
                            id="select-armazenamento"
                            options={armazenamentos}
                            disableCloseOnSelect
                            getOptionLabel={(armazenamento) => returnDescricaoArmazenamento(armazenamento).replace(/<br \/>/g, '\n')}
                            value={armazenamentoSelecionado}
                            onChange={(e, v) => handleSetArmazenamento(v)}
                            filterOptions={(options, { inputValue }) => {
                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                return options.filter((cotacao) => {
                                    const cotacaoText = returnDescricaoArmazenamento(cotacao).toLowerCase();
                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                });
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label=" Armazenamento " multiline minRows={5} />
                            )}
                            renderOption={(option) => (
                                <div>
                                    {returnDescricaoArmazenamento(option).split('\n').map((line, index) => (
                                        <Typography key={index}>{line}</Typography>
                                    ))}
                                </div>
                            )}
                        />
                    </Grid>

                </Grid>
            </Grid>
        );
    }


    return (
        <div>
            <NavegadorGestao
                ListarOrdemSevicos={'underline'}
                corGestaoServicosMarcado={'orange'}
                Gestao={'underline'}
                corGestaoMarcado={'orange'}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <Container style={{ paddingTop: 5 }} >
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={12}
                                style={{ width: '100%', margin: 0, padding: 0 }}
                            >

                                <a href={"/gestao/administracao/ordensdeservicos/editar/" + idOrdemServico} style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"< Ordem de Serviço nº " + idOrdemServico}</a>
                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >

                                        <Tabs
                                            value={value}
                                            onChange={handleChangeTab}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"


                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Imagens" style={{ backgroundColor: 'green' }} />
                                            <Tab label="Vídeos" style={{ backgroundColor: 'green' }} />
                                            <Tab label="Documentos" style={{ backgroundColor: 'green' }} />

                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                item xs={6}
                            >
                                <Typography align="center" variant="h5">
                                    {procedimentoExecucao.procedimento.nome}
                                </Typography>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                item xs={6}
                            >
                                <Button style={{ marginTop: 5 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={salvar}
                                    disabled={botaoClicado}
                                > Salvar  </Button>
                            </Grid>


                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >


                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >

                                    <Grid
                                        value={value} index={0}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >




                                        {
                                            procedimentoExecucao.procedimento.id_procedimento === 1
                                                ? procedimentoDefinirEquipe()
                                                : <div></div>
                                        }

                                        {
                                            procedimentoExecucao.procedimento.id_procedimento === 2
                                                ? procedimentoDefinirVeiculo()
                                                : <div></div>
                                        }

                                        {
                                            procedimentoExecucao.procedimento.id_procedimento === 3
                                                ? procedimentoDefinirRota()
                                                : <div></div>
                                        }
                                        {

                                            procedimentoExecucao.procedimento.id_procedimento === 5
                                                ? procedimentoDefinirAgendamento()
                                                : <div></div>
                                        }



                                        {

                                            procedimentoExecucao.procedimento_ordem_compra === 1
                                                ? procedimentoDefinirQuantidades()
                                                : <div></div>
                                        }

                                        {
                                            procedimentoExecucao.procedimento.id_procedimento === 7
                                                ? procedimentoDefinirAgendamentoCompleto()
                                                : <div></div>
                                        }



                                        <Grid item xs={12}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="resultado-selector-label">Resultado da Execução do Procedimento</InputLabel>
                                                    <Select
                                                        labelId="resultado-selector-label"
                                                        id="resultado-selector"
                                                        value={resultado}
                                                        onChange={handleChangeResultado}
                                                        label="Resultado"
                                                    >
                                                        <MenuItem value={0}>Falha</MenuItem>
                                                        <MenuItem value={1}>Sucesso</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>



                                        <Grid item xs={12} style={{ padding: 40 }}>
                                            <TextField
                                                variant="standard"
                                                name="observacoes"
                                                label="Observações"
                                                required
                                                id="observacoes"
                                                value={observacoes}
                                                onChange={(e) => setObservacoes(e.target.value)}
                                                fullWidth
                                                multiline
                                                minRows={3}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: 2 }}
                                            container
                                            direction={'row'}
                                            alignItems='center'
                                            justifyContent='center'
                                        >

                                            <Grid item xs={12} style={{ padding: 2 }}
                                                container
                                                direction={'row'}
                                                alignItems='center'
                                                justifyContent='center'
                                            >
                                                <Box component="fieldset" mb={3} borderColor="transparent">
                                                    <Rating
                                                        name="simple-controlled"
                                                        value={nota}
                                                        onChange={handleChangeStart}
                                                        precision={1}
                                                        max={5}
                                                        emptyIcon={<StarBorderIcon />}

                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} style={{ padding: 2 }}
                                                container
                                                direction={'row'}
                                                alignItems='center'
                                                justifyContent='center'
                                            >
                                                <Typography variant="body1" gutterBottom>
                                                    {descricaoNota}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{ padding: 2 }}
                                                container
                                                direction={'row'}
                                                alignItems='center'
                                                justifyContent='center'
                                            >
                                                <TextField
                                                    variant="standard"
                                                    name="comentarioNota"
                                                    label="Avaliação do Procedimento"
                                                    id="comentarioNota"
                                                    value={comentarioNota}
                                                    onChange={(e) => setComentarioNota(e.target.value)}
                                                    fullWidth
                                                    multiline
                                                    minRows={3}
                                                />
                                            </Grid>
                                        </Grid>







                                    </Grid>

                                    <Grid
                                        value={value} index={1}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >




                                        <Grid item xs={12} style={{ padding: 40 }}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={3}>
                                                <Card>
                                                    <CardContent>
                                                        {imagemSelecionada1 ? (
                                                            <div style={{ textAlign: 'center' }}>
                                                                <img
                                                                    src={URL.createObjectURL(imagemSelecionada1)}
                                                                    alt="Imagem Selecionada 1"
                                                                    style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                                />
                                                                <button
                                                                    style={{
                                                                        backgroundColor: '#ff4d4f',
                                                                        color: '#fff',
                                                                        border: 'none',
                                                                        padding: '10px 15px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer',
                                                                        marginTop: '10px',
                                                                    }}
                                                                    onClick={() => setImagemSelecionada1(null)}
                                                                >
                                                                    Remover Imagem
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div {...getRootProps1()} style={dropzoneStyles}>
                                                                <input {...getInputProps1()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte uma imagem aqui ou clique para adicionar ou faça colagem
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoImagem1"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoImagem1"
                                                                value={descricaoImagem1}
                                                                onChange={(e) => setDescricaoImagem1(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Card>
                                                    <CardContent>
                                                        {imagemSelecionada2 ? (
                                                            <div style={{ textAlign: 'center' }}>
                                                                <img
                                                                    src={URL.createObjectURL(imagemSelecionada2)}
                                                                    alt="Imagem Selecionada 1"
                                                                    style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                                />
                                                                <button
                                                                    style={{
                                                                        backgroundColor: '#ff4d4f',
                                                                        color: '#fff',
                                                                        border: 'none',
                                                                        padding: '10px 15px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer',
                                                                        marginTop: '10px',
                                                                    }}
                                                                    onClick={() => setImagemSelecionada2(null)}
                                                                >
                                                                    Remover Imagem
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div {...getRootProps2()} style={dropzoneStyles}>
                                                                <input {...getInputProps2()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte uma imagem aqui ou clique para adicionar ou faça colagem
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoImagem2"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoImagem2"
                                                                value={descricaoImagem2}
                                                                onChange={(e) => setDescricaoImagem2(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Card>
                                                    <CardContent>
                                                        {imagemSelecionada3 ? (
                                                            <div style={{ textAlign: 'center' }}>
                                                                <img
                                                                    src={URL.createObjectURL(imagemSelecionada3)}
                                                                    alt="Imagem Selecionada 1"
                                                                    style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                                />
                                                                <button
                                                                    style={{
                                                                        backgroundColor: '#ff4d4f',
                                                                        color: '#fff',
                                                                        border: 'none',
                                                                        padding: '10px 15px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer',
                                                                        marginTop: '10px',
                                                                    }}
                                                                    onClick={() => setImagemSelecionada3(null)}
                                                                >
                                                                    Remover Imagem
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div {...getRootProps3()} style={dropzoneStyles}>
                                                                <input {...getInputProps3()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte uma imagem aqui ou clique para adicionar ou faça colagem
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoImagem3"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoImagem3"
                                                                value={descricaoImagem3}
                                                                onChange={(e) => setDescricaoImagem3(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>


                                            <Grid item xs={3}>
                                                <Card>
                                                    <CardContent>
                                                        {imagemSelecionada4 ? (
                                                            <div style={{ textAlign: 'center' }}>
                                                                <img
                                                                    src={URL.createObjectURL(imagemSelecionada4)}
                                                                    alt="Imagem Selecionada 1"
                                                                    style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                                                                />
                                                                <button
                                                                    style={{
                                                                        backgroundColor: '#ff4d4f',
                                                                        color: '#fff',
                                                                        border: 'none',
                                                                        padding: '10px 15px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer',
                                                                        marginTop: '10px',
                                                                    }}
                                                                    onClick={() => setImagemSelecionada4(null)}
                                                                >
                                                                    Remover Imagem
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div {...getRootProps4()} style={dropzoneStyles}>
                                                                <input {...getInputProps4()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte uma imagem aqui ou clique para adicionar ou faça colagem
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoImagem4"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoImagem4"
                                                                value={descricaoImagem4}
                                                                onChange={(e) => setDescricaoImagem4(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                    <Grid
                                        value={value} index={2}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >



                                        <Grid item xs={12} style={{ padding: 40 }}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >

                                            <Grid item xs={8}>
                                                <Card>
                                                    <CardContent>
                                                        {videoSelecionado1 ? (
                                                            <div style={{ textAlign: 'center' }}>
                                                                <video
                                                                    controls
                                                                    src={URL.createObjectURL(videoSelecionado1)}
                                                                    alt="Vídeo Selecionado 1"
                                                                    style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                                />
                                                                <button
                                                                    style={{
                                                                        backgroundColor: '#ff4d4f',
                                                                        color: '#fff',
                                                                        border: 'none',
                                                                        padding: '10px 15px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer',
                                                                        marginTop: '10px',
                                                                    }}
                                                                    onClick={() => setVideoSelecionado1(null)}
                                                                >
                                                                    Remover
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                    <div {...getRootVideoProps1()} style={dropzoneStyles}>
                                                        <input {...getInputVideoProps1()} />
                                                        <Typography align="center" variant="h5">
                                                            Arraste e solte um vídeo aqui ou clique para adicionar
                                                        </Typography>

                                                    </div>
                                                    <div> {isRecording ? (
                                                        <button onClick={stopRecording}>Parar Gravação</button>
                                                    ) : (
                                                        <button onClick={startRecording}>Iniciar Gravação</button>
                                                    )}</div>
                                                </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoVideo1"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoVideo1"
                                                                value={descricaoVideo1}
                                                                onChange={(e) => setDescricaoVideo1(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <Card>
                                                    <CardContent>
                                                        {videoSelecionado2 ? (
                                                            <div style={{ textAlign: 'center' }}>
                                                                <video
                                                                    controls
                                                                    src={URL.createObjectURL(videoSelecionado2)}
                                                                    alt="Vídeo Selecionado 2"
                                                                    style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                                />
                                                                <button
                                                                    style={{
                                                                        backgroundColor: '#ff4d4f',
                                                                        color: '#fff',
                                                                        border: 'none',
                                                                        padding: '10px 15px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer',
                                                                        marginTop: '10px',
                                                                    }}
                                                                    onClick={() => setVideoSelecionado2(null)}
                                                                >
                                                                    Remover
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div {...getRootVideoProps2()} style={dropzoneStyles}>
                                                            <input {...getInputVideoProps2()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte um vídeo aqui ou clique para adicionar
                                                            </Typography>
                                                            {isRecording ? (
                                                                <button onClick={stopRecording}>Parar Gravação</button>
                                                            ) : (
                                                                <button onClick={startRecording}>Iniciar Gravação</button>
                                                            )}
                                                        </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoVideo2"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoVideo2"
                                                                value={descricaoVideo2}
                                                                onChange={(e) => setDescricaoVideo2(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <Card>
                                                    <CardContent>
                                                        {videoSelecionado3 ? (
                                                            <div style={{ textAlign: 'center' }}>
                                                                <video
                                                                    controls
                                                                    src={URL.createObjectURL(videoSelecionado3)}
                                                                    alt="Vídeo Selecionado 3"
                                                                    style={{ width: '100%', height: '100%', marginBottom: '10px' }}
                                                                />
                                                                <button
                                                                    style={{
                                                                        backgroundColor: '#ff4d4f',
                                                                        color: '#fff',
                                                                        border: 'none',
                                                                        padding: '10px 15px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer',
                                                                        marginTop: '10px',
                                                                    }}
                                                                    onClick={() => setVideoSelecionado3(null)}
                                                                >
                                                                    Remover
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div {...getRootVideoProps3()} style={dropzoneStyles}>
                                                            <input {...getInputVideoProps3()} />
                                                            <Typography align="center" variant="h5">
                                                                Arraste e solte um vídeo aqui ou clique para adicionar
                                                            </Typography>
                                                            {isRecording ? (
                                                                <button onClick={stopRecording}>Parar Gravação</button>
                                                            ) : (
                                                                <button onClick={startRecording}>Iniciar Gravação</button>
                                                            )}
                                                        </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoVideo3"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoVideo3"
                                                                value={descricaoVideo3}
                                                                onChange={(e) => setDescricaoVideo3(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        value={value} index={3}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >




                                        <Grid item xs={12} style={{ padding: 40 }}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={8}>
                                                <Card>
                                                    <CardContent>
                                                        {arquivoSelecionado1 ? (

                                                            <div style={{ textAlign: 'center' }}>
                                                                <div
                                                                    href={URL.createObjectURL(arquivoSelecionado1)}
                                                                    onClick={() => window.open(URL.createObjectURL(arquivoSelecionado1), '_blank')}
                                                                    onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado1))}
                                                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}
                                                                    >
                                                                    <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                                </div>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <button
                                                                        style={{
                                                                            backgroundColor: '#ff4d4f',
                                                                            color: '#fff',
                                                                            border: 'none',
                                                                            padding: '10px 15px',
                                                                            borderRadius: '5px',
                                                                            cursor: 'pointer',
                                                                            marginTop: '10px',
                                                                        }}
                                                                        onClick={() => setArquivoSelecionado1(null)}
                                                                    >
                                                                        Remover
                                                                    </button>
                                                                </div>
                                                            </div>


                                                        ) : (
                                                            <div {...getRootArquivoProps1()} style={dropzoneStyles}>
                                                                <input {...getInputArquivoProps1()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte um arquivo aqui ou clique para adicionar
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoArquivo1"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoArquivo1"
                                                                value={descricaoArquivo1}
                                                                onChange={(e) => setDescricaoArquivo1(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <Card>
                                                    <CardContent>
                                                        {arquivoSelecionado2 ? (
                                                            <div style={{ textAlign: 'center' }}>
                                                            <div
                                                                href={URL.createObjectURL(arquivoSelecionado2)}
                                                                onClick={() => window.open(URL.createObjectURL(arquivoSelecionado2), '_blank')}
                                                                onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado2))}
                                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}
                                                                >
                                                                <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                            </div>
                                                            <div style={{ textAlign: 'center' }}>
                                                                <button
                                                                    style={{
                                                                        backgroundColor: '#ff4d4f',
                                                                        color: '#fff',
                                                                        border: 'none',
                                                                        padding: '10px 15px',
                                                                        borderRadius: '5px',
                                                                        cursor: 'pointer',
                                                                        marginTop: '10px',
                                                                    }}
                                                                    onClick={() => setArquivoSelecionado2(null)}
                                                                >
                                                                    Remover
                                                                </button>
                                                            </div>
                                                        </div>
                                                        ) : (
                                                            <div {...getRootArquivoProps2()} style={dropzoneStyles}>
                                                                <input {...getInputArquivoProps2()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte um arquivo aqui ou clique para adicionar
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoArquivo2"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoArquivo2"
                                                                value={descricaoArquivo2}
                                                                onChange={(e) => setDescricaoArquivo2(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <Card>
                                                    <CardContent>
                                                        {arquivoSelecionado3 ? (
                                                             <div style={{ textAlign: 'center' }}>
                                                             <div
                                                                 href={URL.createObjectURL(arquivoSelecionado3)}
                                                                 onClick={() => window.open(URL.createObjectURL(arquivoSelecionado1), '_blank')}
                                                                 onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado3))}
                                                                 style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}
                                                                 >
                                                                 <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                             </div>
                                                             <div style={{ textAlign: 'center' }}>
                                                                 <button
                                                                     style={{
                                                                         backgroundColor: '#ff4d4f',
                                                                         color: '#fff',
                                                                         border: 'none',
                                                                         padding: '10px 15px',
                                                                         borderRadius: '5px',
                                                                         cursor: 'pointer',
                                                                         marginTop: '10px',
                                                                     }}
                                                                     onClick={() => setArquivoSelecionado3(null)}
                                                                 >
                                                                     Remover
                                                                 </button>
                                                             </div>
                                                         </div>
                                                        ) : (
                                                            <div {...getRootArquivoProps3()} style={dropzoneStyles}>
                                                                <input {...getInputArquivoProps3()} />
                                                                <Typography align="center" variant="h5">
                                                                    Arraste e solte um arquivo aqui ou clique para adicionar
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Grid item xs={12} style={{ padding: 40 }}>
                                                            <TextField
                                                                variant="standard"
                                                                name="descricaoArquivo3"
                                                                label="Descrição"
                                                                required
                                                                id="descricaoArquivo3"
                                                                value={descricaoArquivo3}
                                                                onChange={(e) => setDescricaoArquivo3(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                            />
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </SwipeableViews>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Container >

        </div >
    );
}


