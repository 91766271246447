import axios from 'axios';


import Cookies from 'js-cookie';
/* Endereços para cada emulador/simulador:
** Genymotion:              http://10.0.3.2:3333/
** Emulador Android Studio: http://10.0.2.2:3333/
** Simulador IOS:           http://localhost:3333/
*/


//const url_source = 'http://162.240.226.254:10060';
//const url_source = 'http://pkaislan234-54168.portmap.host:54168/'
const url_source = 'https://gruporosinetos.com/api';

const api = axios.create({
  baseURL: url_source,
});

api.interceptors.response.use(
  response => {
    return response
  },
  async  (error) => {
    const originalRequest = error.config

    console.log("url original: " + originalRequest.url)

    if (
      error.response.status === 401 &&
      originalRequest.url === (url_source + "v1/protected/signin")
    ) {
      Cookies.set('token', undefined);
      window.location.href = "/login"

      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {

      console.log("atualizar token")

      originalRequest._retry = true
      const refreshToken = Cookies.get('refreshToken');
      const regra = Cookies.get('regra');

      console.log("fazer chamda")

      return axios
        .post(url_source + '/v1/protected/refreshtoken', {
          refreshToken: refreshToken,
          regra: regra,
        })
        .then(res => {
          console.log("resposta recebida");


          if (res.status === 200) {


            console.log("chamda feita");

            console.log("res: " + res);

            Cookies.set('token', res.data.accessToken);
           
            console.log("id: " + res.data.id);
            Cookies.set('id', res.data.id);


            api.defaults.headers.common.Authorization = `Bearer ${res.data.accessToken}`;
          
            return axios(originalRequest)
          }else{
            Cookies.set('token', undefined);
            window.location.href = "/login"
      
            return Promise.reject(error)
          }
        })
    }
    
    if(error.response.status === 403){
     // Cookies.set('token', undefined);
    //  window.location.href = "/login"

     // return Promise.reject(error)
    }
    return Promise.reject(error)
  }
);


api.interceptors.response.use(
  response => {
    return response
  },
  async  (error) => {
    const originalRequest = error.config

    console.log("url original: " + originalRequest.url)
    
    if(error.response.status === 403){
      //Cookies.set('token', undefined);
     // window.location.href = "/login"

     // return Promise.reject(error)
    }
    return Promise.reject(error)
  }
);


//baseURL: 'http://aislanldarmazens-64531.portmap.io:64531'
//baseURL: 'http://localhost:8080',
//  baseURL: 'https://titaniwm124-60046.portmap.io:443',

export default api;
