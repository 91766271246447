import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@mui/material/Tooltip';

import AppBar from '@mui/material/AppBar';
import SwipeableViews from 'react-swipeable-views';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Stomp } from '@stomp/stompjs';

import Close from '@material-ui/icons/Close'
import { Snackbar } from '@material-ui/core';
import { Menu } from '@mui/material';

import Cookies from 'js-cookie';
import { MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import {
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import { useHistory } from "react-router-dom";
import moment from 'moment';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Modal from "react-modal";
import CabecalhoInformacoes from '../components/CabecalhoInformacoes/index'
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import './styles.css';


import {
    MaterialReactTable
} from 'material-react-table';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import BuildIcon from '@mui/icons-material/Build';
import Switch from '@mui/material/Switch';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/Power';



import api from '../../../../../services/api';


import NavegadorGestao from "../../../../../components/NavBarGestao";


import switch_off from '../../../../../assets/imgs/switch-off.png';
import switch_on from '../../../../../assets/imgs/switch-on.png';


import mini_icone_carro from '../../../../../assets/imgs/mini_icone_carro.png';

import mini_icone_moto from '../../../../../assets/imgs/mini_icone_moto.png';

import icone_origem from '../../../../../assets/imgs/icone_origem.png';
import icone_destino from '../../../../../assets/imgs/icone_destino.png';
import icone_parada from '../../../../../assets/imgs/icone_parada.png';

import icone_call from '../../../../../assets/imgs/icone_call.png';


import cabo_conectado from '../../../../../assets/imgs/cabo_conectado.png';
import cabo_desconectado from '../../../../../assets/imgs/cabo_desconectado.png';
import cabo_internet from '../../../../../assets/imgs/cabo_internet.png';
import cabo_sem_internet from '../../../../../assets/imgs/cabo_sem_internet.png';

import wifi_conectado from '../../../../../assets/imgs/cabo_conectado.png';

import modem_conectado from '../../../../../assets/imgs/modem_conectado.png';
import modem_desconectado from '../../../../../assets/imgs/modem_desconectado.png';
import modem_internet from '../../../../../assets/imgs/cabo_internet.png';
import modem_sem_internet from '../../../../../assets/imgs/cabo_sem_internet.png';

import icone_pgm from '../../../../../assets/imgs/icone_pgm.png';
import icone_corneta from '../../../../../assets/imgs/corneta.png';

import icone_play from '../../../../../assets/imgs/play.png';


import icone_medidor from '../../../../../assets/imgs/icone_medidor.png';

import icone_subestacao from '../../../../../assets/imgs/icone_subestacao.png';
import icone_casa from '../../../../../assets/imgs/icone_casa.png';
import icone_loja from '../../../../../assets/imgs/icone_loja.png';
import icone_central from '../../../../../assets/imgs/icone_central.png';

import icone_instalacoes from '../../../../../assets/imgs/icone_instalacoes.png';
import icone_equipamentos from '../../../../../assets/imgs/icone_equipamentos.png';

import icone_armar_stay from '../../../../../assets/imgs/armar_stay.png';
import icone_armar_away from '../../../../../assets/imgs/armar_away.png';
import icone_desarmar from '../../../../../assets/imgs/desarmar.png';
import globalConfig from '../../../../../configs/globalConfigs';





import {
    GoogleMap,
    Marker,
    LoadScript,
    OverlayView,
    StandaloneSearchBox,
    DirectionsService,
    DirectionsRenderer,
    DistanceMatrixService,
    MaxZoomService, Circle, Polygon, Polyline
} from "@react-google-maps/api";

import "./MapPage.css";
import MapaDoLocal from './mapaLocal.js';
import alarmeAudio from '../../../../../assets/mp3/alarme.mp3';




const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '1000px',

        backgroundColor: 'white',
    },
    toolbar: {

    },
    toolbarIcon: {

    },
    appBar: {

    },
    appBarShift: {

    },
    menuButton: {

    },
    menuButtonHidden: {

    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {

    },
    drawerPaperClose: {

    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        display: 'flex',


    },
    container: {
        display: 'flex',
        height: '1000px',

        backgroundColor: 'green',


    },
    paper: {

    },
    fixedHeight: {

    },
}));


const libraries = ["places"];


// Gerenciador de Áudio (Singleton)
const audioManager = {
    audio: new Audio(alarmeAudio),
    isPlaying: false,
    play() {
        if (!this.isPlaying) {
            this.audio.loop = true;
            this.audio.volume = 1.0;
            this.audio
                .play()
                .then(() => {
                    this.isPlaying = true;
                })
                .catch(error => console.error("Erro ao reproduzir o áudio:", error));
        }
    },
    pause() {
        if (this.isPlaying) {
            this.audio.pause();
            this.audio.currentTime = 0;
            this.isPlaying = false;
        }
    }
};

const customStyles = {
    content: {
        top: "0", // Alinha ao topo
        left: "0", // Alinha à esquerda
        right: "auto",
        bottom: "0", // Ocupa toda a altura
        margin: "0", // Remove margens
        transform: "none", // Nenhuma transformação necessária
        width: "30%", // Ocupa 30% da largura disponível
        height: "100%", // Ocupa 100% da altura da tela
        zIndex: 9999, // Sobrepõe todas as telas
        backgroundColor: "white", // Fundo branco para o modal
        borderRadius: "0 8px 8px 0", // Bordas arredondadas apenas no lado direito
        boxShadow: "4px 0 10px rgba(0, 0, 0, 0.2)", // Sombra para dar destaque ao modal
    },
    overlay: {
        zIndex: 9998, // Sobrepõe o fundo
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro translúcido
    },
};

Modal.setAppElement("#root");


export default function MonitoramentoDashboard() {
    const audioRef = useRef(new Audio(alarmeAudio)); // Inicializar o ref com o áudio importado

    const [pgmsArmadas, setPgmsArmadas] = useState(0);
    const [pgmsDesarmadas, setPgmsDesarmadas] = useState(0);
    const [pgmsOfflines, setPgmsOfflines] = useState(0);
    const [pgmsAlarmadas, setPgmsAlarmadas] = useState(0);



    const [isOpen, setIsOpen] = useState(false);

    const [urlChamada, setUrlChamada] = useState("");

    const openModal = (id_sigma) => {
        setUrlChamada("https://gruporosinetos.com/call/softphone.html?wp_serveraddress=gruporosinetos.com&wp_username=webrtc&wp_password=1234&wp_callto=" + id_sigma + "0&wp_autoaction=1");
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setUrlChamada("");
    };

    const iframeRef = useRef(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [retries, setRetries] = useState(0);

    const MAX_RETRIES = 7; // Número máximo de tentativas de recarregar o iframe


    

    const checkIframeContent = () => {
        setTimeout(() => {
            try {
                const iframeWindow = iframeRef.current?.contentWindow;

                if (!iframeWindow) {
                    console.warn("Não foi possível acessar o conteúdo do iframe.");
                    return;
                }

                const logs = iframeWindow.document.body.innerText;
                

                // Verifica mensagens indicativas de carregamento completo
                //alert(logs);
                const isLoaded =
                    logs.includes("menu") ||  logs.includes("Menu") ||
                    logs.includes("GetBrowserVersion") ||
                    logs.includes("About:");

                if (isLoaded) {
                    setHasLoaded(true);
                    console.log("Página carregada com sucesso no iframe!");
                } else if (retries < MAX_RETRIES) {
                    console.warn(`Tentativa ${retries + 1}: Página não carregou completamente, recarregando iframe...`);
                    setRetries(retries + 1);
                    iframeRef.current.src += ""; // Recarrega o iframe
                } else {
                    console.error("Número máximo de tentativas atingido. Não foi possível carregar o iframe.");
                }
            } catch (err) {
                console.error("Erro ao verificar conteúdo do iframe:", err);
            }
        }, 2000); // Delay de 1 segundo para aguardar o carregamento
    };

    useEffect(() => {
        if (!isOpen) {
            setHasLoaded(false); // Reseta o estado ao fechar
            setRetries(0); // Reseta o número de tentativas
        }
    }, [isOpen]);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const classes = useStyles();
    const history = useHistory();


    const [value, setValue] = React.useState(0);


    const tempoInativo = 1;


    const [instalacaoSelecionada, setInstalacaoSelecionada] = useState(null);
    const instalacaoSelecionadaRef = useRef(instalacaoSelecionada); // Inicialize a ref com o valor inicial do estado
    const [mapPosition, setMapPosition] = useState(null);
    const mapPositionRef = useRef(null);

    // Atualize a referência sempre que o estado mudar
    useEffect(() => {
        instalacaoSelecionadaRef.current = instalacaoSelecionada;
        if (instalacaoSelecionada) {
            const newMapPosition = {
                lat: parseFloat(instalacaoSelecionada.latitude),
                lng: parseFloat(instalacaoSelecionada.longitude),
            };

            // Verifica se o valor atual é diferente do armazenado na ref
            if (
                !mapPositionRef.current ||
                mapPositionRef.current.lat !== newMapPosition.lat ||
                mapPositionRef.current.lng !== newMapPosition.lng
            ) {
                setMapPosition(newMapPosition);
                mapPositionRef.current = newMapPosition; // Atualiza a ref com a nova posição
            }
        }
    }, [instalacaoSelecionada]);

    async function handleInstalacaoSelecioanada(instalacao) {
        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/cliente/instalacoes/listarporid/" + instalacao.id_instalacao, {
                headers: headers
            }).then(function (response) {
                setInstalacaoSelecionada(response.data)

            });

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }
    }


    const [showTable, setShowTable] = useState(true);

    const [definingPerimeter, setDefiningPerimeter] = useState(false); // Indica se está definindo perímetros
    const [perimeterPoints, setPerimeterPoints] = useState([]); // Armazena os pontos do perímetro
    const [currentPerimeter, setCurrentPerimeter] = useState(1); // Controla qual perímetro está sendo definido (P1 a P4)





    const [endereco, setEndereco] = useState();

    const [instalacoes, setInstalacoes] = useState([]);



    const [configuracoes, setConfiguracoes] = useState();
    const [loading, setLoading] = useState();
    const [loadingInstalacoes, setLoadingInstalacoes] = useState();

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/configuracoes", {
                headers: headers
            }).then(function (response) {
                setConfiguracoes(response.data);



                const logradouro = response.data.unidade_base.logradouro;
                const numero = response.data.unidade_base.numero;
                const bairro = response.data.unidade_base.bairro;
                const complemento = response.data.unidade_base.complemento;
                const cidade = response.data.unidade_base.cidade;
                const estado = response.data.unidade_base.estado;
                const cep = response.data.unidade_base.cep;

                const end = `${logradouro}, ${numero}, ${bairro}, ${cidade}, ${estado} - ${cep}`;
                setEndereco(end);
                console.log("Endereço: " + end);
                setLoading(false);


            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }




    function checkDimenssoes() {


        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        setWidth(largura)

    }

    const [width, setWidth] = useState(0);



    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });


    useEffect(() => {


        listarInformacoes();


    }, []);








    function processarIcone(tipo) {
        if (tipo === 0) {
            return icone_pgm;
        } else if (tipo === 10) {
            return icone_medidor;
        } else if (tipo === 70) {
            return icone_central;
        } else if (tipo === 30) {
            return icone_pgm;
        } else if (tipo === 120) {
            return icone_corneta;
        }
    }



    function processarIconeInstalacao(tipo) {
        if (tipo === 0) {
            return icone_casa;
        } else if (tipo === 1) {
            return icone_loja;
        } else if (tipo == 2) {
            return icone_subestacao;
        }
    }

    const LocalComponent = ({ id_instalacao, lat, lng, onClick, tipo_icone, nome_mapa, status, instalacao }) => {
        const [showMenu, setShowMenu] = useState(false);



        const handleClickGerenciar = () => {
            const url = `/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/${id_instalacao}/0`;
            window.open(url, '_blank');
            setShowMenu(!showMenu);
        };





        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={12}
                style={{ padding: 10 }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    align="flex-start"
                    item xs={12}
                    style={{}}
                >
                    <img
                        alt={"icone"}
                        style={{
                            textAlign: 'center',
                            width: '40px',
                            height: '40px'
                        }}
                        src={processarIconeInstalacao(tipo_icone)}

                    />

                    <spam style={{ textDecoration: "underline" }} > {nome_mapa+ " - " + instalacao.identificador_integrador}</spam>
                    <IconButton color="primary" onClick={handleClickGerenciar}>
                        <SettingsIcon />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    const [logsPgm, setLogsPgm] = useState([]);
    const [limit, setLimit] = useState(200);


    const processarDadosLogs = (dados) => {
        return dados.map((item) => ({
            ...item, // Mantém os outros atributos
            data_hora_requisicao: moment(item.data_hora_requisicao).isValid()
                ? moment(item.data_hora_requisicao).format('HH:mm:ss DD/MM/YYYY')
                : item.data_hora_requisicao, // Formata a data ou mantém o valor original
            data_hora_resposta: moment(item.data_hora_resposta).isValid()
                ? moment(item.data_hora_resposta).format('HH:mm:ss DD/MM/YYYY')
                : item.data_hora_resposta, // Formata a data ou mantém o valor original
            tipo_requisicao: tipoRequisicaoMap[item.tipo_requisicao] || "Tipo Desconhecido", // Mapeia o tipo
            origem: item.origem === 0 ? "Local" : "Remoto", // Mapeia a origem
            respondido_modulo_pgm: respondidoModuloPgmMap[item.respondido_modulo_pgm] || "Status Desconhecido", // Mapeia o status de respondido
        }));
    };

    // Exemplo de como usar


    const ModuloPGMComponent = ({ lat, lng, tipo, onClick, status, equipamento, perimetro, id_instalacao, flag }) => {


        async function handleClickLog() {
            setLogsPgm([]);


            console.log("handleClickPgm")
            try {
                const cadastro = {};
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/listartodasparaareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/" + limit;
                const response = await api.get(url, { headers });

                if (response.data) {
                    const dadosProcessados = processarDadosLogs(response.data);

                    setLogsPgm(dadosProcessados);
                    setOpenLogTable(true);
                }

            } catch (error) {
                console.error("Erro ao enviar o comando:", error);
            }
        }

        async function handleClickPgm(comando) {

            console.log("handleClickPgm")
            try {
                const cadastro = {};
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/criarporareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/" + comando;
                const response = await api.get(url, { headers });

                if (response.data) {
                    setSnackbarMessage('Comando Enviado!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);

                    // Adicionando um atraso de 3 segundos
                    setTimeout(() => {
                        handleInstalacaoSelecioanada(instalacaoSelecionada);
                    }, 5000); // 3000 milissegundos = 3 segundos
                }



            } catch (error) {
                setSnackbarMessage('Erro ao enviar comando');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error("Erro ao enviar o comando:", error);
            }
        }

        const processarIndexAtivarRele = (index) => {
            if (index === 1) {
                //rele 1
                return 1;
            } else if (index === 2) {
                //rele 2
                return 2;
            } else if (index === 3) {
                //rele 3
                return -1;
            } else if (index === 4) {
                //rele 4
                return -1;
            }
        }

        const processarIndexDesativarRele = (index) => {
            if (index === 1) {
                //rele 1
                return 4;
            } else if (index === 2) {
                //rele 2
                return 5;
            } else if (index === 3) {
                //rele 3
                return -1;
            } else if (index === 4) {
                //rele 4
                return -2;
            }
        }

        // Calcular a diferença de tempo
        const now = new Date();
        const lastResponse = new Date(equipamento.modulo_pgm.data_hora_ultima_resposta);
        const differenceInMinutes = (now - lastResponse) / 1000 / 60; // Convertendo de milissegundos para minutos

        // Verificar se o alarme deve ser ativado
        const alarme = equipamento.alarme;

        // Definir o estado do equipamento
        let statusText;
        let statusColor;
        let isBlinking = false;

        const armada = equipamento.modulo_pgm.armada;

        if (alarme) {
            statusText = "ALARME";
            statusColor = "red";
            isBlinking = true; // Habilitar o piscar se estiver em alarme
        } else if (differenceInMinutes < tempoInativo) {
            if (armada) {
                statusText = "ONLINE - ARMADA";
                statusColor = "green";
            }
            else {
                statusText = "ONLINE - DESARMADA";
                statusColor = "orange";
            }
        } else {
            statusText = "OFFLINE";
            statusColor = "red";
            isBlinking = true; // Habilitar o piscar se estiver offline
        }




        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={flag === 0 ? 3 : 12}
                style={{ border: flag === 1 ? '1px solid black' : null, padding: 1, margin: flag === 1 ? 3 : 0 }}

            >
                {
                    flag === 1 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12}
                            style={{ padding: 1 }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={11}
                                style={{ padding: 0 }}
                            >
                                <span style={{ fontWeight: 'bold', fontSize: 12 }}>TITANIWM MODULO PGM</span>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={1}
                                style={{ padding: 0 }}
                            >
                                <button
                                    onClick={() => handleClickLog()} // Função de fechar
                                    style={{
                                        backgroundColor: 'green', // Cor laranja-avermelhada estilosa (TOP!)
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '8px',
                                        padding: '2px 8px',
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra leve
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    LOG
                                </button>
                            </Grid>

                        </Grid>
                    )
                }
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    item xs={flag === 0 ? 12 : 2}
                    style={{
                        boxShadow: alarme && flag === 0 ? `0 0 30px 150px rgba(255, 0, 0, 0.5)` : "none", // Sombra afastada e translúcida
                    }}>
                    <Grid item xs={12}>
                        <img
                            alt={"icone"}
                            style={{
                                textAlign: 'center',
                                width: '40px',  // Define a largura da imagem
                                height: '40px'  // Define a altura da imagem
                            }}
                            src={processarIcone(tipo)}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <span style={{ color: 'black', fontSize: '14px', fontWeight: 'bold' }}>{equipamento.modulo_pgm.codigo} </span>
                    </Grid>


                    <Grid item xs={12}
                    >
                        <span style={{
                            color: statusColor,
                            fontSize: '14px',
                            fontWeight: 'bold',
                            animation: isBlinking && flag === 0 ? 'blink 2s step-start infinite' : 'none'
                        }}>
                            {flag === 0 ? statusText : ''}
                        </span>

                    </Grid>



                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    align="flex-start"
                    item xs={flag === 0 ? 1 : 8}
                >
                    {flag === 1 && (
                        <div style={{
                            color: 'black', fontSize: '14px', fontWeight: 'bold',
                            display: 'flex', alignItems: 'center', gap: '8px', marginTop: '10px'
                        }}>
                            {[1, 2, 3, 4].map((index) => {
                                const releNome = equipamento?.modulo_pgm[`nome_rele${index}`];
                                const status = equipamento?.modulo_pgm[`status_rele${index}`];
                                const iconPath = status === "on" ? switch_on : switch_off;

                                return (
                                    <div key={index} style={{
                                        border: '1px solid black',
                                        borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                                    }}>
                                        <span>{releNome}</span>
                                        {status === "on" ? (
                                            <IconButton color="error" onClick={() => { handleClickPgm(processarIndexDesativarRele(index)) }}>
                                                <img src={iconPath} alt={`${status} icon`} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                            </IconButton>
                                        ) : (
                                            <IconButton color="primary" onClick={() => { handleClickPgm(processarIndexAtivarRele(index)) }}>
                                                <img src={iconPath} alt={`${status} icon`} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                            </IconButton>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </Grid>
            </Grid>
        );
    };


    const ModuloSonoroComponent = ({ lat, lng, tipo, onClick, status, equipamento, perimetro, id_instalacao, flag , id_sigma}) => {

       // const link =
            //"https://gruporosinetos.com/call/softphone.html?wp_serveraddress=gruporosinetos.com&wp_username=webrtc&wp_password=1234&wp_callto=2024111&wp_autoaction=1";

            const link =
            "https://gruporosinetos.com/call/softphone.html?wp_serveraddress=gruporosinetos.com&wp_username=webrtc&wp_password=1234&wp_callto=2024111&wp_autoaction=1";


        const openLinkInNewTab = () => {
            window.open(link, "_blank"); // Abre em uma nova guia
        };

        async function handleClickLog() {
            setLogsPgm([]);


            console.log("handleClickPgm")
            try {
                const cadastro = {};
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulosonoro/tarefas/listartodasparaareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/" + limit;
                const response = await api.get(url, { headers });

                if (response.data) {
                    const dadosProcessados = processarDadosLogs(response.data);

                    setLogsPgm(dadosProcessados);
                    setOpenLogTable(true);
                }

            } catch (error) {
                console.error("Erro ao enviar o comando:", error);
            }
        }

        async function handleClickPgm(rele, comando) {

            console.log("handleClickPgm")
            try {
                const cadastro = {};
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulosonoro/tarefas/criarporareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_sonoro.id_modulo_sonoro + "/" + comando + "/" + rele + "/0/0/0/0/0/0/0";
                const response = await api.get(url, { headers });

                if (response.data) {
                    setSnackbarMessage('Comando Enviado!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);

                    // Adicionando um atraso de 3 segundos
                    setTimeout(() => {
                        handleInstalacaoSelecioanada(instalacaoSelecionada);
                    }, 3000); // 3000 milissegundos = 3 segundos
                }



            } catch (error) {
                setSnackbarMessage('Erro ao enviar comando');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error("Erro ao enviar o comando:", error);
            }
        }

        const processarIndexAtivarRele = (index) => {
            if (index === 1) {
                //rele 1
                return 1;
            } else if (index === 2) {
                //rele 2
                return 2;
            } else if (index === 3) {
                //rele 3
                return 3;
            } else if (index === 4) {
                //rele 4
                return 4;
            } else if (index === 5) {
                //rele 5
                return 5;
            }
        }

        const processarIndexDesativarRele = (index) => {
            if (index === 1) {
                //rele 1
                return 1;
            } else if (index === 2) {
                //rele 2
                return 2;
            } else if (index === 3) {
                //rele 3
                return 3;
            } else if (index === 4) {
                //rele 4
                return 4;
            } else if (index === 5) {
                //rele 5
                return 5;
            }
        }

        // Calcular a diferença de tempo
        const now = new Date();
        const lastResponse = new Date(equipamento.modulo_sonoro.data_hora_ultima_resposta);
        const differenceInMinutes = (now - lastResponse) / 1000 / 60; // Convertendo de milissegundos para minutos

        // Verificar se o alarme deve ser ativado
        const alarme = equipamento.alarme;

        // Definir o estado do equipamento
        let statusText;
        let statusColor;
        let isBlinking = false;

        const armada = equipamento.modulo_sonoro.armada;

        if (alarme) {
            statusText = "ALARME";
            statusColor = "red";
            isBlinking = true; // Habilitar o piscar se estiver em alarme
        } else if (differenceInMinutes < tempoInativo) {
            if (armada) {
                statusText = "ONLINE - ARMADA";
                statusColor = "green";
            }
            else {
                statusText = "ONLINE - DESARMADA";
                statusColor = "orange";
            }
        } else {
            statusText = "OFFLINE";
            statusColor = "red";
            isBlinking = true; // Habilitar o piscar se estiver offline
        }




        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={flag === 0 ? 3 : 12}
                style={{ border: flag === 1 ? '1px solid black' : null, padding: 1, margin: flag === 1 ? 3 : 0 }}

            >
                {
                    flag === 1 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12}
                            style={{ padding: 1 }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={11}
                                style={{ padding: 0 }}
                            >
                                <span style={{ fontWeight: 'bold', fontSize: 12 }}>TITANIWM MODULO UNIFIER</span>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={1}
                                style={{ padding: 0 }}
                            >
                                <button
                                    onClick={() => handleClickLog()} // Função de fechar
                                    style={{
                                        backgroundColor: 'green', // Cor laranja-avermelhada estilosa (TOP!)
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '8px',
                                        padding: '2px 8px',
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra leve
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    LOG
                                </button>
                            </Grid>

                        </Grid>
                    )
                }
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    item xs={flag === 0 ? 12 : 2}
                    style={{
                        boxShadow: alarme && flag === 0 ? `0 0 30px 150px rgba(255, 0, 0, 0.5)` : "none", // Sombra afastada e translúcida
                    }}>
                    <Grid item xs={12}>
                        <img
                            alt={"icone"}
                            style={{
                                textAlign: 'center',
                                width: '40px',  // Define a largura da imagem
                                height: '40px'  // Define a altura da imagem
                            }}
                            src={processarIcone(tipo)}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <span style={{ color: 'black', fontSize: '14px', fontWeight: 'bold' }}>{equipamento.modulo_sonoro.codigo} </span>
                    </Grid>


                    <Grid item xs={12}
                    >
                        <span style={{
                            color: statusColor,
                            fontSize: '14px',
                            fontWeight: 'bold',
                            animation: isBlinking && flag === 0 ? 'blink 2s step-start infinite' : 'none'
                        }}>
                            {flag === 0 ? statusText : ''}
                        </span>

                    </Grid>



                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    align="flex-start"
                    item xs={flag === 0 ? 1 : 6}
                >
                    {flag === 1 && (
                        <div style={{
                            color: 'black', fontSize: '14px', fontWeight: 'bold',
                            display: 'flex', alignItems: 'center', gap: '8px', marginTop: '10px'
                        }}>
                            {[1, 2, 3, 4, 5].map((index) => {
                                const releNome = equipamento?.modulo_sonoro[`nome_rele${index}`];
                                const status = equipamento?.modulo_sonoro[`status_rele${index}`];
                                const iconPath = status === "on" ? switch_on : switch_off;

                                return (
                                    <div key={index} style={{
                                        border: '1px solid black',
                                        borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                                    }}>
                                        <span>{releNome}</span>
                                        {status === "on" ? (
                                            <IconButton color="error" onClick={() => { handleClickPgm(processarIndexDesativarRele(index), 11) }}>
                                                <img src={iconPath} alt={`${status} icon`} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                            </IconButton>
                                        ) : (
                                            <IconButton color="primary" onClick={() => { handleClickPgm(processarIndexAtivarRele(index), 10) }}>
                                                <img src={iconPath} alt={`${status} icon`} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                            </IconButton>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    align="flex-start"
                    item xs={2}
                >
                    {flag === 1 && (

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"

                                item xs={6}
                            >

                                {
                                    equipamento.modulo_sonoro.conectado_eth.includes("cabo_conectado") &&
                                    (<Grid item xs={6}>
                                        <Tooltip
                                            title={<span>Cabo conectado</span>}
                                            placement="top"
                                            arrow
                                            style={{ backgroundColor: 'white' }}
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            <img
                                                alt="Ícone"
                                                style={{
                                                    width: "30px", // Define a largura da imagem
                                                    height: "30px", // Define a altura da imagem
                                                }}
                                                src={cabo_conectado}
                                            />
                                        </Tooltip>

                                    </Grid>)

                                }

                                {
                                    equipamento.modulo_sonoro.conectado_eth.includes("cabo_desconectado") &&
                                    (<Grid item xs={6}>
                                        <Tooltip
                                            title={<span>Cabo desconectado</span>}
                                            placement="top"
                                            arrow
                                            style={{ backgroundColor: 'white' }}
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            <img
                                                alt="Ícone"
                                                style={{
                                                    width: "30px", // Define a largura da imagem
                                                    height: "30px", // Define a altura da imagem
                                                    animation: "piscar 0.5s infinite"
                                                }}
                                                src={cabo_desconectado}
                                            />
                                        </Tooltip>

                                    </Grid>)

                                }


                                {
                                    equipamento.modulo_sonoro.conectado_eth.includes("cabo_conectado") &&
                                    equipamento.modulo_sonoro.conectado_eth.split(';').map(s => s.trim()).includes("internet_cabo") &&
                                    (<Grid item xs={6}>
                                        <Tooltip
                                            title={<span>Internet via cabo</span>}
                                            placement="top"
                                            arrow
                                            style={{ backgroundColor: 'white' }}
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            <img
                                                alt="Ícone"
                                                style={{
                                                    width: "30px", // Define a largura da imagem
                                                    height: "30px", // Define a altura da imagem
                                                }}
                                                src={cabo_internet}
                                            />
                                        </Tooltip>

                                    </Grid>)

                                }

                                {
                                    equipamento.modulo_sonoro.conectado_eth.includes("cabo_conectado") &&
                                    equipamento.modulo_sonoro.conectado_eth.includes("sem_internet_cabo") &&
                                    (<Grid item xs={6}>
                                        <Tooltip
                                            title={<span>Sem Internet via cabo</span>}
                                            placement="top"
                                            arrow
                                            style={{ backgroundColor: 'white' }}
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            <img
                                                alt="Ícone"
                                                style={{
                                                    width: "30px", // Define a largura da imagem
                                                    height: "30px", // Define a altura da imagem
                                                    animation: "piscar 0.5s infinite"
                                                }}
                                                src={cabo_sem_internet}
                                            />
                                        </Tooltip>

                                    </Grid>)

                                }
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                style={{ marginRight: '10px' }}
                                item xs={6}
                            >
                                {
                                    equipamento.modulo_sonoro.conectado_gsm.includes("modem_conectado") &&
                                    (<Grid item xs={6}>
                                        <Tooltip
                                            title={<span>Modem conectado</span>}
                                            placement="top"
                                            arrow
                                            style={{ backgroundColor: 'white' }}
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            <img
                                                alt="Ícone"
                                                style={{
                                                    width: "30px", // Define a largura da imagem
                                                    height: "30px", // Define a altura da imagem
                                                }}
                                                src={modem_conectado}
                                            />
                                        </Tooltip>

                                    </Grid>)

                                }

                                {
                                    equipamento.modulo_sonoro.conectado_gsm.includes("modem_desconectado") &&
                                    (<Grid item xs={6}>
                                        <Tooltip
                                            title={<span>Modem desconectado</span>}
                                            placement="top"
                                            arrow
                                            style={{ backgroundColor: 'white' }}
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            <img
                                                alt="Ícone"
                                                style={{
                                                    width: "30px", // Define a largura da imagem
                                                    height: "30px", // Define a altura da imagem
                                                    animation: "piscar 0.5s infinite"
                                                }}
                                                src={modem_desconectado}
                                            />
                                        </Tooltip>

                                    </Grid>)

                                }


                                {
                                    equipamento.modulo_sonoro.conectado_gsm.includes("modem_conectado") &&
                                    equipamento.modulo_sonoro.conectado_gsm.split(';').map(s => s.trim()).includes("internet_modem") &&
                                    (<Grid item xs={6}>
                                        <Tooltip
                                            title={<span>Internet via modem</span>}
                                            placement="top"
                                            arrow
                                            style={{ backgroundColor: 'white' }}
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            <img
                                                alt="Ícone"
                                                style={{
                                                    width: "30px", // Define a largura da imagem
                                                    height: "30px", // Define a altura da imagem
                                                }}
                                                src={modem_internet}
                                            />
                                        </Tooltip>

                                    </Grid>)

                                }

                                {
                                    equipamento.modulo_sonoro.conectado_gsm.includes("modem_conectado") &&
                                    equipamento.modulo_sonoro.conectado_gsm.includes("sem_internet_modem") &&
                                    (<Grid item xs={6}>
                                        <Tooltip
                                            title={<span>Sem Internet via modem</span>}
                                            placement="top"
                                            arrow
                                            style={{ backgroundColor: 'white' }}
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            <img
                                                alt="Ícone"
                                                style={{
                                                    width: "30px", // Define a largura da imagem
                                                    height: "30px", // Define a altura da imagem
                                                    animation: "piscar 0.5s infinite"

                                                }}
                                                src={modem_sem_internet}
                                            />
                                        </Tooltip>

                                    </Grid>)

                                }
                            </Grid>
                        </Grid>


                    )}
                </Grid>

                {
                    flag === 1 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12}
                            style={{ padding: 1 }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={6}
                                style={{ padding: 1 }}
                            >

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item
                                    xs={12}
                                    style={{ padding: 0 }}
                                >
                                    <button
                                         onClick={() => openModal(id_sigma)}
                                      // onClick={openLinkInNewTab}
                                    >
                                        <img
                                            src={icone_call} // Substitua pelo caminho correto do ícone
                                            alt="Chamar"
                                            style={{
                                                width: "20px", // Tamanho do ícone
                                                height: "20px",
                                            }}
                                        />
                                        Chamar
                                    </button>
                                </Grid>

                            </Grid>

                        </Grid>
                    )
                }

            </Grid>
        );
    };




    const CentralComponent = ({ lat, lng, tipo, onClick, status, equipamento, perimetro, id_instalacao, flag }) => {



        async function handleClickCentral(comando) {

            console.log("handleClickPgm")
            try {
                const cadastro = {};
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                let url = "";
                if (equipamento.modulo_pgm && parseInt(equipamento.modulo_pgm.id_modulo_pgm) > 0) {
                    url = `/v1/protected/modulopgm/tarefas/criarporareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/" + comando;
                } else if (equipamento.modulo_sonoro && parseInt(equipamento.modulo_sonoro.id_modulo_sonoro) > 0) {
                    url = `/v1/protected/modulosonoro/tarefas/criarporareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_sonoro.id_modulo_sonoro + "/" + comando + "/0/0/0/0/0/0/0/0";
                } else {
                    alert("Nenhum Módulo TITANIWM PGM v1.0 ou Módulo TITANIWM Unifer associados a Central");
                }
                const response = await api.get(url, { headers });

                if (response.data) {
                    setSnackbarMessage('Comando Enviado!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);

                    // Adicionando um atraso de 3 segundos
                    setTimeout(() => {
                        handleInstalacaoSelecioanada(instalacaoSelecionada);
                    }, 5000); // 3000 milissegundos = 3 segundos
                }



            } catch (error) {
                setSnackbarMessage('Erro ao enviar comando');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error("Erro ao enviar o comando:", error);
            }
        }

        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={flag === 0 ? 3 : 12}
                style={{ border: flag === 1 ? '1px solid black' : null, padding: 1, margin: flag === 1 ? 3 : 0 }}


            >
                {
                    flag === 1 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12}
                            style={{ padding: 1 }}
                        >
                            <span style={{ fontWeight: 'bold', fontSize: 12 }}>CENTRAL DE ALARME</span>
                        </Grid>
                    )
                }
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"

                    item xs={flag === 0 ? 12 : 2}
                >
                    <Grid item xs={12}

                    >
                        <img
                            alt={"icone"}
                            style={{
                                textAlign: 'center',
                                width: '40px',  // Define a largura da imagem
                                height: '40px'  // Define a altura da imagem
                            }}
                            src={processarIcone(tipo)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <span style={{ color: 'black', fontSize: '14px', fontWeight: 'bold' }}>{equipamento.nome_mapa} </span>
                        {(equipamento.modulo_pgm !== null &&
                            <span style={{ color: 'black', fontSize: '14px', fontWeight: 'bold' }}>{equipamento.modulo_pgm.nome_mapa} </span>
                        )}
                    </Grid>




                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    align="flex-start"
                    item xs={flag === 0 ? 1 : 8}
                >
                    {flag === 1 && (
                        <div style={{
                            color: 'black',
                            display: 'flex', alignItems: 'center', gap: '8px', marginTop: '10px'
                        }}>

                            <div style={{
                                border: '1px solid black',
                                borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                            }}>
                                <IconButton color="error" onClick={() => { handleClickCentral(27) }}  >
                                    <img src={icone_armar_stay} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                    <span style={{ fontSize: 12, color: 'black' }}>Armar em Stay</span>
                                </IconButton>
                            </div>


                            <div style={{
                                border: '1px solid black',
                                borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                            }}>
                                <IconButton color="error" onClick={() => { handleClickCentral(28) }}  >
                                    <img src={icone_armar_away} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                    <span style={{ fontSize: 12, color: 'black' }}>Armar em Away</span>
                                </IconButton>
                            </div>

                            <div style={{
                                border: '1px solid black',
                                borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                            }}>
                                <IconButton color="error" onClick={() => { handleClickCentral(29) }}  >
                                    <img src={icone_desarmar} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                    <span style={{ fontSize: 12, color: 'black' }}>Desarmar</span>
                                </IconButton>
                            </div>

                        </div>
                    )}
                </Grid>

            </Grid>
        );
    };


    const CornetaComponent = ({ lat, lng, tipo, onClick, status, equipamento, perimetro, id_instalacao, flag,id_sigma }) => {


    const link =
            "https://gruporosinetos.com/call/softphone.html" ;//?wp_serveraddress=gruporosinetos.com&wp_username=webrtc&wp_password=1234&wp_callto=" + id_sigma + "&wp_autoaction=1";


        const openLinkInNewTab = () => {
            window.open(link, "_blank"); // Abre em uma nova guia
        };



        async function handleClickCorneta(comando) {

            console.log("handleClickPgm")
            try {
                const cadastro = {};
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/criarporareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/" + comando;
                const response = await api.get(url, { headers });

                if (response.data) {
                    setSnackbarMessage('Comando Enviado!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);

                    // Adicionando um atraso de 3 segundos
                    setTimeout(() => {
                        handleInstalacaoSelecioanada(instalacaoSelecionada);
                    }, 5000); // 3000 milissegundos = 3 segundos
                }



            } catch (error) {
                setSnackbarMessage('Erro ao enviar comando');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error("Erro ao enviar o comando:", error);
            }
        }

        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={flag === 0 ? 3 : 12}
                style={{ border: flag === 1 ? '1px solid black' : null, padding: 1, margin: flag === 1 ? 3 : 0 }}


            >
                {
                    flag === 1 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12}
                            style={{ padding: 1 }}
                        >
                            <span style={{ fontWeight: 'bold', fontSize: 12 }}>CORNETA KHOMP</span>
                        </Grid>
                    )
                }
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    align="flex-start"

                    item xs={flag === 0 ? 12 : 2}
                >
                    <Grid item xs={12}

                    >
                        <img
                            alt={"icone"}
                            style={{
                                textAlign: 'center',
                                width: '40px',  // Define a largura da imagem
                                height: '40px'  // Define a altura da imagem
                            }}
                            src={processarIcone(tipo)}
                        />
                    </Grid>



                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    align="flex-start"
                    item xs={flag === 0 ? 1 : 8}
                >
                    {flag === 1 && (
                        <div style={{
                            color: 'black',
                            display: 'flex', alignItems: 'center', gap: '1px', marginTop: '1px'
                        }}>

                            <div style={{
                                border: '1px solid black',
                                borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                            }}>
                                <IconButton color="error" onClick={() => { handleClickCorneta(6) }}  >
                                    <img src={icone_play} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                    <span style={{ fontSize: 12, color: 'black' }}>Audio 1</span>
                                </IconButton>
                            </div>

                            <div style={{
                                border: '1px solid black',
                                borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                            }}>
                                <IconButton color="error" onClick={() => { handleClickCorneta(7) }}  >
                                    <img src={icone_play} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                    <span style={{ fontSize: 12, color: 'black' }}>Audio 2</span>
                                </IconButton>
                            </div>


                            <div style={{
                                border: '1px solid black',
                                borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                            }}>
                                <IconButton color="error" onClick={() => { handleClickCorneta(8) }}  >
                                    <img src={icone_play} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                    <span style={{ fontSize: 12, color: 'black' }}>Audio 3</span>
                                </IconButton>
                            </div>

                            <div style={{
                                border: '1px solid black',
                                borderRadius: '4px', padding: '1px', display: 'flex', alignItems: 'center', backgroundColor: 'white',
                            }}>
                                <IconButton color="error" onClick={() => { handleClickCorneta(9) }}  >
                                    <img src={icone_play} style={{ width: '20px', height: '20px', marginLeft: '1px' }} />
                                    <span style={{ fontSize: 12, color: 'black' }}>Audio 4</span>
                                </IconButton>
                            </div>






                        </div>
                    )}
                </Grid>

                {
                    flag === 1 && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12}
                            style={{ padding: 1 }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={6}
                                style={{ padding: 1 }}
                            >

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item
                                    xs={12}
                                    style={{ padding: 0 }}
                                >
                                    <button
                                         onClick={() => openModal(id_sigma)}
                                      // onClick={openLinkInNewTab}
                                    >
                                        <img
                                            src={icone_call} // Substitua pelo caminho correto do ícone
                                            alt="Chamar"
                                            style={{
                                                width: "20px", // Tamanho do ícone
                                                height: "20px",
                                            }}
                                        />
                                        Chamar
                                    </button>
                                </Grid>

                            </Grid>

                        </Grid>
                    )
                }

            </Grid>
        );
    };



    const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());


    const [isConnected, setIsConnected] = useState(false);

    const [numEquipamentos, setNumEquipamentos] = useState(0);

    useEffect(() => {
        // Obter o token do cookie
        const token = Cookies.get('token');


        // Criar a conexão WebSocket
        const socket = new WebSocket(`wss://gruporosinetos.com/api/monitoramentowebsocket?token=${token}`);

        // Passando o WebSocket para o Stomp
        const client = Stomp.over(socket);

        if (!isConnected) {
            // Definir o comportamento para quando a conexão for aberta
            client.connect({}, () => {
                setIsConnected(true);
                console.log('Conectado ao WebSocket');

                // Inscrever no tópico /topic/dados
                client.subscribe('/topic/dados', (message) => {
                    // Aqui você pode tratar a mensagem recebida
                    //console.log('Dados recebidos:', message.body);
                    console.log("atualizou");
                    let num_equipamentos = 0;
                    try {
                        const dados = JSON.parse(message.body); // Parse do JSON recebido

                        var num_equipamentos_armados = 0;
                        var num_equipamentos_alarmados = 0;
                        var num_equipamentos_desarmados = 0;
                        var num_equipamentos_offlines = 0;
                        const now = new Date();

                        // Percorre cada instalação
                        for (let i = 0; i < dados.length; i++) {
                            const novaInstalacao = dados[i];

                            if (novaInstalacao.equipamentos !== null) {


                                for (let p = 0; p < novaInstalacao.equipamentos.length; p++) {

                                    const novoEquipamento = novaInstalacao.equipamentos[p];
                                    // Verifica se o ID do equipamento ou status do alarme mudaram
                                    if (novoEquipamento.tipo_equipamento_area_tecnica === 0
                                    ) {

                                        const lastResponse = new Date(novoEquipamento.modulo_pgm.data_hora_ultima_resposta);
                                        const differenceInMinutes = (now - lastResponse) / 1000 / 60; // Convertendo de milissegundos para minutos

                                        const alarme = novoEquipamento.alarme;
                                        const armada = novoEquipamento.modulo_pgm.armada;

                                        if (alarme) {
                                            //statusText = "ALARME";
                                            num_equipamentos_alarmados++;
                                        } else if (differenceInMinutes < tempoInativo) {
                                            if (armada) {
                                                // statusText = "ONLINE - ARMADA";
                                                num_equipamentos_armados++;
                                            }
                                            else {
                                                //  statusText = "ONLINE - DESARMADA";
                                                num_equipamentos_desarmados++;
                                            }
                                        } else {
                                            // statusText = "OFFLINE";
                                            num_equipamentos_offlines++;
                                        }



                                    }

                                }

                                //fazer ossets
                                setPgmsArmadas(num_equipamentos_armados);
                                setPgmsDesarmadas(num_equipamentos_desarmados);
                                setPgmsOfflines(num_equipamentos_offlines);
                                setPgmsAlarmadas(num_equipamentos_alarmados);
                            }
                        }


                        const currentTime = Date.now();


                        // Função para verificar alterações profundas nas instalações e equipamentos
                        const dadosSaoDiferentes = (novosDados, dadosAtuais) => {




                            if (novosDados.length !== dadosAtuais.length) return true;

                            // Percorre cada instalação
                            for (let i = 0; i < novosDados.length; i++) {
                                const novaInstalacao = novosDados[i];
                                const instalacaoAtual = dadosAtuais[i];




                                if (instalacaoSelecionadaRef.current) {
                                    if (novaInstalacao.id_instalacao === instalacaoSelecionadaRef.current.id_instalacao) {
                                        //alert("Atualiuzar instalacao selecionada");
                                        setInstalacaoSelecionada({
                                            ...instalacaoSelecionadaRef.current,
                                            equipamentos: novaInstalacao.equipamentos // Substitui o array de equipamentos
                                        });
                                    }
                                }

                                // Verifica se o ID ou outra propriedade relevante da instalação mudou
                                if (novaInstalacao.id_instalacao !== instalacaoAtual.id_instalacao ||
                                    novaInstalacao.nome_mapa !== instalacaoAtual.nome_mapa) {

                                    if (instalacaoSelecionadaRef.current) {
                                        if (novaInstalacao.id_instalacao === instalacaoSelecionadaRef.current.id_instalacao) {
                                            //alert("Atualiuzar instalacao selecionada");
                                            setInstalacaoSelecionada({
                                                ...instalacaoSelecionadaRef.current,
                                                equipamentos: novaInstalacao.equipamentos // Substitui o array de equipamentos
                                            });
                                        }
                                    }


                                    return true;
                                }

                                // Verifica se o número de equipamentos mudou
                                if (novaInstalacao.equipamentos.length !== instalacaoAtual.equipamentos.length) return true;
                                num_equipamentos += novaInstalacao.equipamentos.length;
                                // Percorre cada equipamento da instalação

                                if (novaInstalacao.equipamentos !== null) {

                                    for (let j = 0; j < novaInstalacao.equipamentos.length; j++) {

                                        const novoEquipamento = novaInstalacao.equipamentos[j];
                                        const equipamentoAtual = instalacaoAtual.equipamentos[j];


                                        if (novoEquipamento.id_equipamento !== equipamentoAtual.id_equipamento ||
                                            novoEquipamento.alarme !== equipamentoAtual.alarme) {

                                            if (instalacaoSelecionadaRef.current) {
                                                if (novaInstalacao.id_instalacao === instalacaoSelecionadaRef.current.id_instalacao) {
                                                    //alert("Atualiuzar instalacao selecionada");
                                                    setInstalacaoSelecionada({
                                                        ...instalacaoSelecionadaRef.current,
                                                        equipamentos: novaInstalacao.equipamentos // Substitui o array de equipamentos
                                                    });
                                                }
                                            }

                                            return true;
                                        }

                                        // Se o equipamento é do tipo modulo_pgm, verifica os status dos relés
                                        if (novoEquipamento.tipo_equipamento_area_tecnica === 0) {
                                            // Verifica status_rele1 até status_rele4
                                            for (let k = 1; k <= 4; k++) {
                                                const releKey = `status_rele${k}`;
                                                if (novoEquipamento.modulo_pgm[releKey] !== equipamentoAtual.modulo_pgm[releKey]) {

                                                    if (instalacaoSelecionadaRef.current) {
                                                        if (novaInstalacao.id_instalacao === instalacaoSelecionadaRef.current.id_instalacao) {
                                                            //alert("Atualiuzar instalacao selecionada");
                                                            setInstalacaoSelecionada({
                                                                ...instalacaoSelecionadaRef.current,
                                                                equipamentos: novaInstalacao.equipamentos // Substitui o array de equipamentos
                                                            });
                                                        }
                                                    }

                                                    return true;
                                                }
                                            }
                                        } else if (novoEquipamento.tipo_equipamento_area_tecnica === 30) {
                                            // Verifica status_rele1 até status_rele4
                                            for (let k = 1; k <= 5; k++) {
                                                const releKey = `status_rele${k}`;
                                                if (novoEquipamento.modulo_sonoro[releKey] !== equipamentoAtual.modulo_sonoro[releKey]) {

                                                    if (instalacaoSelecionadaRef.current) {
                                                        if (novaInstalacao.id_instalacao === instalacaoSelecionadaRef.current.id_instalacao) {
                                                            //alert("Atualiuzar instalacao selecionada");
                                                            setInstalacaoSelecionada({
                                                                ...instalacaoSelecionadaRef.current,
                                                                equipamentos: novaInstalacao.equipamentos // Substitui o array de equipamentos
                                                            });
                                                        }
                                                    }

                                                    return true;
                                                }
                                            }
                                        }
                                    }



                                }
                            }
                            setNumEquipamentos(num_equipamentos);
                            // Se nenhuma diferença foi encontrada
                            return false;
                        };

                        setInstalacoes((instalacoesAtuais) => {
                            const shouldUpdate =
                                dadosSaoDiferentes(dados, instalacoesAtuais) ||
                                (currentTime - lastUpdateTime > 30000); // Verifica se passaram 30 segundos

                            if (shouldUpdate) {
                                setLastUpdateTime(currentTime); // Atualiza o tempo da última atualização
                                return dados; // Atualiza o estado com os novos dados
                            }
                            return instalacoesAtuais; // Mantém o estado inalterado se não houver mudanças
                        });

                    } catch (error) {
                        console.error('Erro ao fazer parse dos dados:', error);
                    }
                    setLoadingInstalacoes(false);


                });

                // Configurar envio a cada 5 segundos
                const intervalId = setInterval(() => {
                    if (client.connected) {
                        client.send("/app/requestData", {}, JSON.stringify({}));
                    }
                }, 2000); // 5000ms = 5 segundos


            }, (error) => {
                console.error('Erro ao conectar ao WebSocket:', error);
            });
        }

        // Limpeza da conexão quando o componente for desmontado
        return () => {
            if (client.connected) {
                client.disconnect(() => {
                    console.log('Desconectado do WebSocket');
                });
            }
        };
    }, []); // O array vazio garante que isso será executado uma única vez


    const [searchTerm, setSearchTerm] = useState("");


    function returnDescricaoEndereco(objeto) {

        return objeto.logradouro + ", " + objeto.numero + ", bairro: " + objeto.bairro + " Complemento: " + objeto.complemento +
            "\n" + objeto.cidade + " UF: " + objeto.estado + " CEP: " + objeto.cep

    }

    const filteredInstalacoes = instalacoes
        ? instalacoes.filter((instalacao) => {
            const matchesNomeMapa =
                instalacao.nome_mapa &&
                instalacao.nome_mapa.toLowerCase().includes(searchTerm.toLowerCase());

            const matchesEquipamentos = instalacao.equipamentos?.some(
                (equipamento) =>
                    equipamento &&
                    equipamento.tipo_equipamento_area_tecnica === 0 &&
                    equipamento.modulo_pgm &&
                    equipamento.modulo_pgm.id_modulo_pgm > 0 &&
                    equipamento.modulo_pgm.codigo.toLowerCase().includes(searchTerm.toLowerCase())
            );

            // Exibe a instalação se algum critério for atendido
            return matchesNomeMapa || matchesEquipamentos;
        })
        : [];

    const [openLogTable, setOpenLogTable] = useState(false);


    const tipoRequisicaoMap = {
        0: "Acionar Ambos Reles",
        1: "Acionar Rele 1",
        2: "Acionar Rele 2",
        3: "Desligar Ambos Reles",
        4: "Desligar Rele 1",
        5: "Desligar Rele 2",
        6: "Reproduzir Audio Personalizado 1",
        7: "Reproduzir Audio Personalizado 2",
        8: "Reproduzir Audio Personalizado 3",
        9: "Reproduzir Audio Personalizado 4",
        10: "Reproduzir Audio Personalizado 5",
        20: "Desativar Automação Geral",
        21: "Ativar Automação Geral",
        22: "Reiniciar PGM",
        23: "Desativar Automação dos Reles",
        24: "Ativar Automação dos Reles",
        25: "Desativar Automação da Corneta",
        26: "Ativar Automação da Corneta",
        27: "Armar Central em Stay",
        28: "Armar Central em Away",
        29: "Desativar Central",
        50: "Central Armada",
        51: "Central Desarmada",
        52: "Central em Alarme"
    };

    const respondidoModuloPgmMap = {
        0: "Não Respondido",
        1: "Respondido"
    };

    const colunasLogsPgm = useMemo(
        () => [
            {
                accessorKey: 'id_tarefa',
                header: 'ID',
                size: 30,
            },
            {
                accessorKey: 'data_hora_requisicao',
                header: 'Data Requisição',
                size: 200,
            },
            {
                accessorKey: 'data_hora_resposta',
                header: 'Data Resposta',
                size: 200,
            },
            {
                accessorKey: 'tipo_requisicao',
                header: 'Tipo',
                size: 200,
            },
            {
                accessorKey: 'origem',
                header: 'Origem',
                size: 200,
            },
            {
                accessorKey: 'respondido_modulo_pgm',
                header: 'Respondido',
                size: 200,
            },
        ],
        [],
    );



    const options = {
        mapTypeId: 'satellite',
        streetViewControl: false, // Desativa o Street View
        fullscreenControl: false, // Desativa o controle de tela cheia
        mapTypeControl: false, // Desativa a opção de alterar o tipo de mapa
        zoomControl: true, // Ativa somente o controle de zoom
        maxZoom: 21,
        styles: [
            {
                featureType: "poi", // Oculta pontos de interesse
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "road", // Simplicidade nas rodovias
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "transit", // Remove transporte público
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "administrative.land_parcel", // Remove labels de terrenos
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "administrative.neighborhood", // Remove labels de bairros
                stylers: [{ visibility: "off" }],
            },
        ],
    };



    const containerStyle = {
        width: '100%',
        height: '100%'
    };



    const [map, setMap] = useState();
    const mapRef = useRef(null);

    const [lat, setLat] = useState();
    const [lng, setLng] = useState();

    const [zoom, setZoom] = useState(15);


    return (
        <div  >
            <NavegadorGestao
                AreaTecnica={true}
                Monitoramento={true}
                MonitoramentoDashboard={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            {loading ?
                <Skeleton animation={"wave"} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    item xs={12}
                >

                    {/* Campo de pesquisa de instalacao*/}
                    <input
                        type="text"
                        placeholder="Pesquisar..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: "10px", padding: "5px", width: "100%" }}
                    />

                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        item xs={12}
                        style={{ padding: 10 }}
                        spacing={1}
                    >

                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            item
                            xs={3}
                            justifyContent={"center"}
                            style={{ backgroundColor: '#720309', padding: 5, margin: 5 }}
                        >
                            <img
                                alt={"icone"}
                                style={{
                                    textAlign: 'center',
                                    width: '70px', // Define a largura da imagem
                                    height: '70px' // Define a altura da imagem
                                }}
                                src={icone_instalacoes}
                            />

                            {/* Container para alinhar os textos */}
                            <div style={{ textAlign: "center" }}>
                                {/* Número de instalações */}
                                <span
                                    style={{
                                        fontSize: 36,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        display: "block", // Para alinhar em bloco
                                        marginBottom: "1px" // Controla a distância vertical
                                    }}
                                >
                                    {instalacoes.length}
                                </span>

                                {/* Texto 'instalações' */}
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        paddingLeft: "10px" // Recuo horizontal para direita
                                    }}
                                >
                                    instalações
                                </span>
                            </div>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            item
                            xs={3}
                            justifyContent={"center"}
                            style={{ backgroundColor: '#ffa424', padding: 5, margin: 5 }}
                        >
                            <img
                                alt={"icone"}
                                style={{
                                    textAlign: 'center',
                                    width: '70px', // Define a largura da imagem
                                    height: '70px' // Define a altura da imagem
                                }}
                                src={icone_equipamentos}
                            />

                            {/* Container para alinhar os textos */}
                            <div style={{ textAlign: "center" }}>
                                {/* Número de instalações */}
                                <span
                                    style={{
                                        fontSize: 36,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        display: "block", // Para alinhar em bloco
                                        marginBottom: "1px" // Controla a distância vertical
                                    }}
                                >
                                    {numEquipamentos}
                                </span>

                                {/* Texto 'instalações' */}
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        paddingLeft: "10px" // Recuo horizontal para direita
                                    }}
                                >
                                    equipamentos
                                </span>
                            </div>
                        </Grid>


                    </Grid>

                    <CabecalhoInformacoes
                                                pgmsArmadas={pgmsArmadas}
                                                pgmsDesarmadas={pgmsDesarmadas}
                                                pgmsOfflines={pgmsOfflines}
                                                pgmsAlarmadas={pgmsAlarmadas}

                                            />

                    <Grid
                        container
                        direction="row"
                        alignItems="flex-start"
                        item xs={12}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={4}
                            style={{}}
                        >


                            {!loadingInstalacoes && instalacoes && filteredInstalacoes.map((instalacao) => {

                                return (
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={12}
                                        style={{ border: "1px solid black", padding: 10, margin: 5 }}
                                        onClick={() => handleInstalacaoSelecioanada(instalacao)} // Atualiza o estado ao clicar

                                    >
                                        <LocalComponent
                                            key={instalacao.id_instalacao}
                                            id_instalacao={instalacao.id_instalacao}
                                            tipo_icone={instalacao.icone_mapa}
                                            nome_mapa={instalacao.nome_mapa}
                                            instalacao={instalacao}
                                        />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item
                                            xs={12}
                                            style={{
                                                border: "1px solid black",
                                                padding: 10,
                                                margin: 5,
                                                position: "relative",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "-10px",
                                                    left: "10px",
                                                    backgroundColor: "white",
                                                    padding: "0 5px",
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                Equipamentos
                                            </div>
                                            {instalacao.equipamentos.map((equipamento) => {

                                                return (
                                                    equipamento && equipamento.tipo_equipamento_area_tecnica === 0 && equipamento.modulo_pgm.id_modulo_pgm > 0 ? (
                                                        <ModuloPGMComponent
                                                            key={equipamento.id_equipamento}
                                                            tipo={equipamento.tipo_equipamento_area_tecnica}
                                                            equipamento={equipamento}
                                                            id_instalacao={instalacao.id_instalacao}
                                                            flag={0}
                                                        />
                                                    ) :
                                                        equipamento && equipamento.tipo_equipamento_area_tecnica === 70 ?
                                                            (
                                                                <CentralComponent
                                                                    key={equipamento.id_equipamento}
                                                                    tipo={equipamento.tipo_equipamento_area_tecnica}
                                                                    equipamento={equipamento}
                                                                    id_instalacao={instalacao.id_instalacao}
                                                                    flag={0}
                                                                    id_sigma={instalacao.identificador_integrador}
                                                                />
                                                            )
                                                            :
                                                            equipamento && equipamento.tipo_equipamento_area_tecnica === 30 ?
                                                                (
                                                                    <ModuloSonoroComponent
                                                                        key={equipamento.id_equipamento}
                                                                        tipo={equipamento.tipo_equipamento_area_tecnica}
                                                                        equipamento={equipamento}
                                                                        id_instalacao={instalacao.id_instalacao}
                                                                        flag={0}
                                                                        id_sigma={instalacao.identificador_integrador}

                                                                    />
                                                                ) : null
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                );
                            })}



                        </Grid>


                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            item xs={7}
                            style={{
                                border: "1px solid black",
                                padding: 10,
                                margin: 5,
                                position: "relative",
                            }}
                        >

                        </Grid>

                    </Grid>
                </Grid>
            }




            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    style: {
                        backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336',
                        marginTop: '60px', // Ajuste a altura conforme necessário
                        fontSize: '16px',
                        fontWeight: 'bold',
                        border: '1px solid black',
                        borderRadius: '4px',
                        padding: '10px',
                        zIndex: 555,
                        boxShadow: '0 2px 6px rgba(0,0,0,0.3)',

                    }
                }}
            />



            {
                instalacaoSelecionada && (
                    <div className="fullPageContainer">
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            align="center"
                            item xs={12}


                        >

                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                item xs={1}
                            >
                                <button
                                    onClick={() => setInstalacaoSelecionada(null)} // Função de fechar
                                    style={{
                                        backgroundColor: '#FF4500', // Cor laranja-avermelhada estilosa (TOP!)
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '8px',
                                        padding: '5px 5px',
                                        fontSize: '16px',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra leve
                                        textTransform: 'uppercase',
                                    }}


                                >
                                    X
                                </button>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={11}
                                style={{}}
                            >

                                <Typography style={{ fontSize: 16 }} variant="h6" color="inherit" noWrap  >
                                    {instalacaoSelecionada.tipo_instalacao === 0 ? (instalacaoSelecionada.nome + " " + instalacaoSelecionada.sobrenome) : instalacaoSelecionada.nome_fantasia} -
                                    {instalacaoSelecionada.tipo_instalacao === 0 ? instalacaoSelecionada.cpf : instalacaoSelecionada.cnpj} -
                                    {instalacaoSelecionada.identificador_integrador}
                                </Typography>

                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={6}
                                style={{}}
                            >


                                <span style={{ paddingTop: 10, paddingBottom: 1, fontSize: 16 }}>{returnDescricaoEndereco(instalacaoSelecionada)}</span>

                            </Grid>


                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={6}
                                style={{ minHeight: '300px' }}

                            >
                                {/*
                                <LoadScript
                                    googleMapsApiKey={globalConfig.mapKey}
                                    libraries={libraries}
                                    loadingElement={<div>Loading...</div>}
                                >
                                    <GoogleMap
                                        //onLoad={onMapLoad}
                                        center={mapPosition} // Posição mantida pelo estado
                                        zoom={21}          // Zoom mantido pelo estado
                                        onLoad={(mapInstance) => setMap(mapInstance)}
                                        mapContainerStyle={containerStyle}
                                        options={options}
                                    >

                                    </GoogleMap>
                                </LoadScript>*/}
                                {
                                    instalacaoSelecionada && (
                                        <MapaDoLocal instalacao={instalacaoSelecionada} />
                                    )
                                }



                            </Grid>



                            {instalacaoSelecionada.equipamentos
                                .sort((a, b) => a.id_equipamento - b.id_equipamento)
                                .map((equipamento) => {

                                    return (
                                        equipamento && equipamento.tipo_equipamento_area_tecnica === 0 && equipamento.modulo_pgm.id_modulo_pgm > 0 ? (
                                            <ModuloPGMComponent
                                                key={equipamento.id_equipamento}
                                                tipo={equipamento.tipo_equipamento_area_tecnica}
                                                equipamento={equipamento}
                                                id_instalacao={instalacaoSelecionada.id_instalacao}
                                                flag={1}
                                            />
                                        ) :
                                            equipamento && equipamento.tipo_equipamento_area_tecnica === 70 ?
                                                (
                                                    <CentralComponent
                                                        key={equipamento.id_equipamento}
                                                        tipo={equipamento.tipo_equipamento_area_tecnica}
                                                        equipamento={equipamento}
                                                        id_instalacao={instalacaoSelecionada.id_instalacao}
                                                        id_sigma={instalacaoSelecionada.identificador_integrador}

                                                        flag={1}
                                                    />
                                                )
                                                :
                                                equipamento && equipamento.tipo_equipamento_area_tecnica === 30 ?
                                                    (
                                                        <ModuloSonoroComponent
                                                            key={equipamento.id_equipamento}
                                                            tipo={equipamento.tipo_equipamento_area_tecnica}
                                                            equipamento={equipamento}
                                                            id_instalacao={instalacaoSelecionada.id_instalacao}
                                                            id_sigma={instalacaoSelecionada.identificador_integrador}
                                                            flag={1}
                                                        />
                                                    ) :
                                                    equipamento && equipamento.tipo_equipamento_area_tecnica === 120 ?
                                                        (
                                                            <CornetaComponent
                                                                key={equipamento.id_equipamento}
                                                                tipo={equipamento.tipo_equipamento_area_tecnica}
                                                                equipamento={equipamento}
                                                                id_instalacao={instalacaoSelecionada.id_instalacao}
                                                                flag={1}
                                                                id_sigma={instalacaoSelecionada.identificador_integrador}

                                                            />
                                                        ) : null
                                    );
                                })}


                        </Grid>
                    </div>

                )
            }




            {/* Modal */}
            {openLogTable && (
                <div
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo escuro com opacidade
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1100",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            width: "80%",
                            height: "80%",
                            borderRadius: "8px",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // Sombra para destaque
                            display: "flex",
                            flexDirection: "column",
                            padding: "20px",
                            position: "relative",
                        }}
                    >
                        {/* Botão de fechar */}
                        <button
                            onClick={() => setOpenLogTable(false)}
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                backgroundColor: "#FF4500",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                                padding: "8px",
                                cursor: "pointer",
                                fontWeight: "bold",
                            }}
                        >
                            Fechar
                        </button>

                        {/* Conteúdo do Modal */}
                        <h6 style={{ marginTop: 0 }}>Historico de Eventos</h6>

                        {/* Espaço reservado para a tabela */}
                        <div
                            style={{
                                flex: 1,
                                overflowY: "auto", // Adiciona rolagem se a tabela for grande
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                                padding: "10px",
                            }}
                        >

                            <div>
                                <div style={{ backgroundColor: 'white', margin: 5 }}>
                                    {logsPgm && (
                                        <MaterialReactTable
                                            columns={colunasLogsPgm}
                                            data={logsPgm}
                                            enableBottomToolbar={true}
                                            enableGlobalFilterModes
                                            enablePagination={true}
                                            enableRowActions={false}
                                            paginationDisplayMode={'pages'}
                                            getRowId={(row) => row.id_tarefa}
                                            enableGrouping
                                            enableExpandAll={false} //hide expand all double arrow in column header
                                            enableExpanding={true}
                                            filterFromLeafRows={true} //apply filtering to all rows instead of just parent rows
                                            paginateExpandedRows={false}
                                            initialState={{
                                                pagination: { pageSize: 200, pageIndex: 0 }, // Correção da sintaxe aqui
                                                showGlobalFilter: true,
                                            }}
                                            muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                            muiPaginationProps={{
                                                rowsPerPageOptions: [200, 300, 500],
                                                variant: 'outlined',
                                            }}
                                            enableFullScreenToggle={true}


                                        />

                                    )}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}


            <div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Janela Modal"
                >
                    <button onClick={closeModal} style={{ float: "center" }}>
                        Fechar
                    </button>
                    <iframe
                       ref={iframeRef}
                       onLoad={checkIframeContent} // Evento chamado ao carregar o iframe
                     
                        src={urlChamada}// {"https://gruporosinetos.com/call/softphone.html?wp_serveraddress=gruporosinetos.com&wp_username=webrtc&wp_password=1234&wp_callto=2024111&wp_autoaction=1"}
                        //src="https://gruporosinetos.com/call/softphone.html"

                        //src="https://gruporosinetos.com/call/click2call.html?wp_serveraddress=gruporosinetos.com&wp_username=webrtc&wp_password=1234&wp_callto=2024111&wp_autoaction=1"

                       
                       
                       style={{
                            width: "100%",
                            height: "100%",
                            border: "none",
                        }}
                        title="Softphone"
                    ></iframe>
                </Modal>
            </div>


        </div >
    );
}





