import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useParams } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import AppBar from '@mui/material/AppBar';
import api from '../../../../../services/api';
import MenuRecursosAdministrativos from '../components/menu';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';

import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

export default function VizualizarProcedimentoExecucao() {

    const [loading, setLoading] = useState(true);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const classes = useStyles();
    const history = useHistory();
    const { idProcedimentoExecucacao } = useParams();
    const { idOrdemServico } = useParams();

    const [observacoes, setObservacoes] = useState('');
    const [resultado, setResultado] = useState("");


    const [arquivos, setArquivos] = useState(null);


    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            console.log("Id a listar: " + idProcedimentoExecucacao);

            await api.get("/v1/protected/ordemservico/listarprocedimentoexecucaoporid/" + idProcedimentoExecucacao, {
                headers: headers
            }).then(function (response) {

                setResultado(response.data.procedimento.resultado);
                setObservacoes(response.data.procedimento.observacoes);


                setArquivos(response.data);


                setLoading(false);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();


    }, []);



    async function baixarArquivo(caminho) {
        try {
            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const response = await api.post("/v1/protected/ordemservico/baixarPdf", { caminho: encodeURIComponent(caminho) }, {
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });

            // Cria um blob com os dados recebidos
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Cria uma URL do blob
            const blobUrl = URL.createObjectURL(blob);

            // Abre uma nova guia com o arquivo PDF
            window.open(blobUrl, '_blank');
        } catch (error) {
            // Trate os erros conforme necessário
            console.log("Erro ao baixar o arquivo: " + error);
        }
    }


    return (
        <div>
            <NavegadorGestao
                ListarOrdemSevicos={'underline'}
                corGestaoServicosMarcado={'orange'}
                Gestao={'underline'}
                corGestaoMarcado={'orange'}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
          
        
                    <Container style={{paddingTop:5}} >
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :

                            <React.Fragment >
                                <Grid container spacing={2}
                                    alignItems="center"
                                    justifyContent="center"
                                >

<Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={12}
                                style={{ width: '100%', margin: 0, padding: 0 }}
                            >

                                <a href={"/gestao/administracao/ordensdeservicos/editar/" + idOrdemServico} style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"< Ordem de Serviço nº " + idOrdemServico}</a>
                            </Grid>

                                    <Grid
                                        item xs={12}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent={"center"}
                                    >


                                        <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                            <AppBar position="static" >

                                                <Tabs
                                                    value={value}
                                                    onChange={handleChange}
                                                    indicatorColor="secondary"
                                                    textColor="inherit"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    aria-label="scrollable auto tabs example"


                                                >
                                                    <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                                    <Tab label="Imagens" style={{ backgroundColor: 'green' }} />
                                                    <Tab label="Vídeos" style={{ backgroundColor: 'green' }} />
                                                    <Tab label="Documentos" style={{ backgroundColor: 'green' }} />

                                                </Tabs>
                                            </AppBar>

                                        </Box>
                                    </Grid>


                                    <SwipeableViews
                                        index={value}
                                        onChangeIndex={handleChangeIndex}
                                        style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                    >

                                        <Grid
                                            value={value} index={0}
                                            style={{ paddingTop: 30 }}
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="center"
                                            spacing={2}
                                        >




                                            <Typography variant="h8">
                                                {`Executado por ${arquivos.procedimento.usuario_executor.nome_amigavel}`} <br />
                                                {`às ${moment(arquivos.procedimento.data_hora_execucao, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}`}
                                            </Typography>

                                            <Grid
                                                value={value} index={0}
                                                style={{ paddingTop: 30 }}
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                            >




                                                <Grid item xs={12}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="resultado-selector-label">Resultado da Execução do Procedimento</InputLabel>
                                                            <Select
                                                                labelId="resultado-selector-label"
                                                                id="resultado-selector"
                                                                value={resultado}
                                                                label="Resultado"
                                                            >
                                                                <MenuItem value={0}>Falha</MenuItem>
                                                                <MenuItem value={1}>Sucesso</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>



                                                <Grid item xs={12} style={{ padding: 40 }}>
                                                    <TextField
                                                        variant="standard"
                                                        name="observacoes"
                                                        label="Observações"
                                                        required
                                                        id="observacoes"
                                                        value={observacoes}
                                                        fullWidth
                                                        multiline
                                                        rows={10}
                                                    />
                                                </Grid>







                                            </Grid>






                                        </Grid>

                                        <Grid
                                            value={value} index={1}
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="center"
                                        >


                                            <Grid item xs={12}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >

                                                <Grid item xs={4}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                                        {
                                                            arquivos.imagem1 &&
                                                            <Typography
                                                                variant="h5"
                                                            >
                                                                {"Descrição: " + arquivos.procedimento.descricao_imagem1}
                                                            </Typography>
                                                        }

                                                    </Grid>
                                                    <Grid item xs={12}
                                                        container
                                                        style={{ paddingBottom: 30 }}
                                                    >
                                                        {arquivos.imagem1 &&
                                                            <img
                                                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                                src={`data:image/jpeg;base64,${arquivos.imagem1}`} alt="Imagem 1" />
                                                        }
                                                    </Grid>

                                                </Grid>


                                                <Grid item xs={4}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12}
                                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                                    >
                                                        {
                                                            arquivos.imagem2 &&
                                                            <Typography
                                                                variant="h5"
                                                            >
                                                                {"Descrição: " + arquivos.procedimento.descricao_imagem2}
                                                            </Typography>
                                                        }

                                                    </Grid>
                                                    <Grid item xs={12}
                                                        container
                                                        style={{ paddingBottom: 30 }}
                                                    >
                                                        {arquivos.imagem2 &&
                                                            <img
                                                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                                src={`data:image/jpeg;base64,${arquivos.imagem2}`} alt="Imagem 2" />
                                                        }
                                                    </Grid>

                                                </Grid>


                                                <Grid item xs={4}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12}
                                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                                    >
                                                        {
                                                            arquivos.imagem3 &&
                                                            <Typography
                                                                variant="h5"
                                                            >
                                                                {"Descrição: " + arquivos.procedimento.descricao_imagem3}
                                                            </Typography>
                                                        }

                                                    </Grid>
                                                    <Grid item xs={12}
                                                        container
                                                        style={{ paddingBottom: 30 }}
                                                    >
                                                        {arquivos.imagem3 &&
                                                            <img
                                                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                                src={`data:image/jpeg;base64,${arquivos.imagem3}`} alt="Imagem 3" />
                                                        }
                                                    </Grid>

                                                </Grid>


                                                <Grid item xs={4}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12}
                                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                                    >
                                                        {
                                                            arquivos.imagem4 &&
                                                            <Typography
                                                                variant="h5"
                                                            >
                                                                {"Descrição: " + arquivos.procedimento.descricao_imagem4}
                                                            </Typography>
                                                        }

                                                    </Grid>
                                                    <Grid item xs={12}
                                                        container
                                                    >
                                                        {arquivos.imagem4 &&
                                                            <img
                                                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                                src={`data:image/jpeg;base64,${arquivos.imagem4}`} alt="Imagem 4" />
                                                        }
                                                    </Grid>

                                                </Grid>


                                            </Grid>

                                        </Grid>

                                        <Grid
                                            value={value} index={2}
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="center"
                                        >



                                            <Grid item xs={12}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >

                                                <Grid item xs={4}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                                        {
                                                            arquivos.video1 &&
                                                            <Typography
                                                                variant="h5"
                                                            >
                                                                {"Descrição: " + arquivos.procedimento.descricao_video1}
                                                            </Typography>
                                                        }

                                                    </Grid>
                                                    <Grid item xs={12}
                                                        container
                                                        style={{ paddingBottom: 30 }}
                                                    >
                                                        {arquivos.video1 &&
                                                            <video controls width="400" height="300">
                                                                <source
                                                                    type="video/mp4"  // ajuste o tipo de mídia conforme necessário (por exemplo, video/mp4, video/webm, etc.)
                                                                    src={`data:video/mp4;base64,${arquivos.video1}`}
                                                                />
                                                                Seu navegador não suporta o elemento de vídeo.
                                                            </video>

                                                        }
                                                    </Grid>

                                                </Grid>


                                                <Grid item xs={4}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12}
                                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                                    >
                                                        {
                                                            arquivos.video2 &&
                                                            <Typography
                                                                variant="h5"
                                                            >
                                                                {"Descrição: " + arquivos.procedimento.descricao_video2}
                                                            </Typography>
                                                        }

                                                    </Grid>
                                                    <Grid item xs={12}
                                                        container
                                                        style={{ paddingBottom: 30 }}
                                                    >
                                                        {arquivos.video2 &&
                                                            <video controls width="400" height="300">
                                                                <source
                                                                    type="video/mp4"  // ajuste o tipo de mídia conforme necessário (por exemplo, video/mp4, video/webm, etc.)
                                                                    src={`data:video/mp4;base64,${arquivos.video2}`}
                                                                />
                                                                Seu navegador não suporta o elemento de vídeo.
                                                            </video>
                                                        }
                                                    </Grid>

                                                </Grid>


                                                <Grid item xs={4}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12}
                                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                                    >
                                                        {
                                                            arquivos.video3 &&
                                                            <Typography
                                                                variant="h5"
                                                            >
                                                                {"Descrição: " + arquivos.procedimento.descricao_video3}
                                                            </Typography>
                                                        }

                                                    </Grid>
                                                    <Grid item xs={12}
                                                        container
                                                        style={{ paddingBottom: 30 }}
                                                    >
                                                        {arquivos.video3 &&
                                                            <video controls width="400" height="300">
                                                                <source
                                                                    type="video/mp4"  // ajuste o tipo de mídia conforme necessário (por exemplo, video/mp4, video/webm, etc.)
                                                                    src={`data:video/mp4;base64,${arquivos.video3}`}
                                                                />
                                                                Seu navegador não suporta o elemento de vídeo.
                                                            </video>
                                                        }
                                                    </Grid>

                                                </Grid>




                                            </Grid>
                                        </Grid>

                                        <Grid
                                            value={value} index={3}
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="center"
                                        >





                                            <Grid item xs={12}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >

                                                <Grid item xs={4}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                                        {
                                                            arquivos.arquivo1 &&
                                                            <Typography
                                                                variant="h5"
                                                            >
                                                                {"Descrição: " + arquivos.procedimento.descricao_arquivo1}
                                                            </Typography>
                                                        }

                                                    </Grid>
                                                    <Grid item xs={12}
                                                        container
                                                        style={{ paddingBottom: 30 }}
                                                    >
                                                        {arquivos.arquivo1 &&
                                                            <div
                                                                onClick={() => {

                                                                    baixarArquivo(arquivos.procedimento.caminho_arquivo1);


                                                                }}
                                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                                <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                            </div>




                                                        }
                                                    </Grid>

                                                </Grid>


                                                <Grid item xs={4}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12}
                                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                                    >
                                                        {
                                                            arquivos.arquivo2 &&
                                                            <Typography
                                                                variant="h5"
                                                            >
                                                                {"Descrição: " + arquivos.procedimento.descricao_arquivo2}
                                                            </Typography>
                                                        }

                                                    </Grid>
                                                    <Grid item xs={12}
                                                        container
                                                        style={{ paddingBottom: 30 }}
                                                    >
                                                        {arquivos.arquivo2 &&
                                                            <div
                                                                onClick={() => {

                                                                    baixarArquivo(arquivos.procedimento.caminho_arquivo2);


                                                                }}
                                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                                <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                            </div>



                                                        }
                                                    </Grid>

                                                </Grid>


                                                <Grid item xs={4}
                                                    container
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12}
                                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                                    >
                                                        {
                                                            arquivos.arquivo3 &&
                                                            <Typography
                                                                variant="h5"
                                                            >
                                                                {"Descrição: " + arquivos.procedimento.descricao_arquivo3}
                                                            </Typography>
                                                        }

                                                    </Grid>
                                                    <Grid item xs={12}
                                                        container
                                                        style={{ paddingBottom: 30 }}
                                                    >
                                                        {arquivos.arquivo3 &&
                                                            <div
                                                                onClick={() => {

                                                                    baixarArquivo(arquivos.procedimento.caminho_arquivo2);


                                                                }}
                                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                                <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                            </div>

                                                        }
                                                    </Grid>

                                                </Grid>





                                            </Grid>

                                        </Grid>

                                    </SwipeableViews>







                                </Grid>


                            </React.Fragment>
                        }

                    </Container>
        
        </div >
    );
}


