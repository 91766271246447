import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Paper } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import SecurityIcon from '@mui/icons-material/Security';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import SNARonda from './snaRonda.js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PostAddIcon from '@mui/icons-material/PostAdd';
import HomeIcon from '@mui/icons-material/Home';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import {
    Box,
    Tooltip
} from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { Email, EmailOutlined, Visibility, VisibilityOff, Message, MessageOutlined } from '@mui/icons-material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import { Button } from "@material-ui/core";

import {
    MaterialReactTable,

} from 'material-react-table';

import ReactApexChart from "react-apexcharts";

import FlagIcon from '@mui/icons-material/Flag'; // Objetivo
import TrendingUpIcon from '@mui/icons-material/TrendingUp'; // Meta
import BlockIcon from '@mui/icons-material/Block'; // Sem/0
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Mínimo 1
import LooksTwoIcon from '@mui/icons-material/LooksTwo'; // Mínimo 2
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'; // Alcance Meta
import LooksOneIcon from '@mui/icons-material/LooksOne'; // Mínimo 2


function novaRondaVirtual() {
    window.location.href = "/gestao/operacional/rondavirtual/cadastrar"

}

export default function RondaVirtual() {

    const [openSnaRondaVirtual, setOpenSnaRondaVirtual] = useState(false);
    const [rondaVirtualSna, setRondaVirtualSna] = useState(null);

    const handleCloseSnaRondaVirtual = () => {
        setRondaVirtualSna(null);
        setOpenSnaRondaVirtual(false);
    };

    const handleOpenSnaRondaVirtual = (ronda) => {
        setRondaVirtualSna(ronda);
        setOpenSnaRondaVirtual(true);
    };




    const [loading, setLoading] = useState(true);
    const [rondasVirtuais, setRondasVirtuais] = useState([]);
    const [value, setValue] = useState(0);

    const [loadingDashboard, setLoadingDashboard] = useState(true);

    const regras = Cookies.get("regra");

    const [dadosGrafico, setDadosGrafico] = useState({
        series: [
            { name: 'Rondas', data: [] },
        ],
        options: {
            chart: {
                height: '100%',
                type: 'line',
                width: '100%',
            },
            colors: ['#0288d1'], // Cor principal da linha
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 4, // Define a espessura da linha
                curve: 'smooth', // Faz a linha suave (opcional)
                colors: ['#0288d1'], // Cor da linha
            },
            responsive: [
                {
                    breakpoint: 768, // Para telas menores que 768px
                    options: {
                        chart: {
                            width: '100%',
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
            xaxis: {
                categories: [], // As categorias (dias do mês)
                title: {
                    text: 'Dias do Mês', // Opcional: título do eixo X
                },
            },
            yaxis: {
                title: {
                    text: 'Rondas Mensais',
                },
            },
            markers: {
                size: 5, // Tamanho dos pontos
                colors: ['#0288d1'], // Cor dos pontos
                strokeColors: '#fff', // Cor da borda do ponto
                strokeWidth: 2, // Espessura da borda dos pontos
            },
            fill: {
                opacity: 1,
            },
        },
    });

    async function listarObjetos() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/rondavirtual/listarresumidas", {
                headers: headers
            }).then(function (response) {

                //console.log(JSON.stringify(response.data));

                const updatedData = response.data.map(item => {
                    // Modificar o atributo 'conta_instalacao'
                    item.conta_instalacao = `${item.identificador_integrador} - `;
                    item.conta_instalacao +=
                        item.tipo_instalacao === 0
                            ? `${item.nome} ${item.sobrenome}` // Corrigir chamada do sobrenome
                            : (item.nome_empresarial && item.nome_empresarial.trim() !== ""
                                ? item.nome_empresarial
                                : item.nome_fantasia);
                    item.status =
                        item.status_ronda_virtual === 0 && item.data_hora_designacao !== null
                            ? "EM EXECUÇÃO"
                            : item.status_ronda_virtual === 1 && item.data_hora_designacao !== null
                                ? "FINALIZADO" :
                                item.status_ronda_virtual === 0 && item.data_hora_designacao === null
                                    ? "ABERTA"
                                    : "INDEFINIDO";

                    // Adicionar o identificador do integrador

                    return item;
                });

                const sortedData = updatedData.sort((a, b) => {
                    const parseDate = (dateString) => {
                        if (!dateString || typeof dateString !== 'string') return null; // Verifica se é uma string válida
                        return moment(dateString, "YYYY-MM-DD HH:mm:ss").toDate(); // Converte para um objeto Date
                    };

                    const dateA = parseDate(a.data_hora_finalizacao);
                    const dateB = parseDate(b.data_hora_finalizacao);

                    // Se uma data for nula, a não nula é considerada mais recente
                    if (!dateA && dateB) return 1;
                    if (dateA && !dateB) return -1;
                    if (!dateA && !dateB) return 0;

                    // Caso ambas as datas sejam válidas, compara normalmente
                    return dateB - dateA; // Do mais recente para o menos recente
                });
                setRondasVirtuais(sortedData);
                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Rondas Virtuais: " + _err)

        }

    }



    const [infoDashboard, setInfoDashboard] = useState(null);

    async function listarInfoDashboard() {


        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/rondavirtual/listarinfodashboard", {
                headers: headers
            }).then(function (response) {

                setInfoDashboard(response.data);


                // Formatando os dados para o gráfico
                const categorias = response.data.rondasMensaisParaGrafico.map(item => {
                    const date = new Date(item.data); // Converte a string de data para um objeto Date
                    const day = date.getDate(); // Obtém o dia
                    const month = date.toLocaleString('default', { month: 'short' }); // Obtém o mês abreviado (Ex: Jan, Feb, Mar)
                    return `${day} ${month}`; // Combina dia e mês abreviado
                });
                const valores = response.data.rondasMensaisParaGrafico.map(item => item.rondas);


                setDadosGrafico(prevState => ({
                    ...prevState,
                    series: [
                        { name: 'Rondas', data: valores },
                    ],
                    options: {
                        ...prevState.options,
                        xaxis: {
                            categories: categorias, // Passa apenas os dias como categorias
                            title: {
                                text: 'Dias do Mês', // Opcional: Título do eixo X
                            },
                        },
                        dataLabels: {
                            enabled: true, // Ativa os rótulos com os valores


                        },
                        tooltip: {
                            enabled: true, // Desativa o tooltip (opcional, para não interferir com os valores fixos)
                        },
                    }
                }));

                setLoadingDashboard(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Rondas Virtuais: " + _err)

        }

    }

    useEffect(() => {

        listarInfoDashboard();


    }, []);



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    async function visualizar(caminho) {

        try {
            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            console.log("Caminho: " + caminho);
            const cadastro = {

                dado1: caminho

            };
            const response = await api.post("/v1/protected/notificacoes/baixarPdf", cadastro, {
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });

            // Cria um blob com os dados recebidos
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Cria uma URL do blob
            const blobUrl = URL.createObjectURL(blob);

            // Abre uma nova guia com o arquivo PDF
            window.open(blobUrl, '_blank');
        } catch (error) {
            // Trate os erros conforme necessário
            console.log("Erro ao baixar o arquivo: " + error);
        }
    }







    const columns = useMemo(
        () => [


            ...(regras.includes("ROOT_MASTER")
                ? [{
                    accessorKey: 'acoes', // Primeira coluna de ações
                    header: '',
                    size: 60,
                    Cell: ({ row }) => {
                        return (
                            <Tooltip title="Deletar">
                                <IconButton
                                    sx={{ color: 'red' }} // Define a cor vermelha
                                    onClick={() => handleClickDeletarRondaVirtual(row.original.id_ronda_virtual)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        );
                    },
                }]
                : []), // Se não tiver ROOT_MASTER, não adiciona a coluna de ações

            {
                accessorKey: 'id_ronda_virtual', //normal accessorKey
                header: 'ID',
                size: 60,
            },




            {
                accessorKey: 'status', // normal accessorKey
                header: 'STATUS',
                size: 180,
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                                style={{
                                    padding: "5px 10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                                    backgroundColor: renderedCellValue === "FINALIZADO" ? "#b2fab4" : "#fbb4b4", // Verde médio ou vermelho médio
                                    color: renderedCellValue === "FINALIZADO" ? "#2e7d32" : "#c62828", // Verde escuro ou vermelho escuro
                                    fontWeight: "bold",
                                    fontSize: 12,
                                }}
                            >
                                {renderedCellValue}
                            </span>
                            {renderedCellValue === "FINALIZADO" && (
                                <button
                                    onClick={() => handleOpenSnaRondaVirtual(row.original)} // Passando o objeto da linha
                                    style={{
                                        marginLeft: '1px',
                                        padding: '1px 1px',
                                        backgroundColor: '#ffffff',
                                        color: 'green',
                                        border: 'none',
                                        borderRadius: '1px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <VisibilityIcon style={{ marginRight: '5px' }} />
                                </button>
                            )}

                        </div>
                    );
                },
            },
            
            {
                accessorKey: 'nome_grupo', //normal accessorKey
                header: 'Grupo Cliente',
                size: 60,
            },

            {
                accessorKey: 'conta_instalacao', // normal accessorKey
                header: 'Conta/Instalação',
                size: 350,
                Cell: ({ row }) => {
                    const idInstalacao = row.original.id_instalacao; // Obtém o id_instalacao
                    const contaInstalacao = row.original.conta_instalacao; // Obtém o valor de conta_instalacao

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <a
                                href={`/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/${idInstalacao}/0`}
                                style={{ color: 'green', textDecoration: 'none' }} // Remove o sublinhado
                                target="_blank"

                            >
                                <SettingsIcon fontSize="small" /> {/* Ícone de engrenagem */}
                            </a>
                            <span>{contaInstalacao}</span> {/* Exibe o texto da conta */}

                        </div>
                    );
                },
            },
            {
                accessorKey: 'nome_amigavel', //normal accessorKey
                header: 'Executor',
                size: 150,
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            {renderedCellValue}
                        </span>
                    )

                },
            },

            {
                accessorKey: 'data_hora_finalizacao',
                header: 'Data e Hora',
                size: 150,
                Cell: ({ cell }) => {

                    if (cell.getValue()) {
                        const formattedDate = moment(cell.getValue(), 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY');
                        return (
                            <span style={{ fontSize: 14, color: 'black', fontWeight: 'bold' }}>
                                {formattedDate}
                            </span>
                        );
                    }

                    return (
                        <span style={{ fontSize: 14, color: 'black', fontSize: 'bold' }}>

                        </span>
                    );


                },
                filterFn: 'includesString', // ou use 'equals', 'lessThan', etc., dependendo da necessidade
                enableSorting: true, // Habilitar a ordenação para a coluna
                enableColumnFilter: true, // Habilitar o filtro para a coluna
            },

            {
                accessorKey: 'duracao', //normal accessorKey
                header: 'Duração (minutos)',
                size: 100,
            },

        ],
        [],
    );




    const handleClickVizualizar = (event, row) => {

        visualizar(row.caminho_arquivo_original);
    };

    const handleMenuClick = (newValue) => {
        if (newValue === 0) {
            listarInfoDashboard();
        } else if (newValue === 1) {
            listarObjetos();
        }
        setValue(newValue);
    };





    async function handleClickDeletarRondaVirtual(id) {

        const confirmacao = window.confirm(`Tem certeza que deseja deletar a Ronda Virtual com ID: ${id}?`);

        if (!confirmacao) {
            // Usuário cancelou a ação
            return;
        }

        try {
            const cadastro = {};
            const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };


            await api.delete("v1/protected/rondavirtual/deletar/" + id, {
                headers: headers
            }).then(function (response) {

                if (response.data) {
                    alert("Ronda Virtual Removida");
                    listarObjetos();

                } else {
                    alert("Erro ao deletar Ronda Virtual!");
                    return;
                }

            });




        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            alert("Erro ao listar Rondas Virtuais: " + _err)
        }
    }


    const [rankingSelecao, setRankingSelecao] = useState('Hoje');



    return (
        <div>

            <NavegadorGestao
                Operacional={true}
                RondaVirtual={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            {value === 0 &&
                <div style={{ paddingTop: 5, paddingLeft: 220, width: '100%', backroungcolor: '#f0f4f4' }}>
                    {!loadingDashboard &&
                        <Grid item xs={12} container style={{ width: '100%', margin: 1 }} direction={'row'} >

                            {/* Linha 1 */}
                            <Grid item xs={12} container
                                style={{ width: '100%', margin: 1 }}
                                direction={'row'}
                                spacing={1}
                            >
                                <Grid item xs={12} sm={2}
                                    style={{
                                        padding: '10px',
                                        backgroundColor: '#00008B',
                                        color: 'white',
                                        borderRadius: '8px',
                                        textAlign: 'center',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        margin: '2px',
                                        display: 'flex', // Adiciona flexbox
                                        flexDirection: 'column', // Coloca os itens em coluna
                                        alignItems: 'center', // Centraliza horizontalmente
                                        justifyContent: 'center', // Centraliza verticalmente
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between', // Espaçamento entre os itens
                                            paddingTop: '10px',
                                            padding: '1px',
                                            borderRadius: '8px',
                                            color: 'white',
                                        }}
                                    >
                                        <FlagIcon />
                                        <span style={{ fontWeight: 'bold' }}>
                                            Metas
                                        </span>
                                    </div>


                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between', // Espaçamento entre os itens
                                            paddingTop: '10px',
                                            padding: '1px',
                                            borderRadius: '8px',
                                            color: 'white',
                                            margin: 10,
                                        }}
                                    >
                                        <span style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                                            <HomeIcon style={{ marginRight: '2px', color: '#FFD700' }} />
                                            <Tooltip title="Número de Instalações Ativadas">
                                                <Typography variant="body1">{infoDashboard.dadosMeta.objetivo}</Typography>
                                            </Tooltip>
                                        </span>

                                        <span style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                                            <SecurityIcon style={{ marginRight: '2px', color: '#FFD700' }} />
                                            <Tooltip title="A meta é 2 rondas mensais em cada Instalação ativa">
                                                <Typography variant="body1">{infoDashboard.dadosMeta.meta}</Typography>
                                            </Tooltip>

                                        </span>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between', // Espaçamento entre os itens
                                            paddingTop: '10px',
                                            padding: '1px',
                                            borderRadius: '8px',
                                            color: 'white',
                                            margin: 10,
                                        }}
                                    >
                                        <span style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                                            <LooksOneIcon style={{ marginRight: '2px', color: '#FFD700' }} />
                                            <Tooltip title="Porcentagem de Instalações que receberam no minimo uma ronda virtual">
                                                <Typography variant="body1">{parseInt(infoDashboard.dadosMeta.executadoumaronda)}%</Typography>
                                            </Tooltip>
                                        </span>

                                        <span style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                                            <LooksTwoIcon style={{ marginRight: '2px', color: 'green' }} />
                                            <Tooltip title="Porcentagem de Instalações que receberam 2 rondas no mês">
                                            <Typography variant="body1">{parseInt(infoDashboard.dadosMeta.executadoduasrondas)}%</Typography>
                                            </Tooltip>
                                        </span>
                                    </div>



                                </Grid>

                                <Grid item xs={12} sm={3}
                                    style={{
                                        padding: '10px',
                                        backgroundColor: '#00008B',
                                        color: 'white',
                                        borderRadius: '8px',
                                        textAlign: 'center',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        margin: '2px',
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <SecurityIcon />
                                    <span style={{ fontWeight: 'bold' }}>
                                        Rondas Virtuais Hoje
                                    </span>

                                    <Grid item xs={12} container
                                        direction={'row'}
                                        style={{ paddingTop: 2 }}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <span style={{ textAlign: 'center', margin: '1px', fontSize: 32, fontWeight: 'bold' }}>
                                            {infoDashboard.dashboardInfo.totalHoje}
                                        </span>
                                    </Grid>

                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginTop: '2px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {/* Exibição de Total de Ontem */}
                                        <Typography variant="body2" style={{ color: '#e0e0e0' }}>
                                            {infoDashboard.dashboardInfo.totalOntem} Ontem
                                        </Typography>

                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            {infoDashboard.dashboardInfo.totalHoje > infoDashboard.dashboardInfo.totalOntem ? (
                                                <>
                                                    <ArrowUpward style={{ color: 'green' }} />
                                                    <Typography variant="body2" style={{ color: 'green', marginLeft: '5px' }}>
                                                        {(((infoDashboard.dashboardInfo.totalHoje - infoDashboard.dashboardInfo.totalOntem) / infoDashboard.dashboardInfo.totalOntem) * 100).toFixed(2)}%
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <ArrowDownward style={{ color: 'red' }} />
                                                    <Typography variant="body2" style={{ color: 'red', marginLeft: '5px' }}>
                                                        {(((infoDashboard.dashboardInfo.totalOntem - infoDashboard.dashboardInfo.totalHoje) / infoDashboard.dashboardInfo.totalOntem) * 100).toFixed(2)}%
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={3}
                                    style={{
                                        padding: '10px',
                                        backgroundColor: '#191970',
                                        color: 'white',
                                        borderRadius: '8px',
                                        textAlign: 'center',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        margin: '2px',
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <AccessTimeIcon />
                                    <span style={{ fontWeight: 'bold', fontSize: 12 }}>
                                        Duração média da RV Hoje
                                    </span>

                                    <Grid item xs={12} container
                                        direction={'row'}
                                        style={{ paddingTop: 2 }}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <span style={{ textAlign: 'center', margin: '1px', fontSize: 32, fontWeight: 'bold' }}>
                                            {parseInt(infoDashboard.dashboardInfo.duracaoHoje) + " minutos"}
                                        </span>
                                    </Grid>

                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginTop: '2px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {/* Exibição de Total de Ontem */}
                                        <Typography variant="body2" style={{ color: '#e0e0e0' }}>
                                            {parseInt(infoDashboard.dashboardInfo.duracaoOntem) + " minutos"} Ontem
                                        </Typography>

                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            {infoDashboard.dashboardInfo.duracaoHoje > infoDashboard.dashboardInfo.duracaoOntem ? (
                                                <>
                                                    <ArrowUpward style={{ color: 'green' }} />
                                                    <Typography variant="body2" style={{ color: 'green', marginLeft: '5px' }}>
                                                        {(((infoDashboard.dashboardInfo.duracaoHoje - infoDashboard.dashboardInfo.duracaoOntem) / infoDashboard.dashboardInfo.duracaoOntem) * 100).toFixed(2)}%
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <ArrowDownward style={{ color: 'red' }} />
                                                    <Typography variant="body2" style={{ color: 'red', marginLeft: '5px' }}>
                                                        {(((infoDashboard.dashboardInfo.duracaoOntem - infoDashboard.dashboardInfo.duracaoHoje) / infoDashboard.dashboardInfo.duracaoOntem) * 100).toFixed(2)}%
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>



                                <Grid item xs={12} sm={3}
                                    style={{
                                        padding: '10px',
                                        backgroundColor: '#008B8B',
                                        color: 'white',
                                        borderRadius: '8px',
                                        textAlign: 'center',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        margin: '2px'
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <SecurityIcon />
                                    <span style={{ fontWeight: 'bold' }}>
                                        Rondas Virtuais no Mês
                                    </span>

                                    <Grid item xs={12} container
                                        direction={'row'}
                                        style={{ paddingTop: 2 }}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <span style={{ textAlign: 'center', margin: '1px', fontSize: 32, fontWeight: 'bold' }}>
                                            {infoDashboard.dashboardInfo.totalMes}
                                        </span>
                                    </Grid>

                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginTop: '2px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {/* Exibição de Total de Ontem */}
                                        <Typography variant="body2" style={{ color: '#e0e0e0' }}>
                                            {infoDashboard.dashboardInfo.totalMesPassado} Mês Passado
                                        </Typography>

                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            {infoDashboard.dashboardInfo.totalMes > infoDashboard.dashboardInfo.totalMesPassado ? (
                                                <>
                                                    <ArrowUpward style={{ color: 'green' }} />
                                                    <Typography variant="body2" style={{ color: 'green', marginLeft: '5px' }}>
                                                        {(((infoDashboard.dashboardInfo.totalMes - infoDashboard.dashboardInfo.totalMesPassado) / infoDashboard.dashboardInfo.totalMesPassado) * 100).toFixed(2)}%
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <ArrowDownward style={{ color: 'red' }} />
                                                    <Typography variant="body2" style={{ color: 'red', marginLeft: '5px' }}>
                                                        {(((infoDashboard.dashboardInfo.totalMesPassado - infoDashboard.dashboardInfo.totalMes) / infoDashboard.dashboardInfo.totalMesPassado) * 100).toFixed(2)}%
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>


                            {/* Linha 2*/}
                            <Grid item xs={12} container
                                style={{ width: '100%', margin: 1 }}
                                direction={'row'} >

                                <Grid item xs={8}
                                    style={{
                                        padding: '5px',

                                        borderRadius: '8px',
                                        textAlign: 'center',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        marginTop: '5px',
                                        marginRight: '12px',
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <div style={{ width: '100%', height: '100%' }}>
                                        <ReactApexChart
                                            options={dadosGrafico.options}
                                            series={dadosGrafico.series}
                                            type="line"
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={3}
                                    style={{
                                        padding: '5px',
                                        backgroundColor: '#191970',
                                        color: 'white',
                                        borderRadius: '8px',
                                        textAlign: 'center',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        marginTop: '5px',
                                        marginRight: 15,
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    {/* Cabeçalho */}
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            fontWeight: 'bold',
                                            padding: '5px',
                                            borderBottom: '1px solid white',
                                        }}
                                    >
                                        <span style={{ flex: 1, fontSize: '12px' }}>{"       "}</span>
                                        <span style={{ flex: 1, fontSize: '12px' }}>Nome</span>
                                        <span style={{ flex: 1, fontSize: '12px' }}>RVs</span>
                                    </div>

                                    {/* Linhas do Ranking - Renderiza com base na seleção */}
                                    {infoDashboard[`rankingDiario${rankingSelecao}`]?.map((ranking, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '5px',
                                                backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff', // Alterna as cores
                                                color: 'black',
                                                fontSize: '14px',
                                            }}
                                        >
                                            <span style={{ flex: 1, textAlign: 'center' }}>
                                                {index === 0 ? '👑' : index === 1 ? '🥈' : index === 2 ? '🥉' : index + 1}
                                            </span>
                                            <span style={{ flex: 1, textAlign: 'center' }}>{ranking.nomeAmigavel}</span>
                                            <span style={{ flex: 1, textAlign: 'center' }}>{ranking.rondas}</span>
                                        </div>
                                    ))}

                                    {/* Bolinhas de Seleção */}
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '10px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <button
                                            onClick={() => setRankingSelecao('Hoje')}
                                            style={{
                                                backgroundColor: rankingSelecao === 'Hoje' ? '#ffcc00' : 'transparent',
                                                border: '2px solid #ffcc00',
                                                borderRadius: '50%',
                                                width: '20px',
                                                height: '20px',
                                                margin: '0 5px',
                                            }}
                                        />
                                        <span style={{ marginRight: '10px', fontSize: '12px', color: 'white' }}>Hoje</span>

                                        <button
                                            onClick={() => setRankingSelecao('Ontem')}
                                            style={{
                                                backgroundColor: rankingSelecao === 'Ontem' ? '#ffcc00' : 'transparent',
                                                border: '2px solid #ffcc00',
                                                borderRadius: '50%',
                                                width: '20px',
                                                height: '20px',
                                                margin: '0 5px',
                                            }}
                                        />
                                        <span style={{ marginRight: '10px', fontSize: '12px', color: 'white' }}>Ontem</span>

                                        <button
                                            onClick={() => setRankingSelecao('Mes')}
                                            style={{
                                                backgroundColor: rankingSelecao === 'Mes' ? '#ffcc00' : 'transparent',
                                                border: '2px solid #ffcc00',
                                                borderRadius: '50%',
                                                width: '20px',
                                                height: '20px',
                                                margin: '0 5px',
                                            }}
                                        />
                                        <span style={{ fontSize: '12px', color: 'white' }}>Mês</span>
                                    </div>
                                </Grid>

                            </Grid>


                        </Grid>
                    }
                </div>

            }

            {
                value === 1 &&
                <div style={{ paddingTop: 5, paddingLeft: 220 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        item xs={12}
                    >
                        <Button style={{ margin: 5 }}
                            variant="contained"
                            color="primary"
                            onClick={novaRondaVirtual}
                        >
                            Nova Ronda Virtual
                        </Button>
                    </Grid>
                    {loading ?
                        <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                        </Skeleton>
                        :

                        <div>
                            {rondasVirtuais && (
                                <MaterialReactTable
                                    columns={columns}
                                    data={rondasVirtuais}
                                    enableGlobalFilterModes
                                    enablePagination={true}
                                    enableRowActions
                                    paginationDisplayMode={'pages'}
                                    getRowId={(row) => row.id_ronda_virtual}
                                    enableTopToolbar={true}
                                    enableBottomToolbar={true}
                                    enableColumnFilters={true}
                                    enableColumnActions={false}
                                    sortable={true}
                                    enableSorting={true}
                                    enableRowSelection={false}
                                    enableColumnFilterModes={true}
                                    enableExpandAll={true}
                                    enableColumnPinning={true}
                                    enableColumnResizing={true}
                                    initialState={{
                                        pagination: { pageSize: 100, pageIndex: 0 }, // Correção da sintaxe aqui
                                        showGlobalFilter: true,
                                        density: 'compact',
                                    }}

                                    muiPaginationProps={{
                                        rowsPerPageOptions: [100, 500, 1000],
                                        variant: 'outlined',
                                    }}
                                    enableFullScreenToggle={true}
                                    renderDetailPanel={({ row }) => (
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                left: '30px',
                                                maxWidth: '1000px',
                                                position: 'sticky',
                                                width: '100%',
                                            }}
                                        >
                                        </Box>
                                    )}
                                    renderRowActions={({ row }) => (
                                        <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>
                                            <Tooltip title="Gerenciar">
                                                <IconButton
                                                    color="primary"
                                                    href={`/gestao/operacional/rondavirtual/gerenciar/${row.original.id_ronda_virtual}`}
                                                >
                                                    <SettingsIcon />
                                                </IconButton>
                                            </Tooltip>

                                        </Box>
                                    )}

                                />
                            )}


                        </div>



                    }


                </div>
            }

            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    top: '100px', // Ajuste conforme a altura do NavegadorGestao
                    height: 'calc(100vh - 100px)',
                    '& .MuiDrawer-paper': {
                        position: 'fixed',
                        top: '100px', // Ajuste conforme a altura do NavegadorGestao
                        width: '220px', // Defina a largura do Drawer conforme necessário
                    },
                }}
            >
                <List>
                    <ListItem
                        sx={{
                            backgroundColor: value === 0 ? 'orange' : 'transparent', // Fundo laranja se value === 0
                        }}
                        button onClick={() => handleMenuClick(0)}>
                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem
                        sx={{
                            backgroundColor: value === 1 ? 'orange' : 'transparent', // Fundo laranja se value === 0
                        }}
                        button onClick={() => handleMenuClick(1)}>
                        <ListItemIcon><PostAddIcon /></ListItemIcon>
                        <ListItemText primary="Rondas Virtuais" />
                    </ListItem>
                    <ListItem
                        sx={{
                            backgroundColor: value === 2 ? 'orange' : 'transparent', // Fundo laranja se value === 0
                        }}
                    >
                        <ListItemIcon><PostAddIcon /></ListItemIcon>
                        <ListItemText primary="Relatoria(em breve)" />
                    </ListItem>
                </List>
            </Drawer>



            <Dialog open={openSnaRondaVirtual} onClose={handleCloseSnaRondaVirtual} PaperProps={{ style: { maxWidth: '1000px', minWidth: '800px' } }}>
                <DialogContent>
                    <SNARonda
                        rondaVirtual={rondaVirtualSna}
                    />
                </DialogContent>
            </Dialog>


        </div>
    );
}



