import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import globalConfig from '../../../../../configs/globalConfigs';
import AppBar from '@mui/material/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Stomp } from '@stomp/stompjs';

import Close from '@material-ui/icons/Close'
import { Snackbar } from '@material-ui/core';
import { Menu } from '@mui/material';

import Cookies from 'js-cookie';
import { MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import {
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import { useHistory } from "react-router-dom";
import moment from 'moment';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    GoogleMap,
    Marker,
    LoadScript,
    OverlayView,
    StandaloneSearchBox,
    DirectionsService,
    DirectionsRenderer,
    DistanceMatrixService,
    MaxZoomService, Circle, Polygon, Polyline
} from "@react-google-maps/api";


import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import BuildIcon from '@mui/icons-material/Build';
import Switch from '@mui/material/Switch';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/Power';



import api from '../../../../../services/api';


import NavegadorGestao from "../../../../../components/NavBarGestao";
import "./MapPage.css";

import switch_off from '../../../../../assets/imgs/switch-off.png';
import switch_on from '../../../../../assets/imgs/switch-on.png';


import mini_icone_carro from '../../../../../assets/imgs/mini_icone_carro.png';

import mini_icone_moto from '../../../../../assets/imgs/mini_icone_moto.png';

import icone_origem from '../../../../../assets/imgs/icone_origem.png';
import icone_destino from '../../../../../assets/imgs/icone_destino.png';
import icone_parada from '../../../../../assets/imgs/icone_parada.png';


import icone_pgm from '../../../../../assets/imgs/icone_pgm.png';

import icone_medidor from '../../../../../assets/imgs/icone_medidor.png';

import icone_subestacao from '../../../../../assets/imgs/icone_subestacao.png';
import icone_casa from '../../../../../assets/imgs/icone_casa.png';
import icone_loja from '../../../../../assets/imgs/icone_loja.png';


import alarmeAudio from '../../../../../assets/mp3/alarme.mp3';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '1000px',

        backgroundColor: 'white',
    },
    toolbar: {

    },
    toolbarIcon: {

    },
    appBar: {

    },
    appBarShift: {

    },
    menuButton: {

    },
    menuButtonHidden: {

    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {

    },
    drawerPaperClose: {

    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        display: 'flex',


    },
    container: {
        display: 'flex',
        height: '1000px',

        backgroundColor: 'green',


    },
    paper: {

    },
    fixedHeight: {

    },
}));


const libraries = ["places"];


// Gerenciador de Áudio (Singleton)
const audioManager = {
    audio: new Audio(alarmeAudio),
    isPlaying: false,
    play() {
        if (!this.isPlaying) {
            this.audio.loop = true;
            this.audio.volume = 1.0;
            this.audio
                .play()
                .then(() => {
                    this.isPlaying = true;
                })
                .catch(error => console.error("Erro ao reproduzir o áudio:", error));
        }
    },
    pause() {
        if (this.isPlaying) {
            this.audio.pause();
            this.audio.currentTime = 0;
            this.isPlaying = false;
        }
    }
};


export default function MapaDoLocal(props) {


    const [instalacao, setInstalacao] = useState(props.instalacao);
    const instalacaoRef = useRef(instalacao); // Inicialize a ref com o valor inicial do estado
    const [mapPosition, setMapPosition] = useState(null);
    const mapPositionRef = useRef(null);

    // Atualizar o estado local quando as props mudarem
    useEffect(() => {
        setInstalacao(props.instalacao);
    }, [props.instalacao]);

    useEffect(() => {
        instalacaoRef.current = instalacao;
        if (instalacao) {
            const newMapPosition = {
                lat: parseFloat(instalacao.latitude),
                lng: parseFloat(instalacao.longitude),
            };

            // Verifica se o valor atual é diferente do armazenado na ref
            if (
                !mapPositionRef.current ||
                mapPositionRef.current.lat !== newMapPosition.lat ||
                mapPositionRef.current.lng !== newMapPosition.lng
            ) {
                setMapPosition(newMapPosition);
                mapPositionRef.current = newMapPosition; // Atualiza a ref com a nova posição
            }
        }
    }, [instalacao]);

    const audioRef = useRef(new Audio(alarmeAudio)); // Inicializar o ref com o áudio importado

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const classes = useStyles();
    const history = useHistory();


    const [value, setValue] = React.useState(0);



    const [showTable, setShowTable] = useState(true);

    const [idLocalDefinindoPerimetro, setIdLocalDefinindoPerimetro] = useState(0); // Indica se está definindo perímetros
    const [definingPerimeter, setDefiningPerimeter] = useState(false); // Indica se está definindo perímetros
    const [perimeterPoints, setPerimeterPoints] = useState([]); // Armazena os pontos do perímetro
    const [currentPerimeter, setCurrentPerimeter] = useState(1); // Controla qual perímetro está sendo definido (P1 a P4)



    const handleMapClick = (event) => {
        if (definingPerimeter && currentPerimeter <= 8) {
            const newPoint = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
                label: `Perímetro ${currentPerimeter}`
            };
            setPerimeterPoints([...perimeterPoints, newPoint]); // Adiciona o novo ponto ao estado
            setCurrentPerimeter(currentPerimeter + 1); // Avança para o próximo perímetro


        } else {
            //  event.preventDefault(); // Impede o menu de contexto padrão do navegador
            // event.stopPropagation(); // Impede a propagação do evento para o mapa
        }
    };



    const handleMenuClick = (index) => {
        // Aqui você pode adicionar lógica para lidar com cliques em outros itens
        // Além de exibir a tabela ao clicar no ícone "Instalações"
        if (index === 0) {
            setShowTable(!showTable); // Alterna a visibilidade da tabela
        }
    };



    const [map, setMap] = useState();
    const mapRef = useRef(null);



    const [lat, setLat] = useState();
    const [lng, setLng] = useState();

    const [zoom, setZoom] = useState(18);

    const handleCenterChanged = () => {
        if (map) {
            const center = map.getCenter();
            const newPosition = { lat: center.lat(), lng: center.lng() };
            // Somente atualiza se houver uma mudança real na posição
            if (newPosition.lat !== mapPosition.lat || newPosition.lng !== mapPosition.lng) {
                setMapPosition(newPosition);
            }
        }
    };

    const handleZoomChanged = () => {
        if (map) {
            const newZoom = map.getZoom();
            // Somente atualiza se houver uma mudança real no nível de zoom
            if (newZoom !== zoom) {
                setZoom(newZoom);
            }
        }
    };


    const onMapLoad = (map) => {
        setMap(map);
        mapRef.current = map; // Armazena a instância do mapa
        setGoogleLoaded(true);
    };




    const handleMarkerClick = (lat, lng) => {

        console.log("cliqou: " + lat + "," + lng)

        setZoom(18);
        setMapPosition({ lat, lng });

    };

    const options = {
        mapTypeId: 'satellite',
        streetViewControl: false, // Desativa o Street View
        fullscreenControl: false, // Desativa o controle de tela cheia
        mapTypeControl: false, // Desativa a opção de alterar o tipo de mapa
        zoomControl: true, // Ativa somente o controle de zoom
        maxZoom: 21,
        styles: [
            {
                featureType: "poi", // Oculta pontos de interesse
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "road", // Simplicidade nas rodovias
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "transit", // Remove transporte público
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "administrative.land_parcel", // Remove labels de terrenos
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "administrative.neighborhood", // Remove labels de bairros
                stylers: [{ visibility: "off" }],
            },
        ],
    };

    const [endereco, setEndereco] = useState();





    const containerStyle = {
        width: '100%',
        height: '100%'
    };



    const [configuracoes, setConfiguracoes] = useState();
    const [loading, setLoading] = useState();
    const [loadingInstalacoes, setLoadingInstalacoes] = useState();



    const [googleLoaded, setGoogleLoaded] = useState(false);

    const handleLoadGoogle = () => {
        setGoogleLoaded(true);
    };




    function checkDimenssoes() {


        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        setWidth(largura)

    }

    const [width, setWidth] = useState(0);



    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });





    function processarIcone(tipo) {
        if (tipo === 0) {
            return icone_pgm;
        } else if (tipo === 10) {
            return icone_medidor;
        }
    }



    function processarIconeInstalacao(tipo) {
        if (tipo === 0) {
            return icone_casa;
        } else if (tipo === 1) {
            return icone_loja;
        } else if (tipo == 2) {
            return icone_subestacao;
        }
    }

    const LocalComponent = ({ id_instalacao, lat, lng, onClick, tipo_icone, nome_mapa, perimetro, status, instalacao }) => {
        const [showMenu, setShowMenu] = useState(false);

        const handleContextMenu = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setShowMenu(!showMenu);
        };

        const handleClickGerenciar = () => {
            const url = `/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/${id_instalacao}/0`;
            window.open(url, '_blank');
            setShowMenu(!showMenu);
        };

        const handleClickDefinirPerimetro = (event, id_instalacao) => {
            event.stopPropagation();
            setDefiningPerimeter(true);
            setIdLocalDefinindoPerimetro(id_instalacao);
            setCurrentPerimeter(1);
            setPerimeterPoints([]); // Reseta o perímetro para iniciar um novo
            setShowMenu(false);
        };

        const handleClickSalvarPerimetro = (event, id_instalacao) => {
            event.stopPropagation();
            salvarPerimetro(id_instalacao);
            setDefiningPerimeter(false);
            setIdLocalDefinindoPerimetro(0);
            setPerimeterPoints([]);
            setShowMenu(false);
        };

        async function salvarPerimetro() {
            try {
                console.log("cadastrar chamado");
                const cadastro = {};
                perimeterPoints.slice(0, 8).forEach((point, index) => {
                    if (point) {
                        cadastro[`latitude${index + 1}`] = point.lat.toString();
                        cadastro[`longitude${index + 1}`] = point.lng.toString();
                    }
                });

                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/clientes/instalacoes/atualizarperimetro/${id_instalacao}`;
                const response = await api.put(url, cadastro, { headers });

                if (response.data) {
                    setSnackbarMessage('Perímetro salvo com sucesso!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                }
            } catch (error) {
                setSnackbarMessage('Erro ao salvar perímetro.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error("Erro ao salvar perímetro:", error);
            }
        }

        const localPerimeterPoints = useMemo(() => {
            return perimetro
                ? Array.from({ length: 8 }, (_, i) => {
                    const lat = parseFloat(perimetro[`latitude${i + 1}`]);
                    const lng = parseFloat(perimetro[`longitude${i + 1}`]);
                    return !isNaN(lat) && !isNaN(lng) ? { lat, lng } : null;
                }).filter(point => point !== null)
                : [];
        }, [perimetro]);

        const statusColor = "black";

        return (
            <OverlayView
                position={{ lat, lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -(height / 2),
                })}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        transform: "translate(-50%, -50%)",
                        height: "50px",
                        width: "50px",
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        onClick(lat, lng);
                    }}

                >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 0 }}>
                        <img
                            alt={"icone"}
                            style={{
                                textAlign: 'center',
                                width: '40px',
                                height: '40px'
                            }}
                            src={processarIconeInstalacao(tipo_icone)}
                            onContextMenu={handleContextMenu}
                        />
                        <span style={{
                            color: 'white',
                            fontSize: '10px',
                            textShadow: '1px 1px 0px black, -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black',
                            fontWeight: 'bold'
                        }}>
                            {nome_mapa}
                        </span>
                    </div>
                    {showMenu && (
                        <div style={{
                            position: 'absolute',
                            backgroundColor: 'white',
                            border: '1px solid black',
                            borderRadius: '4px',
                            padding: '10px',
                            top: '50px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 1000,
                            boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                        }}>
                            <IconButton color="error" onClick={handleClickGerenciar}>
                                <SettingsIcon />
                                <span style={{ marginLeft: '8px' }}>Gerenciar</span>
                            </IconButton>

                            {!definingPerimeter && (
                                <IconButton color="error" onClick={(event) => handleClickDefinirPerimetro(event, id_instalacao)}>
                                    <SettingsIcon />
                                    <span style={{ marginLeft: '8px' }}>Definir Perímetro</span>
                                </IconButton>
                            )}

                            {definingPerimeter && (
                                <IconButton color="error" onClick={(event) => handleClickSalvarPerimetro(event, id_instalacao)}>
                                    <SettingsIcon />
                                    <span style={{ marginLeft: '8px' }}>Salvar Perímetro</span>
                                </IconButton>
                            )}
                        </div>
                    )}

                    {zoom > 5 && (
                        localPerimeterPoints.length >= 2 && idLocalDefinindoPerimetro !== id_instalacao) && (
                            localPerimeterPoints.length === 2 ? (
                                <Polyline
                                    path={localPerimeterPoints}
                                    options={{ strokeColor: 'black', strokeOpacity: 1.0, strokeWeight: 2 }}
                                />
                            ) : (
                                <Polygon
                                    paths={localPerimeterPoints}
                                    options={{
                                        fillColor: statusColor,
                                        fillOpacity: 0.35,
                                        strokeColor: statusColor,
                                        strokeOpacity: 0.8,
                                        strokeWeight: 2
                                    }}
                                />
                            )
                        )}



                </div>
            </OverlayView>
        );
    };



    const ModuloPGMComponent = ({ lat, lng, tipo, onClick, status, equipamento, perimetro, id_instalacao }) => {

        const [isHovered, setIsHovered] = React.useState(false);

        const [showMenu, setShowMenu] = useState(false);

        const [anchorEl, setAnchorEl] = useState(null);
        const [anchorElReles, setAnchorElReles] = useState(null);
        const [anchorElAudios, setAnchorElAudios] = useState(null);

        const [subMenuOpen, setSubMenuOpen] = useState(false);
        const [subMenuOpenReles, setSubMenuOpenReles] = useState(false);
        const [subMenuOpenAudios, setSubMenuOpenAudios] = useState(false);




        const handleMenuOpen = useCallback((event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
            setSubMenuOpen(true);
        }, []);

        const handleMenuOpenReles = (event) => {
            event.preventDefault(event);
            event.stopPropagation();
            setAnchorElReles(event.currentTarget);
            setSubMenuOpenReles(true);
        };

        const handleMenuOpenAudios = (event) => {
            event.preventDefault(event);
            event.stopPropagation();
            setAnchorElAudios(event.currentTarget);
            setSubMenuOpenAudios(true);
        };



        const handleMenuClose = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorEl(null);
            setSubMenuOpen(false);
        };

        const handleMenuCloseReles = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorElReles(null);
            setSubMenuOpenReles(false);
        };

        const handleMenuCloseAudios = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorElAudios(null);
            setSubMenuOpenAudios(false);
        };



        const handleContextMenu = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setShowMenu(!showMenu);
        };

        const localPerimeterPoints = useMemo(() => {
            return perimetro
                ? Array.from({ length: 8 }, (_, i) => {
                    const lat = parseFloat(perimetro[`latitude${i + 1}`]);
                    const lng = parseFloat(perimetro[`longitude${i + 1}`]);
                    return !isNaN(lat) && !isNaN(lng) ? { lat, lng } : null;
                }).filter(point => point !== null)
                : [];
        }, [perimetro]);

        // Calcular a diferença de tempo
        const now = new Date();
        const lastResponse = new Date(equipamento.modulo_pgm.data_hora_ultima_resposta);
        const differenceInMinutes = (now - lastResponse) / 1000 / 60; // Convertendo de milissegundos para minutos

        // Verificar se o alarme deve ser ativado
        const alarme = equipamento.alarme;



        // Definir o estado do equipamento
        let statusText;
        let statusColor;
        let isBlinking = false;




        const armada = equipamento.modulo_pgm.armada;

        if (alarme) {
            statusText = "ALARME";
            statusColor = "red";
            isBlinking = true; // Habilitar o piscar se estiver em alarme
        } else if (differenceInMinutes < 2) {
            if (armada) {
                statusText = "ONLINE - ARMADA";
                statusColor = "green";
            }
            else {
                statusText = "ONLINE - DESARMADA";
                statusColor = "yellow";
            }
        } else {
            statusText = "OFFLINE";
            statusColor = "red";
            isBlinking = true; // Habilitar o piscar se estiver offline
        }

        // Controlar reprodução de áudio
        useEffect(() => {
            if (alarme) {
                audioManager.play();
            } else {
                audioManager.pause();
            }
        }, [alarme]);

        const [mostrarRele, setMostrarRele] = useState(false);

        useEffect(() => {
            let timeoutId;
            if (!isHovered) {
                // Define um temporizador de 5 segundos para ocultar os relés
                timeoutId = setTimeout(() => {
                    setMostrarRele(false);
                }, 5000);
            } else {
                // Cancela o temporizador caso esteja em estado "hovered"
                setMostrarRele(true);
                clearTimeout(timeoutId);
            }

            return () => clearTimeout(timeoutId);
        }, [isHovered]);


        async function handleClickPgm(comando) {

            console.log("handleClickPgm")
            try {
                const cadastro = {};
                const headers = { 'Authorization': 'Bearer ' + Cookies.get("token") };
                const url = `/v1/protected/modulopgm/tarefas/criarporareatecnica/` + Cookies.get("id_usuario") + "/" + equipamento.modulo_pgm.id_modulo_pgm + "/" + comando;
                const response = await api.get(url, { headers });

                if (response.data) {
                    setSnackbarMessage('Comando Enviado!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                }

                setAnchorElAudios(null);
                setSubMenuOpenAudios(false);
                setAnchorElReles(null);
                setSubMenuOpenReles(false);
                setAnchorEl(null);
                setSubMenuOpen(false);

            } catch (error) {
                setSnackbarMessage('Erro ao enviar comando');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error("Erro ao enviar o comando:", error);
            }
        }

        const processarIndexAtivarRele = (index) => {
            if (index === 1) {
                //rele 1
                return 1;
            } else if (index === 2) {
                //rele 2
                return 2;
            } else if (index === 3) {
                //rele 3
                return -1;
            } else if (index === 4) {
                //rele 4
                return -1;
            }
        }

        const processarIndexDesativarRele = (index) => {
            if (index === 1) {
                //rele 1
                return 4;
            } else if (index === 2) {
                //rele 2
                return 5;
            } else if (index === 3) {
                //rele 3
                return -1;
            } else if (index === 4) {
                //rele 4
                return -2;
            }
        }


        return (
            <OverlayView
                position={{ lat, lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -(height / 2),
                })}
            >
                <div>
                    {(alarme || differenceInMinutes > 2 || zoom > 15) && (
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                transform: "translate(-50%, -50%)",
                                height: "100px",
                                width: "100px",
                                // border: `2px solid ${alarme ? statusColor : 'transparent'}`, // Borda de 2px, incolor se sem alarme
                                borderRadius: "50%", // Fazer o perímetro circular
                                boxShadow: alarme ? `0 0 30px 150px rgba(255, 0, 0, 0.5)` : "none", // Sombra afastada e translúcida
                            }}
                            onClick={(event) => {
                                event.stopPropagation(); // Impede a propagação do evento para o mapa
                                onClick(lat, lng);
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                                <img
                                    alt={"icone"}
                                    style={{
                                        textAlign: 'center',
                                        width: '40px',  // Define a largura da imagem
                                        height: '40px'  // Define a altura da imagem
                                    }}
                                    src={processarIcone(tipo)}
                                    onContextMenu={handleContextMenu}

                                />
                            </div>






                        </div>
                    )}
                </div>
            </OverlayView>
        );
    };




    const polygonOptions = {
        strokeColor: 'blue',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'blue',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
    };




    const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());


    const [isConnected, setIsConnected] = useState(false);





    return (

        <LoadScript
            googleMapsApiKey={globalConfig.mapKey}
            libraries={libraries}
            loadingElement={<div>Loading...</div>}
        >
            <GoogleMap
                //onLoad={onMapLoad}

                onClick={handleMapClick}
                center={mapPosition} // Posição mantida pelo estado
                zoom={zoom}          // Zoom mantido pelo estado
                onLoad={(mapInstance) => setMap(mapInstance)}
                onCenterChanged={handleCenterChanged}
                onZoomChanged={handleZoomChanged}
                mapContainerStyle={containerStyle}
                options={options}
            >

                {
                    instalacao && (
                        <React.Fragment key={instalacao.id_instalacao}>
                            <LocalComponent
                                id_instalacao={instalacao.id_instalacao}
                                lat={parseFloat(instalacao.latitude)}
                                lng={parseFloat(instalacao.longitude)}
                                onClick={handleMarkerClick}
                                tipo_icone={instalacao.icone_mapa}
                                nome_mapa={instalacao.nome_mapa}
                                instalacao={instalacao}
                                perimetro={instalacao.perimetro}
                            />
                            {instalacao.equipamentos.map((equipamento) => {
                                const latEquipamento = parseFloat(equipamento.latitude);
                                const lngEquipamento = parseFloat(equipamento.longitude);
                                const equipamentoValido = !isNaN(latEquipamento) && !isNaN(lngEquipamento);

                                return (
                                    equipamentoValido && equipamento.tipo_equipamento_area_tecnica === 0 && (
                                        <ModuloPGMComponent
                                            key={equipamento.id_equipamento}
                                            lat={latEquipamento}
                                            lng={lngEquipamento}
                                            tipo={equipamento.tipo_equipamento_area_tecnica}
                                            onClick={handleMarkerClick}
                                            equipamento={equipamento}
                                            perimetro={instalacao.perimetro}
                                            id_instalacao={instalacao.id_instalacao}
                                        />
                                    )
                                );
                            })}
                        </React.Fragment>
                    )

                }

                {/* Exibe os marcadores dos perímetros definidos */}
                {perimeterPoints.map((point, index) => (
                    <Marker
                        key={index}
                        position={{ lat: point.lat, lng: point.lng }}
                        label={point.label}
                    />
                ))}


                {perimeterPoints.length === 2 && (
                    <Polyline
                        path={perimeterPoints.map(point => ({ lat: point.lat, lng: point.lng }))}
                        options={{ strokeColor: '#FF0000', strokeOpacity: 1.0, strokeWeight: 2 }}
                    />
                )}

                {perimeterPoints.length >= 3 && perimeterPoints.length <= 8 && (
                    <Polygon
                        paths={perimeterPoints.map(point => ({ lat: point.lat, lng: point.lng }))}
                        options={{
                            fillColor: '#FF0000',
                            fillOpacity: 0.35,
                            strokeColor: '#FF0000',
                            strokeOpacity: 0.8,
                            strokeWeight: 2
                        }}
                    />
                )}


            </GoogleMap>
        </LoadScript>

    );
}





