import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';


import { format, addDays, isValid, parse } from 'date-fns';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import { useParams } from "react-router-dom";


// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

export default function VizualizarProcedimentoExecucaoTratamentoEmCampo() {


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    const [botaoClicado, setBotaoClicado] = useState(false);





    const { idProcedimentoExecucacao } = useParams();
    const { idOrdemServico } = useParams();


    const [itemOrdemCompra, setItemOrdemCompra] = useState(null);

    const [unidadeBase, setUnidadebase] = useState(null);
    const [ordemServico, setOrdemServico] = useState(null);
    const [instalacaoSelecionada, setInstalacaoSelecionada] = useState(null);

    const classes = useStyles();
    const history = useHistory();

    const [observacoes, setObservacoes] = useState('');
    const [resultado, setResultado] = useState("");
    const [quantidadesAceitos, setQuantidadesAceitos] = useState("");
    const [quantidadesAveriguados, setQuantidadesAveriguados] = useState("");
    const [quantidadesRecebidos, setQuantidadesRecebidos] = useState("");



    const [descricaoImagem1, setDescricaoImagem1] = useState('');
    const [descricaoImagem2, setDescricaoImagem2] = useState('');
    const [descricaoImagem3, setDescricaoImagem3] = useState('');
    const [descricaoImagem4, setDescricaoImagem4] = useState('');


    const [descricaoVideo1, setDescricaoVideo1] = useState('');
    const [descricaoVideo2, setDescricaoVideo2] = useState('');
    const [descricaoVideo3, setDescricaoVideo3] = useState('');


    const [descricaoArquivo1, setDescricaoArquivo1] = useState('');
    const [descricaoArquivo2, setDescricaoArquivo2] = useState('');
    const [descricaoArquivo3, setDescricaoArquivo3] = useState('');



    const [obrigatorio, setObrigatorio] = useState(0);


    const [imagemSelecionada1, setImagemSelecionada1] = useState(null);
    const [imagemSelecionada2, setImagemSelecionada2] = useState(null);
    const [imagemSelecionada3, setImagemSelecionada3] = useState(null);
    const [imagemSelecionada4, setImagemSelecionada4] = useState(null);


    const onDrop1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada1(acceptedFiles[0]);
    }, []);

    const onDrop2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada2(acceptedFiles[0]);
    }, []);

    const onDrop3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada3(acceptedFiles[0]);
    }, []);

    const onDrop4 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('image/png')) {
            alert('Apenas arquivos PNG são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setImagemSelecionada4(acceptedFiles[0]);
    }, []);


    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: onDrop1,

        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: onDrop2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({
        onDrop: onDrop3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });
    const { getRootProps: getRootProps4, getInputProps: getInputProps4 } = useDropzone({
        onDrop: onDrop4,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.png', // Restringe a aceitação apenas para arquivos .png
    });



    const [videoSelecionado1, setVideoSelecionado1] = useState(null);
    const [videoSelecionado2, setVideoSelecionado2] = useState(null);
    const [videoSelecionado3, setVideoSelecionado3] = useState(null);


    const onDropVideo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropVideo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropVideo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('video/mp4')) {
            alert('Apenas arquivos MP4 são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setVideoSelecionado3(acceptedFiles[0]);
    }, []);


    const { getRootProps: getRootVideoProps1, getInputProps: getInputVideoProps1 } = useDropzone({
        onDrop: onDropVideo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps2, getInputProps: getInputVideoProps2 } = useDropzone({
        onDrop: onDropVideo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });
    const { getRootProps: getRootVideoProps3, getInputProps: getInputVideoProps3 } = useDropzone({
        onDrop: onDropVideo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.mp4', // Restringe a aceitação apenas para arquivos .png

    });



    const [arquivoSelecionado1, setArquivoSelecionado1] = useState(null);
    const [arquivoSelecionado2, setArquivoSelecionado2] = useState(null);
    const [arquivoSelecionado3, setArquivoSelecionado3] = useState(null);

    const [arquivos, setArquivos] = useState(null);



    const onDropArquivo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado1(acceptedFiles[0]);

    }, []);

    const onDropArquivo2 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado2(acceptedFiles[0]);
    }, []);

    const onDropArquivo3 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado3(acceptedFiles[0]);
    }, []);



    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps2, getInputProps: getInputArquivoProps2 } = useDropzone({
        onDrop: onDropArquivo2,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });
    const { getRootProps: getRootArquivoProps3, getInputProps: getInputArquivoProps3 } = useDropzone({
        onDrop: onDropArquivo3,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });



    const [loading, setLoading] = useState(true);

    const [procedimentoExecucao, setProcedimentoExecucao] = useState();


    async function listarInformacoes() {
        setLoading(true);

        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/ordemservico/listarprocedimentoexecucaoporid/" + idProcedimentoExecucacao, {
                headers: headers
            }).then(function (response) {

                setProcedimentoExecucao(response.data.procedimento);
                console.log("obs: " + response.data.observacoes);
                setObservacoes(response.data.procedimento.observacoes);
                setResultado(response.data.procedimento.resultado);
                setArquivos(response.data);

                console.log("id do procedimento: " + response.data.procedimento.procedimento.id_procedimento);

                api.get("/v1/protected/ordemservico/listarporid/" + idOrdemServico, {
                    headers: headers
                }).then(function (response_os) {

                    console.log("ordem de servico encontrada " + response_os.data.id_ordem_servico)
                    setOrdemServico(response_os.data);
                    setInstalacaoSelecionada(response_os.data.instalacao);
                    setRotaSelecionada(response_os.data.rota);

                    // if(response_os.data.agendamento_equipe.equipe)
                    // setEquipeSelecionada(response_os.data.agendamento_equipe.equipe);

                    // if (response_os.data.agendamento_veiculo)
                    //     setVeiculoSelecionado(response_os.data.agendamento_veiculo.veiculo);





                    api.get("v1/protected/configuracoes", {
                        headers: headers
                    }).then(function (response) {
                        setUnidadebase(response.data.unidade_base);

                        console.log("antes de true");
                        setLoading(false);
                    });


                });

            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();



    }, []);





    const [equipes, setEquipes] = useState([]);
    const [equipeSelecionada, setEquipeSelecionada] = useState();





    function returnDescricaoStatusEquipe(status) {
        switch (status) {
            case -1:
                return "Desativada";
            case 0:
                return "Agendado - Não Iniciado";
            case 1:
                return "Livre";
            case 2:
                return "Designada - Em Deslocamento para Tratamento de O.S em campo";
            case 3:
                return "Designada - Tratando Equipe em Campo";
            case 4:
                return "Designada - Retornando de Tratamento de O.S em campo";
            case 5:
                // Adicione mais casos conforme necessário
                return "Outro status";
            default:
                return "Status Desconhecido";
        }
    }


    function returnDescricaoAgendamento(agendamento) {
        var texto = "ID:  " + agendamento.id_agendamento +
            "\nDe " + format(new Date(agendamento.data_hora_inicial), 'HH:mm dd/MM/yyyy') + " até " + format(new Date(agendamento.data_hora_final), 'HH:mm dd/MM/yyyy') +
            "\nMotivo: " + agendamento.motivo +
            "\nDescrição: " + agendamento.descricao;



        return texto;

    }

    function returnDescricaoEquipe(equipe) {
        var texto = "ID Equipe: " + equipe.id_equipe +
            "\n Status: " + (returnDescricaoStatusEquipe(equipe.status_equipe)) +
            "\n Nome: " + equipe.nome +
            "\n Descrição: " + equipe.descricao +
            "\n Região de Atuação: " + equipe.regiao_atuacao;


        texto += ("\n\nAgendamento:\n" + returnDescricaoAgendamento(ordemServico.agendamento_equipe));

        return texto;
    }


    const procedimentoDefinirEquipe = () => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={12}
                style={{ marginTop: 20 }}
            >



                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                    item xs={5}
                >

                    <Autocomplete
                        id="select-instalacao"
                        options={[]}
                        disableCloseOnSelect
                        getOptionLabel={(equipe) => returnDescricaoEquipe(equipe).replace(/<br \/>/g, '\n')}
                        value={equipeSelecionada}
                        filterOptions={(options, { inputValue }) => {
                            const inputKeywords = inputValue.toLowerCase().split(" ");
                            return options.filter((cotacao) => {
                                const cotacaoText = returnDescricaoEquipe(cotacao).toLowerCase();
                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                            });
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label=" Equipe " multiline minRows={5} />
                        )}
                        renderOption={(option) => (
                            <div>
                                {returnDescricaoEquipe(option).split('\n').map((line, index) => (
                                    <Typography key={index}>{line}</Typography>
                                ))}
                            </div>
                        )}
                    />
                </Grid>



            </Grid>
        );
    }




    const [veiculos, setVeiculos] = useState([]);
    const [veiculoSelecionado, setVeiculoSelecionado] = useState(null);


    function returnDescricaoStatusServicoVeiculo(status) {
        switch (status) {
            case -1:
                return "Desativada";
            case 0:
                return "Livre";
            case 1:
                return "Designada - Em Deslocamento para Tratamento de O.S em campo";
            case 2:
                return "Designada - Tratando Equipe em Campo";
            case 3:
                return "Designada - Retornando de Tratamento de O.S em campo";
            case 4:
                // Adicione mais casos conforme necessário
                return "Outro status";
            default:
                return "Status Desconhecido";
        }
    }

    function returnDescricaoVeiculo(veiculo) {
        var texto = "ID Veículo: " + veiculo.id_veiculo +
            "\n Status: " + (returnDescricaoStatusEquipe(veiculo.status_servico_veiculo)) +
            "\n Placa: " + veiculo.placa +
            "\n Marca|Modelo Ano/Ano: " + veiculo.marca + "|" + veiculo.modelo + " " + veiculo.ano_modelo + "/" + veiculo.ano_veiculo +
            "\n Região de Atuação: " + veiculo.regiao_atuacao;


        texto += ("\n\nAgendamento:\n" + returnDescricaoAgendamento(ordemServico.agendamento_veiculo));


        return texto;
    }



    const procedimentoDefinirVeiculo = () => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={12}
                style={{ marginTop: 20 }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                    item xs={5}
                >

                    <Autocomplete
                        id="select-instalacao"
                        options={veiculos}
                        disableCloseOnSelect
                        getOptionLabel={(veiculo) => returnDescricaoVeiculo(veiculo).replace(/<br \/>/g, '\n')}
                        value={veiculoSelecionado}
                        filterOptions={(options, { inputValue }) => {
                            const inputKeywords = inputValue.toLowerCase().split(" ");
                            return options.filter((cotacao) => {
                                const cotacaoText = returnDescricaoVeiculo(cotacao).toLowerCase();
                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                            });
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label=" Veículo " multiline minRows={5} />
                        )}
                        renderOption={(option) => (
                            <div>
                                {returnDescricaoVeiculo(option).split('\n').map((line, index) => (
                                    <Typography key={index}>{line}</Typography>
                                ))}
                            </div>
                        )}
                    />

                </Grid>

            </Grid>
        );
    }


    const [rotas, setRotas] = useState([]);
    const [rotaSelecionada, setRotaSelecionada] = useState();





    function returnDescricaoRota(rota) {

        var componentes = rota.componentes_rota;

        // Encontre o componente com tipo igual a 0
        const componenteTipo0 = Array.from(componentes).find(componente => componente.tipo === 0);
        const componenteTipo1 = Array.from(componentes).find(componente => componente.tipo === 1);

        // Se o componente com tipo 0 for encontrado, atribua o valor do atributo endereco à variável texto
        const origem = componenteTipo0.endereco;
        const destino = componenteTipo1.endereco;



        var texto = "ID Rota: " + rota.id_rota +
            "\nDescrição: " + rota.descricao +
            "\nOrigem: " + origem +
            "\nDestino: " + destino;

        return texto;
    }

    function returnDescricaoUnidade(unidade) {



        var nome = unidade.nome_fantasia;

        var endereco = unidade.logradouro + ", nº " + unidade.numero + ", " + unidade.bairro + ", " + unidade.cidade + " " + unidade.estado + " CEP: " + unidade.cep;


        var texto = "ID: " + unidade.id_unidade + "\nCNPJ: " + unidade.cnpj +
            "\n " + nome + "\n Endereço: " + endereco;
        return texto;
    }


    function returnDescricaoInstalacao(instalacao) {

        var endereco = instalacao.logradouro + ", nº " + instalacao.numero + ", " + instalacao.bairro + ", " + instalacao.cidade + " " + instalacao.estado + " CEP: " + instalacao.cep;


        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia)
            + "\n Endereço: " + endereco;

        return texto;
    }


    const procedimentoDefinirRota = () => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={12}
                style={{ marginTop: 20 }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    item xs={12}
                    style={{ marginTop: 5 }}
                >
                    <Typography align="center" variant="h7" > Definição de Rota</Typography>

                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    item xs={12}
                    style={{ marginTop: 5 }}
                >
                    <Typography align="center" style={{ fontSize: 12 }} >{"Origem: " + returnDescricaoUnidade(unidadeBase)} </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    item xs={12}
                    style={{ marginTop: 5 }}
                >
                    <Typography align="center" style={{ fontSize: 12 }} >{"Destino: " + returnDescricaoInstalacao(instalacaoSelecionada)} </Typography>
                </Grid>

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    align="center"
                    item xs={5}
                >

                    <Autocomplete
                        id="select-instalacao"
                        options={rotas}
                        disableCloseOnSelect
                        getOptionLabel={(veiculo) => returnDescricaoRota(veiculo).replace(/<br \/>/g, '\n')}
                        value={rotaSelecionada}
                        filterOptions={(options, { inputValue }) => {
                            const inputKeywords = inputValue.toLowerCase().split(" ");
                            return options.filter((cotacao) => {
                                const cotacaoText = returnDescricaoRota(cotacao).toLowerCase();
                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                            });
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label=" Rota " multiline rows={5} />
                        )}
                        renderOption={(option) => (
                            <div>
                                {returnDescricaoRota(option).split('\n').map((line, index) => (
                                    <Typography key={index}>{line}</Typography>
                                ))}
                            </div>
                        )}
                    />
                </Grid>

            </Grid>
        );
    }


    const procedimentoDefinirQuantidades = () => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                align="center"
                item xs={7}
                style={{ marginTop: 5 }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    align="center"
                    item xs={12}
                >

                    <Grid item xs={12} >

                        <Typography align="center" variant="h5">
                            {procedimentoExecucao.quantidades_averiguar + " " + itemOrdemCompra.cotacao.insumo.marca + "|" + itemOrdemCompra.cotacao.insumo.modelo + " para averiguação"}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            variant="standard"
                            name="quantidadesRecebidos"
                            label="Quantidade de Itens Recebidos"
                            required
                            id="quantidadesRecebidos"
                            value={quantidadesRecebidos}
                            onChange={(e) => setQuantidadesRecebidos(e.target.value)}
                            fullWidth

                        />
                    </Grid>


                    <Grid item xs={12} >
                        <TextField
                            variant="standard"
                            name="quantidadesAveriguados"
                            label="Quantidade de Itens Averiguados"
                            required
                            id="quantidadesAveriguados"
                            value={quantidadesAveriguados}
                            onChange={(e) => setQuantidadesAveriguados(e.target.value)}
                            fullWidth

                        />
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            variant="standard"
                            name="quantidadesAceitos"
                            label="Quantidade de Itens Aceitos"
                            required
                            id="quantidadesAceitos"
                            value={quantidadesAceitos}
                            onChange={(e) => setQuantidadesAceitos(e.target.value)}
                            fullWidth

                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }


    return (
        <div>
            <NavegadorGestao
                ListarOrdemSevicos={'underline'}
                corGestaoServicosMarcado={'orange'}
                Gestao={'underline'}
                corGestaoMarcado={'orange'}
                corFundo={'white'} travado={true} corTexto={'black'}
            />


            <Container style={{ paddingTop: 5 }} >
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :
                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >

                                        <Tabs
                                            value={value}
                                            onChange={handleChangeTab}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"


                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Imagens" style={{ backgroundColor: 'green' }} />
                                            <Tab label="Vídeos" style={{ backgroundColor: 'green' }} />
                                            <Tab label="Documentos" style={{ backgroundColor: 'green' }} />

                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>




                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >


                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >

                                    <Grid
                                        value={value} index={0}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >


                                        {
                                            procedimentoExecucao.procedimento.id_procedimento === 1
                                                ? procedimentoDefinirEquipe()
                                                : <div></div>
                                        }

                                        {
                                            procedimentoExecucao.procedimento.id_procedimento === 2
                                                ? procedimentoDefinirVeiculo()
                                                : <div></div>
                                        }

                                        {
                                            procedimentoExecucao.procedimento.id_procedimento === 3
                                                ? procedimentoDefinirRota()
                                                : <div></div>
                                        }
                                        {

                                            procedimentoExecucao.procedimento_ordem_compra === 1
                                                ? procedimentoDefinirQuantidades()
                                                : <div></div>
                                        }



                                        <Grid item xs={12}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="resultado-selector-label">Resultado da Execução do Procedimento</InputLabel>
                                                    <Select
                                                        labelId="resultado-selector-label"
                                                        id="resultado-selector"
                                                        value={resultado}
                                                        label="Resultado"
                                                        disabled

                                                    >
                                                        <MenuItem value={0}>Falha</MenuItem>
                                                        <MenuItem value={1}>Sucesso</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>



                                        <Grid item xs={12} style={{ padding: 40 }}>
                                            <TextField
                                                variant="standard"
                                                name="observacoes"
                                                label="Observações"
                                                required
                                                id="observacoes"
                                                value={observacoes}
                                                fullWidth
                                                disabled
                                                multiline
                                                rows={10}
                                            />
                                        </Grid>







                                    </Grid>

                                    <Grid
                                        value={value} index={1}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                    >


                                        <Grid item xs={12}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >

                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                                    {
                                                        arquivos.imagem1 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_imagem1}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                    style={{ paddingBottom: 30 }}
                                                >
                                                    {arquivos.imagem1 &&
                                                        <img
                                                            src={`data:image/jpeg;base64,${arquivos.imagem1}`} alt="Imagem 1" />
                                                    }
                                                </Grid>

                                            </Grid>


                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12}
                                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                                >
                                                    {
                                                        arquivos.imagem2 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_imagem2}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                    style={{ paddingBottom: 30 }}
                                                >
                                                    {arquivos.imagem2 &&
                                                        <img
                                                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                            src={`data:image/jpeg;base64,${arquivos.imagem2}`} alt="Imagem 2" />
                                                    }
                                                </Grid>

                                            </Grid>


                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12}
                                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                                >
                                                    {
                                                        arquivos.imagem3 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_imagem3}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                    style={{ paddingBottom: 30 }}
                                                >
                                                    {arquivos.imagem3 &&
                                                        <img
                                                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                            src={`data:image/jpeg;base64,${arquivos.imagem3}`} alt="Imagem 3" />
                                                    }
                                                </Grid>

                                            </Grid>


                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12}
                                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                                >
                                                    {
                                                        arquivos.imagem4 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_imagem4}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                >
                                                    {arquivos.imagem4 &&
                                                        <img
                                                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                            src={`data:image/jpeg;base64,${arquivos.imagem4}`} alt="Imagem 4" />
                                                    }
                                                </Grid>

                                            </Grid>


                                        </Grid>

                                    </Grid>


                                    <Grid
                                        value={value} index={2}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                    >



                                        <Grid item xs={12}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >

                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                                    {
                                                        arquivos.video1 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_video1}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                    style={{ paddingBottom: 30 }}
                                                >
                                                    {arquivos.video1 &&
                                                        <video controls width="400" height="300">
                                                            <source
                                                                type="video/mp4"  // ajuste o tipo de mídia conforme necessário (por exemplo, video/mp4, video/webm, etc.)
                                                                src={`data:video/mp4;base64,${arquivos.video1}`}
                                                            />
                                                            Seu navegador não suporta o elemento de vídeo.
                                                        </video>

                                                    }
                                                </Grid>

                                            </Grid>


                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12}
                                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                                >
                                                    {
                                                        arquivos.video2 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_video2}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                    style={{ paddingBottom: 30 }}
                                                >
                                                    {arquivos.video2 &&
                                                        <video controls width="400" height="300">
                                                            <source
                                                                type="video/mp4"  // ajuste o tipo de mídia conforme necessário (por exemplo, video/mp4, video/webm, etc.)
                                                                src={`data:video/mp4;base64,${arquivos.video2}`}
                                                            />
                                                            Seu navegador não suporta o elemento de vídeo.
                                                        </video>
                                                    }
                                                </Grid>

                                            </Grid>


                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12}
                                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                                >
                                                    {
                                                        arquivos.video3 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_video3}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                    style={{ paddingBottom: 30 }}
                                                >
                                                    {arquivos.video3 &&
                                                        <video controls width="400" height="300">
                                                            <source
                                                                type="video/mp4"  // ajuste o tipo de mídia conforme necessário (por exemplo, video/mp4, video/webm, etc.)
                                                                src={`data:video/mp4;base64,${arquivos.video3}`}
                                                            />
                                                            Seu navegador não suporta o elemento de vídeo.
                                                        </video>
                                                    }
                                                </Grid>

                                            </Grid>




                                        </Grid>
                                    </Grid>
                                    <Grid
                                        value={value} index={3}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                    >



                                        <Grid item xs={12}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >

                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                                    {
                                                        arquivos.arquivo1 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_arquivo1}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                    style={{ paddingBottom: 30 }}
                                                >
                                                    {arquivos.arquivo1 &&
                                                        <div
                                                            onClick={() => {

                                                                baixarArquivo(arquivos.procedimento.caminho_arquivo1);


                                                            }}
                                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                            <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                        </div>




                                                    }
                                                </Grid>

                                            </Grid>


                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12}
                                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                                >
                                                    {
                                                        arquivos.arquivo2 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_arquivo2}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                    style={{ paddingBottom: 30 }}
                                                >
                                                    {arquivos.arquivo2 &&
                                                        <div
                                                            onClick={() => {

                                                                baixarArquivo(arquivos.procedimento.caminho_arquivo2);


                                                            }}
                                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                            <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                        </div>



                                                    }
                                                </Grid>

                                            </Grid>


                                            <Grid item xs={4}
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12}
                                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                                >
                                                    {
                                                        arquivos.arquivo3 &&
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {"Descrição: " + arquivos.procedimento.descricao_arquivo3}
                                                        </Typography>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}
                                                    container
                                                    style={{ paddingBottom: 30 }}
                                                >
                                                    {arquivos.arquivo3 &&
                                                        <div
                                                            onClick={() => {

                                                                baixarArquivo(arquivos.procedimento.caminho_arquivo2);


                                                            }}
                                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                                            <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                                                        </div>

                                                    }
                                                </Grid>

                                            </Grid>



                                        </Grid>


                                    </Grid>



                                </SwipeableViews>







                            </Grid>





                        </Grid>


                    </React.Fragment>

                }
            </Container>

        </div >
    );
}


