import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';


// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';


const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};


export default function CadastrarEquipamento() {


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const [insumos, setInsumos] = useState([]);
    const [insumoSelecionado, setInsumoSelecionado] = useState(null);


    const [ip, setIp] = useState(null);
    const [porta, setPorta] = useState(null);

    function returnDescricaoInsumo(insumo_) {

        let insumo = insumo_.insumo;
        let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao;

        if (insumo.variacoes && insumo.variacoes.length > 0) {
            textoComQuebraDeLinha += insumo.variacoes

                .map((variacao, index) => (
                    `<br />*Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                )).join('');
        }

        var texto =

            //"<br />Marca/Modelo: " + insumo.marca + "/" + insumo.modelo +
            // "<br />Código Fornecedor: " + insumo.codigo_fornecedor +
            //"<br />Tipo: " + insumo.tipo +
            //"<br />Categoria: " + insumo.categoria +
            textoComQuebraDeLinha + "<br />Estoque: " + (Array.isArray(insumo_.itens_estoque) ? insumo_.itens_estoque.length : 0) + " unidades";
        return texto;
    }




    function returnDescricaoStatus(status_item) {
        var texto = "";

        if (status_item === 0) {
            texto = "Disponível";
        }
        else if (status_item === 1) {
            texto = "Alugado";
        }
        else if (status_item === 2) {
            texto = "Vendido";
        }
        else if (status_item === 3) {
            texto = "Emprestado";
        }

        return texto
    }



    function returnDescricaoItemEstoque(item) {

        return "ID: " + item.id_item_estoque + "<br />" +
            "Status: " + returnDescricaoStatus(item.status_item) + "<br />" +
            "Fornecedor: " + (item.item.cotacao.fornecedor.tipo_fornecedor === 0 ? (item.item.cotacao.fornecedor.nome + " " + item.item.cotacao.fornecedor.sobrenome) : item.item.cotacao.fornecedor.nome_fantasia) + "<br />";


    }







    const { flagBack } = useParams();
    const { idCliente } = useParams();
    const { idInstalacao } = useParams();



    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const [instalacao, setInstalacao] = useState(null);
    const [botaoClicado, setBotaoClicado] = useState(false);


    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [nomeMapa, setNomeMapa] = useState("");
    const [iconeMapa, setIconeMapa] = useState(0);


    const handleIconeMapa = (event) => {
        setIconeMapa(event.target.value);
    };

    const [modulosSonoros, setModulosSonoros] = useState(null);

    const [moduloSonoroSelecionado, setModuloSonoroSelecionado] = useState(null);





    const handleItemEstoque = (item_estoque) => {
        setItemEstoqueSelecionado(item_estoque);

    }





    const handleInsumo = (insumo) => {
        setInsumoSelecionado(insumo);
    }


    const [modulosPgms, setModulosPgms] = useState(null);

    const [moduloPgmSelecionado, setModuloPgmSelecionado] = useState(null);

    const [modulosPgmsAssociados, setModulosPgmsAssociados] = useState([]);
    const [modulosSonorosAssociados, setModulosSonorosAssociados] = useState([]);

    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };



    const history = useHistory();



    async function funcaoSalvar() {


        const cadastro = {
            latitude: latitude,
            longitude: longitude,
            nome_mapa: nomeMapa,
            ip: ip,
            porta: porta,
            tipo_equipamento_area_tecnica: insumoSelecionado.tipo_equipamento_area_tecnica,
        }

        try {

            if (insumoSelecionado) {
                cadastro.id_insumo = insumoSelecionado.insumo.id_insumo;
            } else {
                alert("Selecione o Insumo!");
                return;
            }




            if (itemEstoqueSelecionado) {
                cadastro.id_item_estoque = itemEstoqueSelecionado.id_item_estoque;
            }


            //modulo pgm
            if (moduloPgmSelecionado?.id_modulo_pgm) {
                cadastro.modulo_pgm = { id_modulo_pgm: moduloPgmSelecionado.id_modulo_pgm };
            }

            //modulo pgm
            if (moduloSonoroSelecionado?.id_modulo_sonoro) {
                cadastro.modulo_sonoro = { id_modulo_sonoro: moduloSonoroSelecionado.id_modulo_sonoro };
            }


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.post('/v1/protected/equipamentos/cadastrar/' + idInstalacao + "/" + insumoSelecionado.insumo.tipo_equipamento_area_tecnica, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {

                alert("Equipamento Cadastrado");
                if (parseInt(flagBack) === 1) {
                    history.push({

                        pathname: "/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/" + idInstalacao + "/" + idCliente,
                    })
                }

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
                //setBotaoSalvarClickado(false);

            }

        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }



    const [clienteSelecionado, setClienteSelecionado] = useState();

    const [clientes, setClientes] = useState();

    const [loading, setLoading] = useState(true);


    const [itemEstoqueSelecionado, setItemEstoqueSelecionado] = useState(null);

    const handleCliente = (event, cliente) => {

        setClienteSelecionado(cliente);
    };



    function returnDescricaoCliente(cliente) {
        var texto = "ID: " + cliente.id_cliente + " " +
            (cliente.tipo_cliente === 0 ? ("CPF: " + cliente.cpf) : ("CNPJ: " + cliente.cnpj)) + " " +
            (cliente.tipo_cliente === 0 ? (cliente.nome + " " + cliente.sobrenome) : cliente.nome_fantasia);

        return texto;
    }


    function returnDescricaoInstalacao(instalacao) {
        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }



    function returnDescricaoModuloPgm(modulo) {
        var texto = "ID: " + modulo.id_modulo_pgm + "\n Código: " + modulo.codigo;
        return texto;
    }


    function returnDescricaoModuloSonoro(modulo) {
        var texto = "ID: " + modulo.id_modulo_sonoro + "\n Código: " + modulo.codigo;
        return texto;
    }




    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            let id_cliente = parseInt(idCliente);
            let id_instalacao = parseInt(idInstalacao);
            let flag = parseInt(flagBack);

            if (flag === 0) {
                await api.get("v1/protected/clientes/listarTodos", {
                    headers: headers
                }).then(function (response) {
                    setClientes(response.data)
                });
            } else if (flag === 1) {
                setClientes([]);

                await api.get("/v1/protected/retornardadoscliente/" + id_cliente, {
                    headers: headers
                }).then(function (response) {
                    setClienteSelecionado(response.data);

                    //listar instalacao
                    api.get("/v1/protected/cliente/instalacoes/listarporid/" + idInstalacao, {
                        headers: headers
                    }).then(function (response) {
                        setInstalacao(response.data);


                        api.get("v1/protected/almoxarifado/estoque/listarporinsumodisponiveis/" + idInstalacao, {
                            headers: headers
                        }).then(function (response) {
                            setInsumos(response.data)
                            console.log(JSON.stringify(response.data));
                            //setLoading(false);

                        });

                    });
                });




            }

            //pgms
            try {
                const response = await api.get("v1/protected/modulopgm/listardisponiveis", {
                    headers: headers
                });
                setModulosPgms(response.data);

                //modulos sonoros
                try {
                    const response = await api.get("v1/protected/modulosonoro/listardisponiveis", {
                        headers: headers
                    });
                    setModulosSonoros(response.data);
                    try {
                        const response = await api.get("v1/protected/modulopgm/listardisponiveisporinstalacao/" + idInstalacao, {
                            headers: headers
                        });
                        setModulosPgmsAssociados(response.data);
                        try {
                            const response = await api.get("v1/protected/modulosonoro/listardisponiveisporinstalacao/" + idInstalacao, {
                                headers: headers
                            });
                            setModulosSonorosAssociados(response.data);
                            setLoading(false);
                        } catch (error) {
                            console.error('Erro ao buscar módulos sonoros:', error);
                        }

                    } catch (error) {
                        console.error('Erro ao buscar módulos PGM:', error);
                    }
                } catch (error) {
                    console.error('Erro ao buscar módulos sonoros:', error);
                }

            } catch (error) {
                console.error('Erro ao buscar módulos PGM:', error);
            }


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar os Clientes: " + _err)
        }
    }

    useEffect(() => {

        listarInformacoes();


    }, []);



    function handleSetInstalacao(instalacao) {
        setInstalacao(instalacao);
    }




    return (
        <div>
            <NavegadorGestao
                BaseDados={true}
                Projetos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }} />


            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <Container >

                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >



                                        <Tabs
                                            value={value}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                            style={{ width: '100%', margin: 0, padding: 0 }}
                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />


                                        </Tabs>

                                    </AppBar>

                                </Box>
                            </Grid>





                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={10}
                                >
                                    <Button style={{ margin: 5 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={salvar}
                                        disabled={botaoClicado}
                                    > Salvar  </Button>
                                </Grid>

                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >

                                    <Grid
                                        value={value} index={0}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >



                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={5}
                                            >

                                                <Autocomplete
                                                    id="select-ordem-servico"
                                                    options={clientes}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(cliente) => returnDescricaoCliente(cliente).replace(/<br \/>/g, '\n')}
                                                    value={clienteSelecionado}
                                                    disabled={flagBack === 0 ? false : true}
                                                    onChange={handleCliente}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cliente) => {
                                                            const cotacaoText = returnDescricaoCliente(cliente).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Cliente " multiline rows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoCliente(option) }} />
                                                    )}

                                                />
                                            </Grid>
                                        </Grid>




                                        {clienteSelecionado &&
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                                style={{ marginTop: 20 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={5}
                                                >

                                                    <Autocomplete
                                                        id="select-instalacao"
                                                        options={[]}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(instalacao) => returnDescricaoInstalacao(instalacao).replace(/<br \/>/g, '\n')}
                                                        value={instalacao}
                                                        disabled={flagBack === 0 ? false : true}
                                                        onChange={(e, v) => handleSetInstalacao(v)}
                                                        filterOptions={(options, { inputValue }) => {
                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                            return options.filter((instalacao) => {
                                                                const cotacaoText = returnDescricaoInstalacao(instalacao).toLowerCase();
                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                            });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Instalação " multiline minRows={3} />
                                                        )}
                                                        renderOption={(option) => (
                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoInstalacao(option) }} />
                                                        )}

                                                    />
                                                </Grid>
                                            </Grid>
                                        }



                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={8}
                                        >

                                            <Autocomplete
                                                id="select-insumo"
                                                options={insumos}
                                                getOptionLabel={(insumo) => returnDescricaoInsumo(insumo).replace(/<br \/>/g, '\n')}
                                                value={insumoSelecionado}
                                                getOptionSelected={(option, value) => true}
                                                onChange={(e, v) => handleInsumo(v)}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((insumo) => {
                                                        const insumoText = returnDescricaoInsumo(insumo).toLowerCase();
                                                        return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Insumo " multiline minRows={2} />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoInsumo(option) }} />
                                                )}
                                            />
                                        </Grid>


                                        {insumoSelecionado &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >


                                                <Autocomplete
                                                    id="select-item-estoque"
                                                    options={insumoSelecionado.itens_estoque}
                                                    getOptionLabel={(itemEstoque) => returnDescricaoItemEstoque(itemEstoque).replace(/<br \/>/g, '\n')}
                                                    value={itemEstoqueSelecionado}
                                                    getOptionSelected={(option, value) => true}
                                                    onChange={(e, v) => handleItemEstoque(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoItemEstoque(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Item do Estoque " multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoItemEstoque(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }



                                        {insumoSelecionado && (insumoSelecionado.insumo.tipo_equipamento_area_tecnica === 0) &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-modulo_pgm"
                                                    options={modulosPgms}
                                                    getOptionLabel={(modulo) => returnDescricaoModuloPgm(modulo).replace(/<br \/>/g, '\n')}
                                                    value={moduloPgmSelecionado}
                                                    getOptionSelected={(option, value) => true}
                                                    onChange={(e, v) => setModuloPgmSelecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoModuloPgm(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Módulo PGM " multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoModuloPgm(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }




                                        {insumoSelecionado && (insumoSelecionado.insumo.tipo_equipamento_area_tecnica === 30) &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-modulo_sonoro"
                                                    options={modulosSonoros}
                                                    getOptionLabel={(modulo) => returnDescricaoModuloSonoro(modulo).replace(/<br \/>/g, '\n')}
                                                    value={moduloSonoroSelecionado}
                                                    getOptionSelected={(option, value) => true}
                                                    onChange={(e, v) => setModuloSonoroSelecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoModuloSonoro(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Módulo Sonoro " multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoModuloSonoro(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }


                                        {insumoSelecionado &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-modulo_pgm"
                                                    options={modulosPgmsAssociados}
                                                    getOptionLabel={(modulo) => returnDescricaoModuloPgm(modulo).replace(/<br \/>/g, '\n')}
                                                    value={moduloPgmSelecionado}
                                                    getOptionSelected={(option, value) => true}
                                                    onChange={(e, v) => setModuloPgmSelecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoModuloPgm(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Módulo PGM associado" multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoModuloPgm(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }



                                        {insumoSelecionado &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-modulo_sonoro"
                                                    options={modulosSonorosAssociados}
                                                    getOptionLabel={(modulo) => returnDescricaoModuloSonoro(modulo).replace(/<br \/>/g, '\n')}
                                                    value={moduloSonoroSelecionado}
                                                    getOptionSelected={(option, value) => true}
                                                    onChange={(e, v) => setModuloSonoroSelecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoModuloSonoro(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Módulo Unifier associado" multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoModuloSonoro(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }






                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography style={{ margin: 15 }} >
                                                    <span style={{ fontSize: 22, textDecoration: 'underline' }}>Dados de Mapa: </span>
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12} spacing={2}
                                            >
                                                <Grid item xs={4}>
                                                    <TextField

                                                        id="nome_mapa"
                                                        variant="standard"
                                                        name="nome_mapa"
                                                        fullWidth
                                                        label="Nome Mapa"
                                                        value={nomeMapa}
                                                        onChange={e => setNomeMapa(e.target.value)}
                                                    />
                                                </Grid>



                                            </Grid>





                                            <Grid item xs={4}>
                                                <TextField

                                                    id="latitude"
                                                    variant="standard"
                                                    name="latitude"
                                                    fullWidth
                                                    label="Latitude"
                                                    value={latitude}
                                                    onChange={e => setLatitude(e.target.value)}
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <TextField

                                                    id="longitude"
                                                    variant="standard"
                                                    name="longitude"
                                                    fullWidth
                                                    label="Longitude"
                                                    value={longitude}
                                                    onChange={e => setLongitude(e.target.value)}
                                                />
                                            </Grid>


                                        </Grid>


                                    </Grid>

                                    <Grid
                                        value={value}
                                        index={1}
                                        style={{ margin: 1, padding: 1, width: '100%' }}

                                    >

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12} spacing={2}
                                        >
                                            <Grid item xs={4}>
                                                <TextField

                                                    id="ip"
                                                    variant="standard"
                                                    name="ip"
                                                    fullWidth
                                                    label="IP"
                                                    value={ip}
                                                    onChange={e => setIp(e.target.value)}
                                                />
                                            </Grid>

                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12} spacing={2}
                                        >
                                            <Grid item xs={4}>
                                                <TextField

                                                    id="porta"
                                                    variant="standard"
                                                    name="porta"
                                                    fullWidth
                                                    label="Porta"
                                                    value={porta}
                                                    onChange={e => setPorta(e.target.value)}
                                                />
                                            </Grid>

                                        </Grid>

                                    </Grid>


                                </SwipeableViews>


                            </Grid>

                        </Grid>


                    </React.Fragment>


                </Container>
            }

        </div >
    );
}


