import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import {
    Box,
     Tooltip
} from '@mui/material';
import {
    MaterialReactTable,

} from 'material-react-table';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@material-ui/core/IconButton';
import { Button, MenuItem, Select, InputLabel } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { styled } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";

import api from '../../../../services/api';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NavegadorGestao from "../../../../components/NavBarGestao";



const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));


function novoUsuario() {
    window.location.href = "/administracao/cadastrarusuario"

}

export default function Usuarios() {


    const history = useHistory();


    const [loading, setLoading] = useState(true);
    const [usuarios, setUsuarios] = useState([]);
    const [click, setClick] = useState(1);
    const [parametros, setParametros] = useState(
        {
            identificacao: "",
            page: 0,
            size: 1000,
        }
    );

    const [idUsuarioAAlterar, setIdUsuarioAlterar] = useState(0);





    const [alertOpen, setAlertOpen] = useState(false);
    const [mensagem, setMensagem] = useState('');


    const handleOpenAlert = (id) => {
        setIdUsuarioAlterar(id);
        setMensagem('');

        setAlertOpen(true);
    };

    async function handleCloseAlert() {

        try {



            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = Cookies.get('id_usuario');

            const response = await api.put('/v1/protected/users/mudarStatus/' + id_usuario_logado + "/" + idUsuarioAAlterar + "/" + mensagem,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Usuário Atualizado com sucesso!")

                history.push({
                    pathname: '/usuarios',
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }


        setIdUsuarioAlterar(0);
        setMensagem('');
        setAlertOpen(false);

        history.push({
            pathname: '/usuarios',
        })

    }



 

    useEffect(() => {



        async function listarUsuarios() {

            setLoading(true);

            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }


                await api.get("v1/protected/user/listartodos", {
                    headers: headers
                }).then(function (response) {

                    const updatedData = response.data.map(item => {
                        // Modificar o atributo 'conta_instalacao'
                       item.status_usuario = item.status === 1 ? "ATIVADO":"DESATIVADO";
       
                       if (item.tipo_associacao === 0) {
                           item.identificador = item.colaborador.cpf;
                       }
       

                        return item;
                    });
    
                    setUsuarios(updatedData)




                    setLoading(false);

                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar usuarios: " + _err)

            }

        }
        listarUsuarios();


    }, []);




    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }







    

    const columnsUsuarios  = useMemo(
        () => [

            {
                accessorKey: 'id_usuario', //normal accessorKey
                header: 'ID',
                size: 60,
            },

            {
                accessorKey: 'status_usuario', //normal accessorKey
                header: 'STATUS',
                size: 150,
            },
            {
                accessorKey: 'nome_amigavel', //normal accessorKey
                header: 'Nome',
                size: 200,
            },
            
            {
                accessorKey: 'identificador', //normal accessorKey
                header: 'Identificação',
                size: 200,
            },

            
            {
                accessorKey: 'email', //normal accessorKey
                header: 'E-mail',
                size: 200,
            },
              
            {
                accessorKey: 'login', //normal accessorKey
                header: 'Login',
                size: 200,
            },

        ],
        [],
    );



    return (
        <div>

            <NavegadorGestao
                Administracao={true}
                Usuarios={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ paddingTop: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novoUsuario}
                    >
                        Novo Usuário
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        <div style={{ height: '100%', width: '100%' }}>
                            {usuarios && (
                                <MaterialReactTable
                                    columns={columnsUsuarios}
                                    data={usuarios}
                                    enableGlobalFilterModes
                                    enablePagination={true}
                                    enableRowActions
                                    paginationDisplayMode={'pages'}
                                    getRowId={(row) => row.id_usuario}
                                    enableTopToolbar={true}
                                    enableBottomToolbar={true}
                                    enableColumnFilters={true}
                                    enableColumnActions={false}
                                    sortable={true}
                                    enableSorting={true}
                                    enableRowSelection={false}
                                    enableColumnFilterModes={true}
                                    enableExpandAll={true}
                                    enableColumnPinning={true}
                                    enableColumnResizing={true}
                                    initialState={{
                                        pagination: { pageSize: 10, pageIndex: 0 }, // Correção da sintaxe aqui
                                        showGlobalFilter: true,
                                        density: 'compact',
                                    }}
                                    muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                    muiPaginationProps={{
                                        rowsPerPageOptions: [10, 100, 200],
                                        variant: 'outlined',
                                    }}
                                    enableFullScreenToggle={true}
                                   
                                    renderRowActions={({ row }) => (
                                        <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>


                                            <Tooltip title="Gerenciar">
                                                <IconButton color="error" href={"/administracao/gerenciarusuario/" + row.original.id_usuario}>
                                                    <SettingsIcon />
                                                </IconButton>
                                            </Tooltip>

                                        </Box>
                                    )}
                                />
                            )}
                        </div>
                    </div>
                }


            </div>


            <Dialog
                fullScreen={false}
                open={alertOpen}
                onClose={handleCloseAlert}
                aria-labelledby="confirm-dialog"
                BackdropProps={{
                    invisible: true,
                }}
            >
                <DialogTitle
                    style={{ backgroundColor: 'black', color: 'white' }}
                    id="confirm-dialog"> Mudar Status de Usuário</DialogTitle>
                <DialogContent
                    style={{ backgroundColor: 'black' }}
                >
                    <div>
                        <AppBar
                            style={{ backgroundColor: 'black', color: 'white' }}

                            sx={{ position: 'relative' }}>

                            <Grid container spacing={1}

                                style={{ backgroundColor: 'black', color: 'white' }}

                                item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    id="mensagem"
                                    variant="standard"
                                    name="mensagem"
                                    fullWidth
                                    label="Mensagem"
                                    required
                                    autoComplete="mensagem"
                                    value={mensagem}
                                    onChange={e => setMensagem(e.target.value)}
                                    multiline
                                    rows={3}
                                    InputProps={{
                                        style: { color: 'white' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'white' },
                                    }}

                                />

                                <Grid

                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                    onClick={handleCloseAlert}
                                >
                                    <ColorButtonGreen style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        ok
                                    </ColorButtonGreen>
                                </Grid>
                            </Grid>
                        </AppBar>
                    </div>
                </DialogContent>

            </Dialog >

        </div >
    );
}



