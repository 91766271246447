import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import AppBar from '@mui/material/AppBar';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import { Box, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@material-ui/icons';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { styled } from '@material-ui/styles';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import ChatIcon from '@mui/icons-material/Chat';
import VariationsIcon from '@mui/icons-material/ViewCarousel';
import moment from 'moment';
import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';
import office from '../../../../../assets/imgs/office.png';
import Notifications from '@mui/icons-material/Notifications';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import ReplyIcon from '@mui/icons-material/Reply';


import api from '../../../../../services/api';
import {
    DataGrid, ptBR,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import {
    MaterialReactTable
} from 'material-react-table';

import NavegadorGestao from '../../../../../components/NavBarGestao';
 
import { Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';

import { Email, EmailOutlined, Visibility, VisibilityOff, Message, MessageOutlined } from '@mui/icons-material';



const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));


const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'whit',
        borderColor: 'red',
    },
}));


const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'whit',
        borderColor: 'blue',
    },
}));



export default function EditarOrdemDeCompra() {

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });


     const [arquivos, setArquivos] = useState(null);


    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [value, setValue] = React.useState(0);

    const [botaoEscolherFaturamentoCliquado, setBotaoEscolherFaturamentoCliquado] = useState(false);

    const [motivoEscolha, setMotivoEscolha] = useState('');

    const [columnsAnalise, setColumnsAnalise] = useState([]);


    const [unidadeSelecionada, setUnidadeSelecionada] = useState(null);



    const [idUsuarioLogado, setIdUsuarioLogado] = useState(0);
    const [idUsuarioCriador, setIdUsuarioCriador] = useState(0);

    async function baixarArquivo(caminho) {
        try {
            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const response = await api.post("/v1/protected/ordensdecompras/baixarPdf", { caminho: encodeURIComponent(caminho) }, {
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });

            // Cria um blob com os dados recebidos
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Cria uma URL do blob
            const blobUrl = URL.createObjectURL(blob);

            // Abre uma nova guia com o arquivo PDF
            window.open(blobUrl, '_blank');
        } catch (error) {
            // Trate os erros conforme necessário
            console.log("Erro ao baixar o arquivo: " + error);
        }
    }




    const history = useHistory();

    const [descricao, setDescricao] = useState('');
    const [loading, setLoading] = useState(true);

    const [valorTotalGlobal, setValorTotalGlobal] = useState(0.0);
    const [valorFreteGlobal, setValorFreteGlobal] = useState(0.0);



    const [ordemCompra, setOrdemCompra] = useState(null);
    const { idOrdemCompra } = useParams();
    const [ordemServicoSelecionada, setOrdemServicoSelecionada] = useState(null);

    const [informacoes, setInformacoes] = useState("");

    const [solicitantesSelecionados, setSolicitantesSelecionados] = useState([]);

    const [isSolicitante, setIsSolicitante] = useState(false);
    const [isSupervisor, setIsSupervisor] = useState(false);

    const [autorizou, setAutorizou] = useState(false);

    const [preCotacoes, setPreCotacoes] = useState([]);

    const [objetosAnalise, setObjetosAnalise] = useState([]);
    const [fornecedoresAnalise, setFornecedoresAnalise] = useState([]);

    const [infoOrdem, setInfoOrdem] = useState('');

    const [openAtualizando, setOpenAtualizando] = useState(false);


    const [arquivoSelecionado1, setArquivoSelecionado1] = useState(null);
    const onDropArquivo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado1(acceptedFiles[0]);

    }, []);

    async function listarInformacoes() {
        try {


            const token = Cookies.get('token');

            let status_ordem = -1;
            let isSolicitanteLocal = false;

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/ordensdecompras/ordemdecompra/listarporid/" + idOrdemCompra, {
                headers: headers
            }).then(function (response) {

                console.log("ordem de compra listada!");

                const ordem_compra = response.data.ordem_compra;
                setOrdemCompra(ordem_compra);


                let text_info0 = ordem_compra.status === 0 ? "Status: Elaborando Cotações" :
                    ordem_compra.status === 1 ? "Status: Em Ánalise Orçamentária" :
                        ordem_compra.status === 2 ? "Status: Aguardando Autorizações" :

                            ordem_compra.status === 3 ? "Status: Ordem Autorizada, em processo de Compra e Faturamento" : '';
                text_info0 += "\n\n";

                let text_info1 = "1) " + ordem_compra.usuario.nome_amigavel + " criou a Ordem em " + moment(ordem_compra.data_criacao).format('HH:mm:ss DD/MM/YYYY')
                let text_info2 = "\n2) " + ordem_compra.usuario.nome_amigavel + " finalizou a elaboração de Cotações em " + moment(ordem_compra.data_usuario_finalizou_pre_cotacoes).format('HH:mm:ss DD/MM/YYYY')

                let text_info3 = "\n\nÁnalise Orçamentária:\n3) " +
                    (ordem_compra.status >= 2 ?
                        (ordem_compra.usuario_finalizou_analise_orcamentaria.nome_amigavel + " em " + moment(ordem_compra.data_hora_autorizacao).format('HH:mm:ss DD/MM/YYYY') + " finalizou a Ánalise Orçamentária"
                        )
                        : "Ánalise Orçamentária não finalizada");


                let text_info4 = "\n\nValor Total: " + currencyFormatter.format(ordem_compra.valor_total);





                status_ordem = ordem_compra.status;
                console.log("Status ordem: " + status_ordem);
                setOrdemServicoSelecionada(response.data.ordem_compra.ordem_servico);

                console.log("Pre cotacoes: " + JSON.stringify(response.data.ordem_compra.pre_cotacoes));

                setPreCotacoes(response.data.ordem_compra.pre_cotacoes);
                setIdUsuarioCriador(response.data.ordem_compra.usuario.id_usuario);
                setUnidadeSelecionada(response.data.ordem_compra.unidade);

                setDescricao(response.data.ordem_compra.descricao);
                setSolicitantesSelecionados(response.data.ordem_compra.solicitantes);

                let text_info5 = "\n\nAutorizações dos Solicitantes:\n";

                response.data.ordem_compra.solicitantes.some(solicitante => {
                    text_info5 += (solicitante.solicitante.nome_amigavel +
                        (solicitante.autorizado === 0 ? " ainda não autorizou a Ordem de Compra" :
                            (" autorizou a Ordem de Compra em " + moment(solicitante.data_hora_autorizacao).format('HH:mm:ss DD/MM/YYYY')))
                        + "\n");
                    if (parseInt(solicitante.solicitante.id_usuario) === parseInt(Cookies.get("id_usuario"))) {
                        setIsSolicitante(true);
                        isSolicitanteLocal = true;
                        if (parseInt(solicitante.autorizado) === 1) {
                            setAutorizou(true);
                        }
                    }
                });


                let text_info51 = "\n\nAutorizações dos Supervisores:\n";

                response.data.ordem_compra.supervisores.some(solicitante => {
                    text_info51 += ("O supervisor " + solicitante.solicitante.nome_amigavel +
                        (solicitante.autorizado === 0 ? " ainda não autorizou a Ordem de Compra" :
                            (" autorizou a Ordem de Compra em " + moment(solicitante.data_hora_autorizacao).format('HH:mm:ss DD/MM/YYYY')))
                        + "\n");
                    if (parseInt(solicitante.solicitante.id_usuario) === parseInt(Cookies.get("id_usuario"))) {
                        setIsSupervisor(true);
                        isSolicitanteLocal = true;
                        if (parseInt(solicitante.autorizado) === 1) {
                            setAutorizou(true);
                        }
                    }
                });



                let text_info6 = "\n\nAutorizações da Mesa Diretora:\n";

                if (ordem_compra.numero_diretores > 0) {
                    response.data.ordem_compra.diretores.some(solicitante => {
                        text_info6 += (solicitante.solicitante.nome_amigavel +
                            (solicitante.autorizado === 0 ? " ainda não autorizou a Ordem de Compra" :
                                (" autorizou a Ordem de Compra em " + moment(solicitante.data_hora_autorizacao).format('HH:mm:ss DD/MM/YYYY')))
                            + "\n");
                        if (parseInt(solicitante.solicitante.id_usuario) === parseInt(Cookies.get("id_usuario"))) {

                            setIsSolicitante(true);
                            isSolicitanteLocal = true;
                            if (parseInt(solicitante.autorizado) === 1) {
                                setAutorizou(true);
                            }
                        }
                    });

                }
                setInfoOrdem(text_info0 + text_info1 + text_info2 + text_info3 + text_info4 + text_info5 + text_info51 + text_info6);

                setArquivos(response.data);

                api.get("/v1/protected/ordensdecompras/ordemdecompra/analiseorcamentaria/" + idOrdemCompra, {
                    headers: headers
                }).then(function (response_analise) {

                    const objetosAnaliseLocal = response_analise.data.itens;

                    const total_itens = objetosAnaliseLocal.length;

                    const tam = parseInt(response_analise.data.quantidade_fornecedores);

                    setValorTotalGlobal(response_analise.data.valor_total_global);
                    setValorFreteGlobal(response_analise.data.valor_total_frete_global);

                    const columns = [
                        {
                            accessorKey: 'id_item_analise',
                            header: 'ID',
                            size: 50,
                            maxWidth: 50,
                            Cell: ({ cell }) => (
                                <span style={{ fontSize: 12, color: 'blue' }}>
                                    {cell.getValue()}
                                </span>
                            ),
                        },
                        {
                            header: 'Descrição',
                            size: 500,
                            accessorFn: row => {
                                return {
                                    insumo: row.insumo,

                                };

                            },
                            Cell: ({ renderedCellValue }) => {
                                const processarTextoComQuebraDeLinha = (insumo) => {
                                    let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao + "\n\n";

                                    if (insumo.variacoes && insumo.variacoes.length > 0) {
                                        console.log("tem variacoes");
                                        textoComQuebraDeLinha += insumo.variacoes
                                            .map((variacao, index) => (
                                                `Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                                            )).join('');
                                        return textoComQuebraDeLinha; // Retorna o texto processado apenas se houver variações
                                    } else {
                                        console.log("nao tem variacoes");
                                        return null; // Se não houver variações, retorne null
                                    }
                                };

                                const textoComQuebraDeLinha = processarTextoComQuebraDeLinha(renderedCellValue.insumo);

                                if (textoComQuebraDeLinha) {
                                    return (
                                        <Tooltip
                                            title={
                                                <div style={{ backgroundColor: 'white', color: 'black', size: 14, minHeight: '50px', minWidth: '400px', border: '2px solid black' }}>
                                                    <span>
                                                        {textoComQuebraDeLinha.split('\n').map((line, index) => (
                                                            <React.Fragment key={index}>
                                                                {line}
                                                                <br />
                                                            </React.Fragment>
                                                        ))}
                                                    </span>
                                                </div>
                                            }
                                            placement="top"
                                            arrow
                                            style={{ backgroundColor: 'white' }}
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            <span>{renderedCellValue.insumo.descricao}</span>
                                            <VariationsIcon />
                                        </Tooltip>
                                    );
                                } else {
                                    return <span>{renderedCellValue.insumo.descricao}</span>;
                                }
                            }
                        },
                        {
                            accessorKey: 'quantidade',
                            header: 'Quantidade',
                            size: 25,
                            maxWidth: 25,
                            Cell: ({ cell }) => (
                                <span style={{ fontSize: 14, color: 'black', fontSize: 'bold' }}>
                                    {cell.getValue()}
                                </span>
                            ),
                        },

                    ];

                    for (let i = 0; i < tam; i++) {
                        const fornecedorNome = response_analise.data.nome_fornecedores[i];
                        const id_fornecedor = response_analise.data.ids_fornecedores[i];
                        const valor_total_por_fornecedor = response_analise.data.valores_totais[i];
                        const valor_total_selecionado_por_fornecedor = response_analise.data.valores_totais_selecionados[i];
                        const valor_frete_por_fornecedor = response_analise.data.valores_fretes[i];
                        const preCotacaoSelecionada = response_analise.data.cotacoes[i].selecionado;
                        const idPreCotacao = response_analise.data.cotacoes[i].id_pre_cotacao;

                        const valor_frete__se_1_item_selecionadopor_fornecedor = response_analise.data.valores_fretes_se_1_item_selecionado[i];
                        const quantidade_itens_por_fornecedor = response_analise.data.quantidade_itens_por_fornecedor[i];




                        console.log("id: " + id_fornecedor);

                        columns.push(
                            {
                                id: `fornecedor${i + 1}`,
                                header: `${fornecedorNome}`,
                                size: 210,
                                maxWidth: 210,
                                Header: (
                                    <Tooltip
                                        title={
                                            <div style={{ backgroundColor: 'white', fontSize: 14, color: 'black', width: '200px' }}>
                                                <Grid
                                                    item xs={12}
                                                    container
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent={"center"}
                                                >
                                                    <Grid
                                                        item xs={12}
                                                        container
                                                        direction="row"
                                                        alignItems="center"
                                                        justifyContent={"center"}
                                                    >
                                                        <span style={{ padding: 20, color: 'black' }}>
                                                            {"Este fornecedor tem " + quantidade_itens_por_fornecedor + " de " + total_itens + " itens requeridos"}
                                                        </span>

                                                    </Grid>
                                                </Grid>
                                            </div>
                                        }
                                        placement='bottom-start'
                                        arrow
                                        style={{ backgroundColor: 'white', color: 'black' }}
                                    >
                                        <Grid
                                            item xs={12}
                                            container
                                            direction="row"
                                            alignItems="center"
                                            justifyContent={"center"}
                                        >

                                            <Grid
                                                item xs={6}
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justifyContent={"flex-end"}
                                            >

                                                <span style={{ fontSize: 12, wordWrap: 'break-word', display: 'inline-block' }}>
                                                    {fornecedorNome}
                                                </span>
                                            </Grid>

                                            <Grid
                                                item xs={1}
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justifyContent={"flex-start"}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={preCotacaoSelecionada === 1}

                                                            onChange={(e) => {
                                                                handleChangePreCotacaoSelected(idPreCotacao)
                                                            }}
                                                            disabled={
                                                                !(parseInt(status_ordem) <= 1)
                                                            }

                                                            inputProps={{ 'aria-label': 'controlled' }}

                                                        />
                                                    }
                                                    label=""
                                                />

                                            </Grid>

                                        </Grid>
                                    </Tooltip>

                                ),

                                columns: [
                                    {
                                        accessorFn: row => {
                                            const cotacao = row.fornecedor_insumo[i];
                                            if (cotacao) {
                                                return {
                                                    valor_unitario: cotacao.valor_unitario,
                                                    descricao_cotacao: cotacao.descricao,
                                                    observacoes: cotacao.observacoes,
                                                    link: cotacao.link,
                                                    cotacao: cotacao,
                                                };
                                            } else {
                                                return {
                                                    valor_unitario: 0.0,
                                                    descricao_cotacao: "",
                                                    observacoes: "",
                                                    link: "",
                                                    descricao_insumo: "",
                                                    cotacao: null,
                                                };
                                            }
                                        },
                                        id: `valor_unitario${i + 1}`,
                                        header: 'Valor Unitário',
                                        size: 130,
                                        maxHeight: 130,
                                        Cell: ({ renderedCellValue }) => {

                                            if (renderedCellValue.valor_unitario > 0.0) {
                                                return (
                                                    <Tooltip
                                                        title={
                                                            <div style={{ backgroundColor: 'white', fontSize: 14, color: 'black', padding: 5, maxWidth: '400px', minWidth: '200px' }}>
                                                                <div style={{ backgroundColor: 'white', fontSize: 14, color: 'black' }}><strong>Descrição da Cotação:</strong> {renderedCellValue.descricao_cotacao}</div>
                                                                <div style={{ backgroundColor: 'white', fontSize: 14, color: 'black' }} ><strong>Observações do Insumo:</strong> {renderedCellValue.observacoes}</div>
                                                                {renderedCellValue.link && <div><a href={renderedCellValue.link} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: 'white', fontSize: 14, color: 'blue' }}>Acessar</a></div>}

                                                                <Grid item xs={12} style={{ paddingTop: 50 }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="h7" >Tarefas</Typography>
                                                                    </Grid>
                                                                    {renderedCellValue.cotacao.tarefas?.map((tarefa, index) => (

                                                                        <Grid item xs={12} key={index}>
                                                                            {/* xs=12 para dispositivos pequenos, sm=6 para médios, md=4 para grandes, lg=3 para extra grandes */}
                                                                            <Card style={{ borderRadius: '10px', border: '1px solid #e0e0e0', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '3px' }}>
                                                                                <CardContent>

                                                                                    <Typography variant="h6" style={{ fontSize: 12 }}>{tarefa.nome}</Typography>
                                                                                    <br />
                                                                                    <Tooltip
                                                                                        title={
                                                                                            <span> {tarefa.vizualizada === 0 ? (tarefa.usuario_destinado.nome_amigavel + " ainda não vizualizou esta tarefa") : (tarefa.usuario_destinado.nome_amigavel + " vizualizou esta tarefa")}</span>
                                                                                        }
                                                                                        placement='top-start'
                                                                                        arrow
                                                                                        style={{ backgroundColor: 'white', color: 'black' }}
                                                                                    >
                                                                                        {tarefa.vizualizada === 0 ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                                                                                    </Tooltip>

                                                                                    <Tooltip
                                                                                        title={
                                                                                            <span> {tarefa.status === 0 ? (tarefa.usuario_destinado.nome_amigavel + " ainda não executou esta tarefa") : (tarefa.usuario_destinado.nome_amigavel + " executou esta tarefa, verifique a mensagem de resposta!")}</span>
                                                                                        }
                                                                                        placement='top-start'
                                                                                        arrow
                                                                                        style={{ backgroundColor: 'white', color: 'black' }}
                                                                                    >
                                                                                        {tarefa.status === 0 ? <CloseIcon fontSize="small" /> : <CheckCircleIcon fontSize="small" />}
                                                                                    </Tooltip>

                                                                                    <br />
                                                                                    <br />
                                                                                    <Tooltip
                                                                                        title={
                                                                                            <div style={{ backgroundColor: 'white', fontSize: 14, color: 'black', padding: 5, maxWidth: '400px', maxHeight: '100px' }}>
                                                                                                <span style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>{tarefa.mensagem}</span>

                                                                                            </div>
                                                                                        }
                                                                                        placement='left'
                                                                                        arrow
                                                                                        style={{ backgroundColor: 'white', color: 'black' }}
                                                                                    >
                                                                                        <span style={{ backgroundColor: 'white', color: 'black' }}>{"Mensagem"}</span>
                                                                                        {
                                                                                            tarefa && tarefa.status === 0 && (parseInt(Cookies.get("id_usuario")) === parseInt(tarefa.usuario_destinado.id_usuario)) &&
                                                                                            <IconButton
                                                                                                color="primary"
                                                                                                aria-label="Responder"
                                                                                                onClick={(e) => openModalResposta(tarefa)}

                                                                                            >
                                                                                                <ReplyIcon />
                                                                                            </IconButton >
                                                                                        }

                                                                                    </Tooltip>
                                                                                    <br />
                                                                                    <Tooltip
                                                                                        title={

                                                                                            <div style={{ backgroundColor: 'white', fontSize: 14, color: 'black', padding: 5, maxWidth: '400px', maxHeight: '100px' }}>
                                                                                                <span style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>{tarefa.resposta}</span>

                                                                                            </div>}
                                                                                        placement='left'
                                                                                        arrow
                                                                                        style={{ backgroundColor: 'white', color: 'black' }}
                                                                                    >
                                                                                        <span>{"Resposta"}</span>

                                                                                    </Tooltip>



                                                                                </CardContent>
                                                                            </Card>

                                                                        </Grid>
                                                                    ))}
                                                                </Grid>

                                                                <Tooltip
                                                                    title={"Nova Tarefa"
                                                                    }
                                                                    placement='top-start'
                                                                    arrow
                                                                    style={{ backgroundColor: 'white', color: 'blue' }}
                                                                >
                                                                    <IconButton
                                                                        color="primary"
                                                                        aria-label="Nova Tarefa"
                                                                        onClick={(e) => renderedCellValue.valor_unitario > 0.0 ? openModal(renderedCellValue.cotacao) : openModal(null)}
                                                                    >
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                </Tooltip>


                                                            </div>
                                                        }
                                                        placement="top"
                                                        arrow
                                                        style={{ backgroundColor: 'white', color: 'black' }}
                                                    >


                                                        <span style={{ maxWidth: '130px', wordWrap: 'break-word', display: 'inline-block', color: '#333333' }}>
                                                            {renderedCellValue.valor_unitario > 0.0 ? currencyFormatter.format(renderedCellValue.valor_unitario) : "---"}
                                                        </span>



                                                        {renderedCellValue.cotacao.tarefas.length > 0 ? (
                                                            <ChatIcon
                                                                className={renderedCellValue.cotacao.tarefas.some(tarefa => tarefa.status === 0) ? "shake-bell" : ""}
                                                                style={{ color: "green" }}
                                                                sx={{ fontSize: 15, color: 'green' }}
                                                            />
                                                        ) : null}
                                                    </Tooltip>);
                                            }
                                            else {
                                                return (
                                                    <span style={{ maxWidth: '130px', wordWrap: 'break-word', display: 'inline-block', color: '#333333' }}>
                                                        {"---"}
                                                    </span>

                                                );
                                            }
                                        },
                                    },
                                    {
                                        accessorFn: row => {
                                            const cotacao = row.fornecedor_insumo[i];
                                            const item = row.fornecedor_insumo2[i];


                                            if (cotacao)
                                                return {
                                                    valor_parcial: cotacao.valor_unitario * row.quantidade,
                                                    selecionado: item.selecionado,
                                                    id_item_ordem_compra: item.id_item_ordem_compra,
                                                }
                                            else
                                                return {
                                                    valor_parcial: 0.0,
                                                    selecionado: 0,
                                                    id_cotacao: cotacao.id_cotacao,
                                                }
                                        },
                                        Footer: () => (
                                            <Stack style={{ color: 'black', backgroundColor: 'white', fontWeight: 'initial', fontSize: 16 }}>
                                                <Tooltip
                                                    title={
                                                        <div
                                                            style={{ backgroundColor: 'white', border: '2px solid black', color: 'black', padding: 20 }}
                                                        > Valor Itens:
                                                            <Box>
                                                                {currencyFormatter.format(valor_total_por_fornecedor)}
                                                            </Box>
                                                            <br></br>
                                                            Valor Frete:
                                                            <Box>
                                                                {currencyFormatter.format(valor_frete_por_fornecedor)}
                                                            </Box>

                                                        </div>
                                                    }
                                                    placement="top"
                                                    arrow
                                                    style={{ backgroundColor: 'white' }}
                                                    sx={{ backgroundColor: 'white' }}
                                                >
                                                    Valor Total:
                                                    <Box>
                                                        {currencyFormatter.format(valor_total_por_fornecedor + valor_frete_por_fornecedor)}
                                                    </Box>
                                                </Tooltip>





                                                <br></br><br></br>

                                                <Tooltip
                                                    title={
                                                        <div
                                                            style={{ backgroundColor: 'white', border: '2px solid black', color: 'black', padding: 20 }}
                                                        >
                                                            Valor Itens Selecionados:
                                                            <Box>
                                                                {currencyFormatter.format(valor_total_selecionado_por_fornecedor)}
                                                            </Box>
                                                            <br></br>
                                                            Valor Frete:
                                                            <Box>
                                                                {currencyFormatter.format(valor_frete__se_1_item_selecionadopor_fornecedor)}
                                                            </Box>

                                                        </div>
                                                    }
                                                    placement="top"
                                                    arrow
                                                    style={{ backgroundColor: 'white' }}
                                                    sx={{ backgroundColor: 'white' }}
                                                >
                                                    Valor Total de Itens Selecionados:
                                                    <Box>
                                                        {currencyFormatter.format(valor_total_selecionado_por_fornecedor + valor_frete__se_1_item_selecionadopor_fornecedor)}
                                                    </Box>
                                                </Tooltip>





                                            </Stack>
                                        ),
                                        id: `valor_total${i + 1}`,
                                        header: 'Valor Total',
                                        size: 120,
                                        maxWidth: 120,
                                        Cell: ({ renderedCellValue }) => (

                                            <Grid
                                                item xs={12}
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justifyContent={"center"}
                                            >

                                                <Grid
                                                    item xs={6}
                                                    container
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent={"flex-end"}
                                                >

                                                    <span style={{ wordWrap: 'break-word', display: 'inline-block', color: 'black' }}>
                                                        {renderedCellValue.valor_parcial > 0.0 ? currencyFormatter.format(renderedCellValue.valor_parcial) : "---"}
                                                    </span>
                                                </Grid>

                                                <Grid
                                                    item xs={1}
                                                    container
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent={"flex-start"}
                                                >
                                                    {renderedCellValue.valor_parcial > 0.0 &&
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={renderedCellValue.selecionado === 1}
                                                                    onChange={(e) => {
                                                                        handleChangeSelected(renderedCellValue.id_item_ordem_compra);
                                                                        e.target.checked = renderedCellValue.selecionado === 0; // Define imediatamente o estado do Checkbox para o valor oposto
                                                                    }}
                                                                    disabled={
                                                                        !(parseInt(status_ordem) <= 1)
                                                                    }
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            }
                                                            label=""
                                                        />
                                                    }

                                                </Grid>
                                            </Grid>






                                        ),
                                    },
                                ],
                            }
                        );
                    }




                    setObjetosAnalise(objetosAnaliseLocal);
                    setColumnsAnalise(columns);



                    setLoading(false);
                });
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }





    async function recalcular() {
        try {

            const token = Cookies.get('token');

            let status_ordem = -1;
            let isSolicitanteLocal = false;

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("/v1/protected/ordensdecompras/ordemdecompra/analiseorcamentaria/" + idOrdemCompra, {
                headers: headers
            }).then(function (response_analise) {

                const objetosAnaliseLocal = response_analise.data.itens;
                const tam = parseInt(response_analise.data.quantidade_fornecedores);

                setValorTotalGlobal(response_analise.data.valor_total_global);
                setValorFreteGlobal(response_analise.data.valor_total_frete_global);

                const columns = [
                    {
                        accessorKey: 'id_item_analise',
                        header: 'ID',
                        size: 30,
                    },
                    {
                        accessorKey: 'insumo.descricao',
                        header: 'Descrição',
                        size: 500,
                        headerStyle: {
                            backgroundColor: 'red',
                            color: '#333',
                            fontWeight: 'bold',
                        },
                    },
                    {
                        accessorKey: 'quantidade',
                        header: 'Quantidade',
                        size: 100,
                    },

                ];

                for (let i = 0; i < tam; i++) {
                    const fornecedorNome = response_analise.data.nome_fornecedores[i];
                    const id_fornecedor = response_analise.data.ids_fornecedores[i];
                    const valor_total_por_fornecedor = response_analise.data.valores_totais[i];
                    const valor_total_selecionado_por_fornecedor = response_analise.data.valores_totais_selecionados[i];
                    const valor_frete_por_fornecedor = response_analise.data.valores_fretes[i];
                    const preCotacaoSelecionada = response_analise.data.cotacoes[i].selecionado;
                    const idPreCotacao = response_analise.data.cotacoes[i].id_pre_cotacao;

                    console.log("id: " + id_fornecedor);

                    columns.push(
                        {
                            id: `fornecedor${i + 1}`,
                            header: `${fornecedorNome}`,
                            size: 330,
                            Header: (
                                <Grid
                                    item xs={12}
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent={"center"}
                                    spacing={2}
                                >

                                    <Grid
                                        item xs={6}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent={"flex-end"}
                                    >

                                        <span style={{ fontSize: 14, wordWrap: 'break-word', display: 'inline-block' }}>
                                            {fornecedorNome}
                                        </span>
                                    </Grid>

                                    <Grid
                                        item xs={6}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent={"flex-start"}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={preCotacaoSelecionada === 1}

                                                    onChange={(e) => {
                                                        handleChangePreCotacaoSelected(idPreCotacao)
                                                    }}
                                                    disabled={
                                                        !(parseInt(status_ordem) <= 1)
                                                    }

                                                    inputProps={{ 'aria-label': 'controlled' }}

                                                />
                                            }
                                            label=""
                                        />

                                    </Grid>
                                </Grid>


                            ),

                            columns: [
                                {
                                    accessorFn: row => {
                                        const cotacao = row.fornecedor_insumo[i];
                                        if (cotacao) {
                                            return {
                                                valor_unitario: cotacao.valor_unitario,
                                                descricao_cotacao: cotacao.descricao,
                                                observacoes: cotacao.observacoes,
                                                link: cotacao.link,
                                            };
                                        } else {
                                            return {
                                                valor_unitario: 0.0,
                                                descricao_cotacao: "",
                                                observacoes: "",
                                                link: "",
                                                descricao_insumo: ""
                                            };
                                        }
                                    },
                                    id: `valor_unitario${i + 1}`,
                                    header: 'Valor Unitário',
                                    size: 130,
                                    Cell: ({ renderedCellValue }) => (
                                        <Tooltip
                                            title={
                                                <div style={{ backgroundColor: 'white', fontSize: 14, color: 'black' }}>
                                                    <div style={{ backgroundColor: 'white', fontSize: 14, color: 'black' }}><strong>Descrição da Cotação:</strong> {renderedCellValue.descricao_cotacao}</div>
                                                    <div style={{ backgroundColor: 'white', fontSize: 14, color: 'black' }} ><strong>Observações do Insumo:</strong> {renderedCellValue.observacoes}</div>
                                                    {renderedCellValue.link && <div><a href={renderedCellValue.link} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: 'white', fontSize: 14, color: 'blue' }}>Acessar</a></div>}
                                                </div>
                                            }
                                            placement="top"
                                            arrow
                                            style={{ backgroundColor: 'white', color: 'black' }}
                                        >
                                            <span style={{ maxWidth: '130px', wordWrap: 'break-word', display: 'inline-block' }}>
                                                {currencyFormatter.format(renderedCellValue.valor_unitario)}
                                            </span>
                                        </Tooltip>
                                    ),
                                },
                                {
                                    accessorFn: row => {
                                        const cotacao = row.fornecedor_insumo[i];
                                        const item = row.fornecedor_insumo2[i];


                                        if (cotacao)
                                            return {
                                                valor_parcial: cotacao.valor_unitario * row.quantidade,
                                                selecionado: item.selecionado,
                                                id_item_ordem_compra: item.id_item_ordem_compra,
                                            }
                                        else
                                            return {
                                                valor_parcial: 0.0,
                                                selecionado: 0,
                                                id_cotacao: cotacao.id_cotacao,
                                            }
                                    },
                                    Footer: () => (
                                        <Stack>
                                            <Tooltip
                                                title={
                                                    <div
                                                        style={{ backgroundColor: 'white', border: '2px solid black', color: 'black', padding: 20 }}
                                                    > Valor Itens:
                                                        <Box>
                                                            {currencyFormatter.format(valor_total_por_fornecedor)}
                                                        </Box>
                                                        <br></br>
                                                        Valor Frete:
                                                        <Box>
                                                            {currencyFormatter.format(valor_frete_por_fornecedor)}
                                                        </Box>

                                                    </div>
                                                }
                                                placement="top"
                                                arrow
                                                style={{ backgroundColor: 'white' }}
                                                sx={{ backgroundColor: 'white' }}
                                            >
                                                Valor Total:
                                                <Box>
                                                    {currencyFormatter.format(valor_total_por_fornecedor + valor_frete_por_fornecedor)}
                                                </Box>
                                            </Tooltip>





                                            <br></br><br></br>

                                            <Tooltip
                                                title={
                                                    <div
                                                        style={{ backgroundColor: 'white', border: '2px solid black', color: 'black', padding: 20 }}
                                                    >
                                                        Valor Itens Selecionados:
                                                        <Box>
                                                            {currencyFormatter.format(valor_total_selecionado_por_fornecedor)}
                                                        </Box>
                                                        <br></br>
                                                        Valor Frete:
                                                        <Box>
                                                            {currencyFormatter.format(valor_frete_por_fornecedor)}
                                                        </Box>

                                                    </div>
                                                }
                                                placement="top"
                                                arrow
                                                style={{ backgroundColor: 'white' }}
                                                sx={{ backgroundColor: 'white' }}
                                            >
                                                Valor Total de Itens Selecionados:
                                                <Box>
                                                    {currencyFormatter.format(valor_total_selecionado_por_fornecedor + valor_frete_por_fornecedor)}
                                                </Box>
                                            </Tooltip>





                                        </Stack>
                                    ),
                                    id: `valor_total${i + 1}`,
                                    header: 'Valor Total',
                                    size: 200,
                                    Cell: ({ renderedCellValue }) => (

                                        <Grid
                                            item xs={12}
                                            container
                                            direction="row"
                                            alignItems="center"
                                            justifyContent={"center"}
                                            spacing={2}
                                        >

                                            <Grid
                                                item xs={6}
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justifyContent={"flex-end"}
                                            >

                                                <span style={{ wordWrap: 'break-word', display: 'inline-block' }}>
                                                    {currencyFormatter.format(renderedCellValue.valor_parcial)}
                                                </span>
                                            </Grid>

                                            <Grid
                                                item xs={6}
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justifyContent={"flex-start"}
                                            >
                                                {renderedCellValue.valor_parcial > 0.0 &&
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={renderedCellValue.selecionado === 1}
                                                                onChange={(e) => {
                                                                    handleChangeSelected(renderedCellValue.id_item_ordem_compra);
                                                                    e.target.checked = renderedCellValue.selecionado === 0; // Define imediatamente o estado do Checkbox para o valor oposto
                                                                }}
                                                                disabled={
                                                                    !(parseInt(status_ordem) <= 1)
                                                                }
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label=""
                                                    />
                                                }

                                            </Grid>
                                        </Grid>





                                    ),
                                },
                            ],
                        }
                    );
                }




                setObjetosAnalise(objetosAnaliseLocal);
                setColumnsAnalise(columns);



                setLoading(false);
            });

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }

    const [atualizador, setAtualizador] = useState(0);

    useEffect(() => {

        setIdUsuarioLogado(parseInt(Cookies.get("id_usuario")));

        listarInformacoes();


    }, [atualizador]);






    async function finalizarParaAutorizar() {

        setIsLoading(true); // Ativar o indicador de carregament
        const shouldProceed = window.confirm("Ao finalizar a edição, ela será posta em Ánalise e só poderá ser corrigida após análise de um dos Solicitantes da Ordem de Compra. Deseja prosseguir?");

        if (shouldProceed) {
            setOpen(true);

            try {

                console.log("cadastrar chamado");
                const cadastro = {

                }

                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const response = await api.put('/v1/protected/ordensdecompras/ordemdecompra/finalizarparaautorizar/' + idOrdemCompra,
                    { headers: headers });

                const cadastro_salvo = response.data;

                if (cadastro_salvo) {
                    alert("Ordem foi Fechada para Ánalise dos Solicitantes")
                    history.push({
                        pathname: "/gestao/administracao/ordensdecompras/listar",
                    })
                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }


            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");

            }
        }


    }




    async function handleChangePreCotacaoSelected(id_pre_cotacao) {

        setOpenAtualizando(true);

        try {

            console.log("cadastrar chamado");
            const cadastro = {

            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put("/v1/protected/ordensdecompras/ordemdecompra/atualizarprecotacaoselecionada/" + id_pre_cotacao + "/" + idOrdemCompra,
                { headers: headers });

            const cadastro_salvo = response.data;

            if (cadastro_salvo) {
                recalcular();

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }

            setOpenAtualizando(false);

        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
            setOpenAtualizando(false);

        }

    }


    async function handleChangeSelected(id_item_ordem_compra) {
        setOpenAtualizando(true);

        try {

            console.log("cadastrar chamado");
            const cadastro = {

            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put("/v1/protected/ordensdecompras/ordemdecompra/atualizaritemordemcompraselecionado/" + id_item_ordem_compra + "/" + idOrdemCompra,
                { headers: headers });

            const cadastro_salvo = response.data;

            if (cadastro_salvo) {


                recalcular();


            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }

            setOpenAtualizando(false);

        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
            setOpenAtualizando(false);

        }

    }






    const handleChange = (event, newValue) => {
        setValue(newValue);

    }



    const handleOpenAutorizar = (event, newValue) => {
        console.log("open autorizar chamado!");
        setOpenAutorizar(true);
    }





    const handleChangeIndex = (index) => {
        setValue(index);
    };



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };






    function verificarSolicitante() {
        const id_usuario = parseInt(Cookies.get("id_usuario"));

        if (!id_usuario) {
            // Se o ID do usuário não estiver definido no cookie, retorne falso
            return false;
        }

        // Verifique cada solicitante selecionado
        for (let i = 0; i < solicitantesSelecionados.length; i++) {
            const solicitante = solicitantesSelecionados[i];
            if (parseInt(solicitante.id_usuario) === id_usuario) {
                // Se o ID do solicitante for igual ao ID do usuário atual, retorne verdadeiro
                return true;
            }
        }

        // Se nenhum dos IDs dos solicitantes for igual ao ID do usuário, retorne falso
        return false;
    }






    function returnDescricaoFornecedor(fornecedor) {

        var identificacao = fornecedor.tipo_fornecedor === 0 ? "CPF " : "CNPJ;"

        var valor = fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj;

        var nome = fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) :
            (fornecedor.nome_fantasia);

        var endereco = fornecedor.logradouro + ", nº " + fornecedor.numero + ", " + fornecedor.bairro + ", " + fornecedor.cidade + " " + fornecedor.estado + " CEP: " + fornecedor.cep;


        var texto = "ID: " + fornecedor.id_fornecedor +
            "\n " + nome +
            + "\n" + identificacao + " " + valor + "\n Endereço: " + endereco;
        return texto;
    }

    const responseMessage = (response) => {
        //console.log(response);
    };
    const errorMessage = (error) => {
        // console.log(error);
    };


    const [openAutorizar, setOpenAutorizar] = useState(false)
    const [codigoValidacao, setCodigoValidacao] = useState("");


    const [cotacaoEscolhida, setCotacaoEscolhida] = useState(-1);
    const [openDialogMotivo, setOpenDialogMotivo] = useState(false);



    function handleCloseDialogMotivo() {
        setCotacaoEscolhida(-1);
        setOpenDialogMotivo(false);
    }

    function handleClickMotivoEscolha(escolha) {
        setCotacaoEscolhida(escolha);
        setOpenDialogMotivo(true);
    }

    async function autorizar() {

        try {

            setOpen(true);
            const id_usuario = Cookies.get('id_usuario');

            console.log("id usuario: " + id_usuario);



            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            /*
            const response = await api.post('/v1/protected/validarparaautorizar/key/' + id_usuario + "/" + parseInt(codigoValidacao),
                { headers: headers });

            const valido = response.data.valid;*/
            if (true) {


                const response = await api.put('/v1/protected/ordensdecompras/ordemdecompra/autorizar/' + idOrdemCompra + "/" + Cookies.get("id_usuario"),
                    { headers: headers });

                const cadastro_salvo = response.data;

                if (cadastro_salvo) {
                    alert("Autorização Concluída, aguarde autorizações dos demais Solicitantes ou Diretores!");
                    window.location.reload();
                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }



            } else {
                alert("Código Inválido!");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }

    }

    function returnDescricaoSolicitante(ordem_compra_solicitante) {
        var texto = "ID_Usuario: " + ordem_compra_solicitante.solicitante.id_usuario + "\n Nome: " + ordem_compra_solicitante.solicitante.nome_amigavel;
        return texto;
    }


    function returnDescricaoUsuario(usuario) {
        var texto = "ID_Usuario: " + usuario.id_usuario + "\n Nome: " + usuario.nome_amigavel;
        return texto;
    }


    function returnDescricaoOrdem(ordem) {
        var texto = "ID: " + ordem.id_ordem_servico + "\n Solicitação: " + ordem.solicitacao.solicitacao;
        return texto;
    }


    async function escolher() {



        const shouldProceed = window.confirm("Ao finalizar a Ánalise Orçamentária, a Ordem de Compra será posta em status de autorização, após a autorização será enviada para Faturamento, prosseguir?");

        if (shouldProceed) {

            setBotaoEscolherFaturamentoCliquado(true);

            try {

                console.log("cadastrar chamado");
                const cadastro = {
                    dado1: motivoEscolha,
                }

                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const response = await api.put('/v1/protected/ordensdecompras/ordemdecompra/finalizaranaliseorcamentaria/' + idOrdemCompra + "/" + Cookies.get("id_usuario"), cadastro,
                    { headers: headers });

                const cadastro_salvo = response.data;

                if (cadastro_salvo) {
                    alert("Ordem aguardando todas as autorizações necessárias!")
                    window.location.reload();
                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }


            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");
                setBotaoEscolherFaturamentoCliquado(false);

            }
        }

    }



    function novaPreCotacao() {

        var id_usuario_logado = parseInt(Cookies.get("id_usuario"));

        if (ordemCompra.usuario.id_usuario === id_usuario_logado)
            window.location.href = "/gestao/administracao/ordensdecompras/editar/" + idOrdemCompra + "/cadastrarcotacao";
        else {
            alert("Somente o usuário criador da Ordem pode fazer esta operação!");
            return;
        }
    }


    const handleClickEdicaoPreCotacao = (cellValues) => {

        window.location.href = "/gestao/administracao/ordensdecompras/editar/" + idOrdemCompra + "/editarcotacao/" + cellValues.row.id_pre_cotacao;

    };

    const handleClickEdicaoCompra = (cellValues) => {

        window.location.href = "/gestao/administracao/ordensdecompras/editar/" + idOrdemCompra + "/editarcompra/" + cellValues.row.id_pre_cotacao;

    };


    async function handleClickEdicaoRemoverPreCotacao(cellValues) {



        const shouldProceed = window.confirm("Remover a Pré-Cotação?");

        if (shouldProceed) {

            try {

                console.log("cadastrar chamado");
                const cadastro = {
                    dado1: motivoEscolha,
                }

                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const response = await api.delete("/v1/protected/ordensdecompras/ordemdecompra/removerprecotacao/" + idOrdemCompra + "/" + cellValues.row.id_pre_cotacao,
                    { headers: headers });

                const cadastro_salvo = response.data;

                if (cadastro_salvo) {
                    alert("Pré-Cotação Removida!");
                    window.location.reload();
                } else {
                    alert("Erro de Conexão, tente novamente");
                }


            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente!");
            }
        }


    }



    async function baixarAnexoPreCotacao(preCotacao) {
        try {
            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const response = await api.post("/v1/protected/ordensdecompras/precotacao/baixaranexo", { caminho: encodeURIComponent(preCotacao.caminho_arquivo_fornecedor) }, {
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });


            const extensao = preCotacao.caminho_arquivo_fornecedor.split('.').pop().toLowerCase(); // Obtém a última parte do caminho após o último ponto e converte para minúsculas


            // Determina o tipo de mídia com base na extensão do arquivo
            let mediaType = 'application/octet-stream'; // Define um tipo padrão
            switch (extensao) {
                case 'pdf':
                    mediaType = 'application/pdf';
                    break;
                case 'doc':
                    mediaType = 'application/msword';
                    break;
                case 'docx':
                    mediaType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                    break;
                case 'xls':
                    mediaType = 'application/vnd.ms-excel';
                    break;
                case 'xlsx':
                    mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    break;
                // Adicione outros tipos de arquivo conforme necessário
            }

            const blob = new Blob([response.data], { type: mediaType });

            // Cria uma URL do blob
            const blobUrl = URL.createObjectURL(blob);

            // Obtém o nome do arquivo a partir do caminho
            const nomeArquivo = preCotacao.caminho_arquivo_fornecedor.split('/').pop(); // Obtém a última parte do caminho após a última barra

            // Cria um link <a> temporário
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = nomeArquivo; // Define o nome do arquivo a ser baixado
            link.click(); // Simula um clique no link para iniciar o download
        } catch (error) {
            // Trate os erros conforme necessário
            console.log("Erro ao baixar o arquivo: " + error);
        }
    }


    const columnsPreCotacaoDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {
                return (
                    <div>
                        <IconButton onClick={() => handleClickEdicaoPreCotacao(cellValues)}>
                            <EditIcon />
                        </IconButton>
                        {ordemCompra.status === 0 &&
                            <IconButton onClick={() => handleClickEdicaoRemoverPreCotacao(cellValues)}>
                                <Delete />
                            </IconButton>
                        }

                    </div>
                )
            },
        },


        {
            headerName: 'ID',
            field: 'id_pre_cotacao',
            id: 1,
            width: 50,
            headerClassName: 'cabecalho_azul',
        },

        {
            id: 2,
            headerName: 'Arquivo',
            width: '50',
            sortable: false,
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {
                return (
                    <div >
                        {
                            cellValues.row.caminho_arquivo_fornecedor ? (
                                <div
                                    onClick={() => {
                                        baixarAnexoPreCotacao(cellValues.row);
                                    }}
                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '30px', height: '30px' }}
                                >
                                    {/* Verifica a extensão do arquivo e exibe a miniatura ou o ícone correspondente */}
                                    {cellValues.row.caminho_arquivo_fornecedor.toLowerCase().endsWith('.pdf') ? (
                                        <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf} />
                                    ) : (
                                        <img alt={'office'} style={{ textAlign: 'center' }} src={office} />
                                    )}

                                </div>
                            ) : (
                                <div >

                                </div>
                            )
                        }
                    </div>
                )

            },
        },

        {
            field: 'fornecedor',
            id: 3,
            headerName: 'Fornecedor',
            minWidth: '250',
            headerClassName: 'cabecalho_azul',
            type: 'number',
            renderCell: (cellValues) => {
                var fornecedor = cellValues.row.fornecedor
                var texto = + fornecedor.id_fornecedor
                    + "\n" + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia)
                    + "\nCPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj);

                return (
                    <div >
                        {
                            texto
                        }
                    </div>
                )

            },
        },


        {
            field: 'itens_pre_cotacao',
            id: 4,
            headerName: 'Itens',
            minWidth: '700',
            headerClassName: 'cabecalho_azul',
            type: 'number',
            valueFormatter: (params) => {
                var itens = params.value;
                var texto = '';

                if (Array.isArray(itens) && itens.length > 0) {
                    itens.forEach((item, index) => {
                        if (index !== 0) {
                            texto += ', ';
                        }
                        texto += item.insumo.descricao;
                    });
                }

                return texto;
            },
        },

        {
            field: 'valor_frete',
            id: 5,
            headerName: 'Valor do Frete',
            minWidth: '100',
            headerClassName: 'cabecalho_azul',
            type: 'number',
            valueFormatter: (params) => {
                var valor = params.value;
                return currencyFormatter.format(valor);
            },
        },



    ];

    function handleClickEditarListaRequerimentos() {


        if (ordemCompra.ordem_servico?.itens_pre_requerimento) {
            //ordem de compra com ordem de servico e deve ser editado pelo criador da OS

        } else {
            //ordem de compra criada sem OS anexada

            var id_usuario_logado = parseInt(Cookies.get("id_usuario"));

            if (ordemCompra.usuario.id_usuario === id_usuario_logado) {

                const url = "/gestao/administracao/ordensdecompras/" + ordemCompra.id_ordem_compra + "/editarlistarequerimentos";
                window.location.href = url;
            } else {
                alert("Somente o usuário criador da Ordem pode fazer esta operação!");
                return;
            }

        }


    }




    function handleClickGerenciarLancamento(id_lancamento) {
        const url = `/gestao/financas/lancamento/gerenciar/${id_lancamento}`;
        window.open(url, '_blank');
    }


    const columnsComprasDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 50,
            renderCell: (cellValues) => {
                return (
                    <div>
                        <IconButton onClick={() => handleClickEdicaoCompra(cellValues)}>
                            <EditIcon />
                        </IconButton>


                    </div>
                )
            },
        },


        {
            headerName: 'ID',
            field: 'id_pre_cotacao',
            id: 2,
            width: 50,
            headerClassName: 'cabecalho_azul',
        },

        {
            id: 3,
            field: 'arquivo', // Adicionei um campo único aqui
            headerName: 'Arquivo',
            width: 50,
            sortable: false,
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {
                return (
                    <div >
                        {
                            cellValues.row.caminho_arquivo ? (
                                <div
                                    onClick={() => {
                                        baixarArquivo(cellValues.row.caminho_arquivo);
                                    }}
                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '30px', height: '30px' }}
                                >
                                    {/* Verifica a extensão do arquivo e exibe a miniatura ou o ícone correspondente */}
                                    {cellValues.row.caminho_arquivo.toLowerCase().endsWith('.pdf') &&
                                        <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf} />
                                    }

                                </div>
                            ) : (
                                <div >

                                </div>
                            )
                        }
                    </div>
                )

            },
        },

        {
            id: 4,
            field: 'informacoes', // Adicionei um campo único aqui
            headerName: 'Informações',
            minWidth: 50,
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {

                const envios = cellValues.row.registros_envio;



                return (
                    <Tooltip
                        title={
                            <div style={{ backgroundColor: 'white', padding: 20, color: 'black', size: 16, minHeight: '50px', minWidth: '400px', border: '2px solid black' }}>

                                <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', paddingTop: 20 }}>
                                    <strong>Código da Compra:</strong> {cellValues.row.codigo_compra}
                                    {cellValues.row.link_compra &&
                                        <a
                                            href={cellValues.row.link_compra}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Typography variant="body2" style={{ paddingLeft: 10 }}>Acessar Compra</Typography>
                                        </a>
                                    }
                                </Typography>
                                <br></br>
                                <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', paddingTop: 20 }}>
                                    <strong>Código do Rastreio:</strong> {cellValues.row.codigo_rastreio}
                                    {cellValues.row.link_rastreio &&
                                        <a
                                            href={cellValues.row.link_rastreio}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Typography variant="body2" style={{ paddingLeft: 10 }}>Acessar Rastreio</Typography>
                                        </a>
                                    }
                                </Typography>
                                <br></br>
                                <Typography variant="body2">
                                    <strong>Observações:</strong> {cellValues.row.observacao_compra}
                                </Typography>
                                <br></br>

                                {envios.map((envio, index) => (
                                    <Card key={index} style={{ marginBottom: '10px', borderRadius: '8px', border: '1px solid gray' }}>
                                        <CardContent>
                                            <Typography variant="body2">
                                                <strong>Vendedor:</strong> {envio.vendedor.nome_contato}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Plataforma de Envio:</strong> {envio.plataforma_envio === 0 ? 'WhatsApp' : 'Email'}
                                            </Typography>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <strong>Enviado:</strong> {envio.enviado === 1 ? <Email /> : <EmailOutlined />}
                                                </Typography>
                                                {envio.enviado === 1 && (
                                                    <Typography variant="body2" style={{ marginLeft: '8px' }}>
                                                        {moment(envio.data_hora_envio).format('DD/MM/YYYY HH:mm')}
                                                    </Typography>
                                                )}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <strong>Visualizado:</strong> {envio.vizualizado === 1 ? <Visibility /> : <VisibilityOff />}
                                                </Typography>
                                                {envio.vizualizado === 1 && (
                                                    <Typography variant="body2" style={{ marginLeft: '8px' }}>
                                                        {moment(envio.data_hora_vizualizado).format('DD/MM/YYYY HH:mm')}
                                                    </Typography>
                                                )}
                                            </div>
                                        </CardContent>
                                    </Card>
                                ))}
                            </div>
                        }
                        placement="top"
                        arrow
                        style={{ backgroundColor: 'white' }}
                        sx={{ backgroundColor: 'white' }}
                    >
                        <span>Informações</span>
                    </Tooltip>

                );
            },

        },


        {
            field: 'fornecedor',
            id: 5,
            headerName: 'Fornecedor',
            minWidth: 250,
            headerClassName: 'cabecalho_azul',
            type: 'number',
            renderCell: (cellValues) => {
                var fornecedor = cellValues.row.fornecedor
                var texto = + fornecedor.id_fornecedor
                    + "\n" + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia)
                    + "\nCPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj);

                return (
                    <div >
                        {
                            texto
                        }
                    </div>
                )

            },
        },


        {
            field: 'itens_pre_cotacao',
            id: 6,
            headerName: 'Itens',
            minWidth: 200,
            headerClassName: 'cabecalho_azul',
            type: 'number',
            valueFormatter: (params) => {
                var itens = params.value;
                var texto = '';

                if (Array.isArray(itens) && itens.length > 0) {
                    itens.forEach((item, index) => {
                        if (index !== 0) {
                            texto += ', ';
                        }
                        texto += item.insumo.descricao;
                    });
                }

                return texto;
            },
          
            
        },

        {
            field: 'valor_frete',
            id: 7,
            headerName: 'Valor do Frete',
            minWidth: 100,
            headerClassName: 'cabecalho_azul',
            type: 'number',
            valueFormatter: (params) => {
                var valor = params.value;
                return currencyFormatter.format(valor);
            },
        },

        {
            field: 'valor_total',
            id: 8,
            headerName: 'Valor dos Itens',
            minWidth: 100,
            headerClassName: 'cabecalho_azul',
            type: 'number',
            valueFormatter: (params) => {
                var valor = params.value;
                return currencyFormatter.format(valor);
            },
        },

        {
            field: 'valor_final',
            id: 9,
            headerName: 'Valor Total',
            minWidth: 100,
            headerClassName: 'cabecalho_azul',
            type: 'number',
            renderCell: (cellValues) => {
                const valor = parseFloat(cellValues.row.valor_frete) + parseFloat(cellValues.row.valor_total);
                return currencyFormatter.format(valor);
            },
        },

        {
            field: 'lancamento',
            id: 9,
            headerName: 'Lançamento',
            minWidth: 100,
            headerClassName: 'cabecalho_azul',
            type: 'number',
            renderCell: (cellValues) => {
                return (
                    <div>
                        <IconButton onClick={() => handleClickGerenciarLancamento(cellValues.row.id_lancamento)}>
                            <MonetizationOnIcon />
                        </IconButton>
                    </div>
                );
            },
        },



    ];



    const handleClickVizualizarProcedimento = (id) => {
        //   window.open(`/gestao/administracao/ordensdeservicos/vizualizarprocedimentoexecucao/${id}/ordemservico/${ordemServico.id_ordem_servico}`, '_blank');
    };


    const handleClickAtualizarProcedimento = (procedimento) => {

        window.location.href = "/gestao/administracao/ordensdecompras/" + ordemCompra.id_ordem_compra + "/atualizarprocedimentoexecucao/" + procedimento.id_procedimento_execucao;

    };



    function returnDescricaoUnidade(unidade) {



        var nome = unidade.nome_fantasia;

        var endereco = unidade.logradouro + ", nº " + unidade.numero + ", " + unidade.bairro + ", " + unidade.cidade + " " + unidade.estado + " CEP: " + unidade.cep;


        var texto = "ID: " + unidade.id_unidade + "\nCNPJ: " + unidade.cnpj +
            "\n " + nome + "\n Endereço: " + endereco;
        return texto;
    }




    function returnDescricaoInsumo(insumo) {

        // Se não houver correspondência, retorna a descrição principal do insumo
        return insumo.descricao;
    }


    function returnDescricaoInsumoSimplificada(insumo) {


        var texto =


            "Descrição: " + insumo.descricao;
        return texto;
    }

    const columnsItensDataGrid = [

        {
            headerName: 'Insumo',
            field: 'insumo',
            id: 2,
            width: 700,
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {
                const insumo = cellValues.row.insumo;



                let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao + "\n\n";

                if (insumo.variacoes && insumo.variacoes.length > 0) {
                    textoComQuebraDeLinha += insumo.variacoes

                        .map((variacao, index) => (
                            `Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                        )).join('');
                } else {
                    return (
                        <span>{returnDescricaoInsumo(insumo)}</span>
                    );
                }

                return (
                    <Tooltip
                        title={
                            <div style={{ backgroundColor: 'white', color: 'black', size: 16, minHeight: '50px', minWidth: '400px', border: '2px solid black' }}>
                                <span>
                                    {textoComQuebraDeLinha.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </span>
                            </div>
                        }
                        placement="top"
                        arrow
                        style={{ backgroundColor: 'white' }}
                        sx={{ backgroundColor: 'white' }}
                    >
                        <span>{returnDescricaoInsumo(insumo)}</span>
                    </Tooltip>
                );
            },
        },


        {
            headerName: 'Quantidade',
            field: 'quantidade',
            id: 3,
            width: 80,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Observações',
            field: 'observacoes',
            id: 4,
            width: 200,
            headerClassName: 'cabecalho_azul',
        },

    ];



    const [isOpen, setIsOpen] = useState(false);
    const [cotacaoNovaTarefa, setCotacaoNovaTarefa] = useState(null);

    const openModal = (cotacao) => {
        if (cotacao) {
            setCotacaoNovaTarefa(cotacao);
            setIsOpen(true);
        }
    };

    const closeModal = () => {
        setIsOpen(false);
        setCotacaoNovaTarefa(null);
    };


    const [isOpenResposta, setIsOpenResposta] = useState(false);
    const [tarefaResponder, setTarefaResponder] = useState(null);

    const openModalResposta = (tarefa) => {
        if (tarefa) {
            console.log("id da tarefa a responder: " + tarefa.id_tarefa);
            setTarefaResponder(tarefa);
            setIsOpenResposta(true);
        }
    };

    const closeModalResposta = () => {
        setIsOpenResposta(false);
        setTarefaResponder(null);
    };



    const CustomDialog = ({ cotacao }) => {
        const [mensagem, setMensagem] = useState('');



        const handleMensagemChange = (event) => {
            setMensagem(event.target.value);
        };

        async function cadastrarTarefa() {


            console.log("id do cotacao a criar nova tarefa: " + cotacao.id_cotacao);


            try {

                console.log("cadastrar chamado");
                const cadastro_tarefa = {

                    mensagem: mensagem,
                    usuario_criador: { id_usuario: Cookies.get("id_usuario") },
                    id_ordem_compra: ordemCompra.id_ordem_compra,
                    id_cotacao: cotacao.id_cotacao,
                    usuario_destinado: { id_usuario: ordemCompra.usuario.id_usuario },


                }



                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const response = await api.post('/v1/protected/almoxarifado/cotacao/' + cotacao.id_cotacao + "/cadastrartarefa", cadastro_tarefa,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {
                    alert("Tarefa Cadastrada!");
                    closeModal();

                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }


            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");

            }
        }


        return (

            <Dialog open={isOpen} onClose={closeModal} PaperProps={{ style: { maxWidth: '600px', minWidth: '300px' } }}>
                <DialogTitle>Nova Tarefa</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{ paddingTop: 20 }}>
                            <TextField
                                label="Mensagem"
                                variant="outlined"
                                fullWidth
                                value={mensagem}
                                onChange={handleMensagemChange}
                                multiline
                                minRows={3}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={cadastrarTarefa} variant="contained" color="primary">
                                ok
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );

    };



    const CustomDialogResposta = ({ tarefa }) => {
        const [resposta, setResposta] = useState('');



        const handleRespostaChange = (event) => {
            setResposta(event.target.value);
        };

        async function responderTarefa() {



            try {

                console.log("cadastrar chamado");
                const cadastro_tarefa = {

                    resposta: resposta,
                }



                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const response = await api.post('/v1/protected/almoxarifado/cotacao/respondertarefa/' + tarefa.id_tarefa, cadastro_tarefa,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {
                    alert("Tarefa Finalizada!");
                    closeModalResposta();

                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }


            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");

            }



        }



        return (

            <Dialog open={isOpenResposta} onClose={closeModalResposta} PaperProps={{ style: { maxWidth: '600px', minWidth: '300px' } }}>
                <DialogTitle>Finalizar Tarefa</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{ paddingTop: 20 }}>
                            <TextField
                                label="Questão"
                                variant="outlined"
                                fullWidth
                                disabled
                                value={tarefa.mensagem}
                                multiline
                                minRows={1}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 20 }}>
                            <TextField
                                label="Resposta"
                                variant="outlined"
                                fullWidth
                                value={resposta}
                                onChange={handleRespostaChange}
                                multiline
                                minRows={3}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={responderTarefa} variant="contained" color="primary">
                                Finalizar
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    };

    // Função para filtrar preCotacoes
    const filtrarPreCotacoes = (preCotacoes) => {
        return preCotacoes.filter(preCotacao =>
            preCotacao.itens_pre_cotacao.some(item => item.selecionado === 1)
        );
    };



    return (
        <div style={{ width: '100%', margin: 0, padding: 0 }}>

            <NavegadorGestao
                Gestao={true}
                OrdensCompras={true}
                corFundo={'white'} travado={true} corTexto={'black'}
                style={{ width: '100%', margin: 0, padding: 0 }}

            />

            <div style={{ paddingTop: 5, width: '100%', margin: 0, backgroundColor: 'white' }} >
                {loading
                    ?
                    <div></div>

                    :

                    <div style={{ width: '100%', margin: 0, padding: 0 }}>

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                            style={{ width: '100%', margin: 0, padding: 0 }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={12}
                                style={{ width: '100%', margin: 0, padding: 0 }}
                            >

                                <a href="/gestao/administracao/ordensdecompras/listar" style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"< Ordens de Compras"}</a>
                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                                style={{ width: '100%', margin: 0, padding: 0 }}
                            >


                                <Box style={{ width: '100%', margin: 0, padding: 0 }}>
                                    <AppBar position="static"
                                        style={{ width: '100%', margin: 0, padding: 0 }}
                                    >

                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                            style={{ width: '100%', margin: 0, padding: 0 }}
                                        >
                                            <Tab label="Especificações" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Cotações" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Análise Orçamentária" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Compras" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Procedimentos" style={{ backgroundColor: '#1C1C1C' }} />


                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>


                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                style={{ width: '100%', margin: 0, padding: 0 }}
                                justifyContent={"center"}
                            >

                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ width: '100%', margin: 0, padding: 0 }}
                                >
                                    <Grid value={value} index={0}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ width: '100%', alignItems: 'center', }}
                                    >

                                        <Grid
                                            style={{ paddingTop: 10 }}
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="center"
                                        >

                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="standard"
                                                    name="descricao"
                                                    label="Descrição"
                                                    id="descricao"
                                                    value={descricao}
                                                    onChange={(e) => setDescricao(e.target.value)}
                                                    fullWidth
                                                    multiline
                                                    minRows={2}
                                                />
                                            </Grid>

                                        </Grid>

                                        {ordemCompra.usuario &&
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={6}
                                                style={{ margin: 5 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={12}
                                                >

                                                    <Autocomplete
                                                        id="select-ordem-usuario"
                                                        options={[ordemCompra.usuario]}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(usuario) => returnDescricaoUsuario(usuario).replace(/<br \/>/g, '\n')}
                                                        value={ordemCompra.usuario}
                                                        getOptionSelected={(option, value) => true}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Usuário Criador " multiline minRows={3} />
                                                        )}
                                                        renderOption={(option) => (
                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoUsuario(option) }} />
                                                        )}

                                                    />
                                                </Grid>
                                            </Grid>
                                        }


                                        {unidadeSelecionada && (
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={6}
                                                style={{ margin: 5 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={12}
                                                >

                                                    <Autocomplete
                                                        id="select-ordem-unidade"
                                                        options={[unidadeSelecionada]}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(unidade) => returnDescricaoUnidade(unidade).replace(/<br \/>/g, '\n')}
                                                        value={unidadeSelecionada}
                                                        getOptionSelected={(option, value) => true}

                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Unidade Administrativa " multiline minRows={3} />
                                                        )}
                                                        renderOption={(option) => (
                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoUnidade(option) }} />
                                                        )}

                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                        }


                                        {ordemServicoSelecionada && (
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={6}
                                                style={{ margin: 5 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={12}
                                                >

                                                    <Autocomplete
                                                        id="select-ordem-servico"
                                                        options={[ordemServicoSelecionada]}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(ordem) => returnDescricaoOrdem(ordem).replace(/<br \/>/g, '\n')}
                                                        value={ordemServicoSelecionada}
                                                        getOptionSelected={(option, value) => true}
                                                        filterOptions={(options, { inputValue }) => {
                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                            return options.filter((ordem) => {
                                                                const cotacaoText = returnDescricaoOrdem(ordem).toLowerCase();
                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                            });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Ordem de Serviço " multiline minRows={3} />
                                                        )}
                                                        renderOption={(option) => (
                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoOrdem(option) }} />
                                                        )}

                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                        }

                                        {solicitantesSelecionados && (

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={6}
                                                style={{ margin: 5 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={12}
                                                >

                                                    <Autocomplete
                                                        id="select-procedimentos"
                                                        options={[solicitantesSelecionados]}
                                                        disableCloseOnSelect
                                                        multiple
                                                        getOptionLabel={(solicitante) => returnDescricaoSolicitante(solicitante).replace(/<br \/>/g, '\n')}
                                                        value={solicitantesSelecionados}
                                                        getOptionSelected={(option, value) => true}

                                                        filterOptions={(options, { inputValue }) => {
                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                            return options.filter((solicitante) => {
                                                                const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                            });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Solicitantes " multiline minRows={3} />
                                                        )}
                                                        renderOption={(option) => (
                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                                        )}

                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                        }

                                    </Grid>



                                    <Grid value={value} index={1}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ width: '100%', margin: 0, padding: 0 }}
                                    >


                                        <Grid
                                            container
                                            direction="row"
                                            item xs={1}
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            style={{ width: '100%', margin: 0, padding: 0 }}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                item xs={12}
                                                style={{ marginTop: 5 }}
                                            >
                                                {ordemCompra.ordem_servico ?
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        align="flex-start"
                                                        item xs={6}
                                                        style={{ marginTop: 5 }}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                <div
                                                                    style={{ backgroundColor: 'white', minHeight: '400px', minWidth: '1000px', border: '2px solid black' }}
                                                                >

                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        item xs={12}
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        {
                                                                            ordemCompra.ordem_servico?.itens_pre_requerimento &&
                                                                            <>
                                                                                <DataGrid
                                                                                    rows={ordemCompra.ordem_servico.itens_pre_requerimento}
                                                                                    getRowId={(row) => row.id_item_requerimento}
                                                                                    columns={columnsItensDataGrid}
                                                                                    disableColumnResize={false}
                                                                                    rowHeight={30}

                                                                                />

                                                                            </>
                                                                        }



                                                                    </Grid>
                                                                </div>
                                                            }
                                                            placement="right"
                                                            arrow
                                                            style={{ backgroundColor: 'white' }}
                                                            sx={{ backgroundColor: 'white' }}
                                                        >
                                                            <IconButton aria-label="Requerimento de Itens" style={{ color: 'black', fontSize: 12 }}>
                                                                <HelpOutlineIcon color='blue' >
                                                                </HelpOutlineIcon>
                                                                Requerimento de Itens
                                                            </IconButton>
                                                        </Tooltip>

                                                    </Grid>
                                                    :
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        align="flex-start"
                                                        item xs={6}
                                                        style={{ marginTop: 5 }}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                <div
                                                                    style={{ backgroundColor: 'white', minHeight: '400px', minWidth: '1000px', border: '2px solid black' }}
                                                                >

                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        item xs={12}
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >

                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            item xs={12}
                                                                            alignItems="flex-start"
                                                                            justifyContent="flex-start"
                                                                        >
                                                                            <IconButton aria-label="Editar Lista de Requerimentos" style={{ color: 'green', fontSize: 12 }} onClick={handleClickEditarListaRequerimentos}>
                                                                                <EditIcon color='green' />
                                                                                Editar Lista
                                                                            </IconButton>
                                                                        </Grid>

                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            item xs={12}
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >


                                                                            {
                                                                                ordemCompra.itens_pre_requerimento &&
                                                                                <>
                                                                                    <DataGrid
                                                                                        rows={ordemCompra.itens_pre_requerimento}
                                                                                        getRowId={(row) => row.id_item_requerimento}
                                                                                        columns={columnsItensDataGrid}
                                                                                        disableColumnResize={false}
                                                                                        rowHeight={30}

                                                                                    />

                                                                                </>
                                                                            }


                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            }
                                                            placement="right"
                                                            arrow
                                                            style={{ backgroundColor: 'white' }}
                                                            sx={{ backgroundColor: 'white' }}
                                                        >
                                                            <IconButton aria-label="Requerimento de Itens" style={{ color: 'black', fontSize: 12 }}>
                                                                <HelpOutlineIcon color='blue' >
                                                                </HelpOutlineIcon>
                                                                Requerimento de Itens
                                                            </IconButton>

                                                        </Tooltip>

                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            item xs={11}
                                        >

                                            {ordemCompra.status === 0 && (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                    item xs={12}
                                                >

                                                    <Button
                                                        style={{ margin: 5 }}
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<AddIcon />}
                                                        onClick={() => novaPreCotacao()} // Substitua handleNovoOrcamento pelo seu manipulador de evento
                                                    >
                                                        Orçamento
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>

                                        {preCotacoes && (
                                            <>
                                                <DataGrid
                                                    pagination
                                                    checkboxSelection
                                                    rows={preCotacoes}
                                                    getRowId={(row) => row.id_pre_cotacao}
                                                    columns={columnsPreCotacaoDataGrid}
                                                    disableColumnResize={false}
                                                    onCellClick={handleCellClick}
                                                    onRowClick={handleRowClick}
                                                    components={{
                                                        Toolbar: CustomToolbar,
                                                    }}
                                                />
                                            </>
                                        )}





                                        {ordemCompra.status === 0 && idUsuarioLogado === idUsuarioCriador && (
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                item xs={12}
                                            >
                                                <Button style={{ margin: 5, marginTop: 5 }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={finalizarParaAutorizar}
                                                > Finalizar para Autorização  </Button>
                                            </Grid>
                                        )}



                                    </Grid>


                                    <Grid
                                        value={value} index={2}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ width: '100%', margin: 0, padding: 0 }}
                                    >

                                        {ordemCompra.ordem_servico ?
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                align="flex-start"
                                                item xs={6}
                                                style={{ marginTop: 5 }}
                                            >
                                                <Tooltip
                                                    title={
                                                        <div
                                                            style={{ backgroundColor: 'white', minHeight: '400px', minWidth: '1000px', border: '2px solid black' }}
                                                        >

                                                            <Grid
                                                                container
                                                                direction="row"
                                                                item xs={12}
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                {
                                                                    ordemCompra.ordem_servico?.itens_pre_requerimento &&
                                                                    <>
                                                                        <DataGrid
                                                                            rows={ordemCompra.ordem_servico.itens_pre_requerimento}
                                                                            getRowId={(row) => row.id_item_requerimento}
                                                                            columns={columnsItensDataGrid}
                                                                            disableColumnResize={false}
                                                                            rowHeight={30}

                                                                        />

                                                                    </>
                                                                }



                                                            </Grid>
                                                        </div>
                                                    }
                                                    placement="right"
                                                    arrow
                                                    style={{ backgroundColor: 'white' }}
                                                    sx={{ backgroundColor: 'white' }}
                                                >
                                                    <IconButton aria-label="Requerimento de Itens" style={{ color: 'black', fontSize: 12 }}>
                                                        <HelpOutlineIcon color='blue' >
                                                        </HelpOutlineIcon>
                                                        Requerimento de Itens
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            :
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                align="flex-start"
                                                item xs={6}
                                                style={{ marginTop: 5 }}
                                            >
                                                <Tooltip
                                                    title={
                                                        <div
                                                            style={{ backgroundColor: 'white', minHeight: '400px', minWidth: '1000px', border: '2px solid black' }}
                                                        >

                                                            <Grid
                                                                container
                                                                direction="row"
                                                                item xs={12}
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                {
                                                                    ordemCompra.itens_pre_requerimento &&
                                                                    <>
                                                                        <DataGrid
                                                                            rows={ordemCompra.itens_pre_requerimento}
                                                                            getRowId={(row) => row.id_item_requerimento}
                                                                            columns={columnsItensDataGrid}
                                                                            disableColumnResize={false}
                                                                            rowHeight={30}

                                                                        />

                                                                    </>
                                                                }



                                                            </Grid>
                                                        </div>
                                                    }
                                                    placement="right"
                                                    arrow
                                                    style={{ backgroundColor: 'white' }}
                                                    sx={{ backgroundColor: 'white' }}
                                                >
                                                    <IconButton aria-label="Requerimento de Itens" style={{ color: 'black', fontSize: 12 }}>
                                                        <HelpOutlineIcon color='blue' >
                                                        </HelpOutlineIcon>
                                                        Requerimento de Itens
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        }

                                        {(ordemCompra.status === 1 && (isSolicitante || isSupervisor)) ?
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="flex-end"
                                                item xs={6}
                                            >
                                                <Tooltip title="Encerrar Análise">
                                                    <Button onClick={(e) => setOpenDialogMotivo(true)}>
                                                        <DoneIcon style={{ margin: 5, marginTop: 5 }} />
                                                        <span style={{ fontSize: 12, color: 'black' }}>Encerrar Análise</span>
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                            :
                                            (isSolicitante || isSupervisor) && !autorizou && ordemCompra.status >= 2 ?

                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-end"
                                                    item xs={6}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleOpenAutorizar}
                                                    > Autorizar  </Button>
                                                </Grid>
                                                :
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-end"
                                                    item xs={6}
                                                >
                                                </Grid>



                                        }




                                        <>
                                            {objetosAnalise && (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{ width: '100%', margin: 0, padding: 0 }}
                                                >
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        item xs={12}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        style={{ width: '100%', margin: 0, padding: 0, color: 'black' }}
                                                    >
                                                        <MaterialReactTable
                                                            columns={columnsAnalise}
                                                            data={objetosAnalise}
                                                            enableTopToolbar={false}
                                                            enableBottomToolbar={false}
                                                            enablePagination={false}
                                                            enableColumnFilters={false}
                                                            enableColumnActions={false}
                                                            sortable={false}
                                                            enableSorting={false}
                                                            enableRowSelection={false}
                                                            color={'black'}
                                                            paginationDisplayMode={'pages'}
                                                            getRowId={(row) => row.id_item_analise}
                                                            initialState={{
                                                                pagination: { pageSize: 20, pageIndex: 0 },
                                                                showGlobalFilter: true,
                                                                color: 'black'
                                                            }}
                                                            muiTableContainerProps={{ sx: { maxHeight: "1000px", color: 'black' } }}
                                                            muiTableBodyRowProps={{
                                                                sx: {
                                                                    height: 40,
                                                                    color: 'black' // Define a cor do texto para preto
                                                                },
                                                            }}
                                                            enableColumnFilterModes={false}
                                                            enableExpandAll={true}
                                                            enableColumnPinning={true}
                                                            enableColumnResizing={true}
                                                            muiPaginationProps={{
                                                                rowsPerPageOptions: [20, 50, 100],
                                                                variant: 'outlined',
                                                            }}
                                                            enableFullScreenToggle={true}
                                                            muiTablePaperProps={({ table }) => ({
                                                                style: {
                                                                    zIndex: table.getState().isFullScreen ? 1000 : undefined,
                                                                    color: 'black',
                                                                },
                                                            })}
                                                        />
                                                    </Grid>


                                                    <Grid
                                                        container
                                                        direction="row"
                                                        item xs={12}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        style={{ paddingTop: 10 }}
                                                    >
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            item xs={6}
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >

                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                align="flex-start"
                                                                item xs={12}
                                                                style={{ marginTop: 5 }}
                                                            >
                                                                <Tooltip
                                                                    title={
                                                                        <div
                                                                            style={{ backgroundColor: 'white', minHeight: '400px', minWidth: '600px', border: '2px solid black', color: 'black' }}
                                                                        >

                                                                            <Grid
                                                                                container
                                                                                direction="row"
                                                                                item xs={12}
                                                                                alignItems="center"
                                                                                justifyContent="flex-start"
                                                                            >
                                                                                <Typography style={{ padding: 30 }}>
                                                                                    <Box sx={{ whiteSpace: 'pre-line' }}>
                                                                                        {"Informações:\n" +
                                                                                            infoOrdem}
                                                                                    </Box>
                                                                                </Typography>
                                                                            </Grid>
                                                                        </div>
                                                                    }
                                                                    placement="right"
                                                                    arrow
                                                                    style={{ backgroundColor: 'white' }}
                                                                    sx={{ backgroundColor: 'white' }}
                                                                >
                                                                    <IconButton aria-label="Informaçoes">
                                                                        <InfoIcon color='blue' />
                                                                        <span style={{ fontSize: 12, color: 'black' }}>Informçaões</span>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>


                                                        </Grid>


                                                        <Grid
                                                            container
                                                            direction="row"
                                                            item xs={6}
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            style={{ paddingTop: 10 }}
                                                        >
                                                            <Stack>


                                                                Valor Total Cotações Selecionadas:
                                                                <Box>
                                                                    {currencyFormatter.format(valorTotalGlobal)}
                                                                </Box>
                                                                Valor Frete Cotações Selecionadas:
                                                                <Box>
                                                                    {currencyFormatter.format(valorFreteGlobal)}
                                                                </Box>
                                                                Valor Total Final:
                                                                <Box>
                                                                    {currencyFormatter.format(valorTotalGlobal + valorFreteGlobal)}
                                                                </Box>


                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            )}
                                        </>




                                    </Grid>



                                    <Grid
                                        value={value} index={3}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                    >

                                        <Grid item xs={12}
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                        >

                                            {ordemCompra.status >= 3 && preCotacoes &&
                                                <>
                                                    < DataGrid
                                                        pagination
                                                        checkboxSelection
                                                        rows={filtrarPreCotacoes(preCotacoes)}
                                                        getRowId={(row) => row.id_pre_cotacao}
                                                        columns={columnsComprasDataGrid}
                                                        disableColumnResize={false}
                                                        onCellClick={handleCellClick}
                                                        onRowClick={handleRowClick}
                                                        components={{
                                                            Toolbar: CustomToolbar,
                                                        }}
                                                    />
                                                </>
                                            }
                                        </Grid>

                                    </Grid>

                                    <Grid
                                        value={value} index={4}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                    >


                                        <Grid container spacing={2}
                                            item xs={12}
                                            style={{ padding: 30 }}
                                            rowSpacing={2}>
                                            {ordemCompra.procedimentos_execucao?.map((procedimento_analise, index) => (
                                                <Grid item xs={3} key={index}>
                                                    {/* xs=12 para dispositivos pequenos, sm=6 para médios, md=4 para grandes, lg=3 para extra grandes */}
                                                    <Card style={{ borderRadius: '10px', border: '1px solid #e0e0e0', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}>
                                                        <CardContent>
                                                            <Link
                                                                target="_blank"
                                                                to={`/gestao/operacional/procedimentos/vizualizar/${procedimento_analise.procedimento.id_procedimento}`}
                                                            >
                                                                <Typography variant="h7">{procedimento_analise.procedimento.nome}</Typography>
                                                            </Link>
                                                            <br /> <br /> <br />
                                                            <Typography variant="h8">{"Status: " + (procedimento_analise.status === 0 ? "Não Executado" : "Concluído")}</Typography>
                                                            <br />
                                                            {
                                                                procedimento_analise.status === 1 ?
                                                                    <Typography variant="h8">{(procedimento_analise.resultado === 0 ? "Resultado: Falha" : "Resultado: Sucesso")}</Typography>
                                                                    :
                                                                    <Typography variant="h8"></Typography>

                                                            }
                                                            <br />
                                                            {
                                                                procedimento_analise.status === 1 ?
                                                                    <Typography variant="h8">
                                                                        {`Executado por ${procedimento_analise.usuario_executor.nome_amigavel}`} <br />
                                                                        {`às ${moment(procedimento_analise.data_hora_execucao).format('HH:mm:ss DD/MM/YYYY')}`}
                                                                    </Typography> :
                                                                    <Typography variant="h8"></Typography>

                                                            }
                                                            <br /> <br />
                                                            <Typography variant="h8">{(procedimento_analise.procedimento.obrigatorio === 1 ? "Procedimento Obrigatório" : "Procedimento Opcional")}</Typography>
                                                            <br /> <br />
                                                            {
                                                                procedimento_analise.status === 0 ?
                                                                    <ColorButtonBlue variant="contained" color="primary"
                                                                        onClick={(event) => handleClickAtualizarProcedimento(procedimento_analise)}
                                                                    >
                                                                        Atualizar
                                                                    </ColorButtonBlue>
                                                                    :
                                                                    <ColorButtonGreen variant="contained" color="primary"
                                                                        onClick={(event) => handleClickVizualizarProcedimento(procedimento_analise.id_procedimento_execucao)}
                                                                    >
                                                                        Vizualizar
                                                                    </ColorButtonGreen>
                                                            }

                                                        </CardContent>
                                                    </Card>

                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>




                                </SwipeableViews>
                            </Grid>
                        </Grid>







                    </div>
                }


            </div>


            <Dialog open={open}
                disableBackdropClick>
                <DialogTitle>Processando Solicitação, Aguarde!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {isLoading ? (
                            <CircularProgress /> // Indicador de carregamento
                        ) : (
                            'Por favor, aguarde enquanto sua solicitação está sendo processada...'
                        )}
                    </DialogContentText>
                </DialogContent>



            </Dialog>


            <Dialog
                open={openAutorizar}
                onClose={() => setOpenAutorizar(false)}
                aria-labelledby="autorize-dialog"
            >
                <DialogTitle id="autorize-dialog">{"Autorizar Ordem de Compra"}</DialogTitle>
                <DialogContent>{"Digite o Código do Aplicativo Autenticador"}</DialogContent>
                <Grid item xs={12} style={{ padding: 5 }}>
                    <TextField
                        id="codigoValidacao"
                        variant="standard"
                        name="codigoValidacao"
                        fullWidth
                        label="Código de Validação"
                        value={codigoValidacao}
                        onChange={(e) => setCodigoValidacao(e.target.value)}
                    />

                </Grid>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => setOpenAutorizar(false)}
                        color="secondary"
                    >
                        No
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpen(false);
                            autorizar();
                        }}
                        color="default"
                    >
                        Autorizar
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openDialogMotivo} onClose={handleCloseDialogMotivo} PaperProps={{ style: { maxWidth: '600px', minWidth: '300px' } }}>
                <DialogTitle>Finalizar Ánalise Orçamentária</DialogTitle>
                <DialogContent>
                    <Grid
                        direction={"row"}
                        item xs={12}
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={3}
                    >

                        <Grid
                            item xs={12}
                            style={{ margin: 20, padding: 20 }}
                        >
                            <TextField
                                label="Motivo"
                                variant="outlined"
                                fullWidth
                                minRows={3}
                                value={motivoEscolha}
                                onChange={(e) => setMotivoEscolha(e.target.value)}
                            />
                        </Grid>
                        <Grid
                            item xs={12}
                        >


                            <Button style={{ margin: 20, padding: 20 }} variant="contained" color="primary" onClick={escolher}>
                                Finalizar
                            </Button>
                        </Grid>



                    </Grid>

                </DialogContent>
            </Dialog>

            <Snackbar
                open={openAtualizando}
                autoHideDuration={1500}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <MuiAlert elevation={6} variant="filled"  >
                    Atualizando...
                </MuiAlert>
            </Snackbar>

            <CustomDialog cotacao={cotacaoNovaTarefa} />
            {isOpenResposta && <CustomDialogResposta tarefa={tarefaResponder} />}


        </div >
    );
}


