import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import Collapse from '@material-ui/core/Collapse';
import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory } from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


import { Select, MenuItem, InputLabel } from '@material-ui/core';

import api from '../../../../services/api';


import NavegadorGestao from "../../../../components/NavBarGestao";




export default function CadastroUsuario() {
    const history = useHistory();


    const [roles, setRoles] = useState("SUPER_ADMIN");

    /* variaveis pessoa fisica */
    const [loading, setLoading] = useState(true);


    const [email, setEmail] = useState('');
    const [login, setLogin] = useState('');
    /*variaveis gerais*/



    const [tipoColaborador, setTipoColaborador] = useState(true);
    const [tipoCliente, setTipoCliente] = useState(false);


    const [colaboradores, setColaboradores] = useState([]);
    const [colaboradorSelecionado, setColaboradorSelecionado] = useState();



    const [clientes, setClientes] = useState([]);
    const [clienteSelecionado, setClienteSelecionado] = useState();


    /* variaveis  de controle gerais */
    const [erroLogin, setErroLogin] = useState(false);
    const [textoErroLogin, setTextoErroLogin] = useState('');


    const [erroEmail, setErroEmail] = useState(false);
    const [textoErroEmail, setTextoErroEmail] = useState('');


    /* variaveis  de controle gerais */

    const [sobre, setSobre] = useState('');




    const verificaEspaco = (string) => /\s/g.test(string);




    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }



            await api.get("v1/protected/colaboradores/listartodossemusuarios", {
                headers: headers
            }).then(function (response) {
                setColaboradores(response.data)


                api.get("v1/protected/clientes/listarTodos", {
                    headers: headers
                }).then(function (response) {
                    setClientes(response.data)


                    setLoading(false);

                });


            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar os Solicitantes: " + _err)

        }

    }


    useEffect(() => {
        listarInformacoes();

    }, []);


 

    async function salvar() {



            //verificar por cpf ja cadastrado
            try {
                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                //verificar por email ja cadastrado
                try {
                    console.log("procurar email chamado: " + email);
                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }
                    const response = await api.get('/v1/protected/signupemailverify/' + email,
                        { headers: headers });
                    const resposta_email = response.data.resposta;
                    if (resposta_email === "Error: Email Já Cadastrado") {
                        setErroEmail(true);
                        setTextoErroEmail("Email Já Cadastrado!");
                    } else {

                        setErroEmail(false);
                        setTextoErroEmail("Email aceito");


                        const response = await api.get('/v1/protected/signuploginverify/' + login,
                            { headers: headers });
                        const resposta_login = response.data.resposta;
                        if (resposta_login === "Error: Login Já Cadastrado") {
                            setErroLogin(true);
                            setTextoErroLogin("Login Já Cadastrado!");
                        }
                        else {

                            setErroLogin(false);
                            setTextoErroLogin("Login Aceito!");
                            //prosseguir cadastro

                            console.log("cadastro aceito!")

                            if (tipoColaborador) {
                                if (!colaboradorSelecionado) {
                                    alert("Selecione o Colaborador a ser Associado!");
                                    return;
                                }
                            } else if (tipoCliente) {
                                if (!clienteSelecionado) {
                                    alert("Selecione o Cliente a ser Associado!");
                                    return;
                                }
                            }


                            try {



                                const cadastro = {
                                    login: login,
                                    email: email,
                                    tipo_associacao: tipoColaborador ? 0 : tipoCliente ? 1 : -1,
                                };


                                console.log("id colaborador: " + colaboradorSelecionado.id_colaborador);

                                if (tipoColaborador) {
                                    cadastro.colaborador = { id_colaborador: colaboradorSelecionado.id_colaborador };
                                } else if (tipoCliente) {
                                    cadastro.cliente = { id_cliente: clienteSelecionado.id_cliente };
                                }

                                const headers = {
                                    'Authorization': 'Bearer ' + Cookies.get("token")
                                }

                                const response = await api.post('/v1/protected/users/cadastrar', cadastro,
                                    { headers: headers });

                                const cadastro_salvo = response.data;
                                if (cadastro_salvo) {
                                    alert("Usuário cadastrado com sucesso!")

                                    history.push({
                                        pathname: '/administracao/usuarios',
                                    })
                                } else {
                                    alert("Erro de Conexão, tente novamente mais tarde");
                                }
                            } catch (_err) {
                                console.log("erro ao cadastrar: " + _err);
                                alert("Erro de Conexão, tente novamente mais tarde");

                            }



                        }

                    }
                } catch (_err) {

                    alert("Erro de Conexão, tente novamente mais tarde");
                    return false;
                }



            } catch (_err) {

                alert("Erro de Conexão, tente novamente mais tarde");

            }


        





    }


    const handleTipoUsuario = (event) => {
        setRoles(event.target.value);
    };

    function returnDescricaoEndereco(objeto) {
        var texto = objeto.logradouro + ", " + objeto.numero + ", " + " bairro " +
            objeto.bairro + ", " + objeto.cidade + " UF: " + objeto.estado + " CEP: " + objeto.cep;
        return texto;
    }

    function returnDescricaoColaborador(colaborador) {

        var endereco = returnDescricaoEndereco(colaborador);

        var texto = "Colaborador_ID: " + colaborador.id_colaborador +
            "\nCPF: " + colaborador.cpf +
            "\nNome: " + colaborador.nome + " " + colaborador.sobrenome + "\n " + endereco;
        return texto;
    }




    function returnDescricaoCliente(cliente) {
        var endereco = returnDescricaoEndereco(cliente);


        var texto = "ID: " + cliente.id_cliente + " " +
            (cliente.tipo_cliente === 0 ? ("\nCPF: " + cliente.cpf) : ("\nCNPJ: " + cliente.cnpj)) + " " +
            (cliente.tipo_cliente === 0 ? (cliente.nome + " " + cliente.sobrenome) : cliente.nome_fantasia) + " " +
            "\nEndrereço: " + endereco;

        return texto;
    }


    const handleChange1 = (event) => {
        setTipoColaborador(true);
        setTipoCliente(false);
    };

    const handleChange2 = (event) => {
        setTipoColaborador(false);
        setTipoCliente(true);
    };




    return (
        <div>
            <NavegadorGestao
                Administracao={true}
                Usuarios={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }} />
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item xs={12}
            >
                <Button style={{ margin: 5 }}
                    variant="contained"
                    color="primary"
                    onClick={salvar}
                > Salvar  </Button>
            </Grid>
            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <Container>

                    <React.Fragment >
                        <Grid container spacing={2}   >





                            <Grid
                                container
                                direction="row"
                                item xs={12}
                                spacing={2}>



                                <Grid item xs={2}  >
                                </Grid>





                            </Grid>

















                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                            >


                                <Grid component={Paper} elevation={12}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"

                                    spacing={2}>


                                    <Grid
                                        container
                                        direction="row"
                                        item xs={12}
                                        spacing={2}>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            item xs={12}

                                        >
                                            <Typography  >
                                                Tipo Associação:
                                            </Typography>
                                            <FormControlLabel style={{ paddingLeft: 10 }} control={<Checkbox checked={tipoColaborador}
                                                onChange={handleChange1} />} label="Associado a Colaborador" />
                                            <FormControlLabel control={<Checkbox checked={tipoCliente}
                                                onChange={handleChange2} />} label="Associado a Cliente" />

                                        </Grid>


                                        <Collapse style={{ width: '100%' }} in={tipoColaborador} timeout="auto" unmountOnExit>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                                style={{ marginTop: 20 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={5} >
                                                    <Autocomplete
                                                        id="select-colaborador"
                                                        options={colaboradores}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(colaborador) => returnDescricaoColaborador(colaborador)}
                                                        value={colaboradorSelecionado}
                                                        onChange={(e, v) => setColaboradorSelecionado(v)}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Colaborador "

                                                                multiline
                                                                rows={6} />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Collapse>

                                        <Collapse style={{ width: '100%' }} in={tipoCliente} timeout="auto" unmountOnExit>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                                style={{ marginTop: 20 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={5} >
                                                    <Autocomplete
                                                        id="select-cliente"
                                                        options={clientes}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(cliente) => returnDescricaoCliente(cliente)}
                                                        value={clienteSelecionado}
                                                        onChange={(e, v) => setClienteSelecionado(v)}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Cliente "

                                                                multiline
                                                                rows={6} />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Collapse>

                                    </Grid>






                                    <Grid item xs={5}  >

                                        <Typography style={{ margin: 15 }} >
                                            <span style={{ fontSize: 18, textDecoration: 'underline' }}>Dados de Login: </span>
                                        </Typography>

                                        <TextField
                                            error={erroLogin}
                                            id="login"
                                            helperText={textoErroLogin}
                                            variant="standard"
                                            name="login"
                                            fullWidth
                                            label="Login"
                                            required
                                            autoComplete="login"
                                            value={login}
                                            onChange={e => verificaEspaco(e.target.value) ? setTextoErroLogin("") : setLogin(e.target.value)}
                                            inputProps={{ maxLength: 50, minLength: 15 }}

                                        />

                                        <TextField
                                            error={erroEmail}
                                            id="email"
                                            helperText={textoErroEmail}
                                            variant="standard"
                                            name="email"
                                            fullWidth
                                            label="E-Mail"
                                            required
                                            autoComplete="email"
                                            value={email}
                                            onChange={e => verificaEspaco(e.target.value) ? setTextoErroEmail("") : setEmail(e.target.value)}
                                            inputProps={{ maxLength: 50, minLength: 15 }}

                                        />
                                    </Grid>


                                </Grid>
                            </Grid>




                        </Grid>


                    </React.Fragment>


                </Container>
            }

        </div >
    );
}


