import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Chip from '@mui/material/Chip';

import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import {
    MaterialReactTable,

} from 'material-react-table';

import { Button, MenuItem, Select, InputLabel } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { styled } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import TextField from '@material-ui/core/TextField';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Box,
    Tooltip
} from '@mui/material';




const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));


const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'whit',
        borderColor: 'blue',
    },
}));


function novoGrupo() {
    window.location.href = "/gestao/administracao/clientes/gruposcontrole/cadastrar"

}

export default function GruposControle() {



    const [loading, setLoading] = useState(true);
    const [objetos, setObjetos] = useState([]);



    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/grupocontrole/listartodos", {
                headers: headers
            }).then(function (response) {
                setObjetos(response.data)

                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Grupos de Controle: " + _err)

        }

    }


    useEffect(() => {




        listarInformacoes();


    }, []);







    const columns = useMemo(
        () => [



            {
                accessorKey: 'id_grupo_controle', //normal accessorKey
                header: 'ID',
                size: 60,
            },




            {
                accessorKey: 'nome', // normal accessorKey
                header: 'Nome',
                size: 180,
            },



        ],
        [],
    );





    return (
        <div>
            <NavegadorGestao
                TabelasAuxiliaresClientes={true}
                GruposControle={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ padding: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={novoGrupo}
                    >
                        Novo Grupo
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div style={{ height: '100%', width: '100%' }}>
                        {objetos && (
                            <MaterialReactTable
                                columns={columns}
                                data={objetos}
                                enableGlobalFilterModes
                                enablePagination={true}
                                enableRowActions
                                paginationDisplayMode={'pages'}
                                getRowId={(row) => row.id_grupo_controle}
                                enableTopToolbar={true}
                                enableBottomToolbar={true}
                                enableColumnFilters={true}
                                enableColumnActions={false}
                                sortable={true}
                                enableSorting={true}
                                enableRowSelection={false}
                                enableColumnFilterModes={true}
                                enableExpandAll={true}
                                enableColumnPinning={true}
                                enableColumnResizing={true}
                                initialState={{
                                    pagination: { pageSize: 100, pageIndex: 0 }, // Correção da sintaxe aqui
                                    showGlobalFilter: true,
                                    density: 'compact',
                                }}

                                muiPaginationProps={{
                                    rowsPerPageOptions: [100, 500, 1000],
                                    variant: 'outlined',
                                }}
                                enableFullScreenToggle={true}
                                renderDetailPanel={({ row }) => (
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            left: '30px',
                                            maxWidth: '1000px',
                                            position: 'sticky',
                                            width: '100%',
                                        }}
                                    >
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '8px' }}>
                                            {row.original.instalacoes.map((instalacao) => (
                                                <Chip key={instalacao.id_instalacao} label={instalacao.identificador_integrador + "-" + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia)} />
                                            ))}
                                        </div>
                                    </Box>
                                )}
                                renderRowActions={({ row }) => (
                                    <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>
                                        <Tooltip title="Gerenciar">
                                            <IconButton
                                                color="primary"
                                                href={`/gestao/administracao/clientes/gruposcontrole/editar/${row.original.id_grupo_controle}`}
                                            >
                                                <SettingsIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </Box>
                                )}

                            />
                        )}

                    </div>
                }


            </div>



        </div>
    );
}



