import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';

import Skeleton from '@material-ui/lab/Skeleton';

import Chip from '@mui/material/Chip';
import {
    Box,
    Tooltip
} from '@mui/material';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import {
    MaterialReactTable,

} from 'material-react-table';


import { Button } from "@material-ui/core";


function novoColaborador() {
    window.location.href = "/gestao/rh/colaboradores/cadastrar"

}

export default function ListarColaboradores() {
    const [colaboradores, setColaboradores] = useState([]);

    const [loading, setLoading] = useState(true);






    const columns = useMemo(
        () => [

            {
                accessorKey: 'id_colaborador', //normal accessorKey
                header: 'ID Colaborador',
                size: 30,
                Cell: ({ cell }) => <span style={{ width: '20px' }}>{cell.getValue()}</span>, // Força estilo
            },


            {
                header: 'Status',
                accessorKey: 'status',
                size: 50,
                Cell: ({ cell }) => <span style={{ width: '50px' }}>{cell.getValue()}</span>, // Força estilo

            },

            {
                header: 'Nome',
                accessorKey: 'nome',
                size: 100,
                muiTableBodyCellProps: { style: { width: '100px' } },
                muiTableHeadCellProps: { style: { width: '100px' } },
            },


            {
                header: 'CPF',
                accessorKey: 'cpf',
                size: 100,
                muiTableBodyCellProps: { style: { width: '100px' } },
                muiTableHeadCellProps: { style: { width: '100px' } },
            },


            {
                header: 'Endereço',
                accessorKey: 'endereco',
                size: 100,
                muiTableBodyCellProps: { style: { width: '100px' } },
                muiTableHeadCellProps: { style: { width: '100px' } },
            },

        ],
        [],
    );


    /*
        const columnsDataGrid = [
    
            {
                headerName: 'ID',
                field: 'id_colaborador',
                id: 1,
                minWidth: 25,
    
            },
    
            {
                headerName: 'Status',
                field: 'status_colaborador',
                id: 2,
                minWidth: 200,
                renderCell: (cellValues) => {
                    return (
                        <span style={{ backgroundColor: cellValues.row.status_colaborador === 0 ? 'red' : 'green' }}>
                            {
                                cellValues.row.status_colaborador === 0 ? "INATIVO" : "ATIVO"
                            }
                        </span>
                    )
                }
    
            },
    
            {
                field: "nome",
                headerName: "Nome e Sobrenome",
                id: 3,
                minWidth: 250,
                renderCell: (cellValues) => {
                    return (
                        <span> {cellValues.row.nome} {cellValues.row.sobrenome} </span>
                    )
                }
    
    
            },
    
    
    
    
    
            {
                headerName: 'CPF',
                field: 'cpf',
                minWidth: 150,
                id: 4,
    
            },
    
            {
                field: "endereco",
                headerName: "Endereço completo",
                id: 5,
                minWidth: 250,
                renderCell: (cellValues) => {
                    return (
                        <span> {cellValues.row.logradouro},{cellValues.row.numero},{cellValues.row.complemento}, bairro {cellValues.row.bairro}, {cellValues.row.cidade} - {cellValues.row.estado}    </span>
                    )
                }
            },
    
            {
                field: "departamentos",
                headerName: "Departamentos",
                id: 6,
                minWidth: 350,
    
                renderCell: (cellValues) => (
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
                        {cellValues.row.departamentos.map((departamento) => (
                            <Chip key={departamento.id_departamento} label={departamento.nome} />
                        ))}
                    </div>
                ),
            },
    
    
            {
                field: "cargos",
                headerName: "Cargos",
                id: 7,
                minWidth: 350,
    
                renderCell: (cellValues) => (
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
                        {cellValues.row.cargos.map((cargo) => (
                            <Chip key={cargo.id_cargo} label={cargo.nome} />
                        ))}
                    </div>
                ),
            },
    
    
            {
                field: "funcoes",
                headerName: "Funções",
                id: 8,
                minWidth: 350,
    
                renderCell: (cellValues) => (
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
                        {cellValues.row.funcoes.map((funcao) => (
                            <Chip key={funcao.id_funcao} label={funcao.nome} />
                        ))}
                    </div>
                ),
            },
    
            {
                field: "editar",
                headerName: "Editar",
                sortable: false,
                id: 9,
                minWidth: 150,
                renderCell: (cellValues) => {
                    return (
                        <Button color="primary"
                            href={"/alterarcolaborador/" + cellValues.row.id_colaborador}
                            target="_blank"
                        >Editar</Button>
                    );
                }
            },
    
    
    
        ];
        */






    useEffect(() => {




        async function listarColaboradores() {
            try {
                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }



                await api.get("v1/protected/colaboradores/listartodos", {
                    headers: headers
                }).then(function (response) {

                    //console.log("COlaboradores: " + JSON.stringify(response.data));

                    const colaboradoresModificados = response.data.map((colaborador) => {
                        return {
                            ...colaborador, // Copia todos os atributos do objeto original
                            status: colaborador.status_colaborador === 0 ? "INATIVO" : "ATIVO", // Adiciona um novo atributo
                            nome: `${colaborador.nome} ${colaborador.sobrenome}`, // Concatena nome e sobrenome
                            endereco: `${colaborador.logradouro}, ${colaborador.numero}${colaborador.complemento ? `, ${colaborador.complemento}` : ""
                                }, bairro ${colaborador.bairro}, ${colaborador.cidade} - ${colaborador.estado}`, // Formata o endereço
                        };
                    });

                    setColaboradores(colaboradoresModificados);

                    setLoading(false);


                });


            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar colaboradores: " + _err)

            }

        }

        listarColaboradores();


    }, []);



    return (
        <div>

            <NavegadorGestao
                RH={true}
                Colaboradores={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />


            <div style={{ padding: 5, width: '100%', height: '100%' }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novoColaborador}
                    >
                        Novo Colaborador
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div style={{ height: '100%', width: '100%' }}>
                        {colaboradores && (
                            <MaterialReactTable
                                columns={columns}
                                data={colaboradores}
                                enableBottomToolbar={true}
                                enableGlobalFilterModes={true}
                                enablePagination={true}
                                enableGlobalFilter={true}
                                paginationDisplayMode={'pages'}
                                getRowId={(row) => row.id_colaborador}

                                initialState={{
                                    pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe aqui
                                    showGlobalFilter: true,
                                    density: 'compact',
                                }}
                                muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}
                              
                                
                                muiPaginationProps={{
                                    rowsPerPageOptions: [50, 100, 200],
                                    variant: 'outlined',
                                    sx: {
                                        tableLayout: 'fixed', // Define um layout fixo para garantir que as larguras sejam respeitadas
                                    },
                                }}
                                enableFullScreenToggle={true}
                                renderDetailPanel={({ row }) => (
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            left: '30px',
                                            maxWidth: '1000px',
                                            position: 'sticky',
                                            width: '100%',
                                        }}
                                    >
                                    </Box>
                                )}

                            />
                        )}

                    </div>
                }


            </div>



        </div>
    );



}



