import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import { useHistory } from "react-router-dom";

import SettingsIcon from '@mui/icons-material/Settings';

import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';

import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import api from '../../../../../../services/api';
import NavegadorGestao from "../../../../../../components/NavBarGestao";

import icone_instalacoes from '../../../../../../assets/imgs/icone_instalacoes.png';

import {
    Tooltip
} from '@mui/material';
import { Button, MenuItem, Select, InputLabel } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { styled } from '@material-ui/styles';


import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import {
    MaterialReactTable,

} from 'material-react-table';

const drawerWidth = 240;


const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'whit',
        borderColor: 'blue',
    },
}));


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    table: {
        minWidth: 650,
    },
}));

const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'blue',
        borderColor: 'blue',
    },
}));


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));




export default function GerenciarCliente() {


    const history = useHistory();


    const [tipoFisica, setTipoFisica] = useState(true);
    const [tipoJuridica, setTipoJuridica] = useState(false);

    /* variaveis pessoa fisica */
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [nome, setNome] = useState('');
    const [sobreNome, setSobrenome] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    /* variaveis pessoa fisica */



    /* variaveis de estado de cadastro de pessoa juridica*/
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [cnpj, setCnpj] = useState('');
    /* variaveis de estado de cadastro de pessoa juridica*/

    const [instalacoes, setInstalacoes] = useState(null);

    /*variaveis gerais*/
    const [porte, setPorte] = useState('');
    const [atividade, setAtividade] = useState('');
    const [ie, setIe] = useState('');
    const [descricao, setDescricao] = useState('');
    const [email, setEmail] = useState('');
    /*variaveis gerais*/

    const [parametrosEndereco, setParametrosEndereco] = useState(
        {
            logradouro: "",
            numero: "",
            bairro: "",
            complemento: "",
            cidade: "",
            estado: "",
            cep: "",
            latitude: "",
            longitude: "",
        }
    );


    /*variaveis de controle pessoa fisica */

    const [erroNome, setErroNome] = useState(false);
    const [textoErroNome, setTextoErroNome] = useState('');

    const [erroSobreNome, setErroSobreNome] = useState(false);
    const [textoErroSobreNome, setTextoErroSobreNome] = useState('');

    const [erroRg, setErroRg] = useState(false);
    const [textoErroRg, setTextoErroRg] = useState('');


    const [erroDataNascimento, setErroDataNascimento] = useState(false);
    const [textoErroDataNascimento, setTextoErroDataNascimento] = useState('');

    /*variaveis de controle pessoa fisica */


    /*variaveis de controle pessoa juridica */
    const [erroRazaoSocial, setErroRazaoSocial] = useState(false);
    const [textoErroRazaoSocial, setTextoErroRazaoSocial] = useState('');

    const [erroNomeFantasia, setErroNomeFantasia] = useState(false);
    const [textoErroNomeFantasia, setTextoErroNomeFantasia] = useState('');

    /*variaveis de controle pessoa juridica */



    /* variaveis  de controle gerais */

    /* variaveis  de controle gerais */







    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [objetoGerenciado, setObjetoGerenciado] = useState([]);

    const { idCliente } = useParams();


    const [height, setHeight] = useState(0);


    function checkDimenssoes() {

        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;


        setHeight(altura * 0.75);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });




    const [contatos, setContatos] = useState([]);





 function returnDescricaoClienteResumida(){
        var texto = objetoGerenciado.tipo_cliente === 0 ? (objetoGerenciado.nome + " " + objetoGerenciado.sobrenome) :
        objetoGerenciado.nome_fantasia;
        return texto;
 }

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/retornardadoscliente/" + idCliente, {
                headers: headers
            }).then(function (response) {
                setObjetoGerenciado(response.data);

                if (response.data.tipo_cliente === 0) {
                    setTipoFisica(true);
                    setTipoJuridica(false);

                } else {
                    setTipoFisica(false);
                    setTipoJuridica(true);
                }


                setCpf(response.data.cpf);
                setContatos(response.data.contatos);
                setRg(response.data.rg);
                setNome(response.data.nome);
                setSobrenome(response.data.sobrenome);
                setDataNascimento(response.data.nascimento);

                // Atribuindo valores da resposta à pessoa jurídica
                setRazaoSocial(response.data.razao_social);
                setNomeFantasia(response.data.nome_fantasia);
                setCnpj(response.data.cnpj);

                // Atribuindo valores gerais
                setPorte(response.data.porte);
                setAtividade(response.data.atividade);
                setIe(response.data.ie);
                setDescricao(response.data.descricao);
                setEmail(response.data.email);

                setParametrosEndereco(prevState => ({
                    ...prevState,
                    'logradouro': response.data.logradouro,
                    'numero': response.data.numero,
                    'bairro': response.data.bairro,
                    'cidade': response.data.cidade,
                    'estado': response.data.estado,
                    'cep': response.data.cep,
                    'latitude': response.data.latitude,
                    'longitude': response.data.longitude
                }));



                const updatedData = response.data.instalacoes.map(item => {
                    // Modificar o atributo 'conta_instalacao'
                    item.status_instalacao = item.status === 1 ? "ATIVADO" : "DESATIVADO";
                    item.identificacao = item.tipo_instalacao === 0 ? item.cpf : item.cnpj;
                    item.nome_instalacao = item.tipo_instalacao === 0 ? (item.nome + " " + item.sobrenome) : item.nome_fantasia;

                    return item;
                });

                updatedData.sort((a, b) => {
                    if (a.identificador_integrador < b.identificador_integrador) return -1;
                    if (a.identificador_integrador > b.identificador_integrador) return 1;
                    return 0;
                });

                setInstalacoes(updatedData);


                setLoading(false);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }






    useEffect(() => {

        handleChangeIndex(parseInt(Cookies.get("abaPaginaCliente"), 10));
        checkDimenssoes();
        listarInformacoes();


    }, []);






    function validarRazaoSocial() {
        if (razaoSocial?.length > 0) {
            setErroRazaoSocial(false);
            setTextoErroRazaoSocial('');
            return true;
        } else {
            setErroRazaoSocial(true);
            setTextoErroRazaoSocial("Razão Social Inválida");
            return false;
        }
    }

    function validarNomeFantasia() {
        if (nomeFantasia?.length > 0) {
            setErroNomeFantasia(false);
            setTextoErroNomeFantasia('');
            return true;
        } else {
            setErroNomeFantasia(true);
            setTextoErroNomeFantasia("Nome Fantasia Inválido");
            return false;
        }
    }

    function validarNome() {
        if (nome?.length > 0) {
            setErroNome(false);
            setTextoErroNome('');
            return true;
        } else {
            setErroNome(true);
            setTextoErroNome("Nome muito curto");
            return false;
        }
    }





    function validarSobreNome() {
        if (sobreNome?.length > 0) {
            setErroSobreNome(false);
            setTextoErroSobreNome('');
            return true;
        } else {
            setErroSobreNome(true);
            setTextoErroSobreNome("Sobrenome muito curto");
            return false;
        }
    }

    function validarRg() {
        if (rg?.length > 0) {
            setErroRg(false);
            setTextoErroRg('');
            return true;
        } else {
            setErroRg(true);
            setTextoErroRg("Rg muito curto");
            return false;
        }
    }






    function validarData(data) {
        const dateFormat = "DD/MM/YYYY";
        const teste = moment(data, dateFormat, true).isValid();

        if (teste) {
            setErroDataNascimento(false);
            setTextoErroDataNascimento("")
        } else {
            setErroDataNascimento(true);
            setTextoErroDataNascimento("Data de Nascimento Inválida!")
        }
        return teste;
    }





    async function atualizar() {

        if (tipoFisica) {
            //cadastrar pessoa fisica

            validarRg();
            validarData(dataNascimento);
            validarNome();
            validarSobreNome();

            console.log(validarData(dataNascimento));
            //prosseguir cadastro
            if (validarRg() && validarData(dataNascimento) && validarNome() &&
                validarSobreNome()) {
                console.log("cadastro aceito!")

                try {
                    const cadastro_cliente_pf = {
                        tipo_cliente: 0,
                        nome: nome,
                        sobrenome: sobreNome,
                        nascimento: dataNascimento,
                        rg: rg,
                        descricao: descricao,
                        logradouro: parametrosEndereco.logradouro,
                        numero: parametrosEndereco.numero,
                        bairro: parametrosEndereco.bairro,
                        cidade: parametrosEndereco.cidade,
                        estado: parametrosEndereco.estado,
                        cep: parametrosEndereco.cep,
                        latitude: parametrosEndereco.latitude,
                        longitude: parametrosEndereco.longitude,

                    }

                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }

                    const response = await api.put('/v1/protected/clientes/atualizar/' + idCliente, cadastro_cliente_pf,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Cliente atualizado com sucesso!")

                        window.location.reload();

                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }
                } catch (_err) {
                    console.log("erro ao cadastrar: " + _err);
                    alert("Erro de Conexão, tente novamente mais tarde");

                }



            } else {
                console.log("cadastro rejeitado!")
            }



        } else {
            //cadastrar pessoa juridica

            validarRazaoSocial();
            validarNomeFantasia();


            //prosseguir cadastro
            if (validarRazaoSocial() && validarNomeFantasia) {
                console.log("cadastro pj aceito!")

                try {

                    console.log("cadastrar chamado");
                    const cadastro_cliente_pj = {
                        tipo_cliente: 1,
                        razao_social: razaoSocial,
                        nome_fantasia: nomeFantasia,
                        porte: porte,
                        atividade: atividade,
                        descricao: descricao,
                        logradouro: parametrosEndereco.logradouro,
                        numero: parametrosEndereco.numero,
                        bairro: parametrosEndereco.bairro,
                        cidade: parametrosEndereco.cidade,
                        estado: parametrosEndereco.estado,
                        cep: parametrosEndereco.cep,
                        latitude: parametrosEndereco.latitude,
                        longitude: parametrosEndereco.longitude,
                    }

                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }

                    const response = await api.put('/v1/protected/clientes/atualizar/' + idCliente, cadastro_cliente_pj,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Cliente atualizado com sucesso!")
                        window.location.reload();

                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }
                } catch (_err) {
                    console.log("erro ao cadastrar: " + _err);
                    alert("Erro de Conexão, tente novamente mais tarde");

                }



            } else {
                console.log("cadastro pj rejeitado!")
            }

        }
    }




    const handleChange1 = (event) => {
        setTipoFisica(true);
        setTipoJuridica(false);
    };

    const handleChange2 = (event) => {
        setTipoFisica(false);
        setTipoJuridica(true);
    };



    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        Cookies.set("abaPaginaCliente", newValue);
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        Cookies.set("abaPaginaCliente", index);
        setValue(index);
    };


    function novoContato() {
        window.location.href = "/gestao/administracao/clientes/gerenciarcliente/" + idCliente + "/cadastrarcontato"

    }



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    const handleClickGerenciar = (event, row) => {

        window.location.href = "/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/" + row.id_instalacao + "/" + idCliente;

    };


    const handleClickEdicao = (event, cellValues) => {


        window.location.href = "/gestao/administracao/clientes/gerenciarcliente/" + idCliente + "/editarcontato/" + cellValues.row.id_contato;


    };





    const columnsContatosDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 200,
            renderCell: (cellValues) => {
                return (
                    <ColorButtonGreen variant="contained" color="primary"
                        onClick={(event) => handleClickEdicao(event, cellValues)}
                    >
                        Editar
                    </ColorButtonGreen>
                )
            },
        },


        {
            headerName: 'ID',
            field: 'id_contato',
            id: 1,
            headerClassName: 'cabecalho_azul',
        },


        {
            headerName: 'Nome',
            field: 'nome_contato',
            id: 2,
            width: 300,
            headerClassName: 'cabecalho_azul',

        },

        {
            headerName: 'Cargo',
            field: 'cargo_contato',
            id: 3,
            width: 300,
            headerClassName: 'cabecalho_azul',

        },
        {
            headerName: 'Celular',
            field: 'celular_contato',
            id: 4,
            width: 300,
            headerClassName: 'cabecalho_azul',

        },

        {
            headerName: 'Fixo',
            field: 'fixo_contato',
            id: 5,
            width: 300,
            headerClassName: 'cabecalho_azul',

        },

        {
            headerName: 'E-mail',
            field: 'e_mail_contato',
            id: 6,
            width: 300,
            headerClassName: 'cabecalho_azul',

        },
        {
            headerName: 'Envio de E-mail',
            field: 'emails',
            id: 7,
            width: 600,
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {
                var email_principal = cellValues.row.email_principal;
                var email_copia = cellValues.row.email_copia;


                return (
                    <Typography style={{ fontSize: 16 }} variant="h6">
                        {email_principal === 1 ? "É destinátario de e-mail" : email_copia === 1 ? "É destinátario em Cópia" : "Não"}
                    </Typography>
                )
            },
        },
        {
            headerName: 'Descrição',
            field: 'descricao_contato',
            id: 7,
            width: 600,
            headerClassName: 'cabecalho_azul',

        },




    ];





    const columnsInstalacoes = useMemo(
        () => [

            {
                accessorKey: 'id_instalacao', //normal accessorKey
                header: 'ID',
                size: 60,
            },
            {
                accessorKey: 'identificador_integrador', //normal accessorKey
                header: 'SIGMA ID',
                size: 60,
            },
            {
                accessorKey: 'status_instalacao', //normal accessorKey
                header: 'STATUS',
                size: 100,
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span
                            style={{
                                padding: "5px 10px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                                backgroundColor: renderedCellValue === "ATIVADO" ? "#b2fab4" : "#fbb4b4", // Verde médio ou vermelho médio
                                color: renderedCellValue === "ATIVADO" === 1 ? "#2e7d32" : "#c62828", // Verde escuro ou vermelho escuro
                                fontWeight: "bold",
                                fontSize: 12,
                            }}
                        >
                            {renderedCellValue}
                        </span>
                    )

                },

            },
            {
                accessorKey: 'grupo_cliente.nome', //normal accessorKey
                header: 'Grupo Cliente',
                size: 60,
            },
            {
                accessorKey: 'identificacao', //normal accessorKey
                header: 'IDENTIFICAÇÃO',
                size: 200,
            },

            {
                accessorKey: 'nome_instalacao', //normal accessorKey
                header: 'NOME/NOME FANTASIA',
                size: 200,
            },

            {
                accessorKey: 'estado', //normal accessorKey
                header: 'Estado',
                size: 400,
            },



        ],
        [],
    );


    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 200,
            renderCell: (cellValues) => {
                return (
                    <Grid>
                        <ColorButtonBlue variant="contained" color="primary"
                            onClick={(event) => handleClickGerenciar(event, cellValues)}
                        >
                            Gerenciar
                        </ColorButtonBlue>

                    </Grid>

                )
            },
        },
        {
            headerName: 'ID',
            field: 'id_instalacao',
            id: 1,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'ID Integrador',
            field: 'identificador_integrador',
            id: 1,
            headerClassName: 'cabecalho_azul',
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'cabecalho_azul',
            sortable: false,
            width: 200,
            renderCell: (cellValues) => {
                var status = cellValues.row.status;
                return (
                    <Typography component="h1" style={{ fontSize: 16 }} variant="h6" color="inherit" noWrap className={classes.title}>
                        {status === 1 ? "ATIVO" : "DESATVADO"}
                    </Typography>
                )
            },
        },
        {
            field: 'identificacao',
            headerName: 'CPF/CNPJ',
            headerClassName: 'cabecalho_azul',
            sortable: false,
            width: 200,
            renderCell: (cellValues) => {
                var tipo_cliente = cellValues.row.tipo_instalacao;
                return (
                    <Typography component="h1" style={{ fontSize: 16 }} variant="h6" color="inherit" noWrap className={classes.title}>
                        {tipo_cliente === 0 ? cellValues.row.cpf : cellValues.row.cnpj}
                    </Typography>
                )
            },
        },

        {
            field: 'nome',
            headerName: 'Nome/Nome Fantasia',
            headerClassName: 'cabecalho_azul',
            sortable: false,
            width: 200,
            renderCell: (cellValues) => {
                var tipo_cliente = cellValues.row.tipo_instalacao;
                return (
                    <Typography component="h1" style={{ fontSize: 16 }} variant="h6" color="inherit" noWrap className={classes.title}>
                        {tipo_cliente === 0 ? (cellValues.row.nome + " " + cellValues.row.sobrenome) : cellValues.row.nome_fantasia}
                    </Typography>
                )
            },
        },
    ]

    function novaConta() {
        window.location.href = "/gestao/administracao/clientes/gerenciarcliente/adicionarconta/" + idCliente;

    }

    return (
        <div>

            <NavegadorGestao
                BaseDados={true}
                Clientes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />




            <div style={{ paddingTop: 5 }}>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :


                     <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                        >

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={12}
                                style={{ width: '100%', margin: 0, padding: 0 }}
                            >

                                <a href="/gestao/administracao/clientes/listar" style={{ fontSize: 18, color: 'black', fontSize: 'bold', marginRight: '5px' }}>{"< Clientes |"}</a>
                                <a href="/gestao/administracao/clientes/listar" style={{ fontSize: 18, color: 'black', fontSize: 'bold', marginRight: '5px' }}>{idCliente + " " + returnDescricaoClienteResumida() }</a>
                            </Grid>


                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                                style={{marginLeft: 10}}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >


                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                            style={{ width: '100%', margin: 0, padding: 0 }}
                                        >
                                            <Tab label="Especificações" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Contatos" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Contas/Instalações" style={{ backgroundColor: '#1C1C1C' }} />

                                        </Tabs>
                                    </AppBar>

                                </Box>
                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >

                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >
                                    <Grid value={value} index={0}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                    >

                                        <Grid
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="center"
                                            style={{ padding: 15 }}
                                            container  >




                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                item xs={12}
                                            >
                                                <Typography  >
                                                    Tipo Pessoa:
                                                </Typography>
                                                <FormControlLabel style={{ paddingLeft: 10 }} control={<Checkbox checked={tipoFisica}
                                                    onChange={handleChange1} />} label="Pessoa Física" />
                                                <FormControlLabel control={<Checkbox checked={tipoJuridica}
                                                    onChange={handleChange2} />} label="Pessoa Jurídica" />


                                            </Grid>



                                            <Collapse style={{ width: '100%' }} in={tipoJuridica} timeout="auto" unmountOnExit>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    spacing={2}>

                                                    <Grid item xs={4}>
                                                        <TextField

                                                            variant="standard"
                                                            name="cnpj"
                                                            label="CNPJ"
                                                            required
                                                            id="cnpj"
                                                            value={cnpj}
                                                            inputProps={{ maxLength: 14, minLength: 14 }}
                                                            fullWidth
                                                        />
                                                    </Grid>

                                                    <Grid item xs={4} >
                                                        <TextField
                                                            error={erroRazaoSocial}
                                                            helperText={textoErroRazaoSocial}
                                                            variant="standard"
                                                            name="razaoSocial"
                                                            label="Razão Social"
                                                            required
                                                            id="razaoSocial"
                                                            autoComplete="razaoSocial"
                                                            value={razaoSocial}
                                                            onChange={e => setRazaoSocial(e.target.value)}
                                                            inputProps={{ maxLength: 50, minLength: 1 }}
                                                            fullWidth

                                                        />
                                                    </Grid>

                                                    <Grid item xs={4} >
                                                        <TextField
                                                            error={erroNomeFantasia}
                                                            helperText={textoErroNomeFantasia}
                                                            variant="standard"
                                                            name="nomeFantasia"
                                                            label="Nome Fantasia"
                                                            required
                                                            id="nomeFantasia"
                                                            value={nomeFantasia}
                                                            onChange={e => setNomeFantasia(e.target.value)}
                                                            inputProps={{ maxLength: 50, minLength: 1 }}
                                                            fullWidth

                                                        />
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        item xs={12}
                                                        spacing={2}>

                                                        <Grid item xs={4} >

                                                            <TextField

                                                                variant="standard"
                                                                name="porte"
                                                                label="Porte"
                                                                id="porte"
                                                                value={porte}
                                                                onChange={e => setPorte(e.target.value)}
                                                                inputProps={{ maxLength: 100, minLength: 0 }}
                                                                fullWidth

                                                            />
                                                        </Grid>

                                                        <Grid item xs={4} >
                                                            <TextField
                                                                variant="standard"
                                                                name="atividade"
                                                                label="Atividade"
                                                                id="atividade"
                                                                autoComplete="atividade"
                                                                value={atividade}
                                                                onChange={e => setAtividade(e.target.value)}
                                                                inputProps={{ maxLength: 100, minLength: 0 }}
                                                                fullWidth

                                                            />
                                                        </Grid>

                                                        <Grid item xs={4}  >
                                                            <TextField

                                                                variant="standard"
                                                                name="ie"
                                                                label="Inscrição Estadual"
                                                                id="ei"
                                                                value={ie}
                                                                onChange={e => setIe(e.target.value)}
                                                                inputProps={{ maxLength: 20, minLength: 0 }}
                                                                fullWidth

                                                            />
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                            </Collapse>

                                            <Collapse style={{ width: '100%' }} in={tipoFisica} timeout="auto" unmountOnExit>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    spacing={2}>
                                                    <Grid item xs={4}  >

                                                        <TextField

                                                            variant="standard"
                                                            name="cpf"
                                                            label="CPF"
                                                            required
                                                            id="cpf"
                                                            value={cpf}
                                                            inputProps={{ maxLength: 11, minLength: 11 }}
                                                            fullWidth

                                                        />
                                                    </Grid>

                                                    <Grid item xs={4}  >
                                                        <TextField
                                                            error={erroRg}
                                                            helperText={textoErroRg}
                                                            variant="standard"
                                                            name="rg"
                                                            label="RG"
                                                            required
                                                            id="rg"
                                                            value={rg}
                                                            onChange={e => setRg(e.target.value)}
                                                            inputProps={{ maxLength: 20, minLength: 3 }}
                                                            fullWidth

                                                        />
                                                    </Grid>


                                                    <Grid item xs={4}  >
                                                        <TextField
                                                            error={erroDataNascimento}
                                                            helperText={textoErroDataNascimento}
                                                            variant="standard"
                                                            name="dataNascimento"
                                                            label="Data Nascimento"
                                                            required
                                                            id="dataNascimento"
                                                            value={dataNascimento}
                                                            onChange={e => setDataNascimento(e.target.value)}
                                                            inputProps={{ maxLength: 20, minLength: 3 }}
                                                            fullWidth

                                                        />
                                                    </Grid>

                                                    <Grid item xs={4}  >
                                                        <TextField
                                                            error={erroNome}
                                                            helperText={textoErroNome}
                                                            variant="standard"
                                                            name="nome"
                                                            label="Nome"
                                                            required
                                                            id="nome"
                                                            value={nome}
                                                            onChange={e => setNome(e.target.value)}
                                                            inputProps={{ maxLength: 100, minLength: 2 }}
                                                            fullWidth

                                                        />
                                                    </Grid>

                                                    <Grid item xs={4}  >
                                                        <TextField
                                                            error={erroSobreNome}
                                                            helperText={textoErroSobreNome}
                                                            variant="standard"
                                                            name="sobrenome"
                                                            label="Sobrenome"
                                                            required
                                                            id="sobrenome"
                                                            value={sobreNome}
                                                            onChange={e => setSobrenome(e.target.value)}
                                                            inputProps={{ maxLength: 100, minLength: 2 }}
                                                            fullWidth

                                                        />
                                                    </Grid>

                                                </Grid>
                                            </Collapse>





                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12}
                                                style={{ paddingTop: 15 }}
                                                spacing={2}>

                                                <Grid item xs={8} >
                                                    <TextField
                                                        variant="standard"
                                                        name="descricao"
                                                        label="Descrição"
                                                        id="descricao"
                                                        value={descricao}
                                                        onChange={e => setDescricao(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        rows={4}

                                                    />
                                                </Grid>
                                            </Grid>


                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyItems="center"
                                                style={{ paddingTop: 80 }}
                                            >
                                                <Grid style={{ backgroundColor: 'whitesmoke', padding: 20 }} component={Paper} elevation={12}
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    alignItems="center"
                                                >
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        item xs={12}
                                                    >
                                                        <Grid
                                                            container
                                                            direction="row" spacing={2}
                                                            item xs={12}>

                                                            <Typography style={{ margin: 15 }} >
                                                                <span style={{ fontSize: 22, textDecoration: 'underline' }}>Dados de Endereço: </span>
                                                            </Typography>


                                                            <Grid item xs={12}>
                                                                <TextField

                                                                    id="logradouro"
                                                                    variant="standard"
                                                                    name="logradouro"
                                                                    fullWidth
                                                                    label="Logradouro"
                                                                    value={parametrosEndereco.logradouro}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'logradouro': e.target.value }))}
                                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={2}>
                                                                <TextField

                                                                    id="numero"
                                                                    variant="standard"
                                                                    name="numero"
                                                                    fullWidth
                                                                    label="Número"
                                                                    value={parametrosEndereco.numero}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'numero': e.target.value }))}
                                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={5}>
                                                                <TextField

                                                                    id="bairro"
                                                                    variant="standard"
                                                                    name="bairro"
                                                                    fullWidth
                                                                    label="Bairro"
                                                                    value={parametrosEndereco.bairro}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'bairro': e.target.value }))}
                                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                                />
                                                            </Grid>



                                                            <Grid item xs={5}>
                                                                <TextField

                                                                    id="complemento"
                                                                    variant="standard"
                                                                    name="complemento"
                                                                    fullWidth
                                                                    label="Complemento"
                                                                    value={parametrosEndereco.complemento}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'complemento': e.target.value }))}
                                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                <TextField

                                                                    id="cidade"
                                                                    variant="standard"
                                                                    name="cidade"
                                                                    fullWidth
                                                                    label="Cidade"
                                                                    value={parametrosEndereco.cidade}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'cidade': e.target.value }))}
                                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                <TextField

                                                                    id="estado"
                                                                    variant="standard"
                                                                    name="estado"
                                                                    fullWidth
                                                                    label="Estado"
                                                                    value={parametrosEndereco.estado}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'estado': e.target.value }))}
                                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                <TextField

                                                                    id="cep"
                                                                    variant="standard"
                                                                    name="cep"
                                                                    fullWidth
                                                                    label="CEP"
                                                                    value={parametrosEndereco.cep}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'cep': e.target.value }))}
                                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                                />
                                                            </Grid>


                                                            <Grid item xs={4}>
                                                                <TextField

                                                                    id="latitude"
                                                                    variant="standard"
                                                                    name="latitude"
                                                                    fullWidth
                                                                    label="Latitude"
                                                                    value={parametrosEndereco.latitude}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'latitude': e.target.value }))}
                                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                <TextField

                                                                    id="longitude"
                                                                    variant="standard"
                                                                    name="longitude"
                                                                    fullWidth
                                                                    label="Longitude"
                                                                    value={parametrosEndereco.longitude}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'longitude': e.target.value }))}
                                                                    inputProps={{ maxLength: 40, minLength: 6 }}
                                                                />
                                                            </Grid>


                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                item xs={10}
                                            >
                                                <Button style={{ marginTop: 50 }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={atualizar}
                                                > Atualizar  </Button>
                                            </Grid>

                                        </Grid>


                                    </Grid>

                                    <Grid value={value} index={1}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                    >

                                        <>
                                            <DataGrid
                                                pagination
                                                checkboxSelection
                                                rows={contatos}
                                                getRowId={(row) => row.id_contato}
                                                columns={columnsContatosDataGrid}
                                                disableColumnResize={false}
                                                onCellClick={handleCellClick}
                                                onRowClick={handleRowClick}
                                                components={{
                                                    Toolbar: CustomToolbar,
                                                }}
                                            />
                                        </>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Button style={{ marginTop: 50 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={novoContato}
                                            > Novo Contato  </Button>
                                        </Grid>

                                    </Grid>

                                    <Grid value={value} index={2}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                    >


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            item xs={12}
                                        >

<Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            item
                            xs={3}
                            justifyContent={"flex-start"}
                            style={{ backgroundColor: '#720309', padding: 5, margin: 5 }}
                        >
                            <img
                                alt={"icone"}
                                style={{
                                    textAlign: 'center',
                                    width: '35px', // Define a largura da imagem
                                    height: '35px' // Define a altura da imagem
                                }}
                                src={icone_instalacoes}
                            />

                            {/* Container para alinhar os textos */}
                            <div style={{ textAlign: "center" }}>
                                {/* Número de instalações */}
                                <span
                                    style={{
                                        fontSize: 18,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        display: "block", // Para alinhar em bloco
                                        marginBottom: "1px" // Controla a distância vertical
                                    }}
                                >
                                    {instalacoes.length}
                                </span>

                                {/* Texto 'instalações' */}
                                <span
                                    style={{
                                        fontSize: 14,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        paddingLeft: "10px" // Recuo horizontal para direita
                                    }}
                                >
                                    instalações
                                </span>
                            </div>
                        </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="flex-end"
                                                item xs={6}
                                            >


                                                <Button style={{ margin: 10 }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={novaConta}
                                                > Nova Conta  </Button>
                                            </Grid>
                                        </Grid>




                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="center"
                                        >

                                            <div>
                                                {instalacoes && (
                                                    <MaterialReactTable
                                                        columns={columnsInstalacoes}
                                                        data={instalacoes}
                                                        enableGlobalFilterModes
                                                        enablePagination={true}
                                                        enableRowActions
                                                        paginationDisplayMode={'pages'}
                                                        getRowId={(row) => row.id_instalacao}
                                                        enableTopToolbar={true}
                                                        enableBottomToolbar={true}
                                                        enableColumnFilters={true}
                                                        enableColumnActions={true}
                                                        sortable={true}
                                                        enableSorting={true}
                                                        enableRowSelection={false}
                                                        enableColumnFilterModes={true}
                                                        enableExpandAll={true}
                                                        enableColumnPinning={true}
                                                        enableColumnResizing={true}
                                                        initialState={{
                                                            pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe aqui
                                                            showGlobalFilter: true,
                                                            density: 'compact',
                                                        }}
                                                        muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                                        muiPaginationProps={{
                                                            rowsPerPageOptions: [50, 100, 200],
                                                            variant: 'outlined',
                                                        }}
                                                        enableFullScreenToggle={true}
                                                        renderDetailPanel={({ row }) => (
                                                            <Box
                                                                sx={{
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    justifyContent: 'space-around',
                                                                    left: '30px',
                                                                    maxWidth: '1000px',
                                                                    position: 'sticky',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                            </Box>
                                                        )}
                                                        renderRowActions={({ row }) => (
                                                            <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>


                                                                <Tooltip title="Gerenciar">
                                                                    <IconButton color="error"
                                                                        onClick={(event) => handleClickGerenciar(event, row.original)}
                                                                    >
                                                                        <SettingsIcon />
                                                                    </IconButton>
                                                                </Tooltip>

                                                            </Box>
                                                        )}
                                                    />
                                                )}


                                            </div>
                                        </Grid>



                                    </Grid>



                                </SwipeableViews>
                            </Grid>
                        </Grid>




                }


            </div>




        </div >
    );
}



