import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { parse, format } from 'date-fns';
import { Snackbar } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";



import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

export default function CadastrarRondaVirtual() {


    const [instalacoes, setInstalacoes] = useState([]);
    const [instalacaoSelecionada, setInstalacaoSelecionada] = useState(null);


    const [loading, setLoading] = useState(true);



    const history = useHistory();



    async function listarInformacoesIniciais() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/clientes/instalacoes/listartodos", {
                headers: headers
            }).then(function (response) {
                setInstalacoes(response.data)
                setLoading(false);
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Instalações: " + _err)

        }

    }

    async function handleSetInstalacao(instalacao) {
        setInstalacaoSelecionada(instalacao);
    }



    function returnDescricaoInstalacao(instalacao) {
        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }

    async function salvar() {


        if (!instalacaoSelecionada) {
            alert("Selecione a Instalação");
            return;
        }

        try {

            const cadastro = {
                usuario_criador: { id_usuario: Cookies.get("id_usuario") },
                instalacao: {id_instalacao: instalacaoSelecionada.id_instalacao},
                usuario_executor: { id_usuario: Cookies.get("id_usuario") },

            }
            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }
            const response = await api.post('/v1/protected/rondavirtual/cadastrar', cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Ronda Virtual Cadastrada!");
                history.push({
                    pathname: "/gestao/operacional/rondavirtual/gerenciar/" + cadastro_salvo.id_ronda_virtual,
                })
            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }



    useEffect(() => {
        listarInformacoesIniciais();


    }, []);

    return (
        <div>
            <NavegadorGestao
                Operacional={true}
                RondaVirtual={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            {!loading &&

                <Grid container spacing={2}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid
                        style={{ paddingTop: 5 }}
                        container
                        direction="row"
                        item xs={12}
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >



                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            item xs={6}
                        >
                            <Button style={{ margin: 5 }}
                                variant="contained"
                                color="primary"
                                onClick={salvar}
                            > Salvar  </Button>
                        </Grid>



                        <Grid
                            container
                            direction="row"
                            item xs={12}
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                        >



                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={5}
                                style={{ margin: 5 }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                >

                                    <Autocomplete
                                        id="select-instalacao"
                                        options={instalacoes}
                                        disableCloseOnSelect
                                        getOptionLabel={(instalacao) => returnDescricaoInstalacao(instalacao).replace(/<br \/>/g, '\n')}
                                        value={instalacaoSelecionada}
                                        onChange={(e, v) => handleSetInstalacao(v)}
                                        filterOptions={(options, { inputValue }) => {
                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                            return options.filter((cotacao) => {
                                                const cotacaoText = returnDescricaoInstalacao(cotacao).toLowerCase();
                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label=" Instalação " multiline minRows={3} />
                                        )}
                                        renderOption={(option) => (
                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoInstalacao(option) }} />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            }
        </div >
    );
}


