import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@mui/material';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Ícone para "finalizar"

import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import TextField from '@material-ui/core/TextField';
import RefreshIcon from '@mui/icons-material/Refresh';
import Cookies from 'js-cookie';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useParams } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import { styled } from '@material-ui/styles';
import { Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckIcon from '@mui/icons-material/Check';
import { useDropzone } from 'react-dropzone';

import api from '../../../../../services/api';
import MenuRecursosAdministrativos from '../components/menu';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { AddToQueueOutlined, LaptopWindows } from '@material-ui/icons';

import GerenciarTratamentoEmCampo from './gerenciarTratamentoEmCampo';

const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'whit',
        borderColor: 'red',
    },
}));


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },

    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));



const ColorButtonGreen = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'green',
        color: 'whit',
        borderColor: 'blue',
    },
}));




const dropzoneStyles = {
    border: '4px dashed #000000',
    borderRadius: '4px',
    padding: '2px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '2px 0',
};



export default function EditorOrdemServico({ idOrdemServico: idOrdemServicoProp, enterEstoque: enterEstoqueProp }) {


    const classes = useStyles();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [ordemServico, setOrdemServico] = useState();
    // const { idOrdemServico } = useParams();
    const { idOrdemServico: idOrdemServicoParams } = useParams(); // Se estiver utilizando URL params


    const [categoria, setCategoria] = useState(0);
    const [projetoSelecionado, setProjetoSelecionado] = useState(null);
    const [propostaSelecionada, setPropostaSelecionada] = useState(null);



    const onDropArquivo1 = useCallback(async (acceptedFiles) => {
        // Valida o tipo de arquivo
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            alert('Apenas arquivos Excel são permitidos.');
            return;
        }

        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            const formData = new FormData();
            formData.append('arquivos', acceptedFiles[0]);
            formData.append('extensao', acceptedFiles[0].name.split('.').pop());


            await api.post("/v1/protected/ordemservico/listarequerimentos/upload/" + idOrdemServico, formData, {
                headers: headers
            }).then(function (response) {

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {


                    setOrdemServico(response.data.ordem);
                    const sortedItensPreRequerimento = response.data.ordem.itens_pre_requerimento.sort((a, b) => a.id_item_na_lista - b.id_item_na_lista);

                    // Atualiza o estado com a ordem de serviço e os itens ordenados
                    setOrdemServico({ ...response.data.ordem, itens_pre_requerimento: sortedItensPreRequerimento });


                    api.get("v1/protected/almoxarifado/estoque/listarporinsumo", {
                        headers: headers
                    }).then(function (response) {
                        setInsumos(response.data)
                    });

                    alert(cadastro_salvo.mensagem);

                } else {
                    // Lógica a ser executada caso ocorra algum erro durante o salvamento
                    alert("Erro ao processar, insira os itens manualmente!");
                }


            });






        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
        }
    }, []);


    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        //accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });

    const idOrdemServico = idOrdemServicoProp || idOrdemServicoParams;

    const { idEntradaEstoque } = useParams();


    const [entradaEstoque, setEntradaEstoque] = useState(null);

    const [solicitante, setSolicitante] = useState(null);
    const [solicitantesSelecionados, setSolicitantesSelecionados] = useState([]);

    const [analista, setAnalista] = useState(null);

    const [instalacaoSelecionada, setInstalacaoSelecionada] = useState(null);
    const [descricao, setDescricao] = useState('');
    const [solicitacaoSelecionada, setSolicitacaoSelecionada] = useState(null);


    const [observacoesItem, setObservacoesItem] = useState('');
    const [descricaoNovoInsumo, setDescricaoNovoInsumo] = useState('');


    const [usuarioSelecionadoTratarRemoto, setUsuarioSelecionadoTratarRemoto] = useState(null);
    const [usuarioSelecionadoTratarEmCampo, setUsuarioSelecionadoTratarEmCampo] = useState(null);

    function returnDescricaoInsumo(insumo_) {

        let insumo = insumo_.insumo;
        let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao;

        if (insumo.variacoes && insumo.variacoes.length > 0) {
            textoComQuebraDeLinha += insumo.variacoes

                .map((variacao, index) => (
                    `<br />*Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                )).join('');
        }

        var texto =

            //"<br />Marca/Modelo: " + insumo.marca + "/" + insumo.modelo +
            // "<br />Código Fornecedor: " + insumo.codigo_fornecedor +
            //"<br />Tipo: " + insumo.tipo +
            //"<br />Categoria: " + insumo.categoria +
            textoComQuebraDeLinha + "<br />Estoque: " + (Array.isArray(insumo_.itens_estoque) ? insumo_.itens_estoque.length : 0) + " unidades";
        return texto;
    }





    function returnDescricaoInsumoSimplificada(insumo) {


        var texto =


            "Descrição: " + insumo.descricao;
        return texto;
    }



    const [insumos, setInsumos] = useState([]);
    const [insumoSelecionado, setInsumoSelecionado] = useState(null);
    const [quantidade, setQuantidade] = useState(0);

    function handleSetInsumo(insumo) {
        setInsumoSelecionado(insumo);
    }


    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/ordemservico/listarporid/" + idOrdemServico, {
                headers: headers
            }).then(function (response) {
                setOrdemServico(response.data);

                try {
                    const sortedItensPreRequerimento = response.data.itens_pre_requerimento.sort((a, b) => a.id_item_na_lista - b.id_item_na_lista);

                    // Atualiza o estado com a ordem de serviço e os itens ordenados
                    setOrdemServico({ ...response.data, itens_pre_requerimento: sortedItensPreRequerimento });
                } catch (_err) {

                }


                setDescricao(response.data.descricao);
                setSolicitante(response.data.usuario_solicitante);
                setSolicitacaoSelecionada(response.data.solicitacao);

                setCategoria(response.data.categoria_ordem);
                console.log("Categoria: " + response.data.categoria_ordem);
                setPropostaSelecionada(response.data.proposta);
                setProjetoSelecionado(response.data.projeto);

                setAnalista(response.data.analista);

                setSolicitantesSelecionados(response.data.solicitantes);
                setInstalacaoSelecionada(response.data.instalacao);


                /*
                                api.get("v1/protected/almoxarifado/insumos/listar", {
                                    headers: headers
                                }).then(function (response) {
                                    setInsumos(response.data)
                
                                });
                   */

                api.get("v1/protected/almoxarifado/estoque/listarporinsumo", {
                    headers: headers
                }).then(function (response) {
                    setInsumos(response.data)

                    console.log(" Objetos: " + response);
                    setLoading(false);


                });




                if (response.data.solicitacao.id_solicitacao === 5) {
                    //solicitacao de entrada de estoque, checklist
                    api.get("v1/protected/almoxarifado/entradaestoque/listarporos/" + response.data.id_ordem_servico, {
                        headers: headers
                    }).then(function (response) {

                        setEntradaEstoque(response.data)

                        console.log(" Objetos: " + response);
                        setLoading(false);


                    });
                } else {
                    setLoading(false);

                }


            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    useEffect(() => {
        listarInformacoes();


    }, []);




    function novoProcedimento(etapaProcedimento) {
        window.location.href = "/gestao/administracao/ordensdeservicos/cadastrarprocedimentoavulso/" + idOrdemServico + "/" + etapaProcedimento + "/0";

    }




    const handleClickAtualizarProcedimento = (procedimento) => {


        window.location.href = "/gestao/administracao/ordensdeservicos/atualizarprocedimentoexecucao/" + procedimento.id_procedimento_execucao + "/ordemservico/" + ordemServico.id_ordem_servico + "/2/" + idEntradaEstoque;



    };


    const handleClickVizualizarProcedimento = (id) => {
        window.open(`/gestao/administracao/ordensdeservicos/vizualizarprocedimentoexecucao/${id}/ordemservico/${ordemServico.id_ordem_servico}`, '_blank');
    };


    const handleClickVizualizarProcedimentoTratamentoRemoto = (id) => {
        window.open(`/gestao/administracao/ordensdeservicos/vizualizarprocedimentotratamentoremoto/${id}/ordemservico/${ordemServico.id_ordem_servico}`, '_blank');
    };



    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {

        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    function returnDescricaoInstalacao(instalacao) {
        var texto = "SIGMA: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }


    function returnDescricaoSolicitacao(solicitacao) {
        var texto = "Solicitação: " + solicitacao.solicitacao + "\n Descrição: " + solicitacao.descricao;
        return texto;
    }

    function returnDescricaoCategoria(categoria) {
        var texto = '';
        if (categoria === 0) {
            texto = "Projeto 🗂️";
        }
        else if (categoria === 1) {
            texto = "Pesquisa & Desenvolvimento 💻";
        }
        else if (categoria === 2) {
            texto = "Serviços e Manutenção 🛠️";
        }
        else if (categoria === 1) {
            texto = "Renovar Estoque 🛒";
        }
        return texto;
    }





    function handleSolucaoTratamentoRemoto(event) {
        setSolucaoTratamentoRemoto(event.target.value);
    }


    function returnDescricaoProjeto(projeto) {
        var texto = projeto.id_projeto + " - " + projeto.descricao_projeto + "\n";//+ returnDescricaoInstalacao(projeto.instalacao);
        return texto;
    }


    function returnDescricaoProposta(proposta) {
        var texto = proposta.id_proposta + " - " + proposta.descricao_proposta;
        return texto;
    }


    function returnDescricaoProjetoPeD(projeto) {
        var texto = projeto.id_projeto + " - " + projeto.descricao_projeto;
        return texto;
    }


    const [open, setOpen] = useState(false);
    const [openTratamentoEmCampo, setOpenTratamentoEmCampo] = useState(false);

    const [descricaoAnalise, setDescricaoAnalise] = useState("");

    const [openTratamentoRemoto, setOpenTratamentoRemoto] = useState(false);
    const [descricaoTratamentoRemoto, setDescricaoTratamentoRemoto] = useState("");
    const [solucaoTratamentoRemoto, setSolucaoTratamentoRemoto] = useState(0);

    const [descricaoTratamentoEmCampo, setDescricaoTratamentoEmCampo] = useState("");

    const handleOpen = (event, cellValues) => {

        setOpen(true);

    }


    const handleClose = () => {
        setOpen(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo
    };



    const verificarObrigatorioRemotoStatus = (ordemServico) => {
        return ordemServico.procedimentos_tratamento_remoto.some((procedimento_tratamento_remoto) => {
            return procedimento_tratamento_remoto.procedimento.obrigatorio === 1 && procedimento_tratamento_remoto.status === 0;
        });
    };

    async function handleOpenTratamentoRemoto() {


        const result = verificarObrigatorioRemotoStatus(ordemServico);
        if (result) {
            alert("Existem Procedimentos Obrigatórios Pendentes, atualize-os!")
        } else {

            try {

                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }

                await api.get("/v1/protected/user/listartodos", {
                    headers: headers
                }).then(function (response) {
                    setUsuarios(response.data)
                    setOpenTratamentoRemoto(true);

                });
            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar Usuarios: " + _err)

            }
        }



    }


    const handleCloseTratamentoRemoto = () => {
        setOpenTratamentoRemoto(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo
    };




    const handleCloseTratamentoEmCampo = () => {
        setOpenTratamentoEmCampo(false); // assumindo que você tem um estado chamado 'open' para controlar a abertura do diálogo
    };

    const verificarObrigatorioStatus = (ordemServico) => {
        return ordemServico.procedimentos_analise.some((procedimento_analise) => {
            return procedimento_analise.procedimento.obrigatorio === 1 && procedimento_analise.status === 0;
        });
    };

    const handleGerenciarTratamentoEmCampo = () => {
        window.location.href = `/gestao/administracao/ordensdeservicos/gerenciartratamentoemcampo/${ordemServico.id_ordem_servico}`;
    };





    async function handleFinalizar() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {
                descricao_analise: descricaoAnalise,
                analista_tratamento_remoto: { id_usuario: parseInt(Cookies.get("id_usuario")) },
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/ordemservico/finalizarAnalise/' + idOrdemServico + "/" + parseInt(Cookies.get("id_usuario")), cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("A Ordem de Serviço foi atualizada!");
                setOpen(false);
                window.location.reload(true);

            }



        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }


    const resultadoTratamentoRemoto = () => {
        const status_ordem = ordemServico.status_ordem;
        const solucionado = ordemServico.solucionado_tratamento_remoto;

        let texto_status = "";
        let cor_fundo = 'white';

        if (status_ordem >= 2) {
            //tratamento remoto avançado
            if (solucionado === 1) {
                texto_status = "Status: ✅ Tratamento Remoto Finalizado Solucionando a Solicitação";
                cor_fundo = '#d4f8d4';
            } else {
                texto_status = "Status: ❌ Tratamento Remoto Finalizado | Necessário Tratamento Em Campo";
                cor_fundo = '#F08080';

            }
        } else if (status_ordem === 1) {
            texto_status = "Status: ⏳ Tratamento Remoto em andamento";
            cor_fundo = '#ADD8E6';

        }



        return (
            <Typography variant="h5"
                style={{
                    border: '1px solid #a2d5a2', // Verde um pouco mais escuro que o fundo
                    backgroundColor: cor_fundo, // Fundo verde claro
                    borderRadius: 5, // Bordas arredondadas (opcional)
                }}
            >
                {texto_status}
                {status_ordem >= 2 && ` às ${moment(ordemServico.data_hora_finalizacao_tratamento_remoto, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}`}
            </Typography>
        )
    }



    const resultadoTratamentoEmCampo = () => {
        const status_ordem = ordemServico.status_ordem;
        const solucionado = ordemServico.solucionado_tratamento_remoto;

        let texto_status = "";

        if (status_ordem >= 3 && status_ordem !== 9) {
            texto_status = "Status: Em Tratamento de Campo";
        } else if (status_ordem > 3) {
            //tratamento remoto avançado
            if (solucionado === 1) {
                texto_status = "Status: Tratamento Remoto Finalizado Solucionando a Solicitação";

            } else {
                texto_status = "Status: Tratamento Remoto Finalizado | Necessário Tratamento Em Campo";

            }
        }



        return (

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                item xs={12}
                style={{ padding: 5 }}
            >

                <Typography variant="h7">
                    {texto_status} <br />
                    {ordemServico.data_hora_finalizacao_tratamento_em_campo !== null ? `às ${moment(ordemServico.data_hora_finalizacao_tratamento_em_campo, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}` : null}
                </Typography>

            </Grid>
        )
    }


    async function handleFinalizarTratamentoRemoto() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {
                descricao_tratamento_remoto: descricaoTratamentoRemoto,
                solucionado_tratamento_remoto: solucaoTratamentoRemoto,
                analista_tratamento_em_campo: { id_usuario: parseInt(Cookies.get("id_usuario")) },
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/ordemservico/finalizartratamentoremotoirparaanaliseprevia/' + idOrdemServico + "/" + parseInt(Cookies.get("id_usuario")), cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("A Ordem de Serviço foi atualizada!");
                setOpen(false);
                window.location.reload(true);

            }



        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }



    function returnDescricaoSolicitante(usuario) {
        try {
            var texto = "ID_Usuario: " + usuario.id_usuario + "\n Nome: " + usuario.nome_amigavel;
            return texto;
        } catch (_err) {
            console.log("erro ao retornar descrição do solicitante: " + _err);
            return "Dado invalido";
        }

    }





    async function adicionarItem() {

        if (!insumoSelecionado) {
            if (descricaoNovoInsumo.length <= 0) {
                alert("Selecione o Insumo ou Insira a descrição para um novo Insumo!");
                return;
            } else {

            }

        }

        if (!quantidade || parseInt(quantidade) <= 0) {
            alert("Informe a quantidade!");
            return;
        }

        try {

            console.log("cadastrar chamado");
            const cadastro = {
                observacoes: observacoesItem,
                quantidade: parseInt(quantidade),
                insumo: insumoSelecionado !== null ? { id_insumo: insumoSelecionado.insumo.id_insumo } : null,
                descricao: descricaoNovoInsumo,

            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const response = await api.put("/v1/protected/ordemservico/editarrequerimento/" + idOrdemServico + "/" + id_usuario_logado + "/inseriritem", cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Adicionado!");
                listarInformacoes();

                setInsumoSelecionado(null);
                setQuantidade(0);
                setObservacoesItem('');
                setDescricao('');

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }




    }






    async function finalizarRequerimento() {


        const confirmacao = window.confirm("Prosseguir com finalização do Requerimento de Itens?");


        if (confirmacao) {
            try {

                console.log("cadastrar chamado");

                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


                const response = await api.put("/v1/protected/ordemservico/finalizarrequerimento/" + idOrdemServico + "/" + id_usuario_logado,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {
                    alert("Requerimento de Itens Finalizado!");
                    window.location.reload();


                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }


            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");

            }

        }


    }


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };


    const [usuarios, setUsuarios] = useState([]);

    async function finalizarAnalise() {

        const result = verificarObrigatorioStatus(ordemServico);
        if (result) {
            alert("Existem Procedimentos Obrigatórios Pendentes, atualize-os!")
        } else {

            try {

                const token = Cookies.get('token');

                const headers = {
                    'Authorization': 'Bearer ' + token
                }

                await api.get("/v1/protected/user/listartodos", {
                    headers: headers
                }).then(function (response) {
                    setUsuarios(response.data)
                    handleOpen();

                });
            } catch (_err) {
                // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
                console.log("Erro ao listar Usuarios: " + _err)

            }
        }
    }



    function returnDescricaoUsuario(usuario) {

        try {
            var texto = "ID_Usuario: " + usuario.id_usuario + "\nNome: " + usuario.nome_amigavel + "\nRegiões de Atuação: " + usuario.regiao_cobertura;

            var dados_colaborador = "";

            if (usuario.tipo_associacao === 0) {
                //colaborador
                dados_colaborador += ("\nDepartamentos: " + usuario.colaborador.departamentos.map(departamento => departamento.nome).join(", "));
                dados_colaborador += ("\nCargos: " + usuario.colaborador.cargos.map(cargo => cargo.nome).join(", "));
                dados_colaborador += ("\nFunções: " + usuario.colaborador.funcoes.map(funcao => funcao.nome).join(", "));


            }

            texto += ("\n" + dados_colaborador);


            return texto;
        } catch (_err) {
            console.log("erro returnDescricaoUsuario " + _err);
            return "Dado invalido";
        }


    }



    async function handleClickRemocao(event, cellValues) {



        try {

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const response = await api.delete("/v1/protected/ordemservico/editarrequerimento/" + idOrdemServico + "/" + id_usuario_logado + "/removeritem/" + cellValues.row.id_item_requerimento,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Removido!");
                listarInformacoes();


            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }


    };



    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {

                return (
                    <ColorButtonRed variant="contained" color="primary"
                        onClick={(event) => handleClickRemocao(event, cellValues)}

                    >
                        Remover
                    </ColorButtonRed>
                )
            },
        },
        {
            headerName: 'Item',
            field: 'id_item_na_lista',
            id: 2,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Insumo',
            field: 'insumo',
            id: 3,
            width: 700,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const insumo = params.row.insumo;
                let descricao = returnDescricaoInsumoSimplificada(insumo);
                descricao = descricao.replace(/<br\s*\/?>/gi, '\n');
                return <div style={{ whiteSpace: 'pre-line' }}>{descricao}</div>;
            },
        },


        {
            headerName: 'Quantidade',
            field: 'quantidade',
            id: 4,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Observações',
            field: 'observacoes',
            id: 5,
            width: 600,
            headerClassName: 'cabecalho_azul',
        },

    ];



    return (
        <div>
            <div style={{ paddingTop: 5 }} >
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <React.Fragment >

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                        >


                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={12}
                                style={{ width: '100%', margin: 0, padding: 0 }}
                            >

                                <a href="/gestao/administracao/ordensdeservicos/listar" style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"< Ordens de Serviços - "}</a>
                                <a href={"/gestao/administracao/ordensdeservicos/editar/" + idOrdemServico} style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"Ordem de Servico Nº " + idOrdemServico}</a>

                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >

                                        {
                                            ordemServico.solicitacao.id_solicitacao === 7 ?
                                                <Tabs
                                                    value={value}
                                                    onChange={handleChange}
                                                    indicatorColor="secondary"
                                                    textColor="inherit"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    aria-label="scrollable auto tabs example"
                                                    style={{ width: '100%', margin: 0, padding: 0 }}
                                                >
                                                    <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                                    <Tab label="Requerimento de Insumos" style={{ backgroundColor: '#1C1C1C' }} />


                                                </Tabs>
                                                :
                                                enterEstoqueProp ?
                                                    <Tabs
                                                        value={value}
                                                        onChange={handleChange}
                                                        indicatorColor="secondary"
                                                        textColor="inherit"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        aria-label="scrollable auto tabs example"


                                                    >
                                                        <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                                        <Tab label="Execução de CheckList" style={{ backgroundColor: '#1C1C1C' }} />
                                                    </Tabs>
                                                    :
                                                    <Tabs
                                                        value={value}
                                                        onChange={handleChange}
                                                        indicatorColor="secondary"
                                                        textColor="inherit"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        aria-label="scrollable auto tabs example"


                                                    >
                                                        <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />
                                                        <Tab label="Tratar Solicitação Remotamente" style={{ backgroundColor: '#1C1C1C' }} />
                                                        <Tab label="Análise Prévia" style={{ backgroundColor: '#1C1C1C' }} />
                                                        <Tab label="Tratar Solicitação com Equipe de Campo" style={{ backgroundColor: '#1C1C1C' }} />
                                                    </Tabs>


                                        }

                                    </AppBar>

                                </Box>
                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                {
                                    ordemServico.solicitacao.id_solicitacao === 7
                                        ?
                                        <SwipeableViews
                                            index={value}
                                            onChangeIndex={handleChangeIndex}
                                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                        >
                                            <Grid value={value} index={0}
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                            >

                                                <Grid
                                                    style={{ paddingTop: 30 }}
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    spacing={2}
                                                >



                                                    {projetoSelecionado &&
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={6}
                                                            style={{ marginTop: 20 }}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={12}
                                                            >

                                                                <TextField
                                                                    variant="standard"
                                                                    name="projeto"
                                                                    label="Projeto"
                                                                    id="projeto"
                                                                    value={returnDescricaoProjeto(projetoSelecionado)}
                                                                    fullWidth
                                                                    multiline
                                                                    minRows={3}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }


                                                    {propostaSelecionada &&
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={6}
                                                            style={{ marginTop: 20 }}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={12}
                                                            >

                                                                <TextField
                                                                    variant="standard"
                                                                    name="proposta"
                                                                    label="Proposta"
                                                                    id="proposta"
                                                                    value={returnDescricaoProposta(propostaSelecionada)}
                                                                    fullWidth
                                                                    multiline
                                                                    minRows={3}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }


                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={12}
                                                        style={{ marginTop: 20 }}
                                                    >
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={6}
                                                        >

                                                            <TextField
                                                                variant="standard"
                                                                name="solicitacao"
                                                                label="Solicitação"
                                                                id="solicitacao"
                                                                value={returnDescricaoSolicitacao(solicitacaoSelecionada)}
                                                                fullWidth
                                                                multiline
                                                                minRows={1}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    {solicitante &&

                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={12}
                                                            style={{ marginTop: 20 }}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={5}
                                                            >

                                                                <Autocomplete
                                                                    id="select-ordem-solicitante"
                                                                    options={[]}
                                                                    disableCloseOnSelect
                                                                    getOptionLabel={(sol) => returnDescricaoSolicitante(sol).replace(/<br \/>/g, '\n')}
                                                                    value={solicitante}
                                                                    filterOptions={(options, { inputValue }) => {
                                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                        return options.filter((solicitante) => {
                                                                            const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                        });
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} label=" Usuário Solicitador " multiline rows={3} />
                                                                    )}
                                                                    renderOption={(option) => (
                                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                                                    )}

                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }


                                                    {solicitantesSelecionados && (

                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={6}
                                                            style={{ margin: 5 }}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={12}
                                                            >

                                                                <Autocomplete
                                                                    id="select-procedimentos"
                                                                    options={[solicitantesSelecionados]}
                                                                    disableCloseOnSelect
                                                                    multiple
                                                                    getOptionLabel={(solicitante) => returnDescricaoSolicitante(solicitante).replace(/<br \/>/g, '\n')}
                                                                    value={solicitantesSelecionados}
                                                                    getOptionSelected={(option, value) => true}

                                                                    filterOptions={(options, { inputValue }) => {
                                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                        return options.filter((solicitante) => {
                                                                            const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                        });
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} label=" Solicitantes " multiline minRows={3} />
                                                                    )}
                                                                    renderOption={(option) => (
                                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                                                    )}

                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                    }


                                                    {analista &&

                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={12}
                                                            style={{ marginTop: 20 }}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={5}
                                                            >

                                                                <Autocomplete
                                                                    id="select-ordem-analista"
                                                                    options={[]}
                                                                    disableCloseOnSelect
                                                                    getOptionLabel={(sol) => returnDescricaoSolicitante(sol).replace(/<br \/>/g, '\n')}
                                                                    value={analista}
                                                                    filterOptions={(options, { inputValue }) => {
                                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                        return options.filter((solicitante) => {
                                                                            const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                        });
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} label=" Usuário Analista " multiline rows={3} />
                                                                    )}
                                                                    renderOption={(option) => (
                                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                                                    )}

                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }





                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricao"
                                                            label="Descrição"
                                                            id="descricao"
                                                            value={ordemServico.descricao_solicitacao}
                                                            // onChange={(e) => setDescricao(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={3}
                                                        />
                                                    </Grid>




                                                </Grid>

                                            </Grid>


                                            <Grid value={value} index={1}
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                            >




                                                {ordemServico.finalizar_requerimento_itens === 0 &&
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        item xs={6}
                                                    >
                                                        <div {...getRootArquivoProps1()} style={dropzoneStyles}>
                                                            <input {...getInputArquivoProps1()} />
                                                            <Typography align="center" variant="h7">
                                                                Anexo
                                                            </Typography>
                                                        </div>


                                                    </Grid>
                                                }


                                                {ordemServico.finalizar_requerimento_itens === 0 &&
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-end"
                                                        alignItems="flex-end"
                                                        item xs={6}
                                                    >


                                                        <Button
                                                            variant='outlined'
                                                            style={{ paddingTop: 2 }}
                                                            color="primary"
                                                            onClick={(ev) => finalizarRequerimento()}
                                                            endIcon={<CheckIcon />}
                                                        >
                                                            Finalizar
                                                        </Button>
                                                    </Grid>
                                                }


                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={12}
                                                    style={{ padding: 20 }}
                                                >
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={8}
                                                    >

                                                        <Autocomplete
                                                            id="select-insumo"
                                                            options={insumos}
                                                            disableCloseOnSelect
                                                            getOptionLabel={(insumo) => returnDescricaoInsumo(insumo).replace(/<br \/>/g, '\n')}
                                                            value={insumoSelecionado}
                                                            getOptionSelected={(option, value) => true}
                                                            onChange={(e, v) => setInsumoSelecionado(v)}
                                                            filterOptions={(options, { inputValue }) => {
                                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                return options.filter((insumo) => {
                                                                    const insumoText = returnDescricaoInsumo(insumo).toLowerCase();
                                                                    return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                                });
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label=" Insumo " multiline minRows={2} />
                                                            )}
                                                            renderOption={(option) => (
                                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoInsumo(option) }} />
                                                            )}
                                                        />
                                                    </Grid>


                                                    <Grid
                                                        container
                                                        item xs={2}
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            name="quantidade"
                                                            label="Quantidade"
                                                            id="quantidade"
                                                            value={quantidade}
                                                            onChange={(e) => setQuantidade(e.target.value)}
                                                            fullWidth
                                                        />
                                                    </Grid>


                                                    <Grid
                                                        container
                                                        item xs={12}
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        style={{ paddingTop: 10 }}
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            name="observacoesItem"
                                                            label="Observações"
                                                            id="observacoesItem"
                                                            value={observacoesItem}
                                                            onChange={(e) => setObservacoesItem(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            minRows={2}
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        item xs={12}
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        style={{ paddingTop: 10 }}
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            name="descricaoNovoInsumo"
                                                            label="Descrição para Novo Insumo"
                                                            id="descricaoNovoInsumo"
                                                            value={descricaoNovoInsumo}
                                                            onChange={(e) => setDescricaoNovoInsumo(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            minRows={2}
                                                        />
                                                    </Grid>

                                                    {ordemServico.finalizar_requerimento_itens === 0 &&

                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            item xs={2}
                                                        >
                                                            <Button style={{ marginTop: 5 }}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={(ev) => adicionarItem()}
                                                            > Adicionar Item  </Button>
                                                        </Grid>
                                                    }




                                                </Grid>







                                                <Grid
                                                    style={{ paddingTop: 5 }}
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    {
                                                        ordemServico.itens_pre_requerimento &&
                                                        <>
                                                            <DataGrid
                                                                pagination
                                                                checkboxSelection
                                                                rows={ordemServico.itens_pre_requerimento}
                                                                getRowId={(row) => row.id_item_requerimento}
                                                                columns={columnsDataGrid}
                                                                disableColumnResize={false}
                                                                rowHeight={40}
                                                                onCellClick={handleCellClick}
                                                                onRowClick={handleRowClick}
                                                                components={{
                                                                    Toolbar: CustomToolbar,
                                                                }}
                                                            />

                                                        </>
                                                    }



                                                </Grid>


                                            </Grid>



                                        </SwipeableViews>
                                        :
                                        <SwipeableViews
                                            index={value}
                                            onChangeIndex={handleChangeIndex}
                                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                        >
                                            <Grid value={value} index={0}
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                            >

                                                <Grid
                                                    style={{ paddingTop: 30 }}
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    spacing={2}
                                                >

                                                    <Grid item xs={12}
                                                        container
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                variant="standard"
                                                                name="categoria"
                                                                label="Categoria"
                                                                id="categoria"
                                                                value={returnDescricaoCategoria(categoria)}
                                                                fullWidth
                                                                multiline
                                                                minRows={1}
                                                                InputProps={{
                                                                    classes: {
                                                                        underline: 'custom-underline', // Aplica uma classe personalizada à borda
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    {categoria === 0 &&
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={12}
                                                            style={{ marginTop: 5 }}
                                                            spacing={2}
                                                        >

                                                            { projetoSelecionado && (
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-end"
                                                                align="flex-end"
                                                                item xs={6}
                                                                style={{ marginTop: 5 }}
                                                            >
                                                                <Grid
                                                                    container
                                                                    direction="column"
                                                                    justifyContent="center"
                                                                    align="center"
                                                                    item xs={8}
                                                                >

                                                                    <TextField
                                                                        variant="standard"
                                                                        name="projeto"
                                                                        label="Projeto"
                                                                        id="projeto"
                                                                        value={returnDescricaoProjeto(projetoSelecionado)}
                                                                        fullWidth
                                                                        multiline
                                                                        minRows={1}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            )}




                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                align="flex-start"
                                                                item xs={6}
                                                                style={{ marginTop: 5 }}
                                                            >
                                                                {
                                                                    projetoSelecionado &&
                                                                    <Grid
                                                                        container
                                                                        direction="column"
                                                                        justifyContent="center"
                                                                        align="center"
                                                                        item xs={8}
                                                                    >
                                                                        <TextField
                                                                            variant="standard"
                                                                            name="projeto"
                                                                            label="Projeto"
                                                                            id="projeto"
                                                                            value={returnDescricaoProposta(propostaSelecionada)}
                                                                            fullWidth
                                                                            multiline
                                                                            minRows={2}
                                                                        />

                                                                    </Grid>
                                                                }
                                                            </Grid>



                                                        </Grid>
                                                    }

                                                    {categoria === 1 &&

                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={12}
                                                            style={{ marginTop: 5 }}
                                                            spacing={2}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={6}
                                                                style={{ marginTop: 5 }}
                                                            >
                                                                <Grid
                                                                    container
                                                                    direction="column"
                                                                    justifyContent="center"
                                                                    align="center"
                                                                    item xs={8}
                                                                >

                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }

                                                    {categoria === 2 && instalacaoSelecionada &&
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={12}
                                                            style={{ marginTop: 5 }}
                                                            spacing={2}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={6}
                                                                style={{ marginTop: 5 }}
                                                            >
                                                                <Grid
                                                                    container
                                                                    direction="column"
                                                                    justifyContent="center"
                                                                    align="center"
                                                                    item xs={8}
                                                                >
                                                                    <TextField
                                                                        variant="standard"
                                                                        name="instalacao"
                                                                        label="Instalação"
                                                                        id="instalacao"
                                                                        value={returnDescricaoInstalacao(instalacaoSelecionada)}
                                                                        fullWidth
                                                                        multiline
                                                                        minRows={2}
                                                                    />


                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        align="center"
                                                        item xs={12}
                                                        style={{ marginTop: 20 }}
                                                    >
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={12}
                                                        >

                                                            <TextField
                                                                variant="standard"
                                                                name="solicitacao"
                                                                label="Solicitação"
                                                                id="solicitacao"
                                                                value={returnDescricaoSolicitacao(solicitacaoSelecionada)}
                                                                fullWidth
                                                                multiline
                                                                rows={3}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    {solicitante &&

                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={12}
                                                            style={{ marginTop: 20 }}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={5}
                                                            >

                                                                <Autocomplete
                                                                    id="select-ordem-solicitante"
                                                                    options={[]}
                                                                    disableCloseOnSelect
                                                                    getOptionLabel={(sol) => returnDescricaoSolicitante(sol).replace(/<br \/>/g, '\n')}
                                                                    value={solicitante}
                                                                    filterOptions={(options, { inputValue }) => {
                                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                        return options.filter((solicitante) => {
                                                                            const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                        });
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} label=" Usuário Solicitador " multiline rows={3} />
                                                                    )}
                                                                    renderOption={(option) => (
                                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                                                    )}

                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }


                                                    {analista &&

                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            align="center"
                                                            item xs={12}
                                                            style={{ marginTop: 20 }}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="center"
                                                                align="center"
                                                                item xs={5}
                                                            >

                                                                <Autocomplete
                                                                    id="select-ordem-analista"
                                                                    options={[]}
                                                                    disableCloseOnSelect
                                                                    getOptionLabel={(sol) => returnDescricaoSolicitante(sol).replace(/<br \/>/g, '\n')}
                                                                    value={analista}
                                                                    filterOptions={(options, { inputValue }) => {
                                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                                        return options.filter((solicitante) => {
                                                                            const cotacaoText = returnDescricaoSolicitante(solicitante).toLowerCase();
                                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                                        });
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} label=" Usuário Analista " multiline rows={3} />
                                                                    )}
                                                                    renderOption={(option) => (
                                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoSolicitante(option) }} />
                                                                    )}

                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }





                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="standard"
                                                            name="descricao"
                                                            label="Descrição"
                                                            id="descricao"
                                                            value={ordemServico.descricao_solicitacao}
                                                            // onChange={(e) => setDescricao(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rows={3}
                                                        />
                                                    </Grid>




                                                </Grid>

                                            </Grid>


                                            <Grid value={value} index={1}
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                            >



                                                <Grid
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{ paddingTop: 20, backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                                    spacing={2}
                                                >

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                        item xs={6}
                                                        style={{
                                                            padding: 20,
                                                        }}
                                                    >
                                                        {resultadoTratamentoRemoto()}

                                                    </Grid>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                        item xs={6}
                                                        style={{
                                                            padding: 20
                                                        }}
                                                    > {ordemServico.status_ordem >= 2 &&
                                                        <TextField
                                                            label="Comentário"
                                                            variant="outlined"
                                                            fullWidth
                                                            multiline
                                                            minRows={1}
                                                            value={ordemServico.usuario_analista_finalizou_tratamento_remoto.nome_amigavel + ": " + ordemServico.descricao_tratamento_remoto}


                                                        />
                                                        }
                                                    </Grid>
                                                </Grid>


                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    item xs={12}
                                                    style={{ padding: 6 }}
                                                >
                                                    {ordemServico.status_ordem > 2 &&
                                                        <Typography variant="h7">
                                                            {"Analista para Tratamento em Campo: " + (ordemServico.analista_tratamento_em_campo ? ordemServico.analista_tratamento_em_campo.nome_amigavel : "Não especificado")}
                                                        </Typography>
                                                    }
                                                </Grid>


                                                {
                                                    ordemServico.status_ordem <= 0 ?
                                                        <div style={{ padding: 5 }}>
                                                            <Typography variant='h7'> Design o Analista para execução da O.S </Typography>
                                                        </div>
                                                        :
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-end"
                                                            alignItems="center"
                                                            spacing={2}
                                                            item xs={12}
                                                        >




                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                                item xs={12}
                                                                style={{ margin: 20, borderRadius: '10px', border: '1px solid #000000', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}
                                                            >


                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center"
                                                                    item xs={12}
                                                                    style={{ padding: 5 }}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="flex-start"
                                                                        alignItems="center"
                                                                        item xs={6}
                                                                        style={{ padding: 5 }}
                                                                    >

                                                                        <Typography variant="h5">Procedimentos de Tratamento Remoto</Typography>
                                                                    </Grid>

                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="flex-end"
                                                                        alignItems="center"
                                                                        item xs={6}
                                                                    >
                                                                        {ordemServico.status_ordem === 1 &&
                                                                            <Button

                                                                                variant="contained"
                                                                                style={{
                                                                                    margin: 5,
                                                                                    backgroundColor: '#4caf50', // Cor de fundo personalizada (verde)
                                                                                    color: '#fff',              // Cor do texto personalizada (branco)
                                                                                }}
                                                                                startIcon={<CheckCircleIcon />} // Ícone de finalizar
                                                                                onClick={handleOpenTratamentoRemoto}
                                                                            > Finalizar Tratamento Remoto  </Button>
                                                                        }

                                                                    </Grid>


                                                                </Grid>







                                                                {ordemServico.status_ordem > 0 ?
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="flex-start"
                                                                        alignItems="center"
                                                                        item xs={12}
                                                                    >

                                                                        <Tooltip title="Adicione novos procedimentos que irá executar ou destinar a outros usuários">
                                                                            <Button
                                                                                style={{ margin: 5 }}
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={(e) => novoProcedimento(1)}
                                                                                startIcon={<AddIcon />} // Ícone de "+" (você também pode usar um emoji, como "➕")
                                                                            >
                                                                                Procedimento
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    :
                                                                    <div></div>

                                                                }

                                                                <Grid container spacing={2}
                                                                    item xs={12}
                                                                    style={{ padding: 5 }}
                                                                    rowSpacing={2}>
                                                                    {ordemServico.procedimentos_tratamento_remoto.map((procedimento_analise, index) => (
                                                                        <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                                                                            {/* xs=12 para dispositivos pequenos, sm=6 para médios, md=4 para grandes, lg=3 para extra grandes */}
                                                                            <Card style={{ borderRadius: '10px', border: '1px solid #000000', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}>
                                                                                <CardContent>
                                                                                    <Link
                                                                                        target="_blank"
                                                                                        to={`/gestao/operacional/procedimentos/vizualizar/${procedimento_analise.procedimento.id_procedimento}`}
                                                                                    >
                                                                                        <Typography variant="h6">{procedimento_analise.procedimento.nome}</Typography>
                                                                                    </Link>
                                                                                    <br />  
                                                                                    <Typography variant="h8">{"Status: " + (procedimento_analise.status === 0 ? "Não Executado" : "Concluído")}</Typography>
                                                                                    <br />
                                                                                    {
                                                                                        procedimento_analise.status === 1 ?
                                                                                            <Typography variant="h8">{(procedimento_analise.resultado === 0 ? "Resultado: Falha" : "Resultado: Sucesso")}</Typography>
                                                                                            :
                                                                                            <Typography variant="h8"></Typography>

                                                                                    }
                                                                                    <br />
                                                                                    {
                                                                                        procedimento_analise.status === 1 ?
                                                                                            <Typography variant="h8">
                                                                                                {`Executado por ${procedimento_analise.usuario_executor.nome_amigavel}`} <br />
                                                                                                {`às ${moment(procedimento_analise.data_hora_execucao, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}`}
                                                                                            </Typography> :
                                                                                            <Typography variant="h8"></Typography>

                                                                                    }
                                                                                    <br />
                                                                                    {
                                                                                        procedimento_analise.status === 1 ?
                                                                                            <Typography variant="h8">
                                                                                                {"Resumo: " + procedimento_analise.observacoes} <br />
                                                                                            </Typography> :
                                                                                            <Typography variant="h8"></Typography>

                                                                                    }
                                                                                    <br /> 
                                                                                    <Typography variant="h8">{(procedimento_analise.procedimento.obrigatorio === 1 ? "Procedimento Obrigatório" : "Procedimento Opcional")}</Typography>
                                                                                    <br />  
                                                                                    {
                                                                                        procedimento_analise.status === 0 ?

                                                                                            <Button style={{ fontSize: 12, border: '2px solid #4caf50' }}
                                                                                                variant="outlined"
                                                                                                startIcon={<RefreshIcon />}
                                                                                                onClick={(event) => handleClickAtualizarProcedimento(procedimento_analise)}
                                                                                            >  atualizar
                                                                                            </Button>
                                                                                            :
                                                                                            <ColorButtonGreen variant="contained" color="primary"
                                                                                                onClick={(event) => handleClickVizualizarProcedimento(procedimento_analise.id_procedimento_execucao)}
                                                                                            >
                                                                                                Vizualizar
                                                                                            </ColorButtonGreen>
                                                                                    }

                                                                                </CardContent>
                                                                            </Card>

                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                }


                                            </Grid>

                                            <Grid value={value} index={2}
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                            >

                                                {
                                                    ordemServico.status_ordem <= 1 ?
                                                        <div style={{ padding: 5 }}>
                                                            <Typography variant='h7'> Encerre o Tratamento Remoto </Typography>
                                                        </div>
                                                        :

                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                            item xs={12}
                                                            style={{ padding: 1 }}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                                item xs={12}
                                                                style={{ padding: 6 }}
                                                            >

                                                                <Typography variant="h7">{"Status: " + (ordemServico.status_ordem == 2 ? "Em Ánalise" : ("Ánalise Finalizada ás " + moment(ordemServico.data_hora_finalizacao_analise, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')))}</Typography>
                                                                <br></br>

                                                            </Grid>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                                item xs={12}
                                                                style={{ padding: 6 }}
                                                            >
                                                                {ordemServico.status_ordem > 1000 &&
                                                                    <Typography variant="h7">{"Comentário de " + ordemServico.usuario_analista_finalizou_analise_previa.nome_amigavel + ": " + ordemServico.descricao_analise}</Typography>
                                                                }

                                                            </Grid>

                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                                item xs={12}
                                                                style={{ padding: 6 }}
                                                            >
                                                                {ordemServico.status_ordem >= 2 &&
                                                                    <Typography variant="h7">{"Analista para Tratamento em Campo: " + ordemServico.analista_tratamento_em_campo.nome_amigavel}</Typography>
                                                                }

                                                            </Grid>
                                                            {ordemServico.status_ordem <= 1 ?

                                                                <div></div>
                                                                :
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="flex-end"
                                                                    alignItems="center"
                                                                    item xs={12}
                                                                >
                                                                    <Button style={{ fontSize: 12, marginTop: 2, marginLeft: 30, border: '2px solid #4caf50' }}
                                                                        variant="outlined"
                                                                        startIcon={<DoneAllIcon />}
                                                                        onClick={finalizarAnalise}
                                                                    > Finalizar Ánalise  </Button>
                                                                </Grid>}




                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                                item xs={12}
                                                                style={{ padding: 10 }}
                                                            >

                                                                <Typography variant="h5">Procedimentos de Análise</Typography>


                                                            </Grid>
                                                            {ordemServico.status_ordem === 1 &&
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center"
                                                                    item xs={12}
                                                                >

                                                                    <Button style={{ marginTop: 2, fontSize: 12, marginLeft: 30, border: '2px solid #191970' }}
                                                                        variant='outlined'
                                                                        startIcon={<AddIcon />}
                                                                        onClick={(e) => novoProcedimento(0)}
                                                                    > procedimento </Button>
                                                                </Grid>
                                                            }

                                                            <Grid container spacing={2}
                                                                item xs={12}
                                                                style={{ padding: 30 }}
                                                                rowSpacing={2}>
                                                                {ordemServico.procedimentos_analise?.map((procedimento_analise, index) => (
                                                                    <Grid item xs={4} key={index}>
                                                                        {/* xs=12 para dispositivos pequenos, sm=6 para médios, md=4 para grandes, lg=3 para extra grandes */}
                                                                        <Card style={{ borderRadius: '10px', border: '1px solid #e0e0e0', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}>
                                                                            <CardContent>
                                                                                <Link
                                                                                    target="_blank"
                                                                                    to={`/gestao/operacional/procedimentos/vizualizar/${procedimento_analise.procedimento.id_procedimento}`}
                                                                                >
                                                                                    <Typography variant="h6">{procedimento_analise.procedimento.nome}</Typography>
                                                                                </Link>
                                                                                <br /> <br /> <br />
                                                                                <Typography variant="h8">{"Status: " + (procedimento_analise.status === 0 ? "Não Executado" : "Concluído")}</Typography>
                                                                                <br />
                                                                                {
                                                                                    procedimento_analise.status === 1 ?
                                                                                        <Typography variant="h8">{(procedimento_analise.resultado === 0 ? "Resultado: Falha" : "Resultado: Sucesso")}</Typography>
                                                                                        :
                                                                                        <Typography variant="h8"></Typography>

                                                                                }
                                                                                <br />
                                                                                {
                                                                                    procedimento_analise.status === 1 ?
                                                                                        <Typography variant="h8">
                                                                                            {`Executado por ${procedimento_analise.usuario_executor.nome_amigavel}`} <br />
                                                                                            {`às ${moment(procedimento_analise.data_hora_execucao, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}`}
                                                                                        </Typography> :
                                                                                        <Typography variant="h8"></Typography>

                                                                                }
                                                                                <br /> <br />

                                                                                {
                                                                                    procedimento_analise.status === 1 ?
                                                                                        <Typography variant="h8">
                                                                                            {"Resumo: " + procedimento_analise.observacoes} <br />
                                                                                        </Typography> :
                                                                                        <Typography variant="h8"></Typography>

                                                                                }
                                                                                <br /> <br />
                                                                                <Typography variant="h8">{(procedimento_analise.procedimento.obrigatorio === 1 ? "Procedimento Obrigatório" : "Procedimento Opcional")}</Typography>
                                                                                <br /> <br />
                                                                                {
                                                                                    procedimento_analise.status === 0 ?

                                                                                        <Button style={{ fontSize: 12, border: '2px solid #4caf50' }}
                                                                                            variant="outlined"
                                                                                            startIcon={<RefreshIcon />}
                                                                                            onClick={(event) => handleClickAtualizarProcedimento(procedimento_analise)}
                                                                                        >  atualizar
                                                                                        </Button>
                                                                                        :
                                                                                        <ColorButtonGreen variant="contained" color="primary"
                                                                                            onClick={(event) => handleClickVizualizarProcedimento(procedimento_analise.id_procedimento_execucao)}
                                                                                        >
                                                                                            Vizualizar
                                                                                        </ColorButtonGreen>
                                                                                }

                                                                            </CardContent>
                                                                        </Card>

                                                                    </Grid>
                                                                ))}
                                                            </Grid>

                                                        </Grid>


                                                }
                                            </Grid>

                                            <Grid value={value} index={3}

                                                container
                                                direction="row"
                                                item xs={12}
                                                style={{ backgroundColor: 'white' }}
                                            >

                                                <Grid
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    style={{ padding: 1 }}
                                                >

                                                    {resultadoTratamentoEmCampo()}

                                                </Grid>

                                                {
                                                    ordemServico.status_ordem <= 2 ?
                                                        <div style={{ padding: 5 }}>
                                                            <Typography variant='h7'> Finalize a Ánalise Prévia para então Iniciar o Tratamento em Campo </Typography>
                                                        </div>
                                                        :


                                                        ordemServico.solucionado_tratamento_remoto !== 1 ?
                                                            <GerenciarTratamentoEmCampo
                                                                ordemServico={ordemServico}
                                                            /> : null

                                                }



                                            </Grid>


                                        </SwipeableViews>
                                }
                            </Grid>
                        </Grid>





                    </React.Fragment>
                }


            </div>


            <Dialog open={open} onClose={handleClose} PaperProps={{ style: { maxWidth: '600px', minWidth: '600px', minHeight: '600px' } }}>
                <DialogTitle>Finalizar Ánalise Prévia</DialogTitle>
                <DialogContent>
                    <Grid
                        direction={"row"}
                        item xs={12}
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={3}
                    >

                        <Grid
                            item xs={12}
                            style={{ margin: 5, padding: 5 }}
                        >
                            <TextField
                                label="Descrição"
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={3}
                                value={descricaoAnalise}
                                onChange={(e) => setDescricaoAnalise(e.target.value)}
                            />
                        </Grid>


                        <Grid
                            item xs={12}
                        >


                            <Button style={{ margin: 5, padding: 5 }} variant="contained" color="primary" onClick={handleFinalizar}>
                                Finalizar
                            </Button>
                        </Grid>



                    </Grid>

                </DialogContent>
            </Dialog>



            <Dialog open={openTratamentoRemoto} onClose={handleCloseTratamentoRemoto} PaperProps={{ style: { maxWidth: '600px', minWidth: '300px' } }}>
                <DialogTitle>Finalizar Tratamento Remoto</DialogTitle>
                <DialogContent>
                    <Grid
                        direction={"row"}
                        item xs={12}
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={3}
                    >

                        <Grid
                            item xs={12}
                            style={{ margin: 20, padding: 20 }}
                        >
                            <TextField
                                label="Descrição"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={descricaoTratamentoRemoto}
                                onChange={(e) => setDescricaoTratamentoRemoto(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}
                            container
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="solucao-tratamento-selector-label">O Tratamento Remoto Solucionou a Solicitação?</InputLabel>
                                    <Select
                                        labelId="solucao-tratamento-remoto-label"
                                        id="solucao-tratamento-remoto"
                                        value={solucaoTratamentoRemoto}
                                        onChange={handleSolucaoTratamentoRemoto}
                                        label="O Tratamento Remoto Solucionou a Solicitação?"
                                    >
                                        <MenuItem value={0}>NÃO - Ir para Tratamento em Campo</MenuItem>
                                        <MenuItem value={1}>SIM - Concluir Solicitação</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>


                        <Grid
                            item xs={12}
                        >


                            <Button style={{ margin: 20, padding: 20 }} variant="contained" color="primary" onClick={handleFinalizarTratamentoRemoto}>
                                Finalizar
                            </Button>
                        </Grid>



                    </Grid>

                </DialogContent>
            </Dialog>

        </div >
    );
}


