import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import { Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { Snackbar } from '@material-ui/core';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import api from '../../../../../services/api';

import { Paper, makeStyles } from '@material-ui/core';

import Slider from "react-slick";


const settings = {
    dots: true, // Adiciona indicadores (bolinhas)
    infinite: true, // Carrossel infinito
    speed: 500, // Velocidade da transição
    slidesToShow: 4, // Quantidade de slides visíveis ao mesmo tempo
    slidesToScroll: 1, // Quantidade de slides para rolar por vez
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};


export default function SNARonda(props) {

    const rondaVirtual = props.rondaVirtual;


    useEffect(() => {


    }, [rondaVirtual]);



    return (
        <div>
            {
                rondaVirtual && (


                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <a
                                href={`/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/${rondaVirtual.instalacao.id_instalacao}/0`}
                                style={{ color: 'green', textDecoration: 'none' }} // Remove o sublinhado
                                target="_blank"

                            >
                                <SettingsIcon fontSize="small" /> {/* Ícone de engrenagem */}
                            </a>
                            <span>{rondaVirtual.conta_instalacao}</span> {/* Exibe o texto da conta */}

                        </div>
                        <br></br>
                        <span
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            {"Executor: " + rondaVirtual.usuario_executor.nome_amigavel}
                        </span>
                        <br></br>
                        <span style={{ fontSize: 14, color: 'black', fontWeight: 'bold' }}>
                            {"Data e Hora da conclusão: " + moment(rondaVirtual.data_hora_finalizacao, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY')}
                        </span>
                        <br></br>
                        <span
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            {"Duração: " + rondaVirtual.duracao + " minutos"}
                        </span>
                        <Slider {...settings}>
                            {rondaVirtual.procedimentos.map((procedimento_analise, index) => (
                                <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                                    {/* xs=12 para dispositivos pequenos, sm=6 para médios, md=4 para grandes, lg=3 para extra grandes */}
                                    <Card style={{ borderRadius: '10px', border: '1px solid #000000', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', marginBottom: '16px' }}>
                                        <CardContent>
                                            <Link
                                                target="_blank"
                                                to={`/gestao/operacional/procedimentos/vizualizar/${procedimento_analise.procedimento.id_procedimento}`}
                                            >
                                                <Typography variant="h6">{procedimento_analise.procedimento.nome}</Typography>
                                            </Link>




                                        </CardContent>
                                    </Card>

                                </Grid>
                            ))}
                        </Slider>
                    </div>

                )
            }

        </div>
    )
}


