import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Chip from '@mui/material/Chip';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import { Button } from "@material-ui/core";
import { format, addDays, isValid, parse } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';



import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';






function novoAgendamento() {
    window.location.href = "/gestao/operacional/agendamentos/cadastrar/-1/0/0";

}

export default function Agendamentos() {



    const [loading, setLoading] = useState(true);
    const [agendamentos, setAgendamentos] = useState([]);


    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/agendamentos/listartodos", {
                headers: headers
            }).then(function (response) {
                setAgendamentos(response.data)

                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Agendamentos: " + _err)

        }

    }


    useEffect(() => {




        listarInformacoes();


    }, []);





    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    function returnDescricaoStatusEquipe(status) {
        switch (status) {
            case -1:
                return "Desativado";
            case 0:
                return "Livre";
            case 1:
                return "Agendado - Não Iniciado";
            case 2:
                return "Agendado - Em Deslocamento para Tratamento de O.S em campo";
            case 3:
                return "Agendado - Tratando Equipe em Campo";
            case 4:
                return "Agendado - Retornando de Tratamento de O.S em campo";
            case 5:
                // Adicione mais casos conforme necessário
                return "Outro status";
            default:
                return "Status Desconhecido";
        }
    }



    function returnDescricaoEquipe(equipe) {
        var texto = "ID Equipe: " + equipe.id_equipe +
            "\nStatus: " + (returnDescricaoStatusEquipe(equipe.status_equipe)) +
            "\nNome: " + equipe.nome +
            "\nDescrição: " + equipe.descricao +
            "\nRegião de Atuação: " + equipe.regiao_atuacao;



        return texto;
    }

    function returnDescricaoVeiculo(veiculo) {


        var texto = "ID Veículo: " + veiculo.id_veiculo +
            "\nStatus: " + (returnDescricaoStatusEquipe(veiculo.status_servico_veiculo)) +
            "\nPlaca: " + veiculo.placa +
            "\nMarca|Modelo Ano/Ano: " + veiculo.marca + "|" + veiculo.modelo + " " + veiculo.ano_modelo + "/" + veiculo.ano_veiculo +
            "\nRegião de Atuação: " + veiculo.regiao_atuacao;




        return texto;
    }




    const CustomCell = ({ value }) => (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '8px' }}>
            {value.map((descricao, index) => (

                <Chip key={index} label={descricao} />

            ))}
        </div>
    );

    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {
                return (
                    <Grid>


                        <IconButton
                            color="primary"
                        >
                            <VisibilityIcon />
                        </IconButton>

                    </Grid>

                )
            },
        },
        {
            headerName: 'ID',
            field: 'id_agendamento',
            id: 1,
            width: 30,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Tipo',
            field: 'tipo',
            id: 2,
            width: 300,
            headerClassName: 'cabecalho_azul',
            valueGetter: (params) => {

                try {
                    const tipo = params.row.tipo_agendamento;
                    var text = "";
                    if (tipo === 0)
                        text = "Veículo: " + returnDescricaoVeiculo(params.row.veiculo);
                    else if (tipo === 1)
                        text = "Equipe" + returnDescricaoEquipe(params.row.equipe);
                    return text;
                } catch (_err) {
                    return "Dados invalidos";
                }

            },
        },
        {
            headerName: 'Escopo',
            field: 'escopo',
            id: 3,
            width: 300,
            headerClassName: 'cabecalho_azul',
            valueGetter: (params) => {
                try {
                    const dataHoraInicial = params.row.data_hora_inicial;
                    const dataHoraFinal = params.row.data_hora_final;

                    // Converter para o formato desejado usando date-fns
                    const dataHoraInicialFormatada = format(new Date(dataHoraInicial), "HH:mm dd/MM/yyyy");
                    const dataHoraFinalFormatada = format(new Date(dataHoraFinal), "HH:mm dd/MM/yyyy");

                    return "De " + dataHoraInicialFormatada + " Até " + dataHoraFinalFormatada;
                } catch (_err) {
                    return "Data Invalida";
                }

            },
        },
        {
            headerName: 'Motivo',
            field: 'motivo',
            id: 4,
            width: 400,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Descrição',
            field: 'descricao',
            id: 5,
            width: 600,
            headerClassName: 'cabecalho_azul',
        },


    ];




    return (
        <div>

            <NavegadorGestao
                Operacional={true}
                Agendamentos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />


            <div style={{ padding: 5, width: '100%', height: '100%' }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novoAgendamento}
                    >
                        Novo Agendamento
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div style={{ height: '100%', width: '100%' }}>
                        <>
                            <DataGrid

                                pagination
                                checkboxSelection
                                rows={agendamentos}
                                pageSize={20}
                                rowHeight={40}
                                getRowId={(row) => row.id_agendamento}
                                columns={columnsDataGrid}
                                disableColumnResize={false}
                                onCellClick={handleCellClick}
                                onRowClick={handleRowClick}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />

                        </>
                    </div>
                }


            </div>



        </div>
    );
}



